import {
  AfterViewInit,
  Component,
  Input,
  OnInit,
  ViewChild,
} from '@angular/core';
import { TranslationsLibService } from '../../../services/libraries/translations-lib.service';
import { IrrigationReportDataModel } from '../../reports.model';
import {
  animate,
  state,
  style,
  transition,
  trigger,
} from '@angular/animations';

import { MatPaginator } from '@angular/material/paginator';
import { MatTableDataSource } from '@angular/material/table';
import { MatSort } from '@angular/material/sort';
import { FormControl, FormGroup } from '@angular/forms';
import { MatTableExporterDirective } from 'mat-table-exporter';

@Component({
  selector: 'app-reports-irrigations-table',
  templateUrl: './reports-irrigations-table.component.html',
  styleUrls: ['./reports-irrigations-table.component.scss'],
  animations: [
    trigger('detailExpand', [
      state('collapsed', style({ height: '0px', minHeight: '0' })),
      state('expanded', style({ height: '*' })),
      transition(
        'expanded <=> collapsed',
        animate('225ms cubic-bezier(0.4, 0.0, 0.2, 1)')
      ),
    ]),
  ],
})
export class ReportsIrrigationsTableComponent implements OnInit, AfterViewInit {
  @Input() data: IrrigationReportDataModel[];
  // ESLINT: Keep as number or it will render all rows!
  // eslint-disable-next-line @typescript-eslint/no-inferrable-types
  @Input() tablePageItemNumber: number = 20;
  @Input() count: number;
  @Input() irrigationColumnsToDisplay: string[];
  @Input() fertilizerColumnsToDisplay: string[];
  @Input() reportName = '';
  @ViewChild(MatPaginator) paginator: MatPaginator;
  @ViewChild(MatSort) sort: MatSort;
  @ViewChild(MatTableExporterDirective, { static: false })
  exporter: MatTableExporterDirective;
  expandedElement: IrrigationReportDataModel | null;
  columnsToDisplayWithExpand = [];
  dataSource: MatTableDataSource<IrrigationReportDataModel>;
  ready = false;
  // Filtering
  @Input() filterProgramList: string[] = [];
  filterValues = {
    programName: [],
    timeBegin: '',
    timeEnd: '',
    activationType: '',
  };
  filterForm = new FormGroup({
    programName: new FormControl(),
    timeBegin: new FormControl(),
    timeEnd: new FormControl(),
    activationType: new FormControl(),
  });

  get programName() {
    return this.filterForm.get('programName');
  }
  get timeBegin() {
    return this.filterForm.get('timeBegin');
  }
  get timeEnd() {
    return this.filterForm.get('timeEnd');
  }
  get activationType() {
    return this.filterForm.get('activationType');
  }
  constructor(public translationsLib: TranslationsLibService) {
    this.dataSource = new MatTableDataSource(this.data);
  }
  // ready triggers ngIf directive and must stay in ngOnInit!!!!
  ngOnInit(): void {
    this.columnsToDisplayWithExpand = [
      ...this.irrigationColumnsToDisplay,
      'expand',
    ];
    this.ready = true;
  }
  // Very important to load data on ngAfterViewInit, doing it on ngOnInit freezes the client for several seconds!
  // https://stackoverflow.com/questions/50283659/angular-6-mattable-performance-in-1000-rows
  ngAfterViewInit() {
    this.dataSource = new MatTableDataSource(this.data);
    this.dataSource.paginator = this.paginator;
    this.dataSource.sort = this.sort;
    this.formSubscribe();
    this.getFormsValue();
  }
  formSubscribe() {
    this.programName.valueChanges.subscribe((programNameValue) => {
      this.filterValues.programName = programNameValue;
      this.dataSource.filter = JSON.stringify(this.filterValues);
    });
    this.timeBegin.valueChanges.subscribe((timeBeginValue) => {
      this.filterValues.timeBegin = timeBeginValue;
      this.dataSource.filter = JSON.stringify(this.filterValues);
    });
    this.timeEnd.valueChanges.subscribe((timeEndValue) => {
      this.filterValues.timeEnd = timeEndValue;
      this.dataSource.filter = JSON.stringify(this.filterValues);
    });
    this.activationType.valueChanges.subscribe((activationTypeValue) => {
      this.filterValues.activationType = activationTypeValue;
      this.dataSource.filter = JSON.stringify(this.filterValues);
    });
  }
  getFormsValue() {
    this.dataSource.filterPredicate = (data, filter: string): boolean => {
      const searchString = JSON.parse(filter);
      let isProgramNameAvailable = false;
      if (searchString.programName.length) {
        for (const d of searchString.programName) {
          if (data.programName === d) {
            isProgramNameAvailable = true;
          }
        }
      } else {
        isProgramNameAvailable = true;
      }
      const resultValue =
        isProgramNameAvailable &&
        data.timeBegin
          .toString()
          .trim()
          .toLowerCase()
          .indexOf(searchString.timeBegin.toLowerCase()) !== -1 &&
        data.timeEnd
          .toString()
          .trim()
          .toLowerCase()
          .indexOf(searchString.timeEnd.toLowerCase()) !== -1 &&
        data.activationType
          .toString()
          .trim()
          .toLowerCase()
          .indexOf(searchString.activationType.toLowerCase()) !== -1;

      return resultValue;
    };
    this.dataSource.filter = JSON.stringify(this.filterValues);
  }

  /**
   * Avoid detail table!
   */
  exportToXLSX() {
    this.exporter.resetToggleRows();
    const v_count = this.count * 2;
    for (let i = 0; i <= v_count; i++) {
      if (i % 2 === 0) {
        this.exporter.toggleRow(i);
      }
    }
    this.exporter.exportTable('xlsx', { fileName: this.reportName });
  }
}
