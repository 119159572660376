import { AfterViewInit, Component, OnInit, ViewChild } from '@angular/core';
import { TranslationsLibService } from '../../services/libraries/translations-lib.service';
import { MatTabGroup } from '@angular/material/tabs';
import { HelpersLibService } from '../../services/libraries/helpers-lib.service';

@Component({
  selector: 'app-notifications-tabs',
  templateUrl: './notifications-tabs.component.html',
  styleUrls: ['./notifications-tabs.component.scss'],
})
export class NotificationsTabsComponent implements OnInit, AfterViewInit {
  breadcrumbs: any = [];
  private tabSelected = this.translationsLib.get('notification_invitations');
  @ViewChild('tabGroup', { static: false }) tab: MatTabGroup;
  constructor(
    private helpersLib: HelpersLibService,
    public translationsLib: TranslationsLibService
  ) {}

  ngOnInit() {
    this.breadcrumbs = [
      {
        text: this.translationsLib.get('notification_notifications'),
        disabled: false,
        to: ' ',
        active: true,
      },
    ];
  }

  ngAfterViewInit() {
    this.helpersLib.focusTab(this.tab);
  }
}
