import { Component, OnInit } from '@angular/core';
import { FormBuilder } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { TerminalsService } from '../terminals.service';
import { HelpersLibService } from '../../services/libraries/helpers-lib.service';
import { SessionLibService } from '../../services/libraries/session-lib.service';
import { TranslationsLibService } from '../../services/libraries/translations-lib.service';
import { TerminalsLibService } from '../../services/libraries/terminals-lib.service';
import * as moment from 'moment/moment';
import { PlatformLibService } from '../../services/libraries/platform-lib.service';
import { DataloggerService } from 'src/app/farming/datalogger/datalogger.service';
import Swal from 'sweetalert2';
import { TerminalDataModel } from '../terminals.model';
import { environment } from '../../../environments/environment';
@Component({
  selector: 'app-terminals-edit',
  templateUrl: './terminals-edit.component.html',
  styleUrls: ['./terminals-edit.component.scss'],
})
export class TerminalsEditComponent implements OnInit {
  vid;
  terminalData: TerminalDataModel = null;
  markerCoordinates;
  database;
  isNative;
  fb: FormBuilder = new FormBuilder();
  fillData = this.fb.group({
    terminalName: [''],
    timeZone: [''],
    coordinates: [],
  });
  activationStatus: number;
  interval;
  progress;
  readingTime: any;
  sleepingTime: any;
  transformedReadingTime: any;
  transformedSleepingTime: any;
  formattedDate: string;
  deviceStatus = true;
  allValves = null;
  selectedValveToEdit;
  loading = true;
  constructor(
    private router: Router,
    private route: ActivatedRoute,
    private terminalService: TerminalsService,
    private helpersLib: HelpersLibService,
    private session: SessionLibService,
    public translationsLib: TranslationsLibService,
    private terminalsLib: TerminalsLibService,
    private dataloggerService: DataloggerService,
    private platformLib: PlatformLibService
  ) {
    this.activationStatus = 0;
    this.progress = 0;
  }

  ngOnInit() {
    this.isNative = this.platformLib.isNative();
    this.vid = this.route.snapshot.paramMap.get('vid');
    this.getTerminalInfo();
  }
  getTerminalInfo() {
    this.terminalService.getTerminal(this.vid).subscribe(
      (res) => {
        console.log(res);
        this.terminalData = res;
        if (res.authed_user_can_admin === false) {
          this.terminalsLib.terminalNoRights();
        }
        if (this.terminalData.source_database !== 'mcom') {
          this.selectedValveToEdit = this.terminalData.name;
          this.allValves = [];
        }
        this.markerCoordinates = { lat: res.latitude, lng: res.longitude };
        this.database = res.source_database;
        if (this.database === 'datalogger') {
          this.dataloggerService
            .getDataloggerData(this.vid)
            .subscribe((res) => {
              this.fillData.setValue({
                terminalName: res.name,
                coordinates: res.latitude + ',' + res.longitude,
                timeZone: res.timezone,
              });
              this.formattedDate = this.formatDate(res.firstCommunication);
              this.readingTime = res.datalogger_configuration.readingTime;
              this.sleepingTime = res.datalogger_configuration.sleepingTime;
              this.transformTime('sleeping', this.sleepingTime);
              this.transformTime('reading', this.readingTime);
            });
        } else {
          this.fillData.setValue({
            terminalName: res.name,
            coordinates: res.latitude + ',' + res.longitude,
            timeZone: res.timezone,
          });
          this.formattedDate = this.formatDate(res.created_at);

          const data = {
            method: 'get',
            terminals_vid: [this.vid],
          };
          if (this.database === 'mcom') {
            this.terminalService
              .getTerminalValves(this.vid)
              .subscribe((res) => {
                if (res.length === undefined) {
                  this.selectedValveToEdit = this.terminalData.name;
                  this.allValves = [];
                } else {
                  this.selectedValveToEdit = '';
                  this.allValves = res;
                }
              });
          }

          this.terminalService.getTerminalsApiInfo(data).subscribe((res) => {
            if (res.length > 0) {
              this.deviceStatus = res[0].connected;
            }
          });
        }
        this.loading = false;
        /*this.loadMap();*/
      },
      (_) => {
        this.terminalsLib.terminalNoRights();
      }
    );
    this.dataloggerService.getDataloggerData(this.vid).subscribe((res) => {
      this.deviceStatus = res.connected;
    });
  }
  saveTerminalChanges(sleeping, reading) {
    if (this.database === 'datalogger') {
      this.saveTimeSlider(sleeping.value, reading.value);
    }
    if (this.database === 'mcom') {
      this.saveValves();
    }
    const coordenadas = this.fillData.value.coordinates.split(',');
    const data = {
      name: this.fillData.value.terminalName,
      latitude: Number(coordenadas[0]),
      longitude: Number(coordenadas[1]),
    };
    this.terminalService.putUpdateTerminal(data, this.vid).subscribe((res) => {
      Swal.fire({
        text: this.translationsLib.get('save_changes_success'),
        icon: 'success',
        confirmButtonText: this.translationsLib.get('accept'),
        confirmButtonColor: '#224261',
      });
    });
  }
  saveValves() {
    this.terminalService
      .putTerminalValves(this.vid, this.allValves)
      .subscribe((res) => {
        this.selectedValveToEdit = '';
      });
  }
  setUpConfig() {
    this.terminalService.setUpConfig(this.vid).subscribe((res) => {
      Swal.fire({
        text: this.translationsLib.get('save_changes_success'),
        icon: 'success',
        confirmButtonText: this.translationsLib.get('accept'),
      });
    });
  }
  pickUpData() {
    this.activationStatus = 0.5;
    this.terminalService.getPickUpConfig(this.vid).subscribe(
      (res) => {
        this.activationStatus = 1;
        this.interval = setInterval(() => {
          this.getConfigStatus();
        }, Math.round(environment.intervalDefaultTimeout / 2));
      },
      (error) => {
        this.activationStatus = 0;
        Swal.fire({
          text: this.translationsLib.get('terminal_error_activating'),
          icon: 'error',
          confirmButtonText: this.translationsLib.get('accept'),
        });
      }
    );
  }
  getConfigStatus() {
    this.terminalService.getConfigStatus(this.vid).subscribe(
      (res) => {
        if (res.percentage >= 100) {
          clearInterval(this.interval);
        }
        if (res.result === 'error') {
          clearInterval(this.interval);
          Swal.fire({
            text: this.translationsLib.get('terminal_error_activating'),
            icon: 'error',
            confirmButtonText: this.translationsLib.get('accept'),
          });
          this.activationStatus = 0;
        }
        this.progress = (Math.round(res.percentage * 100) / 100).toFixed(0);
      },
      (error) => {
        this.activationStatus = 0;
        Swal.fire({
          text: this.translationsLib.get('terminal_error_activating'),
          icon: 'error',
          confirmButtonText: this.translationsLib.get('accept'),
        });
      }
    );
  }
  cancelPickUp() {
    this.activationStatus = 0;
  }
  formatDate(date) {
    return moment(date).format('DD/MM/YYYY');
  }
  transformTime(tipo, value) {
    if (tipo === 'sleeping') {
      switch (value) {
        case 300:
          this.transformedSleepingTime = 0;
          break;
        case 600:
          this.transformedSleepingTime = 1;
          break;
        case 900:
          this.transformedSleepingTime = 2;
          break;
        case 1800:
          this.transformedSleepingTime = 3;
          break;
        case 3600:
          this.transformedSleepingTime = 4;
          break;
        case 21600:
          this.transformedSleepingTime = 5;
          break;
        case 43200:
          this.transformedSleepingTime = 6;
          break;
        case 86400:
          this.transformedSleepingTime = 7;
          break;
      }
    }
    if (tipo === 'reading') {
      switch (value) {
        case 0:
          this.transformedReadingTime = 0;
          break;
        case 1:
          this.transformedReadingTime = 1;
          break;
        case 5:
          this.transformedReadingTime = 2;
          break;
        case 10:
          this.transformedReadingTime = 3;
          break;
        case 20:
          this.transformedReadingTime = 4;
          break;
        case 30:
          this.transformedReadingTime = 5;
          break;
        case 40:
          this.transformedReadingTime = 6;
          break;
        case 50:
          this.transformedReadingTime = 7;
          break;
        case 60:
          this.transformedReadingTime = 8;
          break;
      }
    }
  }
  saveTimeSlider(sleeping, reading) {
    switch (sleeping) {
      case 0:
        this.sleepingTime = 300;
        break;
      case 1:
        this.sleepingTime = 600;
        break;
      case 2:
        this.sleepingTime = 900;
        break;
      case 3:
        this.sleepingTime = 1800;
        break;
      case 4:
        this.sleepingTime = 3600;
        break;
      case 5:
        this.sleepingTime = 21600;
        break;
      case 6:
        this.sleepingTime = 43200;
        break;
      case 7:
        this.sleepingTime = 86400;
        break;
    }
    switch (reading) {
      case 0:
        this.readingTime = 0;
        break;
      case 1:
        this.readingTime = 1;
        break;
      case 2:
        this.readingTime = 5;
        break;
      case 3:
        this.readingTime = 10;
        break;
      case 4:
        this.readingTime = 20;
        break;
      case 5:
        this.readingTime = 30;
        break;
      case 6:
        this.readingTime = 40;
        break;
      case 7:
        this.readingTime = 50;
        break;
      case 8:
        this.readingTime = 60;
        break;
    }
    const data = {
      sleepingTime: this.sleepingTime,
      readingTime: this.readingTime,
    };
    console.log(this.vid);
    this.terminalService.putEquipment(this.vid, data).subscribe((then) => {
      Swal.fire({
        text: this.translationsLib.get('save_changes_success'),
        icon: 'success',
        confirmButtonText: this.translationsLib.get('accept'),
      });
    });
  }
  reload() {
    location.reload();
  }
  stringToNumber(string) {
    return Number(string);
  }
}
