<app-main-header [breadcrumbs]="breadcrumbs"></app-main-header>
<ion-content>
  <div class="container-fluid p-1 p-lg-2">
    <div class="mx-lg-4">
      <div class="col-12">
        <mat-tab-group mat-align-tabs="start">
          <mat-tab label="{{ translationsLib.get('reports_reports') }}">

            <div class="card mt-4 reports-main-card">
              <div class="card-header reports-main-header">
                <h2 class="card-header-title">
                  {{ translationsLib.get('reports_create_report') }}
                </h2>
              </div>
              <div class="row mx-2 mt-3 print-no-padding">
                <div class="col-12 p-0 p-lg-2 print-no-padding">
                  <div id="parameter-container" class="row mx-0 align-items-center">
                    <div class="col-12 col-xl-3">
                      <mat-form-field appearance="fill" class="w-100">
                        <mat-label>{{ translationsLib.get('reports_type') }}</mat-label>
                        <mat-select [formControl]="report">
                          <mat-option *ngFor="let availableReport of availableReports" [value]="availableReport.id">{{ availableReport.name }}</mat-option>
                        </mat-select>
                      </mat-form-field>
                    </div>
                    <div class="col-12 col-xl-3">
                      <ng-select [items]="irrigationTerminals"
                                 bindLabel="name_vid"
                                 groupBy="field_name"
                                 placeholder="{{ translationsLib.get('irrigation_terminals') }}"
                                 [multiple]="true"
                                 [closeOnSelect]="false"
                                 [selectableGroup]="true"
                                 [selectableGroupAsModel]="false"
                                 [compareWith]="compareTerminalReportModel"
                                 [(ngModel)]="selectedIrrigationTerminals"
                                 *ngIf="ready === true"
                      >
                        <ng-template ng-optgroup-tmp let-item="item">
                          {{ item.field_name || 'Unnamed field'}}
                        </ng-template>
                      </ng-select>
                    </div>
                    <div class="col-12 col-xl-3 range-date">
                      <mat-form-field appearance="fill" class="w-100">
                        <mat-label>{{ translationsLib.get('reports_date_range') }}</mat-label>
                        <mat-date-range-input [formGroup]="range" [rangePicker]="picker" [min]="minDate" [max]="maxDate">
                          <input matStartDate formControlName="start" placeholder="{{ translationsLib.get('start_date') }}">
                          <input matEndDate formControlName="end" placeholder="{{ translationsLib.get('end_date') }}">
                        </mat-date-range-input>
                        <mat-datepicker-toggle matIconSuffix [for]="picker"></mat-datepicker-toggle>
                        <mat-date-range-picker [maxRange]=30 #picker></mat-date-range-picker>

                        <mat-error *ngIf="range.controls.start.hasError('matStartDateInvalid')">{{ translationsLib.get('reports_date_range_invalid_start_date') }}</mat-error>
                        <mat-error *ngIf="range.controls.end.hasError('matEndDateInvalid')">{{ translationsLib.get('reports_date_range_invalid_end_date') }}</mat-error>
                      </mat-form-field>
                    </div>
                    <div class="col-12 col-xl-3 text-end">
                      <button class="btn btn-lg btn-primary mb-3" type="submit" (click)="createReport();">
                        {{ translationsLib.get('reports_create_report') }}
                      </button>
                    </div>
                  </div>
                  <section id="logo-container">
                    <div class="row">
                      <div class="col-12">
                        <img class="logo" src="/assets/img/ucrop.svg"/>
                      </div>
                    </div>
                    <div class="row">
                      <div class="col-12 ms-4">
                        <h2>{{ translationsLib.get('reports_reports') }}</h2>
                        <h3>{{ showReportStartDate }} {{ showReportDateSplitter }} {{ showReportEndDate }}</h3>
                      </div>
                    </div>
                    <div class="row">
                      <div class="col-12 ms-4 text-end pe-4">
                        <img class="report-bg" src="/assets/img/reports/report_bg.png">
                      </div>
                    </div>
                  </section>
                  <div class="page-break"> </div>
                  <section id="report-section">
                    <app-reports-irrigations class="reports-main-component-container" *ngIf="selectedReport === 'irrigations'" [createdReport]="createdReport"></app-reports-irrigations>
                    <app-reports-drainages class="reports-main-component-container"  *ngIf="selectedReport === 'drainages'" [createdReport]="createdReport"></app-reports-drainages>
                    <app-reports-alarms class="reports-main-component-container"  *ngIf="selectedReport === 'alarms'" [createdReport]="createdReport"></app-reports-alarms>
                    <app-reports-consumptions class="reports-main-component-container"  *ngIf="selectedReport === 'programs_consumptions'" [createdReport]="createdReport"></app-reports-consumptions>
                    <app-reports-consumptions class="reports-main-component-container"  *ngIf="selectedReport === 'grouping_consumptions'" [createdReport]="createdReport"></app-reports-consumptions>
                  </section>
                </div>
              </div>
            </div>

          </mat-tab>

          <!-- Needs to be shown when ready === true to be sure that input parameters are loaded -->
          <!--
          <mat-tab label="{{ translationsLib.get('reports_reports_tasks') }}" *ngIf="ready === true">
            <app-reports-tasks
              [availableReports]="availableReports"
              [irrigationTerminals]="irrigationTerminals"
            ></app-reports-tasks>
          </mat-tab>
          -->

        </mat-tab-group>
      </div>
    </div>
  </div>
</ion-content>
