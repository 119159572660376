import {
  AfterViewInit,
  Component,
  Input,
  OnInit,
  ViewChild,
} from '@angular/core';
import { TranslationsLibService } from '../../../services/libraries/translations-lib.service';
import { AlarmsReportDataModel } from '../../reports.model';
import {
  animate,
  state,
  style,
  transition,
  trigger,
} from '@angular/animations';
import { MatPaginator } from '@angular/material/paginator';
import { MatTableDataSource } from '@angular/material/table';
import { MatSort } from '@angular/material/sort';
import { FormControl, FormGroup } from '@angular/forms';

@Component({
  selector: 'app-reports-alarms-table',
  templateUrl: './reports-alarms-table.component.html',
  styleUrls: ['./reports-alarms-table.component.scss'],
  animations: [
    trigger('detailExpand', [
      state('collapsed', style({ height: '0px', minHeight: '0' })),
      state('expanded', style({ height: '*' })),
      transition(
        'expanded <=> collapsed',
        animate('225ms cubic-bezier(0.4, 0.0, 0.2, 1)')
      ),
    ]),
  ],
})
export class ReportsAlarmsTableComponent implements OnInit, AfterViewInit {
  @Input() data: AlarmsReportDataModel[];

  // eslint-disable-next-line @typescript-eslint/no-inferrable-types
  @Input() tablePageItemNumber: number = 20;
  @Input() count: number;
  @Input() reportName = '';
  @Input() alarmColumnsToDisplay = [];
  @ViewChild(MatPaginator) paginator: MatPaginator;
  @ViewChild(MatSort) sort: MatSort;
  expandedElement: AlarmsReportDataModel | null;
  dataSource: MatTableDataSource<AlarmsReportDataModel>;
  ready = false;

  filterValues = {
    begin: '',
    end: '',
    active: '',
    typeStr: '',
    whoActivatesStr: '',
  };
  filterForm = new FormGroup({
    begin: new FormControl(),
    end: new FormControl(),
    active: new FormControl(),
    typeStr: new FormControl(),
    whoActivatesStr: new FormControl(),
  });

  get begin() {
    return this.filterForm.get('begin');
  }
  get end() {
    return this.filterForm.get('end');
  }
  get active() {
    return this.filterForm.get('active');
  }
  get typeStr() {
    return this.filterForm.get('typeStr');
  }
  get whoActivatesStr() {
    return this.filterForm.get('whoActivatesStr');
  }
  constructor(public translationsLib: TranslationsLibService) {}
  ngOnInit(): void {
    // ready triggers ngIf directive and must stay in ngOnInit!!!!
    this.ready = true;
  }
  // Very important to load data on ngAfterViewInit, doing it on ngOnInit freezes the client for several seconds!
  // https://stackoverflow.com/questions/50283659/angular-6-mattable-performance-in-1000-rows
  ngAfterViewInit() {
    this.dataSource = new MatTableDataSource(this.data);
    this.dataSource.paginator = this.paginator;
    this.dataSource.sort = this.sort;
    this.formSubscribe();
    this.getFormsValue();
  }
  formSubscribe() {
    this.begin.valueChanges.subscribe((beginValue) => {
      this.filterValues.begin = beginValue;
      this.dataSource.filter = JSON.stringify(this.filterValues);
    });
    this.end.valueChanges.subscribe((endValue) => {
      this.filterValues.end = endValue;
      this.dataSource.filter = JSON.stringify(this.filterValues);
    });
    this.active.valueChanges.subscribe((activeValue) => {
      this.filterValues.active = activeValue;
      this.dataSource.filter = JSON.stringify(this.filterValues);
    });
    this.typeStr.valueChanges.subscribe((typeStrValue) => {
      this.filterValues.typeStr = typeStrValue;
      this.dataSource.filter = JSON.stringify(this.filterValues);
    });
    this.whoActivatesStr.valueChanges.subscribe((whoActivatesStrValue) => {
      this.filterValues.whoActivatesStr = whoActivatesStrValue;
      this.dataSource.filter = JSON.stringify(this.filterValues);
    });
  }
  getFormsValue() {
    this.dataSource.filterPredicate = (data, filter: string): boolean => {
      const searchString = JSON.parse(filter);
      return (
        data.begin
          .toString()
          .trim()
          .toLowerCase()
          .indexOf(searchString.begin.toLowerCase()) !== -1 &&
        data.end
          .toString()
          .trim()
          .toLowerCase()
          .indexOf(searchString.end.toLowerCase()) !== -1 &&
        data.active
          .toString()
          .trim()
          .toLowerCase()
          .indexOf(searchString.active.toLowerCase()) !== -1 &&
        data.typeStr
          .toString()
          .trim()
          .toLowerCase()
          .indexOf(searchString.typeStr.toLowerCase()) !== -1 &&
        data.whoActivatesStr
          .toString()
          .trim()
          .toLowerCase()
          .indexOf(searchString.whoActivatesStr.toLowerCase()) !== -1
      );
    };
    this.dataSource.filter = JSON.stringify(this.filterValues);
  }
}
