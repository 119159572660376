import { Component, Input, OnInit } from '@angular/core';
import { TranslationsLibService } from '../../../services/libraries/translations-lib.service';

@Component({
  selector: 'app-reports-simple-table',
  templateUrl: './reports-simple-table.component.html',
  styleUrls: ['./reports-simple-table.component.scss'],
})
export class ReportsSimpleTableComponent implements OnInit {
  @Input() columnsToDisplay: string[];
  @Input() data: any[];

  p_break_count = [];
  constructor(public translationsLib: TranslationsLibService) {}

  ngOnInit() {
    this.p_break_count = Array(Math.ceil(this.data.length / 35)) // Magic number %D
      .fill(0)
      .map((x, i) => i);
  }
}
