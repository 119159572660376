import {
  Component,
  OnInit,
  Input,
  ContentChild,
  TemplateRef,
  AfterViewInit,
} from '@angular/core';
import { TranslationsLibService } from '../../../services/libraries/translations-lib.service';
import { TerminalTableDataDirective } from '../../directives/terminal-table-data.directive';
@Component({
  selector: 'app-terminal-table-wrapper',
  templateUrl: './terminal-table-wrapper.component.html',
  styleUrls: ['./terminal-table-wrapper.component.scss'],
})
export class TerminalTableWrapperComponent<T> implements OnInit, AfterViewInit {
  @ContentChild(TerminalTableDataDirective, { read: TemplateRef })
  dataTemplate: any;

  @Input()
  includeText: string;
  @Input() states?;
  @Input() data;
  @Input() type: 'climate' | 'irrigation' | 'configurable';
  @Input() isFetchingData = false;
  @Input() terminal_vid: string;
  @Input() searchPlaceholder: string;
  @Input() fertilizerFormulas?;
  @Input() tableHeaders;
  @Input() name: string;
  @Input() filterProperty = 'name';
  @Input() isIndex: boolean;
  searchText = '';
  page = 1;
  cardClass: any;

  constructor(public translationsLib: TranslationsLibService) {}

  loadData() {
    setTimeout(() => {
      if (this.name === 'configurable_programs') {
        // This code add a new object key called 'states' inside the original program API response
        // to match the states response with the programs response
        this.states.states.programs.forEach((state: any) => {
          const program = this.data[state.program];
          if (program) {
            if (!program.states) {
              program.states = [];
            }
            program.states.push(state);
          }
        });
      }
    }, 3000);
  }

  ngAfterViewInit(): void {
    this.loadData();
  }

  ngOnInit() {
    this.cardClass = this.getClass();
  }

  getClass() {
    return {
      'irrigation-card-border irrigation-table': this.type === 'irrigation',
      'climate-card-border climate-table': this.type === 'climate',
      'configurable-card-border configurable-table':
        this.type === 'configurable',
    };
  }
}
