import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { RestApiService } from '../services/api/rest-api.service';
import {PairingDataModel} from "../terminals/terminals.model";

@Injectable({
  providedIn: 'root',
})
export class GraphicsService {
  private restApiService: RestApiService;

  constructor(restApiService: RestApiService) {
    this.restApiService = restApiService;
  }
  public postMultiGraph(data): Observable<any> {
    return new Observable((observer) => {
      this.restApiService.postMultiGraph(data).subscribe(
        (response) => {
          observer.next(response);
        },
        (error) => {
          observer.error(error);
        }
      );
    });
  }
  public postSaveGraph(data): Observable<any> {
    return new Observable((observer) => {
      this.restApiService.postSaveGraph(data).subscribe(
        (response) => {
          observer.next(response);
        },
        (error) => {
          observer.error(error);
        }
      );
    });
  }

  public postSavedMetric(data): Observable<any> {
    return new Observable((observer) => {
      this.restApiService.postSavedMetric(data).subscribe(
        (response) => {
          observer.next(response);
        },
        (error) => {
          observer.error(error);
        }
      );
    });
  }

  public putSaveGraph(vid,data): Observable<any> {
    return new Observable((observer) => {
      this.restApiService.putSaveGraph(vid,data).subscribe(
        (response) => {
          observer.next(response);
        },
        (error) => {
          observer.error(error);
        }
      );
    });
  }
  public putSavedMetric(vid,data): Observable<any> {
    return new Observable((observer) => {
      this.restApiService.putSavedMetric(vid,data).subscribe(
        (response) => {
          observer.next(response);
        },
        (error) => {
          observer.error(error);
        }
      );
    });
  }
  public deleteSavedGraph(vid): Observable<any> {
    return new Observable((observer) => {
      this.restApiService.deleteSavedGraph(vid).subscribe(
        (response) => {
          observer.next(response);
        },
        (error) => {
          observer.error(error);
        }
      );
    });
  }
  public deleteSavedMetric(vid): Observable<any> {
    return new Observable((observer) => {
      this.restApiService.deleteSavedMetric(vid).subscribe(
        (response) => {
          observer.next(response);
        },
        (error) => {
          observer.error(error);
        }
      );
    });
  }
  public getSaveGraph(): Observable<any> {
    return new Observable((observer) => {
      this.restApiService.getSaveGraph().subscribe(
        (response) => {
          observer.next(response);
        },
        (error) => {
          observer.error(error);
        }
      );
    });
  }
  public getSavedMetrics(): Observable<any> {
    return new Observable((observer) => {
      this.restApiService.getSavedMetrics().subscribe(
        (response) => {
          observer.next(response);
        },
        (error) => {
          observer.error(error);
        }
      );
    });
  }
}
