import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { HelpersLibService } from '../../services/libraries/helpers-lib.service';
import { TerminalsService } from '../terminals.service';
import { TranslationsLibService } from '../../services/libraries/translations-lib.service';

@Component({
  selector: 'app-info-terminals',
  templateUrl: './info-terminals.component.html',
  styleUrls: ['./info-terminals.component.scss'],
})
export class InfoTerminalsComponent implements OnInit {
  private vid;
  public terminalName = '';
  public hasPermissions;
  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private helpersLib: HelpersLibService,
    private terminalService: TerminalsService,
    public translationLib: TranslationsLibService
  ) {}

  ngOnInit() {
    this.vid = this.route.snapshot.paramMap.get('vid');
    this.getTerminalInfo();
    setTimeout(() => {
      this.helpersLib.sendEventTerminalView(this.terminalName, this.vid);
    }, 200);
  }

  configTerminal() {
    this.router.navigate(['./edit-terminal', { vid: this.vid }]);
  }
  getTerminalInfo() {
    this.terminalService.getTerminals().subscribe((response) => {
      let terminals: any = [];
      terminals = response;
      terminals.forEach((terminal) => {
        if (terminal.vid === this.vid) {
          this.terminalName = terminal.name;
          this.hasPermissions = terminal.authed_user_can_admin;
          this.helpersLib.setTitle(
            this.translationLib.get('terminal_terminals'),
            this.terminalName,
            null
          );
        }
      });
    });
  }
}
