import { Component, Input, OnInit } from '@angular/core';
import { TranslationsLibService } from '../../../services/libraries/translations-lib.service';
import { UserIdentityDataModel } from '../../permission.model';
import { PermissionsTerminalsService } from '../../../services/libraries/permissions-terminals.service';

@Component({
  selector: 'app-permissions-table-injected-users',
  templateUrl: './permissions-table-injected-users.component.html',
  styleUrls: ['./permissions-table-injected-users.component.scss'],
})
export class PermissionsTableInjectedUsersComponent implements OnInit {
  @Input() injectedRegularUsers: UserIdentityDataModel[] = [];
  constructor(
    public translationsLib: TranslationsLibService,
    public permissionsTerminal: PermissionsTerminalsService
  ) {}

  ngOnInit() {}
}
