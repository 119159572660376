<link rel="stylesheet" href="https://unpkg.com/leaflet@1.6.0/dist/leaflet.css"
      integrity="sha512-xwE/Az9zrjBIphAcBb3F6JVqxf46+CDLwfLMHloNu6KEQCAWi6HcDUbeOfBIptF7tcCzusKFjFw2yuvEpDL9wQ=="
      crossorigin=""/>
<script src="https://unpkg.com/leaflet@1.6.0/dist/leaflet.js"
        integrity="sha512-gZwIG9x3wUXg2hdXF6+rVkLF/0Vi9U8D2Ntg4Ga5I5BZpVkVxlJWbSQtXPSiUTtC0TjtGOmxa1AJPuV0CPthew=="
        crossorigin=""></script>
<link rel="stylesheet" href="https://unpkg.com/leaflet-geoman-free/dist/leaflet-geoman.css" />
<script src="https://unpkg.com/leaflet-geoman-free/dist/leaflet-geoman.min.js"></script>
<script src="https://unpkg.com/leaflet.markercluster@1.4.1/dist/leaflet.markercluster.js"></script>
<link rel="stylesheet" href="https://unpkg.com/leaflet.markercluster@1.4.1/dist/MarkerCluster.Default.css" />
<div style="position: relative;">
  <div [id]="this.coming" class="map" [ngClass]="{'pointer-none': showZoomInstructions}"></div>
  <div id="zoom-instructions" *ngIf="showZoomInstructions" class="zoom-instructions">
   <span [innerHTML]="translationsLib.get('map_advice')"></span>
  </div>
</div>
<!--MODALES-->
<ion-modal #terminalsModal [breakpoints]="[1]">
  <ng-template>
    <div class="block align-self-center">
      <ion-buttons class="close-modal">
        <ion-button (click)="terminalsModal.dismiss()">
          <ion-icon name="close-circle-outline"></ion-icon>
        </ion-button>
      </ion-buttons>

      <div class="row mx-2 my-4 w-100">
        <div class="col-3">
          <img src="{{backend + '/' + selectedMarkerData.image_path}}" width="80px">
        </div>
        <div class="col-9">
          <h2 class="mb-0">{{selectedMarkerData.name}}</h2>
          <h4 class="text-muted">{{selectedMarkerData.pretty_type_name}} - {{selectedMarkerData.vid}}</h4>
        </div>
      </div>
      <div class="row mx-2 my-4" >
        <a class="btn btn-primary" (click)="terminalsModal.dismiss();router.navigate([terminalsLib.generateRoute(selectedMarkerData.vid, selectedMarkerData.type)])">{{this.translationsLib.get('view')}}</a>
      </div>
    </div>
  </ng-template>
</ion-modal>
<ion-modal #fieldsModal [breakpoints]="[1]">
  <ng-template>
    <div class="block align-self-center">
      <ion-buttons class="close-modal">
        <ion-button (click)="fieldsModal.dismiss()">
          <ion-icon name="close-circle-outline"></ion-icon>
        </ion-button>
      </ion-buttons>

      <div class="row mx-2 my-4 w-100">
        <div class="col-3">
          <img src="assets/img/Icons/Icono_UCROP_Finca.svg" width="80px">
        </div>
        <div class="col-9">
          <h1>{{selectedMarkerData.name}}</h1>
        </div>
      </div>
      <div class="row mx-2 my-4" >
        <a class="btn btn-primary" (click)="fieldsModal.dismiss();router.navigate(['./field', { vid: selectedMarkerData.vid }])">{{this.translationsLib.get('view')}}</a>
      </div>
    </div>
  </ng-template>
</ion-modal>
<ion-modal #valvesModal [breakpoints]="[1]">
  <ng-template>
    <ng-container *ngIf="selectedMarkerData.active">
      <div class="block-active align-self-center">
        <ion-buttons class="close-modal">
          <ion-button (click)="valvesModal.dismiss()">
            <ion-icon name="close-circle-outline"></ion-icon>
          </ion-button>
        </ion-buttons>

        <div class="row mx-2 my-4 w-100">
          <div class="col-3">
            <img src="assets/img/Icons/Icono_UCROP_Valvula.svg" width="80px">
          </div>
          <div class="col-9">
            <h2>{{selectedMarkerData.name}}</h2>
            <div class="m-0 mb-1">
              <b>{{this.translationsLib.get('irrigation_valve')}}:</b>
              {{ selectedMarkerData.valve + 1 }}
            </div>
            <div class="m-0 mb-1">
              <b>{{this.translationsLib.get('irrigation_status')}}:</b>
              {{this.translationsLib.get('irrigation_active')}}
              <ng-container *ngIf="selectedMarkerData.irrigationParams.nameProgram !== '-'">
                ({{selectedMarkerData.irrigationParams.nameProgram}}
                {{ this.translationsLib.get('reports_' + selectedMarkerData.irrigationParams.phase) }})
              </ng-container>
            </div>
            <div class="m-0 mb-1" *ngIf="selectedMarkerData.irrigationParams.irrigated.quantity !== '-'">
              <b>{{this.translationsLib.get('irrigation_irrigated')}}:</b>
              {{selectedMarkerData.irrigationParams.irrigated.quantity}}
            </div>
            <div class="m-0 mb-1" *ngIf="selectedMarkerData.irrigationParams.irrigated.time !== '-'">
              <b>{{this.translationsLib.get('irrigation_irrigated_time')}}:</b>
              {{selectedMarkerData.irrigationParams.irrigated.time}}
            </div>
            <div class="m-0 mb-1" *ngIf="selectedMarkerData.irrigationParams.remaining !== '-'">
              <b>{{this.translationsLib.get('irrigation_remaining_time')}}:</b>
              {{selectedMarkerData.irrigationParams.remaining}}
            </div>
            <div class="m-0 mb-1" *ngIf="selectedMarkerData.irrigationParams.activations !== '-'">
              <b>{{this.translationsLib.get('irrigation_activations')}}:</b>
              {{selectedMarkerData.irrigationParams.activations}}
              ({{ translationsLib.get('reports_activation_' + selectedMarkerData.irrigationParams.startedBy) }})
            </div>
            <div class="m-0 mb-1" *ngIf="selectedMarkerData.irrigationParams.activeGroup !== '-'">
              <b>{{this.translationsLib.get('irrigation_active_group')}}:</b>
              <ng-container *ngFor="let group of selectedMarkerData.groups">
                <ng-container  *ngIf="group.name && group.name.trim()">
                  {{ group.name }}
                </ng-container>
                <ng-container  *ngIf="!group.name || !group.name.trim()">
                  {{ group.group + 1 }}
                </ng-container>
              </ng-container>
            </div>
          </div>
        </div>
        <!--<div class="row mx-2 my-4" >
          <a class="btn btn-primary" href="'/field;vid=' + {{selectedMarkerData.vid}}">{{this.translationsLib.get('view')}}</a>
        </div>-->
      </div>
    </ng-container>
    <ng-container *ngIf="!selectedMarkerData.active">
      <div class="block align-self-center">
        <ion-buttons class="close-modal">
          <ion-button (click)="valvesModal.dismiss()">
            <ion-icon name="close-circle-outline"></ion-icon>
          </ion-button>
        </ion-buttons>
        <div class="row mx-2 my-4 w-100">
          <div class="col-3">
            <img src="assets/img/Icons/Icono_UCROP_Valvula.svg" width="80px">
          </div>
          <div class="col-9">
            <h2>{{selectedMarkerData.name}}</h2>
            <div class="m-0 mb-1">
              <b>{{this.translationsLib.get('irrigation_valve')}}:</b> {{selectedMarkerData.valve +1}}
            </div>
            <div class="m-0 mb-1">
              <b>{{this.translationsLib.get('irrigation_status')}}:</b> {{this.translationsLib.get('irrigation_inactive')}}
            </div>
          </div>
        </div>
      </div>
    </ng-container>
  </ng-template>
</ion-modal>
