import { Injectable } from '@angular/core';
import * as moment from 'moment';
import SunCalc from 'suncalc';
@Injectable({
  providedIn: 'root',
})
export class GraphicsLibService {
  constructor() {}
  combineAndSortArrays(array, series) {

    // Combina todos los arrays en uno solo
    const combinedArray = array.flat();

    // Extrae los timestamps únicos
    const uniqueTimestamps = new Set(
      combinedArray.map((item) => item.timestamp)
    );

    // Utiliza un Map para eliminar duplicados por timestamp
    const uniqueTimestampsMap = new Map();
    combinedArray.forEach((item) => {
      uniqueTimestampsMap.set(item.timestamp, item);
    });

    // Convierte el Map de nuevo a un array
    const uniqueTimestampsArray = Array.from(uniqueTimestampsMap.values());

    // Ordena los objetos por timestamp
    uniqueTimestampsArray.sort((a, b) => new Date(a.timestamp).getTime() - new Date(b.timestamp).getTime());

    // Agrega los timestamps faltantes en series con valor null
    series.forEach(serie => {
      const existingDates = new Set(serie.categories.map(category => category.timestamp));

      uniqueTimestamps.forEach(timestamp => {
        if (!existingDates.has(timestamp)) {
          serie.categories.push({ timestamp, value: null });
        }
      });
      // Ordena las categorías por timestamp
      serie.categories.sort((a, b) =>
        a.timestamp-b.timestamp
      );
      serie.data = serie.categories.map((categories) => categories.value);
    });

    const formattedTimestampsArray = uniqueTimestampsArray.map(item =>
      moment.utc(item.timestamp).format('DD/MM HH:mm')
    );
    return { series_x: formattedTimestampsArray, series };
  }
  getPlotBands(sensor, latitude, longitude){
    let sunrise;
    let sunset;
    const plotBands = [];
    sensor.categories.forEach((category, index) => {
      const times = SunCalc.getTimes(
        category.timestamp,
        latitude,
        longitude
      );
      if (
        moment.utc(category.timestamp).format('DD/MM HH:MM') ===
        moment.utc(times.sunrise).format('DD/MM HH:MM')
      ) {
        sunrise = index;
      }
      if (
        moment.utc(category.timestamp).format('DD/MM HH:MM') ===
        moment.utc(times.sunset).format('DD/MM HH:MM')
      ) {
        sunset = index;
      }
      if (
        sunrise > sunset &&
        sunrise !== undefined &&
        sunset !== undefined
      ) {
        const i = plotBands.findIndex((x) => x.from === sunset);
        if (i === -1) {
          plotBands.push({
            color: 'rgba(3,27,128,0.1)',
            fillOpacity: 0.1,
            from: sunset,
            to: sunrise,
          });
        }
      }
    });
    return plotBands;
  }
  getMuestrasInterval(data, series) {
    const begin = new Date(data[0].begin);
    const end = new Date(data[0].end);
    const diferencia = Math.trunc(
      (end.getTime() - begin.getTime()) / (1000 * 60 * 60)
    );
    const interval = data[0].interval / 60;
    switch (diferencia) {
      case 24: {
        let tick = 3 * (60 / interval);
        const data = [];
        if (
          series.length < (24 / 3) * tick ||
          series.length * 0.9 > (24 / 3) * tick
        ) {
          tick = Math.trunc(series.length / 8);
        }
        for (let i = 0; i < 24 / 3; i++) {
          data.push(series[tick * i]);
        }
        return data;
        break;
      }
      case 48: {
        let tick = 6 * (60 / interval);
        const data = [];
        if (series.length < (48 / 6) * tick) {
          tick = Math.trunc(series.length / 8);
        }
        for (let i = 0; i < 48 / 6; i++) {
          data.push(series[tick * i]);
        }
        return data;
        break;
      }
      case 168: {
        let tick = 24 * (60 / interval);
        const data = [];
        if (series.length < (168 / 24) * tick) {
          tick = Math.trunc(series.length / 7);
        }
        for (let i = 0; i < 168 / 24; i++) {
          data.push(series[tick * i]);
        }
        return data;
        break;
      }
      case 720: {
        let tick = 72 * (60 / interval);
        const data = [];
        if (series.length < (720 / 72) * tick) {
          tick = Math.trunc(series.length / 10);
        }
        for (let i = 0; i < 720 / 72; i++) {
          data.push(series[tick * i]);
        }
        return data;
        break;
      }
      default: {
        let hours = 1;
        let labels = null;
        do {
          if (diferencia % hours === 0) {
            labels = diferencia / hours;
          }
          hours++;
        } while (labels > 10);
        let tick = hours * (60 / interval);
        const data = [];
        if (series.length < (diferencia / hours) * tick) {
          tick = tick / 3;
        }
        for (let i = 0; i < series.length; i++) {
          data.push(series[tick * i]);
        }
        return data;
        break;
      }
    }
  }

  getIntervalTime(diferencia) {
    let data;
    if (diferencia > 0 && diferencia < 7) {
      data = [
        { value: 300, option: '5m' },
        { value: 600, option: '10m' },
        { value: 900, option: '15m' },
        { value: 1800, option: '30m' },
        { value: 3600, option: '1h' },
        { value: 21600, option: '6h' },
      ];
    }
    if (diferencia >= 7 && diferencia <= 62) {
      data = [
        { value: 300, option: '5m' },
        { value: 600, option: '10m' },
        { value: 900, option: '15m' },
        { value: 1800, option: '30m' },
        { value: 3600, option: '1h' },
        { value: 21600, option: '6h' },
        { value: 43200, option: '12h' },
        { value: 86400, option: '24h' },
      ];
    }
    if (diferencia > 62) {
      data = [
        { value: 1800, option: '30m' },
        { value: 3600, option: '1h' },
        { value: 21600, option: '6h' },
        { value: 43200, option: '12h' },
        { value: 86400, option: '24h' },
      ];
    }
    return data;
  }

  chooseOperations(series, intervalo) {
    switch (series[0].operation) {
      case 'min': {
        const res = this.minimo(series);
        return this.getMinMaxMediaSensors(res);
      }
      case 'max': {
        const res = this.maximo(series);
        return this.getMinMaxMediaSensors(res);
      }
      case 'med': {
        const res = this.media(series);
        return this.getMinMaxMediaSensors(res);
      }
      case 'sum': {
        const res = this.sumatorio(series);
        return this.getMinMaxMediaSensors(res);
      }
      case 'dif': {
        const res = this.diferencia(series);
        return this.getMinMaxMediaSensors(res);
      }
      case 'acum': {
        const res = this.acumulative(series, intervalo);
        return this.getMinMaxMediaSensors(res);
      }
      case 'acumMagnitude': {
        const res = this.acumulativeByMagnitude(series, intervalo);
        return this.getMinMaxMediaSensors(res);
      }
      case 'esc': {
        const res = this.escalonado(series);
        return this.getMinMaxMediaSensors(res);
      }
    }
  }

  minimo(series) {
    const type = [];
    series.forEach((serie) => {
      if (type.filter((e) => e.subtype === serie.subtype).length > 0) {
        type.forEach((tipo) => {
          if (
            tipo.subtype === serie.subtype &&
            !tipo.name.includes(serie.name)
          ) {
            if (series[0].operationName) {
              tipo.name = series[0].operationName;
            } else {
              tipo.name = tipo.name + '/' + serie.name;
            }
            tipo.media = 0;
            if (tipo.unit === null && serie.unit != null) {
              tipo.unit = serie.unit;
            }
            tipo.data.forEach((data) => {
              serie.data.forEach((datosSerie) => {
                if (data[0] === datosSerie[0]) {
                  if (data[1] > datosSerie[1]) {
                    tipo.media = tipo.media + datosSerie[1];
                    data[1] = datosSerie[1];
                  } else {
                    tipo.media = tipo.media + data[1];
                  }
                }
              });
            });
            tipo.media = tipo.media / tipo.data.length;
            tipo.media = tipo.media.toFixed(2);
          }
        });
      } else {
        type.push(serie);
      }
    });
    return type;
  }
  maximo(series) {
    const type = [];
    series.forEach((serie) => {
      if (type.filter((e) => e.subtype === serie.subtype).length > 0) {
        type.forEach((tipo) => {
          if (
            tipo.subtype === serie.subtype &&
            !tipo.name.includes(serie.name)
          ) {
            if (series[0].operationName) {
              tipo.name = series[0].operationName;
            } else {
              tipo.name = tipo.name + '/' + serie.name;
            }
            tipo.media = 0;
            if (tipo.unit === null && serie.unit != null) {
              tipo.unit = serie.unit;
            }
            tipo.data.forEach((data) => {
              serie.data.forEach((datosSerie) => {
                if (data[0] === datosSerie[0]) {
                  if (data[1] < datosSerie[1]) {
                    tipo.media = tipo.media + datosSerie[1];
                    data[1] = datosSerie[1];
                  } else {
                    tipo.media = tipo.media + data[1];
                  }
                }
              });
            });
            tipo.media = tipo.media / tipo.data.length;
            tipo.media = tipo.media.toFixed(2);
          }
        });
      } else {
        type.push(serie);
      }
    });
    return type;
  }
  media(series) {
    const type = [];
    series.forEach((serie) => {
      if (type.filter((e) => e.subtype === serie.subtype).length > 0) {
        type.forEach((tipo) => {
          if (
            tipo.subtype === serie.subtype &&
            !tipo.name.includes(serie.name)
          ) {
            if (series[0].operationName) {
              tipo.name = series[0].operationName;
            } else {
              tipo.name = tipo.name + '/' + serie.name;
            }
            tipo.media = 0;
            if (tipo.unit === null && serie.unit != null) {
              tipo.unit = serie.unit;
            }
            tipo.data.forEach((data) => {
              serie.data.forEach((datosSerie) => {
                if (data[0] === datosSerie[0]) {
                  data[1] = (data[1] + datosSerie[1]) / 2;
                  data[1] = Number(data[1].toFixed(2));
                  tipo.media = tipo.media + data[1];
                }
              });
            });
            tipo.media = tipo.media / tipo.data.length;
            tipo.media = tipo.media.toFixed(2);
          }
        });
      } else {
        type.push(serie);
      }
    });
    return type;
  }
  sumatorio(series) {
    const type = [];
    series.forEach((serie) => {
      if (type.filter((e) => e.subtype === serie.subtype).length > 0) {
        type.forEach((tipo) => {
          if (
            tipo.subtype === serie.subtype &&
            !tipo.name.includes(serie.name)
          ) {
            if (series[0].operationName) {
              tipo.name = series[0].operationName;
            } else {
              tipo.name = tipo.name + '/' + serie.name;
            }
            tipo.media = 0;
            if (tipo.unit === null && serie.unit != null) {
              tipo.unit = serie.unit;
            }
            tipo.data.forEach((data) => {
              serie.data.forEach((datosSerie) => {
                if (data[0] === datosSerie[0]) {
                  data[1] = data[1] + datosSerie[1];
                  data[1] = Number(data[1].toFixed(2));
                  tipo.media = tipo.media + data[1];
                }
              });
            });
            tipo.media = tipo.media / tipo.data.length;
            tipo.media = tipo.media.toFixed(2);
          }
        });
      } else {
        type.push(serie);
      }
    });
    return type;
  }
  diferencia(series) {
    const type = [];
    series.forEach((serie) => {
      if (type.filter((e) => e.subtype === serie.subtype).length > 0) {
        type.forEach((tipo) => {
          if (
            tipo.subtype === serie.subtype &&
            !tipo.name.includes(serie.name)
          ) {
            if (series[0].operationName) {
              tipo.name = series[0].operationName;
            } else {
              tipo.name = tipo.name + '/' + serie.name;
            }
            tipo.media = 0;
            if (tipo.unit === null && serie.unit != null) {
              tipo.unit = serie.unit;
            }
            tipo.data.forEach((data) => {
              serie.data.forEach((datosSerie) => {
                if (data[0] === datosSerie[0]) {
                  data[1] = data[1] - datosSerie[1];
                  data[1] = Number(data[1].toFixed(2));
                  tipo.media = tipo.media + data[1];
                }
              });
            });
            tipo.media = tipo.media / tipo.data.length;
            tipo.media = tipo.media.toFixed(2);
          }
        });
      } else {
        type.push(serie);
      }
    });
    return type;
  }
  acumulative(series, intervalo) {
    let muestras;
    if (intervalo < 3600) {
      muestras = 3600 / intervalo;
    } else {
      muestras = 86400 / intervalo;
    }
    series.forEach((serie) => {
      const type = [];
      for (let i = 0; i < serie.data.length; i = i + muestras) {
        let muestrasRestantes = muestras;
        let suma = 0;
        for (let x = i; 0 < muestrasRestantes; x++) {
          suma = suma + Number(serie.data[i][1]);
          muestrasRestantes--;
        }
        suma = Number(suma.toFixed(2));
        type.push([serie.data[i][0], suma]);
      }
      if (series[0].operationName) {
        serie.name = series[0].operationName;
      }
      serie.data = type;
    });
    return series;
  }
  acumulativeByMagnitude(series, intervalo) {
    series = this.sumatorio(series);
    let muestras;
    if (intervalo < 3600) {
      muestras = 3600 / intervalo;
    } else {
      muestras = 86400 / intervalo;
    }
    series.forEach((serie) => {
      const type = [];
      for (let i = 0; i < serie.data.length; i = i + muestras) {
        let muestrasRestantes = muestras;
        let suma = 0;
        for (let x = i; 0 < muestrasRestantes; x++) {
          suma = suma + Number(serie.data[i][1]);
          muestrasRestantes--;
        }
        suma = Number(suma.toFixed(2));
        type.push([serie.data[i][0], suma]);
      }
      if (series[0].operationName) {
        serie.name = series[0].operationName;
      }
      serie.data = type;
    });
    return series;
  }
  escalonado(series) {
    series.forEach((serie) => {
      if (serie.subtype !== 'PLUVIOMETER') {
        let suma = 0;
        serie.data.forEach((dato) => {
          suma = suma + dato[1];
          dato[1] = Number(suma.toFixed(2));
        });
        if (series[0].operationName) {
          serie.name = series[0].operationName;
        }
      } else {
        const type = [];
        let total = 0;
        let date = null;
        let firstdate = null;
        for (let i = 0; i < serie.data.length; i++) {
          if (date === null) {
            firstdate = serie.data[i][0];
            date = serie.data[i][0];
            type.push([date, serie.data[i][1]]);
          } else {
            if (
              i > 0 &&
              moment
                .unix(date / 1000)
                .utcOffset('GMT-00:00')
                .format('DD/MM/YYYY') !==
                moment
                  .unix(serie.data[i][0] / 1000)
                  .utcOffset('GMT-00:00')
                  .format('DD/MM/YYYY')
            ) {
              date = serie.data[i][0];
              if (
                moment
                  .unix(date / 100)
                  .utcOffset('GMT-00:00')
                  .hour() === 0
              ) {
                total = total + Number(serie.data[i][1]);
              } else {
                total = total + Number(serie.data[i - 1][1]);
              }
              type.push([serie.data[i][0], total]);
            } else {
              if (
                moment
                  .unix(firstdate)
                  .utcOffset('GMT-00:00')
                  .format('DD/MM/YYYY') ===
                moment
                  .unix(serie.data[i][0])
                  .utcOffset('GMT-00:00')
                  .format('DD/MM/YYYY')
              ) {
                type.push([serie.data[i][0], serie.data[i][1]]);
              } else {
                type.push([serie.data[i][0], total + Number(serie.data[i][1])]);
              }
            }
          }
        }
        if (series[0].operationName) {
          serie.name = series[0].operationName;
        }
        serie.data = type;
      }
    });
    return series;
  }
  getMinMaxMediaSensors(series) {
    series.forEach((sensor) => {
      let min = null;
      let max = null;
      let media = 0;
      sensor.data.forEach((data) => {
        media = media + data[1];
        if (min > data[1] || min === null) {
          min = data[1];
        }
        if (max < data[1] || max === null) {
          max = data[1];
        }
      });
      sensor.min = min;
      sensor.max = max;
      sensor.media = Number((media / sensor.data.length).toFixed(2));
    });
    return series;
  }
  getSerie(metrica) {
    let sensors = [];
    const series = [];
    metrica.forEach((devices) => {
      sensors = sensors.concat(devices.sensors);
    });
    sensors.forEach((sensor) => {
      const dataSeries = [];
      sensor.data.forEach((data) => {
        data.timestamp = Number(data.timestamp);
        if (data.value != null) {
          dataSeries.push([data.timestamp, data.value]);
        }
      });
      const data = {
        name: sensor.name,
        operationName: metrica[0].operationName,
        deviceName: sensor.deviceName,
        unit: sensor.unit,
        subtype: sensor.subtype || sensor.typeName,
        operation: metrica[0].operation,
        data: dataSeries,
      };
      series.push(data);
    });
    return series;
  }
}
