import {
  Component,
  OnInit,
  Input,
  AfterViewInit,
  ChangeDetectorRef,
} from '@angular/core';
import {
  FertilizerFormulasModel,
  IrrigationEquipmentWaterSystem,
  MixtureFormulasModel,
} from '../../../farming/irrigation/irrigation.model';
import { BreakpointObserver, Breakpoints } from '@angular/cdk/layout';
import { TranslationsLibService } from '../../../services/libraries/translations-lib.service';
import {NgClass, NgFor, NgIf, NgStyle} from '@angular/common';
import { ReplaceNegativePipe } from '../../pipes/replace-negative.pipe';
import { IrrigationService } from '../../../farming/irrigation/irrigation.service';
import { AuthModule } from '../../../auth/auth.module';

@Component({
  standalone: true,
  selector: 'app-watersystem-tables',
  templateUrl: './watersystem-tables.component.html',
  styleUrls: ['./watersystem-tables.component.scss'],
  imports: [NgIf, NgFor, ReplaceNegativePipe, NgClass, AuthModule, NgStyle],
})
export class WatersystemTablesComponent implements OnInit, AfterViewInit {
  @Input() terminalVid = '';
  @Input() waterSystems: IrrigationEquipmentWaterSystem[] = [];
  @Input() fertilizerFormulasResponse: FertilizerFormulasModel[] = [];
  @Input() mixtureFormulasResponse: MixtureFormulasModel[] = [];
  @Input() compactTable = false;
  @Input() linkToTerminal = '';
  @Input() terminalType = '';
  isFetchingMixtureFormulasData = true;
  isFetchingFertilizerFormulasData = true;
  isMobile = false;
  constructor(
    private breakpointObserver: BreakpointObserver,
    private cdRef: ChangeDetectorRef,
    private irrigationService: IrrigationService,
    public translationsLib: TranslationsLibService
  ) {}

  ngAfterViewInit() {
    this.breakpointObserver
      .observe([Breakpoints.Small, Breakpoints.XSmall])
      .subscribe((result) => {
        this.isMobile = result.matches;

        this.cdRef.detectChanges();
      });
  }
  ngOnInit() {
    if (this.terminalVid === '') {
      this.isFetchingMixtureFormulasData = false;
      this.isFetchingFertilizerFormulasData = false;
    } else if (
      this.fertilizerFormulasResponse.length > 0 &&
      this.mixtureFormulasResponse.length > 0
    ) {
      this.isFetchingMixtureFormulasData = false;
      this.isFetchingFertilizerFormulasData = false;
    } else {
      this.getFertilizerFormulas().then((r) => {});
      this.getMixtureFormulas().then((r) => {});
    }
  }
  getFertilizerFormulas(): Promise<boolean> {
    return new Promise<boolean>((resolve, _) => {
      try {
        this.irrigationService
          .getFertilizerFormulas(this.terminalVid)
          .subscribe((res) => {
            this.fertilizerFormulasResponse = res;

            this.isFetchingFertilizerFormulasData = false;
            resolve(true);
          });
      } catch (err) {
        throw new Error(err);
      }
    });
  }
  getMixtureFormulas(): Promise<boolean> {
    return new Promise<boolean>((resolve, _) => {
      try {
        this.irrigationService
          .getMixtureFormulas(this.terminalVid)
          .subscribe((res) => {
            this.mixtureFormulasResponse = res;

            this.isFetchingMixtureFormulasData = false;
            resolve(true);
          });
      } catch (err) {
        throw new Error(err);
      }
    });
  }

  getFertizerFormulaName(id: number) {
    if (id === 255) {
      return '-';
    } else {
      const formula = this.fertilizerFormulasResponse.find(
        (f) => f.formula === id
      );

      return formula.name;
    }
  }

  getMixtureFormulaName(id: number) {
    if (id === 255) {
      return '-';
    } else {
      const formula = this.mixtureFormulasResponse.find(
        (f) => f.formula === id
      );

      return (
        this.translationsLib.get('irrigation_ws_mixtureFormula') +
        ' ' +
        (formula.formula + 1)
      );
    }
  }
  goToLink() {
    if (this.linkToTerminal !== '') {
      document.location.href = this.linkToTerminal;
    } else {
      console.warn('no link!');
    }
  }
}
