import { Injectable } from '@angular/core';
@Injectable({
  providedIn: 'root',
})
export class SidebarLibService {
  constructor() {
  }

  public showSidebar() {
    if (
      window.location.pathname !== '/' &&
      window.location.pathname !== '/login' &&
      window.location.pathname !== '/bo-login' &&
      window.location.pathname !== '/register' &&
      window.location.pathname.indexOf('/register-confirmation') < 0 &&
      window.location.pathname.indexOf('/reset-password') < 0 &&
      window.location.pathname.indexOf('/reset-user-invitation') < 0
    ) {
      return true;
    }
    return false;
  }
}
