<app-main-header [breadcrumbs]="breadcrumbs"></app-main-header>
<ion-content>
  <div class="container-fluid p-1 p-lg-2">
    <div class="mx-lg-4">
      <div class="col-12">
        <mat-tab-group class="col-12" (selectedTabChange)="changeTitle($event)" #tabGroup>
          <mat-tab label="{{ translationsLib.get('general') }}">
            <br>
            <app-account></app-account>
          </mat-tab>
          <mat-tab label="{{ translationsLib.get('notification_notifications') }}">
            <br>
            <app-notifications></app-notifications>
          </mat-tab>
          <mat-tab label="{{ translationsLib.get('permission_permissions') }}">
            <br>
            <app-account-permissions *ngIf="layout!== undefined" [showDemoField]="layout.general.show_attach_to_demo"></app-account-permissions>
          </mat-tab>
          <mat-tab label="{{ translationsLib.get('configuration_side_menu') }}">
            <br>
            <app-config-menu></app-config-menu>
          </mat-tab>
          <mat-tab [label]="translationsLib.get('myucrop')">
            <br>
            <app-myucrop></app-myucrop>
          </mat-tab>
        </mat-tab-group>
      </div>
    </div>
  </div>

</ion-content>
