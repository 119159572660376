import * as Highcharts from 'highcharts';

export const consumptionChartsConfigModel: any = {
  chart: {
    type: 'bar',
    zoomType: 'x',
    panning: true,
    panKey: 'shift',
  },
  title: {
    align: 'left',
    text: null,
  },
  subtitle: {
    align: 'left',
    text: '',
  },
  accessibility: {
    announceNewData: {
      enabled: true,
    },
  },
  xAxis: {
    categories: [],
    title: {
      text: null,
    },
  },
  yAxis: {
    title: {
      text: null,
    },
  },
  plotOptions: {
    bar: {
      dataLabels: {
        enabled: true,
      },
    },
  },
  credits: {
    enabled: false,
  },
  tooltip: {
    headerFormat: '<span style="font-size:11px">{series.name}</span><br>',
    pointFormat: '<b>{point.y:.0f} L</b><br/>',
  },
  dataLabels: {
    enabled: true,
    formatter() {
      return Highcharts.numberFormat(this.y, 2);
    },
  },
  series: [
    {
      name: '',
      colorByPoint: false,
      data: [],
    },
  ],
  exporting: {
    buttons: {
      contextButton: {
        menuItems: [
          'printChart',
          'separator',
          'downloadPNG',
          'downloadJPEG',
          'downloadPDF',
          'downloadSVG',
          'separator',
          'downloadCSV',
          'downloadXLS',
          //"viewData",
          'openInCloud',
        ],
      },
    },
  },
  responsive: {
    rules: [
      {
        condition: {
          maxWidth: 500,
        },
        chartOptions: {
          legend: {
            align: 'center',
            verticalAlign: 'bottom',
            layout: 'horizontal',
          },
        },
      },
    ],
  },
};
export interface ConsumptionsReportChartDataModel {
  id: string;
  parent: string;
  name: string;
  value?: number;
}
export const consumptionSunburstConfigModel: any = {
  chart: {
    height: 500,
  },

  // Let the center circle be transparent
  colors: [],

  title: {
    text: '',
  },

  subtitle: {
    text: null,
  },

  series: [
    {
      type: 'sunburst',
      // https://www.highcharts.com/forum/viewtopic.php?f=9&t=45485
      turboThreshold: 0, // Allow more than 1000 results!
      data: [],
      name: 'Root',
      allowDrillToNode: true,
      borderRadius: 3,
      cursor: 'pointer',
      dataLabels: {
        format: '{point.name}',
        filter: {
          property: 'innerArcLength',
          operator: '>',
          value: 16,
        },
      },
      levels: [
        {
          level: 1,
          levelIsConstant: false,
          dataLabels: {
            filter: {
              property: 'outerArcLength',
              operator: '>',
              value: 64,
            },
          },
        },
        {
          level: 2,
          colorByPoint: true,
        },
        {
          level: 3,
          colorByPoint: true,
        },
        {
          level: 4,
          colorVariation: {
            key: 'brightness',
            to: 0.2,
          },
        },
        {
          level: 5,
          colorVariation: {
            key: 'brightness',
            to: 0.4,
          },
        },
        {
          level: 6,
          colorVariation: {
            key: 'brightness',
            to: 0.6,
          },
        },
      ],
    },
  ],

  tooltip: {
    valueDecimals: 2,
    headerFormat: '',
    pointFormat: '<b>{point.name}</b> = <b>{point.value}</b>',
  },
  exporting: {
    buttons: {
      contextButton: {
        menuItems: [
          'printChart',
          'separator',
          'downloadPNG',
          'downloadJPEG',
          'downloadPDF',
          'downloadSVG',
          'separator',
          'downloadCSV',
          'downloadXLS',
          //"viewData",
          'openInCloud',
        ],
      },
    },
  },
  responsive: {
    rules: [
      {
        condition: {
          maxWidth: 500,
        },
        chartOptions: {
          legend: {
            align: 'center',
            verticalAlign: 'bottom',
            layout: 'horizontal',
          },
        },
      },
    ],
  },
};

export const irrigationsReportChartsConfigModel: any = {
  chart: {
    type: 'column',
    zoomType: 'x',
    panning: true,
    panKey: 'shift',
  },
  title: {
    text: null,
    align: 'left',
  },
  xAxis: {
    categories: [],
  },
  yAxis: [
    // Water Y AXIS
    {
      title: {
        text: null,
      },
      stackLabels: {
        enabled: true,
        style: {
          fontWeight: 'bold',
          color:
            // theme
            (Highcharts.defaultOptions.title.style &&
              Highcharts.defaultOptions.title.style.color) ||
            'gray',
          textOutline: 'none',
        },
      },
    },
    // Fertilizer Y AXIS
    {
      opposite: true,
      title: {
        text: null,
      },
      stackLabels: {
        enabled: true,
        style: {
          fontWeight: 'bold',
          color:
            // theme
            (Highcharts.defaultOptions.title.style &&
              Highcharts.defaultOptions.title.style.color) ||
            'gray',
          textOutline: 'none',
        },
      },
    },
  ],
  tooltip: {
    headerFormat: '<b>{point.x}</b><br/>',
    pointFormat: '{series.name}: {point.y}<br/>Total: {point.stackTotal}',
  },
  plotOptions: {
    column: {
      stacking: 'normal',
      dataLabels: {
        enabled: true,
      },
    },
  },
  series: [],
  exporting: {
    buttons: {
      contextButton: {
        menuItems: [
          'printChart',
          'separator',
          'downloadPNG',
          'downloadJPEG',
          'downloadPDF',
          'downloadSVG',
          'separator',
          'downloadCSV',
          'downloadXLS',
          //"viewData",
          'openInCloud',
        ],
      },
    },
  },
  responsive: {
    rules: [
      {
        condition: {
          maxWidth: 500,
        },
        chartOptions: {
          legend: {
            align: 'center',
            verticalAlign: 'bottom',
            layout: 'horizontal',
          },
        },
      },
    ],
  },
};
export const consumptionBarsConfigModel: any = {
  chart: {
    type: 'column',
  },
  title: {
    text: '',
  },
  subtitle: {
    text: '',
  },
  xAxis: {
    type: 'category',
    labels: {
      rotation: -45,
      style: {
        fontSize: '13px',
        fontFamily: 'Verdana, sans-serif',
      },
    },
  },
  yAxis: {
    min: 0,
    title: {
      text: '',
    },
  },
  legend: {
    enabled: false,
  },
  tooltip: {
    pointFormat: '<b>{point.y:.2f}</b>',
  },
  series: [
    {
      name: 'x',
      colors: [],
      colorByPoint: true,
      groupPadding: 0,
      dataSorting: {
        enabled: true,
      },
      data: [],
      dataLabels: {
        enabled: true,
        rotation: 0,
        color: '#FFFFFF',
        align: 'right',
        format: '{point.y:.2f}', // one decimal
        y: 0, // 0 pixels down from the top
        style: {
          fontSize: '13px',
          fontFamily: 'Verdana, sans-serif',
        },
      },
    },
  ],
  exporting: {
    buttons: {
      contextButton: {
        menuItems: [
          'printChart',
          'separator',
          'downloadPNG',
          'downloadJPEG',
          'downloadPDF',
          'downloadSVG',
          'separator',
          'downloadCSV',
          'downloadXLS',
          //"viewData",
          'openInCloud',
        ],
      },
    },
  },
  responsive: {
    rules: [
      {
        condition: {
          maxWidth: 500,
        },
        chartOptions: {
          legend: {
            align: 'center',
            verticalAlign: 'bottom',
            layout: 'horizontal',
          },
        },
      },
    ],
  },
};
