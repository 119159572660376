import { Injectable } from '@angular/core';
import { TranslationsLibService } from './translations-lib.service';

interface Permissions {
  authed_user_can_read: boolean;
  authed_user_can_write: boolean;
  authed_user_can_admin: boolean;
}

@Injectable({
  providedIn: 'root',
})
export class PermissionsTerminalsService {
  translationsLib: TranslationsLibService;
  constructor(translationsLib: TranslationsLibService) {
    this.translationsLib = translationsLib;
  }
  getPermissionText(type): string {
    return this.translationsLib.get('permission_type_' + type);
  }
  setPermissions(terminal_vid: string, permissions: Permissions) {
    localStorage.setItem(terminal_vid, JSON.stringify(permissions));
  }

  getPermissions(terminal_vid: string) {
    const terminalPermissions = localStorage.getItem(terminal_vid);

    if (terminalPermissions === null) {
      return { message: `Error parsing JSON for key ${terminal_vid}` };
    }

    if (terminalPermissions) {
      try {
        return JSON.parse(terminalPermissions);
      } catch (e) {
        console.error(
          `Error parsing JSON for key ${terminal_vid}: ${e.message}`
        );
      }
    }
  }
}
