export const environment = {
  production: true,
  backend: 'https://api-gw.ucrop.net',
  language: 'en',
  intervalCheckConnectionTimeout: 10000,
  intervalDefaultTimeout: 60000,
  google_maps_api: 'AIzaSyCrzZWsKiBzYcMI3PPuENCXBYa1Hfa1Cgo',
  google_maps_api_android: 'AIzaSyCJQl3fML2VigfChPRrooelDgrBrEK5VPM',
  google_maps_api_ios: 'AIzaSyBqFxfwaxKtwFoCfnnrBD2HaaM-_Xy08HU',
  google_maps_static_api: 'AIzaSyAeVz5BLk8oawep9Hmm6XE28O9enpJduYc',
  mapbox_token:
    'pk.eyJ1IjoibnV0cmljb250cm9sIiwiYSI6ImNtMG1namszaTAyc3Yya3NqbXJrcjdlZ3cifQ.Cb6gtZUPyi8PPqzQO5pQHg',
  frontend: 'https://app.ucrop.net/',
  my_ucrop: 'https://my.ucrop.net/',
  timeStamp: '17/09/2024 10:05:00',
  items_per_page: 10,
};
