<app-loader *ngIf="loader === true"></app-loader>

<section class="reports-main-subcomponent-container" *ngFor="let reportData of reportsData;">
  <div class="card pb-3" *ngIf="reportData.ready === true">
    <div class="card-header">
      <!-- Title -->
      <h2 class="card-header-title">
        {{ reportData.reportName }}
      </h2>
      <h2 class="card-header-subtitle">
        {{ reportData.terminalFieldName }}
      </h2>
    </div>
    <div class="row mx-2 mt-3 print-no-padding">
      <div class="col-12 p-0 p-lg-2 drainages-container print-no-padding">

        <div class="alert alert-info mb-2" role="alert" *ngIf="reportData.drainages.length === 0">
          {{ translationsLib.get('reports_no_data') }}
        </div>

        <article *ngIf="reportData.drainages.length > 0">

          <app-reports-drainages-table
            *ngIf="createdReport.simpleTable === false"
            [drainageColumnsToDisplay]="drainageColumnsToDisplay"
            [reportName]="reportData.reportName"
            [data]="reportData.drainages"
            [count]="reportData.drainages.length"
            [tablePageItemNumber]="createdReport.tablePageItemNumber"
          ></app-reports-drainages-table>
          <app-reports-simple-table
            *ngIf="createdReport.simpleTable === true"
            [columnsToDisplay]="drainageColumnsToDisplay"
            [data]="reportData.drainages"
          ></app-reports-simple-table>

        </article>
      </div>
    </div>
  </div>
</section>
