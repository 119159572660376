import { MulticropDataModel } from './../../../fields.model';
import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormBuilder, FormControl, Validators } from '@angular/forms';
import { CropsDataModel } from 'src/app/fields/fields.model';
import { TranslationsLibService } from 'src/app/services/libraries/translations-lib.service';

@Component({
  selector: 'app-multicrop-children',
  templateUrl: './multicrop-children.component.html',
  styleUrls: ['./multicrop-children.component.scss'],
})
export class MulticropChildrenComponent implements OnInit {
  @Input() edit: MulticropDataModel;
  @Input() crops: CropsDataModel['items'];
  cropTypologies = ['integrated', 'ecological', 'traditional'];
  farmTypes = [
    'open_field',
    'open_field_trellis',
    'greenhouse_venlo',
    'greenhouse_tunnel',
    'greenhouse_chapel',
    'greenhouse_multichapel_multitunnel',
    'greenhouse_asymmetric',
    'greenhouse_gothic',
    'greenhouse_parral_flat',
    'greenhouse_parral_scratches_fakes',
  ];
  irrigationMethods = ['drip', 'underground_drip', 'aspersion', 'microspray'];
  irrigationSources = [
    'water_well',
    'surface_water',
    'irrigation_consortium_community',
  ];

  @Output()
  save = new EventEmitter<MulticropDataModel>();

  private fb: FormBuilder = new FormBuilder();
  fieldForm = this.fb.group({
    crop_id: [
      0,
      [Validators.required, Validators.pattern('^[0-9]*$'), Validators.min(1)],
    ],
    crop_typology: [null as string, []],
    farm_type: [null as string, []],
    irrigation_method: [null as string, []],
    irrigation_source: [null as string, []],
    distance_between_rows: [null as number, [Validators.pattern('^[0-9]*$')]],
    distance_between_plants_in_row: [
      null as number,
      [Validators.pattern('^[0-9]*$')],
    ],
    drippers_per_plant: [null as number, [Validators.pattern('^[0-9]*$')]],
    emitter_type: [null as number, [Validators.pattern('^[0-9]*$')]],
  });

  constructor(public translationsLib: TranslationsLibService) {}

  ngOnInit(): void {
    if (this.edit) {
      const {
        crop_id,
        crop_typology,
        farm_type,
        irrigation_method,
        irrigation_source,
        distance_between_rows,
        distance_between_plants_in_row,
        drippers_per_plant,
        emitter_type,
      } = this.edit;
      this.fieldForm.setValue({
        crop_id,
        crop_typology,
        farm_type,
        irrigation_method,
        irrigation_source,
        distance_between_rows,
        distance_between_plants_in_row,
        drippers_per_plant,
        emitter_type,
      });
    }
  }

  onSubmit() {
    event.preventDefault();
    if (this.fieldForm.valid) {
      const multicropToEmit = {
        ...this.fieldForm.value,
        vid: this.edit?.vid || null,
      };
      this.save.emit(multicropToEmit as MulticropDataModel);
    }
  }
}
