import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { FieldsService } from '../fields.service';
import { HelpersLibService } from '../../services/libraries/helpers-lib.service';
import { PermissionsService } from '../../permissions/permissions.service';
import { TerminalsService } from '../../terminals/terminals.service';
import { TerminalsLibService } from '../../services/libraries/terminals-lib.service';
import { TranslationsLibService } from '../../services/libraries/translations-lib.service';
import { PlatformLibService } from '../../services/libraries/platform-lib.service';
import Swal from 'sweetalert2';

@Component({
  selector: 'app-fields-general',
  templateUrl: './fields-general.component.html',
  styleUrls: ['./fields-general.component.scss'],
})
export class FieldsGeneralComponent implements OnInit {
  /*private map: google.maps.Map;*/
  fields: any[] = [];
  loading = true;
  breadcrumbs: any = [];
  private terminals: any = [];
  userTerminals: any = [];
  platform: boolean;
  constructor(
    public router: Router,
    private helpersLib: HelpersLibService,
    private fieldsService: FieldsService,
    private permissionsService: PermissionsService,
    private terminalsService: TerminalsService,
    private terminalsLib: TerminalsLibService,
    public translationsLib: TranslationsLibService,
    private platformLib: PlatformLibService
  ) {}

  ngOnInit() {
    this.helpersLib.setTitle(
      this.translationsLib.get('field_fields'),
      this.translationsLib.get('general'),
      null
    );
    this.platform = this.platformLib.isNative();
    this.getFields();
    this.getUserTerminals();
    this.breadcrumbs = [
      {
        text: this.translationsLib.get('field_fields'),
        disabled: false,
        to: '/fields-general',
        active: false,
      },
      {
        text: this.translationsLib.get('general'),
        disabled: false,
        to: ' ',
        active: true,
      },
    ];
  }

  getUserTerminals() {
    this.terminalsService.getTerminals().subscribe((terminals) => {
      terminals.forEach((terminal) => {
        this.userTerminals.push(terminal);
      });
    });
  }

  getFields() {
    this.fieldsService.getFields().subscribe(async (res) => {
      this.fields = res;
      if (this.fields.length === 0) {
        this.loading = false;
      } else {
        await this.fields.forEach((field) => {
          field.terminals_info.forEach((terminal) => {
            this.terminals.push(terminal.vid);
          });
          const data = {
            method: 'get',
            terminals_vid: this.terminals,
          };
          if (this.terminals.length > 0) {
            this.terminalsService.getTerminalsApiInfo(data).subscribe((res) => {
              this.terminals = [];
              if (!res.status) {
                res.forEach((term) => {
                  if (
                    term.states.irrigating === false &&
                    field.irrgationStatus !== true
                  ) {
                    field.irrgationStatus = term.states.irrigating;
                  } else {
                    field.irrgationStatus = term.states.irrigating;
                  }
                });
                setTimeout(() => {
                  this.loading = false;
                }, 400);
                if (this.fields.length > 0) {
                  this.hideLoaderAndLoadMap();
                }
              } else {
                this.hideLoaderAndLoadMap();
              }
            });
          } else {
            field.irrgationStatus = false;
            this.hideLoaderAndLoadMap();
          }
        });
      }
    });
  }

  get terminalsWithoutField() {
    return this.userTerminals.filter((terminal) => terminal.field === null);
  }

  hideLoaderAndLoadMap() {
    setTimeout(() => {
      this.loading = false;
    }, 400);
  }

  showAttachDemoFieldAlert() {
    Swal.fire({
      icon: 'question',
      title: this.translationsLib.get('field_modal_title_attach_demo'),
      text: this.translationsLib.get('field_modal_text_attach_demo'),
      confirmButtonColor: '#224261',
      confirmButtonText: this.translationsLib.get('field_attach_demo_field'),
      showCancelButton: true,
      cancelButtonText: this.translationsLib.get('no_thanks'),
    }).then((result) => {
      if (result.isConfirmed) {
        this.permissionsService
          .postPairingAttachToDemo()
          .subscribe((result) => {
            Swal.fire({
              icon: 'success',
              title: this.translationsLib.get(
                'field_modal_title_demo_field_attached'
              ),
              text: this.translationsLib.get(
                'field_modal_text_demo_field_attached'
              ),
            }).then((result) => {
              window.location.reload();
            });
          });
      }
    });
  }
}
