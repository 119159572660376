import { Component, OnInit } from '@angular/core';
import { SessionLibService } from '../../services/libraries/session-lib.service';
import { TranslationsLibService } from '../../services/libraries/translations-lib.service';
import { HelpersLibService } from '../../services/libraries/helpers-lib.service';
import { NotificationsService } from '../notifications.service';
import { UsersLibService } from '../../services/libraries/users-lib.service';
import { MainHeaderComponent } from '../../commons/components/main-header/main-header.component';

@Component({
  selector: 'app-invitations',
  templateUrl: './invitations.component.html',
  styleUrls: ['./invitations.component.scss'],
})
export class InvitationsComponent implements OnInit {
  public invitations = [];
  public userEmail;
  private pends = 0;
  constructor(
    private session: SessionLibService,
    public translationsLib: TranslationsLibService,
    private helpersLib: HelpersLibService,
    private notificationsService: NotificationsService,
    public usersLib: UsersLibService,
    private headerService: MainHeaderComponent
  ) {}

  ngOnInit() {
    this.userEmail = this.session.getSessionEmail();
    this.helpersLib.setTitle(
      this.translationsLib.get('notification_notifications'),
      this.translationsLib.get('notification_notifications'),
      null
    );
    this.helpersLib.sendEventPageView('notificaciones');
    this.getPending();
    this.getInvitations();
  }
  getInvitations() {
    this.notificationsService.getInvitations().subscribe((res) => {
      this.invitations = res.items;
    });
  }
  getPending() {
    this.notificationsService.getPendingInvitations().subscribe((res) => {
      this.pends = res.pending_invitations;
    });
  }
  putInvitationResponse(vid, mode, type) {
    const data = {
      status: type,
      mode,
    };
    this.notificationsService.putInvitationResponse(vid, data).subscribe(() => {
      this.getInvitations();
    });
  }
  putResendEmail(vid, mode) {
    const data = {
      mode,
    };
    this.notificationsService.putResendEmail(vid, data).subscribe(() => {
      this.getInvitations();
      this.headerService.getPending();
    });
  }
  getRolText(type) {
    return this.usersLib.getRolText(type);
  }

  reload() {
    location.reload();
  }
}
