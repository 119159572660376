import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { RestApiService } from '../services/api/rest-api.service';
import {
  WeatherForecastDataModel,
  WeatherHistoricDataModel,
  WeatherPredictionDataModel,
} from './weather.model';
@Injectable({
  providedIn: 'root',
})
export class WeatherService {
  private restApiService: RestApiService;

  constructor(restApiService: RestApiService) {
    this.restApiService = restApiService;
  }

  public getWeatherCurrentByField(vid): Observable<WeatherPredictionDataModel> {
    return new Observable((observer) => {
      this.restApiService.getWeatherCurrentByField(vid).subscribe(
        (response) => {
          observer.next(response);
        },
        (error) => {
          observer.next(error);
        }
      );
    });
  }
  public getWeatherCurrentWeatherByMultipleElements(
    data
  ): Observable<WeatherPredictionDataModel[]> {
    return new Observable((observer) => {
      this.restApiService
        .getWeatherCurrentWeatherByMultipleElements(data)
        .subscribe(
          (response) => {
            observer.next(response);
          },
          (error) => {
            observer.next(error);
          }
        );
    });
  }
  public getCurrentWeatherByTerminal(
    vid
  ): Observable<WeatherPredictionDataModel> {
    return new Observable((observer) => {
      this.restApiService.getCurrentWeatherByTerminal(vid).subscribe(
        (response) => {
          observer.next(response);
        },
        (error) => {
          observer.next(error);
        }
      );
    });
  }
  public getForecast(vid): Observable<WeatherForecastDataModel> {
    return new Observable((observer) => {
      this.restApiService.getWeatherForecastByField(vid).subscribe(
        (response) => {
          observer.next(response);
        },
        (error) => {
          observer.next(error);
        }
      );
    });
  }
  public getHistoric(
    vid: string,
    startingDate: string,
    endingDate: string,
    noCache: boolean = false
  ): Observable<WeatherHistoricDataModel> {
    return new Observable((observer) => {
      this.restApiService
        .getWeatherHistoric(vid, startingDate, endingDate, noCache)
        .subscribe(
          (response) => {
            observer.next(response);
          },
          (error) => {
            observer.next(error);
          }
        );
    });
  }
}
