import { Component, Input, OnInit } from '@angular/core';
import { AlarmsLibService } from '../../services/libraries/alarms-lib.service';
import { TranslationsLibService } from '../../services/libraries/translations-lib.service';
import { HelpersLibService } from '../../services/libraries/helpers-lib.service';
import { ReportsService } from '../reports.service';
import {
  CreatedReportModel,
  IrrigationReportDataModel,
  IrrigationReportGroupsDataModel,
  IrrigationReportModel,
} from '../reports.model';
import { TimeLibService } from '../../services/libraries/time-lib.service';
import { HighchartsLibService } from '../../services/libraries/highcharts-lib.service';
import { irrigationsReportChartsConfigModel } from '../reports-charts.model';

import * as moment from 'moment';
import * as Highcharts from 'highcharts';
import theme from 'highcharts/themes/high-contrast-light';
import Exporting from 'highcharts/modules/exporting';
import ExportData from 'highcharts/modules/export-data';
theme(Highcharts);
Exporting(Highcharts);
ExportData(Highcharts);

@Component({
  selector: 'app-reports-irrigations',
  templateUrl: './reports-irrigations.component.html',
  styleUrls: ['./reports-irrigations.component.scss'],
  animations: [],
})
export class ReportsIrrigationsComponent implements OnInit {
  fertilizerColumnsToDisplay: string[] = [
    'group_name',
    'phase',
    'timeBegin',
    'timeEnd',
    'duration',
    'valves',
    'water'
  ];
  irrigationColumnsToDisplay = [
    'programName',
    'numActivation',
    'timeBegin',
    'timeEnd',
    'duration',
    'progConsumption_water',
    'realConsumption_water',
    'activationType',
  ];
  Highcharts = Highcharts;
  chart;
  updateFlag = false;
  chartConstructor = 'chart';
  chartCallback;
  expandedElement: IrrigationReportDataModel | null;
  @Input() createdReport: CreatedReportModel;
  loader = false;
  chartWaterSeries: any = {};
  chartFertilizerSeries: any = {};
  filterProgramList: string[] = [];
  filterProgramIndexes: any = {};
  reportsData: IrrigationReportModel[] = [];
  index_count = 1;
  index_mapping = {};
  constructor(
    private highchartsLib: HighchartsLibService,
    private alarmsLib: AlarmsLibService,
    private helpersLib: HelpersLibService,
    private reportsService: ReportsService,
    private timeLib: TimeLibService,
    public translationsLib: TranslationsLibService
  ) {}
  ngOnInit() {
    Highcharts.setOptions({ lang: this.highchartsLib.getLangOptions() });
    this.chartCallback = (chart) => {};
    this.resetAll();
    this.processIrrigation();
  }

  public processIrrigation(): void {
    const begin = this.timeLib.beginDate(
      this.createdReport.dateRange.start,
      0,
      'days'
    );
    const end = this.timeLib.endDate(
      this.createdReport.dateRange.end,
      0,
      'days'
    );

    this.loader = true;
    const terminal_id_max = this.createdReport.terminals.length - 1;
    this.createdReport.terminals.forEach((terminalReport, terminalId) => {
      this.reportsService
        .getIrrigationReport(terminalReport.vid, begin, end, null)
        .subscribe((res) => {
          this.helpersLib.sendEventPageView(
            'Reports | Irrigations | ' + terminalReport.name_vid
          );
          res.terminalVid = terminalReport.vid;
          res.terminalNameVid = terminalReport.name_vid;
          res.terminalFieldName = terminalReport.field_name;
          res.terminalFieldVid = terminalReport.field_vid;
          res.irrigations.sort((a, b) => {
            console.log(a);
            return new Date(a.date).getTime() - new Date(b.date).getTime();
          });
          res.irrigations.forEach((irrigation, index) => {
            if (!(irrigation.program in this.filterProgramIndexes)) {
              this.filterProgramIndexes[irrigation.program] = true;
              this.filterProgramList.push(irrigation.programName);
            }

            irrigation.timeBegin = moment(
              irrigation.timeBegin,
              'YYYY-MM-DD HH:mm:ss'
            ).format('DD/MM/YYYY HH:mm:ss');
            irrigation.timeEnd = moment(
              irrigation.timeEnd,
              'YYYY-MM-DD HH:mm:ss'
            ).format('DD/MM/YYYY HH:mm:ss');
            irrigation.activationType =
              this.reportsService.getActivationTypePrettyName(
                irrigation.activationType
              );
            ['realConsumption', 'progConsumption'].forEach((m_index) => {
              Object.getOwnPropertyNames(
                res.irrigations[index][m_index]
              ).forEach((key) => {
                const value = +res.irrigations[index][m_index][key].toFixed(2);
                res.irrigations[index][m_index + '_' + key] = value;
                if (m_index === 'realConsumption' && key === 'water') {
                  this.processChartWaterSeries(
                    terminalReport.vid,
                    value,
                    irrigation
                  );
                }
              });
            });
            res.irrigations[index].fertilizers = [];
            irrigation.incidence = false;
            Object.getOwnPropertyNames(irrigation.groups).forEach((g_name) => {
              Object.getOwnPropertyNames(irrigation.groups[g_name]).forEach(
                (key1) => {
                  if (irrigation.groups[g_name][key1].incidence.trim() !== '') {
                    irrigation.incidence = true;
                  }
                  res.irrigations[index].fertilizers.push(
                    this.processFertilizer(
                      terminalReport.vid,
                      irrigation.program,
                      irrigation.programName,
                      g_name,
                      key1,
                      irrigation.groups[g_name][key1]
                    )
                  );
                }
              );
            });
          });
          res.irrigationsReportChart = this.chartMixSeriesData(
            terminalReport.vid,
            irrigationsReportChartsConfigModel
          );
          res.irrigations.forEach(irrigation=>{
            if(irrigation.fertilizers && irrigation.fertilizers.length>0){
              irrigation.fertilizers.sort((a, b) => {
                return (
                  this.parseDate(a.timeBegin).getTime() -
                  this.parseDate(b.timeBegin).getTime()
                );
              });
            }
          });
          res.ready = true;
          this.reportsData.push(res);
        });

      if (terminalId === terminal_id_max) {
        this.loader = false;
      }
    });
  }
  parseDate(dateString: string): Date {
    const [datePart, timePart] = dateString.split(' ');
    const [day, month, year] = datePart.split('/').map(Number);
    const [hours, minutes, seconds] = timePart.split(':').map(Number);

    return new Date(year, month - 1, day, hours, minutes, seconds);
  }
  public processFertilizer(
    terminalVid: string,
    programId: number,
    programName: string,
    group_name: string,
    phase: string,
    irg: IrrigationReportGroupsDataModel
  ): IrrigationReportGroupsDataModel {
    const fertilizerColumnsToDisplay = this.fertilizerColumnsToDisplay;
    ['realConsumption', 'progConsumption'].forEach((m_index) => {
      Object.getOwnPropertyNames(irg[m_index]).forEach((key) => {
        if (typeof irg[m_index][key] === 'number') {
          irg[m_index + '_' + key] = +irg[m_index][key].toFixed(2);
          if (
            m_index.indexOf('realConsumption') >= 0 &&
            key.indexOf('fertilizer') >= 0
          ) {
            this.processChartFertilizerSeries(
              terminalVid,
              programId,
              programName,
              irg[m_index + '_' + key],
              key
            );
            if (
              irg[m_index][key] > 0 &&
              !fertilizerColumnsToDisplay.includes(key)
            ) {
              fertilizerColumnsToDisplay.push(key);
            }
          }
        } else {
          irg[m_index + '_' + key] = irg[m_index][key];
        }
      });
    });
    this.fertilizerColumnsToDisplay = fertilizerColumnsToDisplay.sort((a, b) => this.extractNumber(a) - this.extractNumber(b));
    irg.group_name = group_name;
    if (irg.group_model.nombre !== null && irg.group_model.nombre !== '') {
      irg.group_name = irg.group_model.nombre;
    }
    let valves = '';
    const r_index_max = irg.valves_model.length - 1;
    irg.valves_model.forEach((rawValve, r_index) => {
      if (rawValve.nombre !== '') {
        valves += rawValve.nombre;
      } else {
        valves += rawValve.valvula + 1;
      }
      if (r_index !== r_index_max) {
        valves += ', ';
      }
    });
    irg.valves = valves;
    irg.phase = this.translationsLib.get('reports_' + phase);
    irg.timeBegin = moment(irg.timeBegin, 'YYYY-MM-DD HH:mm:ss').format(
      'DD/MM/YYYY HH:mm:ss'
    );
    irg.timeEnd = moment(irg.timeEnd, 'YYYY-MM-DD HH:mm:ss').format(
      'DD/MM/YYYY HH:mm:ss'
    );

    return irg;
  }

  private processChartWaterSeries(
    terminalVid: string,
    value: number,
    irrigation: IrrigationReportDataModel
  ) {
    if (!(terminalVid in this.chartWaterSeries)) {
      this.chartWaterSeries[terminalVid] = {};
    }
    const ws_index = 'program' + irrigation.program;
    if (ws_index in this.chartWaterSeries[terminalVid]) {
      this.chartWaterSeries[terminalVid][ws_index].value += value;
      this.chartWaterSeries[terminalVid][ws_index].value =
        +this.chartWaterSeries[terminalVid][ws_index].value.toFixed(2);
    } else {
      this.chartWaterSeries[terminalVid][ws_index] = {
        name:
          this.translationsLib.get('irrigation_program') +
          ' ' +
          irrigation.programName +
          ' (' +
          this.translationsLib.get('irrigation_irrigation') +
          ')',
        value,
      };
    }
  }

  private processChartFertilizerSeries(
    terminalVid: string,
    programId: number,
    programName: string,
    value: number,
    fertilizerId: string
  ) {
    if (value > 0) {
      if (!(terminalVid in this.chartFertilizerSeries)) {
        this.chartFertilizerSeries[terminalVid] = {};
      }
      if (programId in this.chartFertilizerSeries[terminalVid]) {
        this.chartFertilizerSeries[terminalVid][programId].fertilizers.push(
          fertilizerId
        );
        this.chartFertilizerSeries[terminalVid][programId].values.push(
          +value.toFixed(2)
        );
      } else {
        this.chartFertilizerSeries[terminalVid][programId] = {
          programId,
          fertilizers: [fertilizerId],
          name:
            this.translationsLib.get('irrigation_program') +
            ' ' +
            programName +
            ' (' +
            this.translationsLib.get('irrigation_fertilizer') +
            ')',
          values: [value],
        };
      }
    }
  }
  private chartMixSeriesData(
    terminalVid: string,
    irrigationsReportChart: any
  ): any {
    let fertilizer_count = 0;
    let water_count = 0;
    const series = [];
    if (terminalVid in this.chartWaterSeries) {
      irrigationsReportChart.xAxis.categories.push(
        this.translationsLib.get('reports_water_m3')
      );
      Object.getOwnPropertyNames(this.chartWaterSeries[terminalVid]).forEach(
        (key) => {
          water_count++;
          series.push({
            name: this.chartWaterSeries[terminalVid][key].name,
            data: [this.chartWaterSeries[terminalVid][key].value],
            yAxis: 0,
            tooltip: {
              headerFormat:
                '<span style="font-size:11px">{series.name}</span><br>',
              pointFormat: '<b>{point.y:.2f} m3</b><br/>',
            },
          });
        }
      );
    }

    const different_fertilizers = [];
    if (terminalVid in this.chartFertilizerSeries) {
      Object.getOwnPropertyNames(
        this.chartFertilizerSeries[terminalVid]
      ).forEach((key) => {
        this.chartFertilizerSeries[terminalVid][key].fertilizers.forEach(
          (fertilizer) => {
            if (!different_fertilizers.includes(fertilizer)) {
              different_fertilizers.push(fertilizer);
              fertilizer_count++;
            }
          }
        );
      });

      different_fertilizers.sort().forEach((fertilizer, f_index) => {
        irrigationsReportChart.xAxis.categories.push(
          this.translationsLib.get('irrigation_fertilizer') +
            ' ' +
            fertilizer.replace('fertilizer', '') +
            'L'
        );
      });

      Object.getOwnPropertyNames(
        this.chartFertilizerSeries[terminalVid]
      ).forEach((key) => {
        const new_data = new Array(1 + fertilizer_count).fill(0);

        console.log('new_data created', new_data);

        different_fertilizers.forEach((fertilizer, f_index) => {
          for (
            let y = 0;
            y < this.chartFertilizerSeries[terminalVid][key].fertilizers.length;
            y++
          ) {
            if (
              this.chartFertilizerSeries[terminalVid][key].fertilizers[y] ===
              fertilizer
            ) {
              const data_index =
                1 + parseInt(fertilizer.replace('fertilizer', ''), 10) - 1;

              let new_data_index = 0;
              if (!(data_index in this.index_mapping)) {
                new_data_index = this.index_count;
                this.index_mapping[data_index] = this.index_count;
                new_data[new_data_index] = 0;
                this.index_count++;
              } else {
                new_data_index = this.index_mapping[data_index];
              }
              new_data[new_data_index] +=
                this.chartFertilizerSeries[terminalVid][key].values[y];
              new_data[new_data_index] = +new_data[new_data_index].toFixed(2);
            }
          }
        });

        series.push({
          name: this.chartFertilizerSeries[terminalVid][key].name,
          data: new_data,
          yAxis: 1,
          tooltip: {
            headerFormat:
              '<span style="font-size:11px">{series.name}</span><br>',
            pointFormat: '<b>{point.y:.2f} L</b><br/>',
          },
        });
      });
    }

    for (let x = 0; x < water_count; x++) {
      for (let y = 0; y < fertilizer_count; y++) {
        // Fill with nulls and not with zeros: zeros are shown in bars
        series[x].data.push(null);
      }
    }
    // Avoid zeros, set as null
    series.forEach((v1, i) => {
      series[i].data.forEach((v2, j) => {
        if (v2 === 0) {
          series[i].data[j] = null;
        }
      });
    });

    irrigationsReportChart.series = series;
    return irrigationsReportChart;
  }
  extractNumber(fertilizerName) {
    const match = fertilizerName.match(/\d+$/); // Busca un número al final de la cadena
    return match ? parseInt(match[0], 10) : 0; // Devuelve el número encontrado o 0
  }
  resetAll() {
    console.log('[ReportsIrrigations]: Reset all');
    this.chartWaterSeries = {};
    this.chartFertilizerSeries = {};
    this.filterProgramList = [];
    this.filterProgramIndexes = {};
    this.reportsData = [];
  }
}
