import {
  AfterViewInit,
  Component,
  ElementRef,
  EventEmitter,
  Input,
  OnInit,
  Output,
  Renderer2,
  ViewChild,
} from '@angular/core';
import * as L from 'leaflet';
import { TerminalsLibService } from '../../../services/libraries/terminals-lib.service';
import { IonModal } from '@ionic/angular';
import { TranslationsLibService } from '../../../services/libraries/translations-lib.service';
import { PlatformLibService } from '../../../services/libraries/platform-lib.service';
import { TerminalsEditComponent } from '../../../terminals/terminals-edit/terminals-edit.component';
import { FieldsNewComponent } from '../../../fields/fields-new/fields-new.component';
import { TerminalActivationComponent } from '../../../terminals/terminal-activation/terminal-activation.component';
import { Router } from '@angular/router';
import { environment } from '../../../../environments/environment';
import SmoothMarkerBouncing from 'leaflet.smooth_marker_bouncing';
import 'leaflet.markercluster';
import { ValvesDataModel } from '../../../farming/irrigation/irrigation.model';
import { TerminalDataModel } from '../../../terminals/terminals.model';
import { MapFieldsDataModel } from '../../app-commons.model';
import { FieldsEditComponent } from '../../../fields/fields-edit/fields-edit.component';
import 'leaflet-geometryutil';
SmoothMarkerBouncing(L);
@Component({
  selector: 'app-leaflet-maps',
  templateUrl: './leaflet-maps.component.html',
  styleUrls: ['./leaflet-maps.component.scss'],
})
export class LeafletMapsComponent implements OnInit, AfterViewInit {
  private map: L.Map;
  selectedMarkerData;
  //Fields puede venir como array o simplemente como un objeto único dependiendo del mapa y los markers que haya, lo compruebo antes de usarlo para que no haya error
  @Input() fields:MapFieldsDataModel[];
  @Input() field: MapFieldsDataModel;
  @Input() coming: string;
  @Input() markerCoordinates: { lat: any; lng: any };
  @Input() terminalData: TerminalDataModel;
  @Input() backofficeTerminal: {
    value: any;
    patchValue: (arg0: { latitude: any; longitude: any }) => void;
  }; //esto es de un formulario
  @Input() allValves: ValvesDataModel[] = [];
  @Input() valve;
  @Input() selectedValve;
  @Output() changeValve = new EventEmitter<any>();
  @Output() changeAllValves = new EventEmitter<any>();
  @Output() backofficeEvent = new EventEmitter<any>();
  @ViewChild('terminalsModal') terminalsModal: IonModal;
  @ViewChild('fieldsModal') fieldsModal: IonModal;
  @ViewChild('valvesModal') valvesModal: IonModal;
  clusters = L.markerClusterGroup({
    showCoverageOnHover: false,
  });
  allMarkers: L.LatLng[] = [];
  allCoordinates: L.LatLng[] = [];
  polygonCoordinates: L.LatLng[] = [];
  polygon: L.Polygon;
  backend: string;
  polyline: L.Polyline;
  markers: L.CircleMarker[] = [];
  markerToEdit: L.marker;
  valvesMapMarkers: L.Marker[] = [];
  ctrlPressed = false;
  showZoomInstructions = false;
  zoomEnabled = false;
  private coordinates;
  constructor(
    public terminalsLib: TerminalsLibService,
    public translationsLib: TranslationsLibService,
    private platformLib: PlatformLibService,
    private terminalEdit: TerminalsEditComponent,
    private fieldsNew: FieldsNewComponent,
    private editField: FieldsEditComponent,
    private terminalActivation: TerminalActivationComponent,
    public router: Router,
    private renderer: Renderer2
  ) {}

  ngOnInit(): void {
    this.backend = environment.backend;
  }

  ngAfterViewInit(): void {
    setTimeout(() => {
      this.initMap();
    }, 500);
    this.renderer.listen('window', 'keydown', (event: KeyboardEvent) => {
      if (event.ctrlKey || event.metaKey) {
        this.ctrlPressed = true;
        this.map.scrollWheelZoom.enable();
        this.map.touchZoom.enable();
        this.showZoomInstructions = false;
        this.zoomEnabled = true;
      }
    });
    this.renderer.listen('window', 'keyup', (event: KeyboardEvent) => {
      if (!event.ctrlKey && !event.metaKey) {
        this.ctrlPressed = false;
        this.map.scrollWheelZoom.disable();
        this.map.touchZoom.disable();
        this.zoomEnabled = false;
      }
    });
    this.renderer.listen(
      document.getElementById(this.coming),
      'wheel',
      (event: WheelEvent) => {
        if (!this.ctrlPressed) {
          event.preventDefault();
          this.showZoomInstructions = true;
          setTimeout(() => {
            this.showZoomInstructions = false;
          }, 1000);
        }
      }
    );
  }

  private initMap(): void {
    this.map = L.map(document.getElementById(this.coming), {
      center: [40.4637, -3.7492],
      zoom: 6,
      scrollWheelZoom: false,
    });
    //ESTO ES EL HACK DE LAS LINEAS BLANCAS
    const originalInitTile = L.GridLayer.prototype._initTile;
    L.GridLayer.include({
      _initTile(tile) {
        originalInitTile.call(this, tile);

        const tileSize = this.getTileSize();

        tile.style.width = tileSize.x + 1 + 'px';
        tile.style.height = tileSize.y + 1 + 'px';
      },
    });
    //HASTA AQUI EL HACK
    if (this.coming !== 'new-field' && this.coming !== 'fields-edit') {
      L.tileLayer('https://{s}.google.com/vt/lyrs=s&x={x}&y={y}&z={z}', {
        maxZoom: 20,
        subdomains: ['mt0', 'mt1', 'mt2', 'mt3'],
      }).addTo(this.map);
    } else {
      L.tileLayer(
        'https://api.mapbox.com/styles/v1/mapbox/satellite-streets-v11/tiles/{z}/{x}/{y}?access_token=' +
          environment.mapbox_token,
        {
          maxZoom: 20,
          attribution: '&copy; <a href="https://www.mapbox.com/">Mapbox</a>',
        }
      ).addTo(this.map);
      if (this.coming !== 'fields-edit') {
        this.checkGeolocationPermission();
      }
    }
    setTimeout(() => {
      this.map.invalidateSize();
    }, 500);
    if (this.coming === 'fields-general') {
      if (Array.isArray(this.fields)){
        for (const field of this.fields) {
          const icon = this.terminalsLib.getFieldNativeMarker(
            field.irrgationStatus
          );
          this.fieldsMethod(field, icon);
        }
      }
    }
    if (this.coming === 'terminals-general') {
      if (Array.isArray(this.fields)) {
        for (const terminal of this.fields) {
          if (terminal.latitude != null && terminal.longitude != null) {
            let icon;
            if (terminal.type >= 20 && terminal.type < 30) {
              icon = this.terminalsLib.getIrrigationNativeMarker(
                terminal.irrgationStatus
              );
            } else {
              icon = this.terminalsLib.getNativeMarker(terminal.type);
            }
            this.addMarker(icon, terminal, terminal, 'terminal');
          }
        }
      }
    }
    if (this.coming === 'fields-dashboard') {
      this.fieldsMethod(this.field.field, null);
      for (const terminal of this.field.terminals) {
        if (terminal.latitude != null && terminal.longitude != null) {
          const icon = this.terminalsLib.getNativeMarker(terminal.type);
          this.addMarker(icon, terminal, terminal, 'terminal');
        }
      }
      for (const valve of this.field.allValves) {
        if (valve.latitude != null && valve.longitude != null) {
          const icon = this.terminalsLib.getIrrigationValveNativeMarker(
            valve.active
          );
          this.addMarker(icon, valve, valve, 'valve');
        }
      }
    }
    if (this.coming === 'new-field') {
      this.addResetButton();
      this.map.on('click', (e: L.LeafletMouseEvent) => {
        this.onMapClick(e);
      });
    }
    if (this.coming === 'fields-edit') {
      console.log(this.fields);
      this.fieldsMethod(this.field, null);
      this.addResetButton();
    }
    if (
      this.coming === 'terminals-activation' ||
      this.coming === 'terminals-edit'
    ) {
      if (this.markerCoordinates !== null) {
        const icon = this.terminalsLib.getNativeMarker(this.terminalData.type);
        if (this.coming === 'terminals-activation') {
          this.addMarker(
            icon,
            {
              latitude: this.markerCoordinates.lat,
              longitude: this.markerCoordinates.lng,
            },
            this.terminalData.name,
            this.terminalData.type,
            false
          );
        } else {
          if (this.allValves.length > 0) {
            this.addMarker(
              icon,
              {
                latitude: this.markerCoordinates.lat,
                longitude: this.markerCoordinates.lng,
              },
              this.terminalData.name,
              this.terminalData.type,
              false
            );
            this.allValves.forEach((valve, index) => {
              if (valve.latitude !== null && valve.longitude !== null) {
                const valveIcon =
                  environment.backend + '/static/terminals-vect/valve.svg';
                this.addMarker(valveIcon, valve, valve, 'valve', false);
              }
              if (index === this.allValves.length - 1) {
                this.repositionValvesAndConcreteMarkers();
              }
            });
          } else {
            this.addMarker(
              icon,
              {
                latitude: this.markerCoordinates.lat,
                longitude: this.markerCoordinates.lng,
              },
              this.terminalData.name,
              this.terminalData.type,
              false
            );
          }
        }
      }
    }
    if (this.coming.includes('valves-edit')) {
      const icon = environment.backend + '/static/terminals-vect/valve.svg';
      if (this.valve.latitude === null) {
        this.checkGeolocationPermission();
        this.positionMarkerWhenNoPreviouslLatLng(icon, this.valve, 'valve');
      } else {
        this.addMarker(icon, this.valve, this.valve, 'valve', false);
      }
    }
    //BACKOFFICE
    if (this.coming === 'irrigation_consulting') {
      this.fieldsMethod(this.fields, null);
    }
    if (this.coming === 'backoffice-edit') {
      if (
        this.markerCoordinates.lat === null ||
        this.markerCoordinates.lng === null
      ) {
        this.checkGeolocationPermission();
        this.positionMarkerWhenNoPreviouslLatLng(
          null,
          this.backofficeTerminal.value,
          'valve'
        );
      } else {
        this.addMarker(
          null,
          this.backofficeTerminal.value,
          this.backofficeTerminal.value,
          'valve',
          false
        );
      }
    }
    //
    if (this.allCoordinates.length > 0) {
      if (
        this.coming === 'fields-general' ||
        this.coming === 'terminals-general'
      ) {
        this.map.addLayer(this.clusters);
      }
      const bounds = L.latLngBounds(this.allCoordinates);
      this.map.fitBounds(bounds, { padding: [15, 15] });
    }
  }

  addMarker(icon, fieldBounds, markerField, markerType, popup = true) {
    let customIcon;
    let marker;
    if (icon !== null) {
      customIcon = L.icon({
        iconUrl: icon,
        iconSize: [35, 40], // tamaño del ícono
      });
      marker = L.marker([fieldBounds.latitude, fieldBounds.longitude], {
        icon: customIcon,
      });
    } else {
      marker = L.marker([fieldBounds.latitude, fieldBounds.longitude]);
    }
    if (
      this.coming === 'fields-general' ||
      this.coming === 'terminals-general'
    ) {
      this.clusters.addLayer(marker);
    } else {
      marker.addTo(this.map);
    }
    if (
      popup === false &&
      (this.coming === 'terminals-activation' ||
        this.coming.includes('valves-edit') ||
        this.coming === 'backoffice-edit' ||
        (this.coming === 'terminals-edit' && this.allValves.length === 0))
    ) {
      this.repositionMarker(icon);
      this.markerToEdit = marker;
    } else {
      if (
        popup === false &&
        this.coming === 'terminals-edit' &&
        this.allValves.length > 0
      ) {
        if (markerField.valve) {
          marker.id = markerField.valve;
          marker.valve = true;
        } else {
          marker.id = markerField;
          marker.valve = false;
        }
        this.valvesMapMarkers.push(marker);
      } else {
        marker.on('click', () => this.onMarkerClick(markerField, markerType));
      }
    }
    this.allMarkers.push([fieldBounds.latitude, fieldBounds.longitude]);
    this.allCoordinates.push([fieldBounds.latitude, fieldBounds.longitude]);
  }
  repositionValvesAndConcreteMarkers() {
    let markerToChange;
    let valveId;
    let isValve;
    let matchingMarker;
    setInterval(() => {
      matchingMarker = this.valvesMapMarkers.find(
        (marker) => marker.id === this.terminalEdit.selectedValveToEdit
      );
      this.valvesMapMarkers.forEach((mark) => {
        if (mark.id === this.terminalEdit.selectedValveToEdit) {
          if (!mark.isBouncing()) {
            markerToChange = mark;
            valveId = mark.id;
            isValve = mark.valve;
            mark.setBouncingOptions({ bounceSpeed: 100 });
            mark.bounce();
          }
        } else {
          mark.stopBouncing();
        }
      });
    }, 100);
    this.map.on('click', (e) => {
      if (this.terminalEdit.selectedValveToEdit !== '') {
        let icon;
        let markerField;
        if (!matchingMarker) {
          isValve = this.allValves.find(
            (valve) =>
              Number(valve.valve) === this.terminalEdit.selectedValveToEdit
          );
        }
        if (isValve === false) {
          markerField = this.terminalEdit.selectedValveToEdit;
          icon = this.terminalsLib.getNativeMarker(this.terminalData.type);
        } else {
          markerField = {
            valve: this.terminalEdit.selectedValveToEdit,
          };
          icon = environment.backend + '/static/terminals-vect/valve.svg';
        }
        //si no hay marcador previo no puedo borrarlo
        if (matchingMarker) {
          this.map.removeLayer(markerToChange);
        } else {
          matchingMarker = true;
        }
        // Crea un nuevo marcador en la posición clicada
        this.addMarker(
          icon,
          {
            latitude: e.latlng.lat,
            longitude: e.latlng.lng,
          },
          markerField,
          this.terminalData.type,
          false
        );
        if (!isValve) {
          this.terminalEdit.fillData.patchValue({
            coordinates: e.latlng.lat + ',' + e.latlng.lng,
          });
        } else {
          let contador = this.allValves.length;
          this.allValves.forEach((valve) => {
            if (
              Number(valve.valve) ===
              Number(this.terminalEdit.selectedValveToEdit)
            ) {
              valve.latitude = Number(e.latlng.lat);
              valve.longitude = Number(e.latlng.lng);
              valve.position = Number(valve.valve) + 1;
              contador--;
              if (contador === 0) {
                this.changeAllValves.emit(this.allValves);
              }
            } else {
              valve.position = Number(valve.valve) + 1;
              contador--;
              if (contador === 0) {
                this.changeAllValves.emit(this.allValves);
              }
            }
          });
        }
      }
    });
  }
  repositionMarker(icon) {
    this.map.on('click', (e) => {
      let name;
      let type;
      if (this.markerToEdit) {
        this.map.removeLayer(this.markerToEdit);
      }
      if (this.terminalData && this.terminalData.name) {
        name = this.terminalData.name;
        type = this.terminalData.type;
      } else {
        if (this.valve && this.valve.valve) {
          name = this.valve.valve;
          type = 'valve';
        } else {
          name = 'device';
          type = 'valve';
        }
      }
      // Crea un nuevo marcador en la posición clicada
      this.addMarker(
        icon,
        {
          latitude: e.latlng.lat,
          longitude: e.latlng.lng,
        },
        name,
        type,
        false
      );
      //reposicionamos también en lo que es el formulario para que lo guarde
      if (this.coming === 'terminals-activation') {
        this.terminalActivation.fillData.patchValue({
          coordinates: e.latlng.lat + ',' + e.latlng.lng,
        });
      }
      if (this.coming === 'terminals-edit') {
        this.terminalEdit.fillData.patchValue({
          coordinates: e.latlng.lat + ',' + e.latlng.lng,
        });
      }
      if (this.coming === 'backoffice-edit') {
        this.backofficeTerminal.patchValue({
          latitude: e.latlng.lat,
          longitude: e.latlng.lng,
        });
        this.backofficeEvent.emit(this.backofficeTerminal);
      }
      if (this.coming.includes('valves-edit')) {
        this.valve.latitude = e.latlng.lat;
        this.valve.longitude = e.latlng.lng;
        this.valve.position = Number(this.valve.valve) + 1;
        this.changeValve.emit(this.valve);
      }
    });
  }
  positionMarkerWhenNoPreviouslLatLng(icon, data, type) {
    this.map.on('click', (e) => {
      this.map.off('click'); // Deshabilitar futuros clics que creen un marker sin nada
      const marker = this.addMarker(
        icon,
        {
          latitude: e.latlng.lat,
          longitude: e.latlng.lng,
        },
        data,
        type,
        false
      );
    });
  }
  fieldsMethod(field, icon) {
    const ws_coords = [];
    // eslint-disable-next-line @typescript-eslint/prefer-for-of
    for (let x = 0; x < field.coordinates.length; x++) {
      ws_coords.push([
        field.coordinates[x].latitude,
        field.coordinates[x].longitude,
      ]);
      this.allCoordinates.push([
        field.coordinates[x].latitude,
        field.coordinates[x].longitude,
      ]);
    }
    const polygon = L.polygon(ws_coords, {
      color: field.color,
      fillColor: field.color,
      fillOpacity: 0.35,
    }).addTo(this.map);
    const center = polygon.getBounds().getCenter();
    const centerData = {
      latitude: center.lat,
      longitude: center.lng,
    };
    if (this.coming === 'fields-edit') {
      this.polygon = polygon;
    }
    if (icon !== null) {
      this.addMarker(icon, centerData, field, 'field');
    }
  }
  onMarkerClick(field, type) {
    switch (type) {
      case 'field':
        this.selectedMarkerData = field;
        this.fieldsModal.present();
        break;
      case 'terminal':
        this.selectedMarkerData = field;
        this.terminalsModal.present();
        break;
      case 'valve':
        this.selectedMarkerData = field;
        this.valvesModal.present();
        break;
    }
  }
  onMapClick(e: L.LeafletMouseEvent): void {
    const { lat, lng } = e.latlng;
    if (this.markers.length > 0 && this.isClickOnFirstMarker(e.latlng)) {
      this.finishPolygon();
      return;
    }
    this.polygonCoordinates.push(e.latlng);
    const marker = L.circleMarker([lat, lng], {
      radius: 5,
      color: 'white',
      fillColor: this.fieldsNew.fieldForm.value.color,
      fillOpacity: 0.5,
    }).addTo(this.map);
    this.markers.push(marker);
    if (this.polygonCoordinates.length > 1) {
      if (this.polyline) {
        this.map.removeLayer(this.polyline);
      }
      this.polyline = L.polyline(this.polygonCoordinates, {
        color: this.fieldsNew.fieldForm.value.color,
      }).addTo(this.map);
    }
  }
  isClickOnFirstMarker(latlng: L.LatLng): boolean {
    const firstMarkerLatLng = this.polygonCoordinates[0];
    const distance = latlng.distanceTo(firstMarkerLatLng); // Calcula la distancia al primer marcador
    return distance < 15; // Un pequeño margen para detectar el clic
  }
  finishPolygon(): void {
    if (this.polygonCoordinates.length > 2) {
      if (this.polyline) {
        this.map.removeLayer(this.polyline);
      }
      if (this.coming === 'fields-edit') {
        this.polygon = L.polygon(this.polygonCoordinates, {
          color: this.field['color'],
        }).addTo(this.map);
        //tanto el nombre del objeto como la página se llaman editField
        this.editField.editField.patchValue({
          coordinates: this.polygonCoordinates,
        });
        this.editField.fieldSize = this.calculatePolygonArea(
          this.polygon.getLatLngs()[0] as L.LatLng[]
        );
      } else {
        this.polygon = L.polygon(this.polygonCoordinates, {
          color: this.fieldsNew.fieldForm.value.color,
        }).addTo(this.map);
        this.fieldsNew.coordinatesMap = this.polygonCoordinates;
      }
      this.map.off('click'); // Deshabilitar futuros clics si no deseas más interacción
    }
  }
  checkGeolocationPermission(): void {
    if (navigator.permissions) {
      // Verificar el estado de los permisos de geolocalización
      navigator.permissions
        .query({ name: 'geolocation' })
        .then((permissionStatus) => {
          if (permissionStatus.state === 'granted') {
            // Si el permiso está concedido, obtener directamente la ubicación
            this.getCurrentLocation();
          } else if (permissionStatus.state === 'prompt') {
            // Si el permiso necesita ser solicitado, pedir la ubicación
            this.getCurrentLocation();
          } else if (permissionStatus.state === 'denied') {
            this.map.setView([40.4637, -3.7492], 6);
          }

          // Escuchar cualquier cambio en el estado del permiso
          permissionStatus.onchange = () => {
            if (permissionStatus.state === 'granted') {
              this.getCurrentLocation();
            }
          };
        });
    } else {
      // Si `navigator.permissions` no está disponible, solicitar la ubicación directamente
      this.getCurrentLocation();
    }
  }
  getCurrentLocation(): void {
    this.map.locate({ setView: true, maxZoom: 16 });
    this.map.on('locationfound', (e: L.LocationEvent) => {
      this.map.setView([e.latlng.lat, e.latlng.lng], 16);
    });
  }
  resetMap(): void {
    // Remover todos los marcadores
    this.markers.forEach((marker) => {
      this.map.removeLayer(marker);
    });
    this.markers = [];

    // Remover la línea temporal
    if (this.polyline) {
      this.map.removeLayer(this.polyline);
      this.polyline = null;
    }

    // Remover el polígono
    if (this.polygon) {
      this.map.removeLayer(this.polygon);
      this.polygon = null;
    }

    // Vaciar las coordenadas del polígono
    this.polygonCoordinates = [];

    // Volver a activar los clics en el mapa
    this.map.on('click', (e: L.LeafletMouseEvent) => {
      this.onMapClick(e);
    });
  }
  addResetButton(): void {
    const resetButton = L.control({ position: 'topright' });
    resetButton.onAdd = () => {
      const button = L.DomUtil.create('button', '');
      button.innerHTML = this.translationsLib.get('reset');
      button.style.padding = '8px';
      button.style.backgroundColor = 'white';
      button.style.border = '2px solid black';
      // Evitar que el clic en el botón haga clic en el mapa
      L.DomEvent.on(button, 'click', (e: MouseEvent) => {
        L.DomEvent.stopPropagation(e); // Evitar que el clic en el botón se propague al mapa
        this.resetMap();
      });
      return button;
    };
    resetButton.addTo(this.map);
  }
  calculatePolygonArea(latlngs: L.LatLng[]): number {
    const earthRadius = 6378137; // Radio de la Tierra en metros
    let area = 0;

    if (latlngs.length > 2) {
      for (let i = 0, len = latlngs.length; i < len; i++) {
        const p1 = latlngs[i];
        const p2 = latlngs[(i + 1) % len];
        area +=
          this.toRadians(p2.lng - p1.lng) *
          (2 +
            Math.sin(this.toRadians(p1.lat)) +
            Math.sin(this.toRadians(p2.lat)));
      }
      area = (area * earthRadius * earthRadius) / 2.0;
    }

    return Math.abs(Number((area / 10000).toFixed(2)));
  }

  toRadians(degrees: number): number {
    return (degrees * Math.PI) / 180;
  }
}
