import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SupportScreenComponent } from './support-screen/support-screen.component';
import { AppCommonsModule } from '../commons/app-commons.module';
import { IonicModule } from '@ionic/angular';



@NgModule({
  declarations: [
    SupportScreenComponent
  ],
  imports: [
    CommonModule,
    AppCommonsModule,
    IonicModule
  ],
  exports: [
    SupportScreenComponent
  ]
})
export class SupportModule { }
