<div class="card">
  <div class="card-header" style="height: auto">
    <!-- Title -->
    <app-device-header
      [equipment]="equipment"
      [link]="'/farming/' + equipment.serialNumber + '/climate'"
    ></app-device-header>
  </div>
  <div class="row mx-2 mt-3">
    <div class="row">
      <app-climate-meteo-table
        [statesResponse]="statesResponse"
        [meteoResponse]="meteoResponse"
        [meteoStructureResponse]="meteoStructureResponse"
        [statesStructureResponse]="statesStructureResponse"
        [meteoVisibility]="meteoVisibility"
        [searchMeteoText]="searchMeteoText"
        [meteoArray]="meteoArray"
      >
      </app-climate-meteo-table>
    </div>
  </div>
</div>
