import {
  Component,
  EventEmitter,
  Input,
  OnChanges,
  OnInit,
  Output,
  SimpleChanges,
} from '@angular/core';
import { PermissionsService } from '../permissions.service';
import { ActivatedRoute } from '@angular/router';
import { TranslationsLibService } from '../../services/libraries/translations-lib.service';
import { UserIdentityDataModel } from '../permission.model';
import { FormControl } from '@angular/forms';
import { Observable } from 'rxjs';
import { map, startWith } from 'rxjs/operators';
import { TerminalsService } from '../../terminals/terminals.service';
import { SessionLibService } from '../../services/libraries/session-lib.service';
import Swal from 'sweetalert2';

@Component({
  selector: 'app-permissions-table',
  templateUrl: './permissions-table.component.html',
  styleUrls: ['./permissions-table.component.scss'],
})
export class PermissionsTableComponent implements OnInit, OnChanges {
  @Input() vid = '';
  @Input() canAdmin = false;
  @Input() owner: UserIdentityDataModel = null;
  @Input() installer: UserIdentityDataModel = null;
  @Input() regularUsers: UserIdentityDataModel[] = [];
  @Input() authedUserCanAdminInstaller = false;
  @Input() authedUserCanAdminOwner = false;
  @Input() contacts: UserIdentityDataModel[] = [];
  @Input() addUser = false;
  @Input() mode = '';
  @Output() getPermissionsList: EventEmitter<any> = new EventEmitter();
  emailForm = new FormControl('');
  attachInstallerForm = new FormControl('');
  attachOwnerForm = new FormControl('');
  showAttachInstaller = false;
  showAttachOwner = false;
  sessionUserEmail = '';
  filteredOptions: Observable<string[]>;
  permissions;
  contactOptions = [];
  nonInjectedRegularUsers: UserIdentityDataModel[] = [];
  nonInjectedRegularUsersLoading = false;
  injectedRegularUsers: UserIdentityDataModel[] = [];
  ready = false;
  constructor(
    private permissionService: PermissionsService,
    private route: ActivatedRoute,
    private sessionLib: SessionLibService,
    private terminalService: TerminalsService,
    public translationsLib: TranslationsLibService
  ) {
    if (this.vid === '') {
      this.vid = this.route.snapshot.paramMap.get('vid');
    }
    setTimeout(() => {
      this.ready = true;
    }, 100);
  }

  ngOnInit() {
    this.sessionUserEmail = this.sessionLib.getSessionEmail();

    // Set default values to avoid undefined access
    this.installer = this.installer || null;
    this.owner = this.owner || null;
    this.regularUsers = this.regularUsers || [];
    this.addUser = this.addUser || false;

    // Not needed here, made at ngOnChanges
    //this.getNonInjectedUsers();

    this.contacts.forEach((contact) => {
      this.contactOptions.push(contact.email);
    });
    this.filteredOptions = this.emailForm.valueChanges.pipe(
      startWith(''),
      map((value) => this.filter(value || ''))
    );
  }

  getNonInjectedUsers() {
    this.regularUsers.forEach((regularUser) => {
      const injectionReason =
        regularUser.permission_regular_user.injection_reason || null;
      if (injectionReason !== null) {
        this.injectedRegularUsers.push(regularUser);
      } else {
        this.nonInjectedRegularUsers.push(regularUser);
      }
    });
  }

  ngOnChanges(changes: SimpleChanges) {
    if ('regularUsers' in changes) {
      this.nonInjectedRegularUsersLoading = false;
      this.nonInjectedRegularUsers = [];
      this.getNonInjectedUsers();
    }
  }

  filter(value) {
    const filterValue = value.toLowerCase();
    return this.contactOptions.filter((option) =>
      option.toLowerCase().includes(filterValue)
    );
  }

  attachUserTo() {
    if (this.mode === 'field') {
      this.attachUserToField();
    } else if (this.mode === 'terminal') {
      this.attachUsertoTerminal();
    }
  }
  changeUserPermission(email, value) {
    this.nonInjectedRegularUsersLoading = true;
    if (this.mode === 'field') {
      this.changeUserFieldPermission(email, value);
    } else if (this.mode === 'terminal') {
      this.changeUserTerminalPermission(email, value);
    }
  }
  detachUserFrom(email) {
    if (this.mode === 'field') {
      this.detachUserFromField(email);
    } else if (this.mode === 'terminal') {
      this.detachUserFromTerminal(email);
    }
  }
  private attachUserToField() {
    this.addUser = false;
    this.permissionService
      .pairUsertoField(this.emailForm.value, this.vid, { permission: 4 })
      .subscribe(
        (res) => {
          this.emailForm.reset();
          this.getPermissionsList.emit();
        },
        (error) => {
          Swal.fire({
            text: this.translationsLib.get('permission_cant_add_that_user'),
            icon: 'error',
            confirmButtonColor: '#224261',
          });
        }
      );
  }
  private detachUserFromField(email) {
    this.permissionService
      .detachUserFromField(email, this.vid)
      .subscribe((res) => {
        if (email === this.sessionUserEmail) {
          window.location.href = '/';
        } else {
          this.getPermissionsList.emit();
        }
      });
  }
  private changeUserFieldPermission(email, value) {
    this.permissionService
      .changeUserFieldPermissions(email, this.vid, { permission: value })
      .subscribe((res) => {
        this.getPermissionsList.emit();
      });
  }
  private attachUsertoTerminal() {
    this.addUser = false;
    this.permissionService
      .pairUsertoTerminal(this.emailForm.value, this.vid, { permission: 4 })
      .subscribe(
        (res) => {
          this.emailForm.reset();
          this.getPermissionsList.emit();
        },
        (error) => {
          Swal.fire({
            text: this.translationsLib.get('permission_cant_add_that_user'),
            icon: 'error',
            confirmButtonColor: '#224261',
          });
        }
      );
  }
  private detachUserFromTerminal(email) {
    this.permissionService
      .detachUserFromTerminal(email, this.vid)
      .subscribe((res) => {
        if (email === this.sessionUserEmail) {
          window.location.href = '/';
        } else {
          this.getPermissionsList.emit();
        }
      });
  }
  private changeUserTerminalPermission(email, value) {
    this.permissionService
      .changeUserTerminalPermissions(email, this.vid, { permission: value })
      .subscribe((res) => {
        this.getPermissionsList.emit();
      });
  }

  attachInstallerToTerminal() {
    this.terminalService
      .postPairing(
        {
          pair_installer: this.attachInstallerForm.value,
        },
        this.vid
      )
      .subscribe((paired) => {
        if (
          paired.paired_installer === this.attachInstallerForm.value &&
          paired.paired_installer_status === 'pending_invitation'
        ) {
          Swal.fire({
            text: this.translationsLib.get('permission_invitation_sent'),
            icon: 'success',
            confirmButtonColor: '#224261',
          }).then((res) => {
            if (res.isConfirmed || res.isDismissed) {
              this.showAttachInstaller = false;
            }
          });
        } else {
          Swal.fire({
            text: this.translationsLib.get(
              'permission_invitation_sent_to_installer_error'
            ),
            icon: 'error',
            confirmButtonColor: '#224261',
          });
        }
      });
  }

  detachInstallerFromTerminal() {
    Swal.fire({
      text: this.translationsLib.get(
        'permission_confirm_detach_installer_from_terminal'
      ),
      icon: 'warning',
      confirmButtonColor: '#224261',
      showDenyButton: true,
      confirmButtonText: this.translationsLib.get('accept'),
      denyButtonText: this.translationsLib.get('cancel'),
    }).then((res) => {
      if (res.isConfirmed) {
        this.permissionService
          .detachInstallerFromTerminal(this.installer.email, this.vid)
          .subscribe((detachData) => {
            Swal.fire({
              text: this.translationsLib.get('save_changes_success'),
              showConfirmButton: true,
              confirmButtonText: this.translationsLib.get('accept'),
              icon: 'success',
            }).then((result) => {
              this.installer = null;
              this.ngOnInit();
            });
          });
      }
    });
  }

  attachOwnerToTerminal() {
    this.terminalService
      .postPairing(
        {
          pair_owner: this.attachOwnerForm.value,
        },
        this.vid
      )
      .subscribe((paired) => {
        if (
          paired.paired_owner === this.attachOwnerForm.value &&
          paired.paired_owner_status === 'pending_invitation'
        ) {
          Swal.fire({
            text: this.translationsLib.get('permission_invitation_sent'),
            icon: 'success',
            confirmButtonColor: '#224261',
          }).then((res) => {
            if (res.isConfirmed || res.isDismissed) {
              this.showAttachOwner = false;
            }
          });
        } else {
          Swal.fire({
            text: this.translationsLib.get(
              'permission_invitation_sent_to_owner_error'
            ),
            icon: 'error',
            confirmButtonColor: '#224261',
          });
        }
      });
  }
}
