import { Injectable } from '@angular/core';
@Injectable({
  providedIn: 'root',
})
export class ETCLibService {
  constructor() {
  }
  getNecesidadesNetas(model, etc) {
    try {
      //let ETc = this.getETc(model, ET0);
      let Kl = this.getKL(model);
      let Kv = model.kv;
      let Ka = model.ka;
      return etc * Kl * Kv * Ka;
    } catch(err) {
      console.warn('Faltan parámetros para calcular NecesidadesNetas');
      return null;
    }
  }
  // Paso 3
  getNecesidadesTotales(model, etc) {
    try {
      let necNetas = this.getNecesidadesNetas(model, etc);
      let LR = this.getLR(model);
      return necNetas / ((model.ea/100) * (model.cu/100)) * (1+LR)
    } catch(err) {
      console.warn('Faltan parámetros para calcular NecesidadesTotales');
      return null;
    }
  }
  // Paso 4
  getLitrosPlantaDia(model, etc) {
    try {
      let necTotales = this.getNecesidadesTotales(model, etc);
      return necTotales * model.a * model.b;
    } catch(err) {
      console.warn('Faltan parámetros para calcular LitrosPlantaDia');
      return null;
    }
  }
  // Paso 5
  getTiempoRiego(model, etc) {
    try {
      let lPlantaDia = this.getLitrosPlantaDia(model, etc);
      return (lPlantaDia * model.dayInterval) / (model.PLANTEMITTERS * model.dripper);
    } catch(err) {
      console.warn('Faltan parámetros para calcular TiempoRiego');
      return null;
    }
  }
  // Paso 6
  getDosisSector(model, etc) {
    try {
      let lPlantaDia = this.getLitrosPlantaDia(model, etc);
      let numPlantasSector = this.getNumPlantasSector(model);
      return (lPlantaDia * numPlantasSector);
    } catch(err) {
      console.warn('Faltan parámetros para calcular DosisSector');
      return null;
    }
  }
  getLR(model) {
    try {
      return model.cew/(2*model.da);
    } catch(err) {
      console.warn('Faltan parámetros para calcular LR');
      return null;
    }
  }
  getAreaSombreada(model) {
    try {
      return (model.da * model.da * 3.14) / (4 * model.a * model.b);
    } catch(err) {
      console.warn('Faltan parámetros para calcular AreaSombreada');
      return null;
    }
  }
  getPlantasHa(model) {
    try{
      return 10000/(model.a * model.b);
    } catch(err) {
      console.warn('Faltan parámetros para calcular PlantasHa');
      return null;
    }
  }
  getKL(model) {
    try{
      let areaSombreada = this.getAreaSombreada(model);
      let kl1 = 1.34 * areaSombreada;
      let kl2 = 0.1 + areaSombreada;
      let kl3 = areaSombreada + 0.5 * (1 - areaSombreada);
      let kl4 = areaSombreada + 0.15 * (1 - areaSombreada);
      return (kl1 + kl2 + kl3 + kl4)/4;
    }catch(err) {
      console.warn('Faltan parámetros para calcular Kl');
      return null;
    }
  }
  getNumPlantasSector(model){
    try{
      return model.sectorSurface * this.getPlantasHa(model);
    }catch(err) {
      console.warn('Faltan parámetros para calcular Kl');
      return null;
    }
  }
}
