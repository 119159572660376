<section>
  <div class="card">
    <div class="card-header p-0">
      <div class="card-header-title">
        <div class="row">
          <div class="col-6">
            <h1 class="text-start">{{ translationsLib.get('permission_main_permissions') }}</h1>
          </div>
          <div class="col-6 align-self-center">

          </div>
        </div>
      </div>
    </div>
    <div class="card-body p-0">
      <!-- MAIN PERMISSIONS -->
      <table class="table table-responsive-sm card-table">
        <thead>
        <tr>
          <th class="col-3 px-0 py-3">
            <span>
              {{ translationsLib.get('permission_user_s') }}
            </span>
          </th>
          <th class="col-2 text-start px-0 py-3">
            <span>
              {{ translationsLib.get('permission_type') }}
            </span>
          </th>
          <th class="col-5 text-start text-md-center px-0 py-3">
            <span>
              {{ translationsLib.get('status') }}
            </span>
          </th>
          <th class="col-2 text-start text-md-center px-0 py-3">
            <span *ngIf="authedUserCanAdminInstaller === true">
              {{ translationsLib.get('permission_action') }}
            </span>
          </th>
        </tr>
        </thead>
        <tbody>
        <!-- MAIN PERMISSIONS: INSTALLER -->
        <tr *ngIf="mode === 'terminal'">
          <td class="text-start pe-0 ps-0">
            <b>
              <span *ngIf="installer !== null">
                {{installer.name}} {{installer.surname}} <br/>
                <span class="text-muted">{{installer.email}}</span>
              </span>
              <span *ngIf="installer === null">
                {{ translationsLib.get('permission_no_installer') }}
              </span>
            </b>
          </td>
          <td style="color: #444444; font-size: 15px" class="p-0 text-start">
            <h2 class="text-start my-2">
              {{ translationsLib.get('permission_installer') }}
            </h2>
          </td>
          <td class="text-center">
            <ng-container *ngIf="installer === null">
              <div class="container-fluid">
                <div class="row">
                  <div class="col-12 col-xxl-3 text-start p-0">
                    <button class="btn btn-sm btn-primary" (click)="showAttachInstaller = true" *ngIf="showAttachInstaller !== true && authedUserCanAdminInstaller === true">
                      {{ translationsLib.get('permission_add_installer') }}
                    </button>
                  </div>
                </div>
                <div class="row">
                  <div class="col-12 my-2 p-0 text-start text-muted">
                    {{ translationsLib.get('permission_invitation_pending') }}
                  </div>
                </div>
              </div>
            </ng-container>
            <ng-container *ngIf="installer !== null">
              <span class="checkbox-ok">
                <ion-icon name="checkmark-outline"></ion-icon>
              </span>
            </ng-container>

            <div class="col-12 text-end" *ngIf="showAttachInstaller === true && authedUserCanAdminInstaller === true">
              <div class="row">
                <div class="col-12 text-end my-1">
                  <input type="email"
                         matInput
                         class="form-control"
                         aria-label="Text"
                         [placeholder]="translationsLib.get('permission_invite_installer')"
                         [formControl]="attachInstallerForm"
                  >
                </div>
                <div class="col-12 col-lg-1 align-self-center my-1">
                  <button class="btn btn-sm btn-primary" (click)="attachInstallerToTerminal()">
                    {{ translationsLib.get('add') }}
                  </button>
                </div>
              </div>
            </div>


          </td>
          <td class="p-0 text-center">
            <a [matMenuTriggerFor]="menu" *ngIf="(installer !== null && authedUserCanAdminInstaller === true)">
              <button mat-icon-button>
                <ion-icon name="ellipsis-vertical-outline"></ion-icon>
              </button>
            </a>
            <mat-menu #menu="matMenu">
              <button mat-menu-item *ngIf="(installer !== null && authedUserCanAdminInstaller === true)">
                <ion-icon class="icono" [ios]="'trash-outline'" [md]="'trash-outline'"></ion-icon>
                <button mat-button (click)="detachInstallerFromTerminal();">
                  {{ translationsLib.get('permission_delete_installer') }}
                </button>
              </button>
            </mat-menu>

          </td>
        </tr>
        <!-- MAIN PERMISSIONS: OWNER -->
        <tr>
          <td class="text-start pe-0 ps-0">
            <b>
              <span *ngIf="owner !== null">
                {{owner.name}} {{owner.surname}} <br/>
                <span class="text-muted">{{owner.email}}</span>
              </span>
              <span *ngIf="owner === null">
                {{ translationsLib.get('permission_no_owner') }}
              </span>
            </b>
          </td>
          <td style="color: #444444; font-size: 15px" class="p-0 text-start">
            <h2 class="text-start my-2">
              {{ translationsLib.get('permission_owner') }}
            </h2>
          </td>
          <td class="text-center">
            <ng-container *ngIf="owner === null">
              <div class="container-fluid">
                <div class="row">
                  <div class="col-12 col-xxl-3 text-start p-0">
                    <button class="btn btn-sm btn-primary" (click)="showAttachOwner = true" *ngIf="showAttachOwner !== true && authedUserCanAdminOwner === true">
                      {{ translationsLib.get('permission_add_owner') }}
                    </button>
                  </div>
                </div>
                <div class="row">
                  <div class="col-12 my-2 p-0 text-start text-muted">
                    {{ translationsLib.get('permission_invitation_pending') }}
                  </div>
                </div>
              </div>
            </ng-container>
            <ng-container *ngIf="owner !== null">
              <span class="checkbox-ok">
                <ion-icon name="checkmark-outline"></ion-icon>
              </span>
            </ng-container>
          </td>
          <td class="p-0 text-center">
            <div class="col-12 text-end" *ngIf="showAttachOwner === true && authedUserCanAdminOwner === true">
              <div class="row">
                <div class="d-none d-lg-block col-lg-5">&nbsp;</div>
                <div class="col-12 col-lg-6 text-end my-1">
                  <input type="email"
                         matInput
                         class="form-control"
                         aria-label="Text"
                         [placeholder]="translationsLib.get('permission_invite_owner')"
                         [formControl]="attachOwnerForm"
                  >
                </div>
                <div class="col-12 col-lg-1 align-self-center my-1">
                  <button class="btn btn-sm btn-primary" (click)="attachOwnerToTerminal()">
                    {{ translationsLib.get('add') }}
                  </button>
                </div>
              </div>
            </div>
          </td>
        </tr>
        </tbody>
      </table>
    </div>
  </div>

  <!-- ADMINISTRATIVE MANAGEMENT -->
  <div class="container-fluid">
    <div class="row">
      <div class="col-12 p-0" *ngIf="mode === 'terminal'">
        <app-permissions-administrative-management
          [terminalVid]="vid"
          [ownerVid]="owner?.vid"
          [installerVid]="installer?.vid"
        >
        </app-permissions-administrative-management>
      </div>
    </div>
  </div>

  <!-- NON INJECTED REGULAR USERS TABLE -->
  <div class="card">
    <div class="card-header p-0">
      <div class="card-header-title">
        <div class="row">
          <div class="col-6">
            <h1 class="text-start">{{ translationsLib.get('permission_user') }}</h1>
          </div>
          <div class="col-6 align-self-center">
            <a class="btn btn-sm btn-primary lift" *ngIf="canAdmin === true" style="float: right;" (click)="addUser = true">
              {{ translationsLib.get('permission_addUser') }}
            </a>
          </div>
        </div>
      </div>
    </div>
    <div class="card-body p-0">
      <table class="table table-responsive-sm card-table"  *ngIf="nonInjectedRegularUsersLoading === false && nonInjectedRegularUsers.length > 0 && addUser === false">
        <thead>
        <tr>
          <th class="col-4 px-0 py-3">
            {{ translationsLib.get('permission_user_s') }}
          </th>
          <th class="col-2 text-start text-md-center px-0 py-3">
            <span>
            {{ translationsLib.get('permission_read') }}
              <mat-raised-button
                #tooltip="matTooltip" (click)="tooltip.toggle()"
                matTooltip="{{ translationsLib.get('permission_read_help') }}">
              <ion-icon name="help-circle-outline"></ion-icon>
            </mat-raised-button>
            </span>
          </th>
          <th class="col-2 text-start text-md-center px-0 py-3">
            {{ translationsLib.get('permission_write') }}
            <mat-raised-button
              #tooltip="matTooltip" (click)="tooltip.toggle()"
              matTooltip="{{ translationsLib.get('permission_write_help') }}">
              <ion-icon name="help-circle-outline"></ion-icon>
            </mat-raised-button></th>
          <th class="col-2 text-start text-md-center px-0 py-3">
            {{ translationsLib.get('permission_admin') }}
            <mat-raised-button
              #tooltip="matTooltip" (click)="tooltip.toggle()"
              matTooltip="{{ translationsLib.get('permission_admin_help') }}">
              <ion-icon name="help-circle-outline"></ion-icon>
            </mat-raised-button></th>
          <th class="col-2 text-start text-md-center px-0 py-3">
            {{ translationsLib.get('permission_action') }}
          </th>
        </tr>
        </thead>
        <tbody>
        <tr *ngFor="let user of nonInjectedRegularUsers">
          <td style="color: #444444; font-size: 15px" class="p-0 text-start">
            <b>
              {{user.name}} {{user.surname}}<br>
              <span class="text-muted text-break">{{ user.email }}</span>
            </b>
          </td>
          <td class="text-center position-relative">
            <mat-radio-group *ngIf="user.permission_regular_user.permission === 4 || this.sessionUserEmail !== user.email">
              <mat-radio-button [checked]="user.permission_regular_user.permission === 4" value="4" (click)="changeUserPermission(user.email, 4)"></mat-radio-button>
            </mat-radio-group>

            <span class="radio-button-off"  *ngIf="user.permission_regular_user.permission !== 4 && this.sessionUserEmail === user.email">-</span>
          </td>
          <td class="text-center position-relative">
            <mat-radio-group *ngIf="user.permission_regular_user.permission === 5 || this.sessionUserEmail !== user.email">
              <mat-radio-button [checked]="user.permission_regular_user.permission === 5" value="5" (click)="changeUserPermission(user.email, 5)"></mat-radio-button>
            </mat-radio-group>

            <span class="radio-button-off"  *ngIf="user.permission_regular_user.permission !== 5 && this.sessionUserEmail === user.email">-</span>
          </td>
          <td class="text-center position-relative">
            <mat-radio-group *ngIf="user.permission_regular_user.permission === 3 || this.sessionUserEmail !== user.email">
              <mat-radio-button [checked]="user.permission_regular_user.permission === 3" value="3" (click)="changeUserPermission(user.email, 3)"></mat-radio-button>
            </mat-radio-group>

            <span class="radio-button-off"  *ngIf="user.permission_regular_user.permission !== 3 && this.sessionUserEmail === user.email">-</span>
          </td>
          <td *ngIf="canAdmin===true" class="p-0 text-center">
            <a class="btn btn-sm btn-danger lift" (click)="detachUserFrom(user.email)">
              <ion-icon class="icono" [ios]="'trash-outline'" [md]="'trash-outline'"></ion-icon>
            </a>
          </td>
        </tr>
        </tbody>
      </table>

      <!-- ADD USER -->
      <div class="mb-2" *ngIf="addUser === true">
        <form class="ion-justify-content-center">
          <label class="col-sm-5 col-form-label">
            {{ translationsLib.get('permission_invite_user') }}
          </label>
          <div>
            <input type="text"
                   matInput
                   class="form-control"
                   aria-label="Text"
                   [formControl]="emailForm"
                   required [matAutocomplete]="auto">
            <mat-autocomplete autoActiveFirstOption #auto="matAutocomplete">
              <mat-option *ngFor="let option of filteredOptions | async" [value]="option" [formControl]="emailForm" name="fieldName" ngDefaultControl>
                {{ option }}
              </mat-option>
            </mat-autocomplete>
          </div><br>
          <button class="btn btn-primary" style="float: right; margin-left:1em" (click)="attachUserTo()">{{ translationsLib.get('add') }}</button>
          <button class="btn btn-danger" style="float: right" (click)="addUser = false">{{ translationsLib.get('cancel') }}</button>
        </form>
      </div>

      <!-- RELOADING USERS -->
      <app-loader *ngIf="nonInjectedRegularUsersLoading === true"></app-loader>

      <!-- NO USERS -->
      <p class="pt-5" style="text-align: center; font-style: oblique" *ngIf="nonInjectedRegularUsersLoading === false && nonInjectedRegularUsers.length === 0 && addUser === false">
        <span *ngIf="mode === 'field'">
          {{ translationsLib.get('permission_field_no_users') }}
        </span>
        <span *ngIf="mode === 'terminal'">
          {{ translationsLib.get('permission_terminal_no_users') }}
        </span>
      </p>
    </div>
  </div>

  <ng-container *ngIf="injectedRegularUsers.length > 0">
    <app-permissions-table-injected-users
      [injectedRegularUsers]="injectedRegularUsers">
    </app-permissions-table-injected-users>
  </ng-container>

  <div class="card px-1 px-lg-4">
    <div class="row mt-lg-2 mt-4 mb-0" >
      <div class="col-12 text-center">
        <ion-icon id="permissions-support-icon" name="help-circle-outline"></ion-icon>
        <span id="permissions-support-text" [innerHTML]="translationsLib.get('permission_support_help')"></span>
      </div>
    </div>
  </div>
</section>
