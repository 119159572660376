import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { RestApiService } from '../services/api/rest-api.service';
import {
  InvitationsDataModel,
  InvitationsPendingDataModel, NotificationDataModel,
} from './notifications.model';

@Injectable({
  providedIn: 'root',
})
export class NotificationsService {
  private restApiService: RestApiService;
  constructor(restApiService: RestApiService) {
    this.restApiService = restApiService;
  }

  public getInvitations(): Observable<InvitationsDataModel> {
    return new Observable((observer) => {
      this.restApiService.getInvitations().subscribe(
        (response) => {
          observer.next(response);
        },
        (error) => {
          observer.error(error);
        }
      );
    });
  }
  public getPendingInvitations(): Observable<InvitationsPendingDataModel> {
    return new Observable((observer) => {
      this.restApiService.getInvitationsPendent().subscribe(
        (response) => {
          observer.next(response);
        },
        (error) => {
          observer.error(error);
        }
      );
    });
  }
  public putInvitationResponse(vid, data): Observable<InvitationsDataModel> {
    return new Observable((observer) => {
      this.restApiService.putInvitationResponse(vid, data).subscribe(
        (response) => {
          observer.next(response);
        },
        (error) => {
          observer.error(error);
        }
      );
    });
  }
  public putResendEmail(vid, data): Observable<InvitationsDataModel> {
    return new Observable((observer) => {
      this.restApiService.putResendEmail(vid, data).subscribe(
        (response) => {
          observer.next(response);
        },
        (error) => {
          observer.error(error);
        }
      );
    });
  }
  public getNotifications(
    startingDate: string,
    endingDate: string,
    userTimeZone: string,
    data
  ): Observable<NotificationDataModel[]> {
    return new Observable((observer) => {
      this.restApiService
        .getNotifications(startingDate, endingDate, userTimeZone, data)
        .subscribe(
          (response) => {
            observer.next(response);
          },
          (error) => {
            observer.error(error);
          }
        );
    });
  }
}
