import { Component, Input, OnInit } from '@angular/core';
import { TranslationsLibService } from '../../../services/libraries/translations-lib.service';
import { ReportsService } from '../../../reports/reports.service';
import { TimeLibService } from '../../../services/libraries/time-lib.service';
import { HighchartsLibService } from '../../../services/libraries/highcharts-lib.service';
import { consumptionChartsConfigModel } from '../../../reports/reports-charts.model';
import { IrrigationEquipmentModel } from '../../../farming/irrigation/irrigation.model';
import { WeatherCalculateET0Request } from '../../../weather/weather.model';
import { AppCommonsService } from '../../../commons/app-commons.service';

import * as moment from 'moment/moment';
import * as Highcharts from 'highcharts';
import theme from 'highcharts/themes/high-contrast-light';
import Exporting from 'highcharts/modules/exporting';
import ExportData from 'highcharts/modules/export-data';
import { environment } from '../../../../environments/environment';
import { PlatformLibService } from '../../../services/libraries/platform-lib.service';
theme(Highcharts);
Exporting(Highcharts);
ExportData(Highcharts);

@Component({
  selector: 'app-fields-dashboard-irrigation',
  templateUrl: './fields-dashboard-irrigation.component.html',
  styleUrls: ['./fields-dashboard-irrigation.component.scss'],
})
export class FieldsDashboardIrrigationComponent implements OnInit {
  @Input() equipment: IrrigationEquipmentModel;
  @Input() calculateET0Request: WeatherCalculateET0Request;
  today_consumption = '-';
  yesterday_ET0 = '-';
  chart;
  updateFlag = false;
  chartConstructor = 'chart';
  chartCallback;
  Highcharts = Highcharts;
  consumptionChart: any = consumptionChartsConfigModel;
  TEMPERATURE = '-';
  HUMIDITY = '-';
  img;
  consumptionNoData = true;
  constructor(
    private commonService: AppCommonsService,
    private highchartsLib: HighchartsLibService,
    private reportsService: ReportsService,
    private timeLib: TimeLibService,
    public translationsLib: TranslationsLibService,
    private platformLib: PlatformLibService
  ) {}
  ngOnInit() {
    const self = this;
    this.img = environment.backend + '/' + this.equipment.img;
    this.equipment.valves.forEach((valve) => {
      valve.name =
        valve.name ||
        this.translationsLib.get('irrigation_valve') + ' ' + (valve.valve + 1);
      if (valve.groups.length > 0 && !valve.groups[0].name) {
        valve.groups[0].name =
          this.translationsLib.get('irrigation_group') +
          ' ' +
          (valve.groups[0].group + 1);
      }
    });
    this.equipment.meteo.forEach((meteo) => {
      if (meteo.key.includes('TEMPERATURE')) {
        this.TEMPERATURE = '-';
        if (!isNaN(meteo.value)) {
          this.TEMPERATURE = meteo.value.toFixed(0);
        }
      }
      if (meteo.key.includes('HUMIDITY')) {
        this.HUMIDITY = '-';
        if (!isNaN(meteo.value)) {
          this.HUMIDITY = meteo.value.toFixed(0);
        }
      }
    });
    Highcharts.setOptions({
      colors: this.highchartsLib.getGlobalBluePaletteColors(),
      lang: this.highchartsLib.getLangOptions(),
    });

    this.chartCallback = (chart) => {
      // saving chart reference
      self.chart = chart;
      //chart.showLoading();
    };

    this.getConsumption(
      this.equipment.serialNumber,
      this.timeLib.beginDate(new Date(), -7, 'days'),
      this.timeLib.endDate(new Date(), 0, 'days')
    );

    this.getET0();
  }
  getET0() {
    this.commonService
      .calculateET0ByTerminalVid(
        this.equipment.serialNumber,
        this.calculateET0Request
      )
      .subscribe((result) => {
        this.yesterday_ET0 = result.ET0.toFixed(2);
      });
  }
  getConsumption(terminalVid: string, begin: string, end: string) {
    const self = this;

    this.reportsService
      .getIrrigationReport(terminalVid, begin, end, null)
      .subscribe((res) => {
        const series_prog_consumption = [];
        const series_real_consumption = [];
        const series_x_axis = [];
        res.irrigations.forEach((irrigation) => {
          this.consumptionNoData = false;
          if (!(irrigation.date in series_prog_consumption)) {
            const date = moment
              .utc(irrigation.date, 'YYYY/MM/DD')
              .format('DD/MM/YYYY');
            series_prog_consumption[irrigation.date] = {
              name: date,
              value: 0,
            };
            series_real_consumption[irrigation.date] = {
              name: date,
              value: 0,
            };
            series_x_axis.push(date);
          }
          series_prog_consumption[irrigation.date].value +=
            irrigation.progConsumption.water;
          series_prog_consumption[irrigation.date].value =
            +series_prog_consumption[irrigation.date].value.toFixed(2);
          series_real_consumption[irrigation.date].value +=
            irrigation.realConsumption.water;
          series_real_consumption[irrigation.date].value =
            +series_real_consumption[irrigation.date].value.toFixed(2);
        });

        const today_index = moment.utc().format('YYYY/MM/DD');
        if (today_index in series_real_consumption) {
          this.today_consumption =
            series_real_consumption[today_index].value.toFixed(2);
        }

        const keys_prog_consumption = Object.keys(series_prog_consumption);
        const keys_real_consumption = Object.keys(series_real_consumption);
        // eslint-disable-next-line prefer-arrow/prefer-arrow-functions
        const series_v_prog_consumption = keys_prog_consumption.map(function (
          v
        ) {
          return series_prog_consumption[v].value;
        });
        // eslint-disable-next-line prefer-arrow/prefer-arrow-functions
        const series_v_real_consumption = keys_real_consumption.map(function (
          v
        ) {
          return parseFloat(series_real_consumption[v].value.toFixed(2));
        });

        const series_data = [
          {
            name: this.translationsLib.get('reports_scheduled_consumption'),
            data: series_v_prog_consumption,
          },
          {
            name: this.translationsLib.get('reports_real_consumption'),
            data: series_v_real_consumption,
          },
        ];
        if (this.platformLib.isNative()) {
          self.consumptionChart.exporting.enabled = false;
        }
        //self.chart.hideLoading();
        self.consumptionChart.xAxis.categories = series_x_axis;
        self.consumptionChart.series = series_data;
        self.consumptionChart.subtitle.text = this.translationsLib.get(
          'reports_consumptions_subtitle'
        );
        self.consumptionChart.tooltip.pointFormat =
          '<b>{point.y:.2f} m<sup>3</sup></b><br/>';
        self.consumptionChart.yAxis.labels = {
          formatter() {
            return Highcharts.numberFormat(this.value, 2);
          },
        };
        self.updateFlag = true;
      });
  }
}
