<div class="terminal-grid mb-4"
  *ngIf="terminal.type === 51">
  <div class="card configurable-card-border">
    <div class="card-body p-3">
      <div class="row g-2 mb-2">
        <div class="col-6 separa separator-custom">
          <div class="data-container">
            <div class="row align-items-center">
              <div class="col-12 text-uppercase data-header custom-line">
                {{translationsLib.get('configurable_states_reading_ph')}}
              </div>
            </div>
            <div class="row align-items-center">
              <div class="col-9 data-value custom-line">
                {{equipment.states.readingPH}}
              </div>
            </div>
          </div>
        </div>
        <div class="col-6 separa separator-custom">
          <div class="data-container">
            <div class="row align-items-center">
              <div class="col-12 text-uppercase data-header custom-line">
                {{translationsLib.get('configurable_states_reading_ce')}}
              </div>
            </div>
            <div class="row align-items-center">
              <div class="col-9 data-value custom-line">
                {{equipment.states.readingCE}}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
