import { environment } from '../../environments/environment';
import { Injectable } from '@angular/core';
import {
  HttpRequest,
  HttpHandler,
  HttpEvent,
  HttpInterceptor,
  HttpErrorResponse,
  HttpHeaders,
} from '@angular/common/http';
import { Observable, throwError, BehaviorSubject, of } from 'rxjs';
import { RestApiService } from '../services/api/rest-api.service';
import { catchError, finalize, switchMap, filter, take } from 'rxjs/operators';
import { SessionLibService } from '../services/libraries/session-lib.service';
import { PlatformLibService } from '../services/libraries/platform-lib.service';
import { TokenDataModel } from '../auth/auth.model';
import Swal from 'sweetalert2';
import {TranslationsLibService} from "../services/libraries/translations-lib.service";

@Injectable()
export class JwtInterceptor implements HttpInterceptor {
  // Used for queued API calls while refreshing tokens
  tokenSubject: BehaviorSubject<string> = new BehaviorSubject<string>(null);
  isRefreshingToken = false;
  private token;
  constructor(
    private platformLib: PlatformLibService,
    private apiService: RestApiService,
    private sessionLib: SessionLibService,
    private translationsLib: TranslationsLibService
  ) {}

  // Intercept every HTTP call
  intercept(
    request: HttpRequest<any>,
    next: HttpHandler
  ): Observable<HttpEvent<any>> {
    // Check if we need additional token logic or not
    if (this.isInBlockedList(request.url)) {
      return next.handle(request);
    } else {
      return next.handle(this.addToken(request)).pipe(
        catchError((err) => {
          if (err instanceof HttpErrorResponse) {
            // Returned by backend
            let err_message = '';
            if ('message' in err) {
              err_message = err.message;
            }
            if ('error' in err) {
              if (typeof err.error === 'string') {
                err_message = err.error;
              } else if (
                'error' in err.error &&
                typeof err.error.error === 'string'
              ) {
                err_message = err.error.error;
              }
            }

            // Handle error!
            switch (err.status) {
              case 400:
                console.error('[UCROP-Interceptor]: Handle 400 error', err);
                return this.handle400Error(err);
              case 401:
                if (err_message.indexOf('[THROW IT]') >= 0) {
                  console.error(
                    '[UCROP-Interceptor]: Handle 401 error -> throw it!',
                    err
                  );
                  return throwError(err);
                } else {
                  console.error(
                    '[UCROP-Interceptor]: Handle 401 error -> handle as 403 error!',
                    err_message,
                    err
                  );
                  return this.handle403Error(request, next, err_message);
                }
              case 403:
                console.error(
                  '[UCROP-Interceptor]: Handle 403 error',
                  err_message,
                  err
                );
                return this.handle403Error(request, next, err_message);
              case 429:
                console.error(
                  '[UCROP-Interceptor]: Handle 429 throttling error',
                  err_message,
                  err
                );
                Swal.fire({
                  text: this.translationsLib.get('throttling_error'),
                  icon: 'error',
                  confirmButtonColor: '#224261',
                });
                return throwError('throttling_error');
              default:
                console.error(
                  '[UCROP-Interceptor]: Handle default error',
                  err_message,
                  err
                );
                return throwError(err);
            }
          } else {
            console.error(
              '[UCROP-Interceptor]: NonHttpErrorResponse Instance',
              err
            );
            return throwError(err);
          }
        })
      );
    }
  }

  // Filter out URLs where you don't want to add the token!
  private isInBlockedList(url: string): boolean {
    // Example: Filter out our login and logout API call
    return url === `${environment.backend}/auth`;
  }

  // Add our current access token from the service if present
  private addToken(req: HttpRequest<any>) {
    if (this.sessionLib.getSessionToken()) {
      return req.clone({
        headers: new HttpHeaders({
          'api-key': `${this.sessionLib.getSessionToken()}`,
          'Content-Type': 'application/json',
          Accept: 'application/json',
          language: this.sessionLib.getLanguage(),
          'User-Platform': this.platformLib.choosePlatform(),
          'User-Platform-Native': this.platformLib.isNative().toString(),
        }),
      });
    } else {
      window.console.info('[UCROP-Interceptor]: addToken Else');
      return req;
    }
  }
  private async handle400Error(err) {
    // Potentially check the exact error reason for the 400
    // then log out the user automatically
    alert('Logged out due to authentication mismatch');
    this.sessionLib.destroySession();
    return of(null);
  }

  // Indicates our access token is invalid, try to load a new one
  private handle403Error(
    request: HttpRequest<any>,
    next: HttpHandler,
    err_message: string
  ): Observable<any> {
    // Check if another call is already using the refresh logic
    window.console.info(
      '[UCROP-Interceptor-handle403Error]: Init: ' + err_message
    );
    // err_message is returned by the backend
    if (err_message.indexOf('Access denied') >= 0 && !this.isRefreshingToken) {
      // Set to null so other requests will wait
      // until we got a new token!
      this.tokenSubject.next(null);
      this.isRefreshingToken = true;
      this.token = {
        vid: this.sessionLib.getSessionVid(),
        token: this.sessionLib.getSessionToken(),
        access_token: this.sessionLib.getAccessToken(),
      };

      window.console.info(
        '[UCROP-Interceptor-handle403Error]: Refresh Token',
        this.token
      );

      // First, get a new access token
      return this.apiService.refreshToken(this.token).pipe(
        switchMap((token: TokenDataModel) => {
          window.console.info(
            '[UCROP-Interceptor-handle403Error]: Token',
            token
          );
          if (token) {
            window.console.info(
              '[UCROP-Interceptor-handle403Error]: Update Session + Force Window Reload. This should happen once when reloading for first time!'
            );
            if (token.user_identity) {
              this.sessionLib.createSession(
                token.user_identity.vid,
                token.user_identity.name,
                token.token,
                token.user_identity.email,
                token.access_token,
                token.user_identity.type,
                token.user_identity.language
              );
              window.console.info(
                '[UCROP-Interceptor-handle403Error]: Create session done'
              );
            }
            this.sessionLib.updateSession(token.token);
            window.location.reload();
          } else {
            // No new token or other problem occurred
            window.console.info(
              '[UCROP-Interceptor-handle403Error]: No new token :('
            );
            return of(null);
          }
        }),
        finalize(() => {
          // Unblock the token reload logic when everything is done
          window.console.info('[UCROP-Interceptor-handle403Error]: Finalize');
          this.isRefreshingToken = false;
        })
      );
    } else {
      window.console.info('[UCROP-Interceptor-handle403Error]: Else');
      // "Queue" other calls while we load a new token
      return this.tokenSubject.pipe(
        filter((token) => token !== null),
        take(1),
        switchMap((token) => {
          // Perform the request again now that we got a new token!
          window.console.info(
            '[UCROP-Interceptor-handle403Error]: addToken',
            request
          );
          return next.handle(this.addToken(request));
        })
      );
    }
  }
}
