<ion-content>
  <div class="container-fluid p-1 p-lg-2">
    <div class="mx-lg-4">
      <div class="col-12">
        <div id="container" class="mt-1 mt-lg-4">
          <div class="card" *ngIf="wizard === true">
            <div class="card-body p-2 p-xl-4">

              <swiper
                [slidesPerView]="1"
                [centeredSlides]="true"
                [navigation]="true"
                [keyboard]="{enabled: true}"
                [virtual]="true"
                [pagination]="{dynamicBullets: true}"
                class="my-swiper">
                <ng-template swiperSlide >
                  <div class="swiper-slide-container" id="firstSlide">
                    <div class="col-12 col-md-8 pb-5">
                      <h1 class="text-start text-uppercase mt-0" id="welcome">
                        {{ translationsLib.get('dashboard_wizard_welcome_to') }}
                        <img id="logonuve" src="/assets/img/ucrop.svg">
                      </h1>
                      <h4 id="platform">
                        {{ translationsLib.get('dashboard_wizard_irrigation_climate_system_control') }}
                      </h4>
                      <h4 class="downTittle">
                        {{ translationsLib.get('dashboard_wizard_lets_go') }}
                      </h4>
                      <div class="pb-5 mb-5"></div>
                    </div>
                  </div>
                </ng-template>
                <ng-template swiperSlide >
                  <div class="swiper-slide-container px-4 px-lg-0">
                    <div class="row mt-3 mb-md-3">
                      <div class="col-12 col-md-6">
                        <div class="row" >
                          <h1 class="title-slide">{{ translationsLib.get('dashboard_wizard_exp1_title') }}</h1>
                          <p class="subtitle">{{ translationsLib.get('dashboard_wizard_exp1_text') }}</p>
                        </div>
                        <div class="d-none d-md-block row text-center pt-3 mt-3 pb-3 mb-3">
                          <img class="center-image-sm" src="../../../assets/img/Home_wizard/wizard-emparejar-activar.svg">
                        </div>
                      </div>
                      <div class="col-12 col-md-6" id="leftBar">
                        <div class="row">
                          <h1 class="title-slide">{{ translationsLib.get('dashboard_wizard_exp2_title') }}</h1>
                          <p class="subtitle">{{ translationsLib.get('dashboard_wizard_exp2_text') }}</p>
                        </div>
                        <div class="d-none d-md-block row text-center pt-3 mt-3 pb-3 mb-3">
                          <img class="center-image-sm" src="../../../assets/img/Home_wizard/wizard-users.svg">
                        </div>
                      </div>
                    </div>
                    <hr class="d-none d-md-block">
                    <div class="row">
                      <div class="col-3 d-none d-md-block"></div>
                      <div class="col-12 col-md-6" >
                        <div class="row">
                          <h1 class="title-slide">{{ translationsLib.get('dashboard_wizard_exp3_title') }}</h1>
                          <p class="subtitle">{{ translationsLib.get('dashboard_wizard_exp3_text') }}</p>
                        </div>
                        <div class="row text-center">
                          <img class="center-image-sm step2-bottom-img" src="../../../assets/img/Home_wizard/welcome_step_3.svg">
                        </div>
                      </div>
                      <div class="col-3 d-none d-md-block"></div>
                    </div>
                  </div>
                </ng-template>
                <ng-template swiperSlide >
                  <div class="swiper-slide-container px-4 px-lg-0" id="lastSlide">
                    <div class="row">
                      <h1 class="last-slide-title">
                        {{ translationsLib.get('dashboard_wizard_step3_title') }}
                      </h1>
                      <p class="last-slide-body">
                        {{ translationsLib.get('dashboard_wizard_step3_text') }}
                      </p>
                    </div>

                    <div class="row mt-3 mb-md-3">
                      <div class="col-12 col-md-6">
                        <div class="row" >
                          <h1 class="title-slide text-center">{{ translationsLib.get('dashboard_wizard_wait_invitation') }}</h1>
                          <p class="subtitle text-center">
                            {{ translationsLib.get('dashboard_wizard_wait_invitation_text') }}
                          </p>
                        </div>
                        <div class="d-none d-md-block row text-center pt-3 mt-3 pb-3 mb-3">
                          <img class="center-image-sm" src="../../../assets/img/Home_wizard/Wizard_esperar.svg" style="max-height: 280px;">
                        </div>
                      </div>
                      <div class="col-12 col-md-6 py-4 pt-lg-0">
                        <div class="row">
                          <h1 class="title-slide text-center">
                            {{ translationsLib.get('dashboard_wizard_diy_title') }}
                          </h1>
                          <p class="subtitle text-center">
                            <button class="btn btn-terciary" (click)="router.navigate(['./pair-new-terminal'])">
                              {{ translationsLib.get('dashboard_wizard_add_new_terminal') }}
                            </button>
                          </p>
                        </div>
                        <div class="d-none d-md-block row text-center pt-3 mt-3 pb-3 mb-3">
                          <img class="center-image-sm" src="../../../assets/img/Home_wizard/Wizard_crear_equipo.svg">
                        </div>
                      </div>
                    </div>
                  </div>
                </ng-template>
              </swiper>
              <div class="text-center mt-4 mb-n4" *ngIf="layout.general.show_attach_to_demo">
                <app-demo-field-card></app-demo-field-card>
                <!--<button id="attachDemo" class="btn btn-warning" (click)="showAttachDemoFieldAlert()">{{ translationsLib.get('field_attach_demo_field') }}</button>
              -->
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</ion-content>
