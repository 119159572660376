<aside>
  <div class="prog_consumption {{ extra_class }}" *ngIf="is_exporting === false">
    {{ processedProg }}
  </div>
  <div class="sep_consumption" *ngIf="is_exporting === false">
    /
  </div>
  <div class="real_consumption {{ extra_class }}">
    {{ real }}
  </div>
</aside>
