import {
  AfterViewInit,
  Component,
  Input,
  OnChanges,
  OnInit,
  ViewChild,
} from '@angular/core';
import { TranslationsLibService } from '../../services/libraries/translations-lib.service';
import { MatPaginator } from '@angular/material/paginator';
import { MatTableDataSource } from '@angular/material/table';
import { CalendarEvent } from 'angular-calendar';
import * as moment from 'moment';

@Component({
  selector: 'app-events-calendar-table',
  templateUrl: './events-calendar-table.component.html',
  styleUrls: ['./events-calendar-table.component.scss'],
})
export class EventsCalendarTableComponent
  implements OnInit, OnChanges, AfterViewInit
{
  show = true;
  @Input() events: CalendarEvent[] = [];
  @Input() dataHeaderLabel1 = '';
  @Input() dataHeaderLabel2 = '';
  @Input() dataHeaderLabel3 = '';
  @Input() showData2 = '';
  @Input() showData3 = '';
  @Input() showFilter = '';
  @Input() defaultPaginationItemsNumber = 20;
  @Input() dateTimeFormat = 'DD-MM-YYYY HH:mm:ss';
  @ViewChild(MatPaginator) paginator: MatPaginator;
  moment: any = moment;
  dataSource: MatTableDataSource<any> = null;
  displayedColumns: string[] = [];
  paginationItemsNumber: number[] = [];

  constructor(public translationsLib: TranslationsLibService) {}
  ngOnInit() {
    this.paginationItemsNumber.push(this.defaultPaginationItemsNumber);

    this.displayedColumns.push('color');
    this.displayedColumns.push('title');
    if (this.showData2 === 'true') {
      this.displayedColumns.push('start');
    }
    if (this.showData3 === 'true') {
      this.displayedColumns.push('end');
    }
    this.dataSource = new MatTableDataSource(this.events);
  }

  ngOnChanges() {
    this.events = JSON.parse(JSON.stringify(this.events));
    this.dataSource = new MatTableDataSource(this.events);
    this.dataSource.paginator = this.paginator;
  }

  ngAfterViewInit() {
    this.dataSource.paginator = this.paginator;
  }

  applyFilter(event: Event) {
    const filterValue = (event.target as HTMLInputElement).value;
    this.dataSource.filter = filterValue.trim().toLowerCase();
    if (this.dataSource.paginator) {
      this.dataSource.paginator.firstPage();
    }
  }
}
