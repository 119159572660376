<nav aria-label="breadcrumb" class="my-3">
  <ng-container *ngIf="!isNative">
    <ol class="d-none d-md-flex breadcrumb text-center">
      <ng-container *ngFor="let breadcrumb of breadcrumbs">
        <li
          class="breadcrumb-item"
          [ngClass]="{ active: breadcrumb.active }"
          aria-current="page"
          *ngIf="breadcrumb.text && !breadcrumb.disabled"
        >
        <span *ngIf="breadcrumb.active">
          {{ breadcrumb.text }}
        </span>

          <a *ngIf="!breadcrumb.active" [routerLink]="breadcrumb.to">
            {{ breadcrumb.text }}
          </a>
        </li>
      </ng-container>
    </ol>
    <div class="d-flex d-md-none breadcrumb text-center" *ngIf="backButton[0] && !loading">
      <a [routerLink]="backButton[0].to"
         style="max-width: fit-content;line-height: 1px;font-size: 25px;">
        <ion-icon name="chevron-back-circle-outline"></ion-icon>
      </a>
      <span class="mx-2 align-self-center" style="color: #224261" *ngIf="breadcrumbs[breadcrumbs.length-2]">
        {{breadcrumbs[breadcrumbs.length-2].text}}
      </span>
    </div>
    <ol class="d-flex d-md-none breadcrumb text-center" *ngIf="!backButton[0] && !loading">
      <ng-container *ngFor="let breadcrumb of breadcrumbs">
        <li
          [ngClass]="{ active: breadcrumb.active }"
          aria-current="page"
          *ngIf="breadcrumb.text && !breadcrumb.disabled"
        >
          <a *ngIf="!breadcrumb.active" [routerLink]="breadcrumb.to">
            {{ breadcrumb.text }}
          </a>
        </li>
      </ng-container>
    </ol>
  </ng-container>
  <ng-container *ngIf="isNative">
    <div class="breadcrumb text-center" *ngIf="backButton[0] && !loading">
      <a [routerLink]="backButton[0].to"
         style="max-width: fit-content;line-height: 1px;font-size: 25px;">
        <ion-icon name="chevron-back-circle-outline"></ion-icon>
      </a>
      <span class="mx-2 align-self-center" style="color: #224261" *ngIf="breadcrumbs[breadcrumbs.length-2]">
        {{breadcrumbs[breadcrumbs.length-2].text}}
      </span>
    </div>
    <ol class="breadcrumb text-center" *ngIf="!backButton[0] && !loading">
      <ng-container *ngFor="let breadcrumb of breadcrumbs">
        <li
          [ngClass]="{ active: breadcrumb.active }"
          aria-current="page"
          *ngIf="breadcrumb.text && !breadcrumb.disabled"
        >
          <a *ngIf="!breadcrumb.active" [routerLink]="breadcrumb.to">
            {{ breadcrumb.text }}
          </a>
        </li>
      </ng-container>
    </ol>
  </ng-container>
</nav>
