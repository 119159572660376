import { Component, Input, OnInit } from '@angular/core';
import { TranslationsLibService } from 'src/app/services/libraries/translations-lib.service';
import { ConfigurableEquipmentModel } from '../configurable.model';
import { NgIf } from '@angular/common';
import { TerminalDataModel } from 'src/app/terminals/terminals.model';

@Component({
  standalone: true,
  selector: 'app-configurable-states',
  templateUrl: './configurable-states.component.html',
  styleUrls: ['./configurable-states.component.scss'],
  imports: [NgIf],
})
export class ConfigurableStatesComponent implements OnInit {
  @Input() equipment: ConfigurableEquipmentModel;
  @Input() terminal: TerminalDataModel;

  constructor(public translationsLib: TranslationsLibService) {}

  ngOnInit(): void {}
}
