import { Component, Input, OnInit } from '@angular/core';
import { TranslationsLibService } from '../../../services/libraries/translations-lib.service';
import { TerminalsService } from '../../../terminals/terminals.service';
import { TerminalAdministrativeManagementIdentityModel } from '../../../terminals/terminals.model';
import { SessionLibService } from '../../../services/libraries/session-lib.service';
import Swal from 'sweetalert2';

@Component({
  selector: 'app-permissions-administrative-management',
  templateUrl: './permissions-administrative-management.component.html',
  styleUrls: ['./permissions-administrative-management.component.scss'],
})
export class PermissionsAdministrativeManagementComponent implements OnInit {
  @Input() terminalVid = '';
  @Input() ownerVid = '';
  @Input() installerVid = '';
  loaded = false;
  canClaim = false;
  canTransferToOwner = false;
  terminalAdministrativeManagement: TerminalAdministrativeManagementIdentityModel;
  constructor(
    private sessionLib: SessionLibService,
    public translationsLib: TranslationsLibService,
    public terminalsService: TerminalsService
  ) {}

  ngOnInit() {
    this.getAM();
  }
  getAM() {
    const authedUserVid = this.sessionLib.getSessionVid();
    this.loaded = false;
    this.canClaim = false;
    this.canTransferToOwner = false;
    this.terminalsService
      .getTerminalAdministrativeManagement(this.terminalVid)
      .subscribe(
        (terminalAdministrativeManagement) => {
          this.loaded = true;
          this.terminalAdministrativeManagement =
            terminalAdministrativeManagement;
          if (
            (this.ownerVid === authedUserVid ||
              this.installerVid === authedUserVid) &&
            terminalAdministrativeManagement.administrative_management_type ===
              'none'
          ) {
            this.canClaim = true;
          } else if (
            terminalAdministrativeManagement.user_identity?.vid ===
              this.installerVid &&
            this.ownerVid !== null &&
            this.ownerVid !== this.installerVid
          ) {
            this.canTransferToOwner = true;
          }
        },
        (error) => {
          this.loaded = true;
        }
      );
  }
  claim() {
    this.loaded = false;
    this.terminalsService
      .claimTerminalAdministrativeManagement(this.terminalVid)
      .subscribe(
        (terminalAdministrativeManagement) => {
          this.getAM();
        },
        (error) => {
          this.loaded = true;
          Swal.fire({
            text: this.translationsLib.get('something_was_wrong'),
            showConfirmButton: true,
            confirmButtonText: this.translationsLib.get('accept'),
            icon: 'error',
          });
        }
      );
  }
  transferToOwner() {
    this.loaded = false;
    this.terminalsService
      .transferTerminalAdministrativeManagement(this.terminalVid)
      .subscribe(
        (terminalAdministrativeManagement) => {
          this.getAM();
        },
        (error) => {
          this.loaded = true;
          Swal.fire({
            text: this.translationsLib.get('something_was_wrong'),
            showConfirmButton: true,
            confirmButtonText: this.translationsLib.get('accept'),
            icon: 'error',
          });
        }
      );
  }
}
