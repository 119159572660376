<ion-content>
  <div class="container-fluid" id="registro">
    <div class="row justify-content-center">
      <div class="col-12 col-md-8 col-lg-6 col-xl-4 px-lg-6 my-5 align-self-center">
        <div id="login-loader">
          <div class="spinner-container">
            <div class="spinner-logo text-center">
              <img src="../../../assets/img/ucrop.svg" alt="LOGO"  style="max-width: 200px">
            </div>
          </div>
        </div>
        <!-- Heading -->
        <h1 class="text-center mb-3">
          {{ translationsLib.get('auth_register_title') }}
        </h1>

        <!-- Subheading -->
        <p class="text-muted text-center mb-5">
          {{ translationsLib.get('auth_register_subtitle') }}
        </p>

        <!-- Form -->
        <form novalidate [formGroup]="signUpForm" (submit)="onSubmit()">

          <div class="form-group form-switch">
            <input class="form-check-input" type="checkbox" formControlName="installer"/>
            <ion-label>&nbsp;{{ translationsLib.get('auth_register_i_am_installer') }}</ion-label>
          </div>

          <div class="form-group row">
            <!-- Nombre -->
            <div class="form-group col-lg-6">

              <!-- Label -->
              <label class="form-label d-block">
                <span>{{ translationsLib.get('profile_account_name') }}</span>
              </label>

              <!-- Input -->
              <input id="name" type="text" class="form-control" placeholder="{{ translationsLib.get('auth_register_name_placeholder') }}" formControlName="name"  autocomplete="new-password">
                <div id="msg" style="color:red; position: absolute " *ngIf="signUpForm.controls['name'].invalid && (signUpForm.controls['name'].dirty || signUpForm.controls['name'].touched) && signUpForm.controls['name'].errors.required">
                  {{ translationsLib.get('auth_register_name_error') }}
                 </div>
            </div>

            <!-- Apellidos -->
            <div class="form-group col-lg-6">

              <!-- Label -->
              <label class="form-label d-block">
                <span>{{ translationsLib.get('profile_account_surname') }}</span>
                <small> {{ translationsLib.get('not_mandatory') }} </small>
              </label>

              <!-- Input -->
              <input id="surname" type="text" class="form-control" placeholder="{{ translationsLib.get('auth_register_surname_placeholder') }}" formControlName="surname"  autocomplete="new-password">
              <!--
              <div  id="msg" style="color:red; position: absolute " *ngIf="signUpForm.controls['surname'].invalid && (signUpForm.controls['surname'].dirty || signUpForm.controls['surname'].touched) && signUpForm.controls['surname'].errors.required">
                {{ translationsLib.get('auth_register_surname_error') }}
              </div>
              -->
            </div>
          </div>
          <div class="form-group row">
            <div class="form-group col-md-12">
              <label class="form-label d-block">
                <span>{{ translationsLib.get('profile_account_business_name') }}</span>
                <small> {{ translationsLib.get('not_mandatory') }} </small>
              </label>
              <input type="text" class="form-control" placeholder="{{ translationsLib.get('auth_register_business_name_placeholder') }}" formControlName="company_name"  autocomplete="new-password">
              <div id="msg" style="color:red; position: absolute " *ngIf="signUpForm.controls['company_name'].invalid && (signUpForm.controls['company_name'].dirty || signUpForm.controls['company_name'].touched) && signUpForm.controls['company_name'].errors.required">
                {{ translationsLib.get('auth_register_business_name_error') }}
              </div>
            </div>
          </div>
          <div class="form-group row">

            <div class="form-group col-lg-6">
              <label class="form-label d-block">
                <span>{{ translationsLib.get('profile_account_country') }}</span>
                <small> {{ translationsLib.get('not_mandatory') }} </small>
              </label>
              <mat-form-field appearance="fill" class="w-100">
                <mat-select id="country" (selectionChange)="setCountry($event.value);">
                  <mat-option *ngFor="let country of countries" [value]="country.id">{{country.name}}</mat-option>
                </mat-select>
              </mat-form-field>
              <div id="msg" style="color:red; position: absolute " *ngIf="signUpForm.controls['country_id'].invalid && (signUpForm.controls['country_id'].dirty || signUpForm.controls['country_id'].touched) && signUpForm.controls['country_id'].errors.required">
                {{ translationsLib.get('auth_register_country_error') }}
              </div>
            </div>
            <div class="form-group col-lg-6">
              <label class="form-label d-block">
                <span>{{ translationsLib.get('profile_account_province') }}</span>
                <small> {{ translationsLib.get('not_mandatory') }} </small>
              </label>
              <input id="province" *ngIf="provinces === null" type="text" class="form-control" placeholder="" formControlName="province"  autocomplete="new-password">
              <mat-form-field appearance="fill" class="w-100" *ngIf="provinces !== null">
                <mat-select id="province" (selectionChange)="setProvince($event.value);">
                  <mat-option *ngFor="let province of provinces" [value]="province.id + '@@@' + province.name">{{province.name}}</mat-option>
                </mat-select>
              </mat-form-field>
              <!--
              <div id="msg" style="color:red; position: absolute " *ngIf="signUpForm.controls['province'].invalid && (signUpForm.controls['province'].dirty || signUpForm.controls['province'].touched) && signUpForm.controls['province'].errors.required">
                {{ translationsLib.get('auth_register_province_error') }}
              </div>
              -->
            </div>
          </div>

          <div class="form-group row">

            <div class="col-md-12">
              <label class="form-label d-block">
                <span>{{ translationsLib.get('profile_account_address') }}</span>
                <small> {{ translationsLib.get('not_mandatory') }} </small>
              </label>
              <input id="adress" type="text" class="form-control" placeholder="{{ translationsLib.get('auth_register_address_placeholder') }}" formControlName="address"  autocomplete="new-password">
              <!--
              <div id="msg"  style="color:red; position: absolute " *ngIf="signUpForm.controls['address'].invalid && (signUpForm.controls['address'].dirty || signUpForm.controls['address'].touched) && signUpForm.controls['address'].errors.required">
                {{ translationsLib.get('auth_register_address_error') }}
              </div>
              -->
            </div>
          </div>
          <br>
          <div class="form-group row">
            <div class="form-group col-lg-6">
              <label class="form-label d-block">
                <span>{{ translationsLib.get('profile_account_phone') }}</span>
                <small> {{ translationsLib.get('not_mandatory') }} </small>
              </label>
              <div class="input-group input-group-merge input-group-reverse ">
                <input id="phone" class="form-control" placeholder="" formControlName="phone"  type="text" aria-label="Input group reverse" aria-describedby="inputGroupReverse"  autocomplete="new-password">
                <div class="input-group-text" id="inputGroupReverse">
                  <span> +{{this.phone}}</span>
                </div>
              </div>
              <!--
              <div id="msg" style="color:red; position: absolute" *ngIf="signUpForm.controls['phone'].invalid && (signUpForm.controls['phone'].dirty || signUpForm.controls['phone'].touched) && signUpForm.controls['phone'].errors.required">
                {{ translationsLib.get('auth_register_phone_error') }}
              </div>
              -->
            </div>
            <!-- Email address -->
            <div class="form-group col-lg-6">
              <label class="form-label d-block">
                <span>{{ translationsLib.get('profile_account_email') }}</span>
              </label>
              <input id="email" type="email" class="form-control" placeholder="{{ translationsLib.get('auth_register_email_placeholder') }}" formControlName="email"  autocomplete="new-password">
              <!--
              <div id="msg" style="color:red; position: absolute " *ngIf="signUpForm.controls['email'].invalid && (signUpForm.controls['email'].dirty || signUpForm.controls['email'].touched) && signUpForm.controls['email'].errors.required">
                {{ translationsLib.get('auth_register_email_error') }}
              </div>
              -->
            </div>
          </div>
          <!-- Contraseña -->
          <div class="form-group row mb-2 mb-lg-0" style="overflow: hidden;" id="passstrength">
            <div class="form-group col-lg-6">
              <!-- Label -->
              <label class="form-label d-block">
                <span>{{ translationsLib.get('auth_register_password') }}</span>
              </label>
              <!-- Input group -->
              <div class="input-group input-group-merge">
                <!-- Input -->
                <input id="passwordregister" type="password" minlength="6" class="form-control" placeholder="{{ translationsLib.get('auth_register_password1_placeholder') }}" formControlName="password" autocomplete="new-password" (keydown)="checkPass()">
                <!-- Icon -->
                <span class="input-group-text" style="cursor: pointer" (click)="mostrarContrasena('pass')">
                  <ion-icon name="eye-outline"></ion-icon>
                </span>
              </div>
              <div class="row" *ngIf="signUpForm.value.password.length>1 && signUpForm.value.password.length<6">
                <div class="col" id="msg" style="color:red;">
                  {{translationsLib.get('auth_register_password_length')}}
                </div>
              </div>
              <app-password-strength *ngIf="signUpForm.value.password.length>=6" [passwordToCheck]="signUpForm.value.password"></app-password-strength>
              <br>
            </div>
              <!-- Contraseña -->
              <div class="form-group col-lg-6" id="passConfirm">

                <!-- Label -->
                <label class="form-label d-block">
                  <span>{{ translationsLib.get('auth_register_confirm_password') }}</span>
                </label>

                <!-- Input group -->
                <div class="input-group input-group-merge">

                  <!-- Input -->
                  <input id="passwordconf" class="form-control" type="password" placeholder="{{ translationsLib.get('auth_register_password1_placeholder') }}" formControlName="confirmpassword" (keyup)="samePassword()"  autocomplete="new-password">

                  <!-- Icon -->
                  <span class="input-group-text" style="cursor: pointer" (click)="mostrarContrasena('conf')">
                    <ion-icon name="eye-outline"></ion-icon>
                  </span>

                </div>
                <div id="msg" style="color:red; position: absolute " *ngIf="!samePassword()">
                  {{ translationsLib.get('auth_register_password_mismatch') }}
                </div>
              </div>
            </div>

          <div class="row">
            <div class="col-12">
              <span class="text-muted ion-text-justify" style="font-size: 0.8rem;" [innerHTML]="translationsLib.get('auth_legal_advice')"></span>
            </div>
          </div>

          <div class="form-check form-switch mb-3">
            <input id="terms" class="form-check-input" type="checkbox" formControlName="terms"/>
            <ion-label>
              <span>
                {{ translationsLib.get('auth_register_terms1') }}
              </span>
              <a style="cursor: pointer; color:#3D6493" (click)="legalAgreementsLib.showCookiePolicy()">{{ translationsLib.get('cookie_policy') }}</a>,
              <a style="cursor: pointer; color:#3D6493" (click)="legalAgreementsLib.showLegalAdvice()">{{ translationsLib.get('legal_advice') }}</a>
              {{ translationsLib.get('and') }}
              <a style="cursor: pointer; color:#3D6493" (click)="legalAgreementsLib.showPrivacyPolicy()">{{ translationsLib.get('privacy_policy') }}</a>
            </ion-label>
          </div>
          <div class="form-check form-switch mb-3">
            <input class="form-check-input" type="checkbox" formControlName="newsletter"/>
            <ion-label>
              <span>
                {{translationsLib.get('auth_accept_newsletter')}}
              </span>
            </ion-label>
          </div>

          <!-- Submit -->
          <button id="registerButton" type="submit" [disabled]="signUpForm.invalid && !getForceCheck()" class="btn btn-lg w-100 btn-primary my-3">
            {{ translationsLib.get('auth_register_button') }}
          </button>

          <!-- Link -->
          <div class="text-center">
            <span class="text-muted text-center">
              {{ translationsLib.get('auth_login_go_to_main_screen') }}
              <a style="cursor: pointer; color:#3d6493" (click)="router.navigate(['/login']);">
                {{ translationsLib.get('auth_login_sign_up_text') }}
              </a>
            </span>
          </div>
        </form>

      </div>
      <div class="col-12 col-md-7 col-lg-6 col-xl-8 d-none d-lg-block">

        <!-- Image -->
        <div class="bg-cover h-100 min-vh-100 mt-n1 me-n3" style="background-image: url(assets/img/covers/Cover_login.jpg);"></div>

      </div>
    </div> <!-- / .row -->
  </div>
</ion-content>
