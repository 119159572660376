import { Input, SimpleChanges, Component } from '@angular/core';
import { ColorGenerator } from './color-generator';

@Component({
  selector: 'text-avatar',
  template: `
    <div class="u-text-avatar" [ngStyle]="styles">{{ firstLetter }}</div>
  `,
  styleUrls: ['./text-avatar.scss'],
})
export class TextAvatarDirective {
  @Input() text: string;
  @Input() color: string;
  @Input() textColor: string;

  public firstLetter = '';
  public styles = {
    border: '2px solid',
    'border-color': '#fff',
    'background-color': '#fff',
    color: '#000',
  };

  constructor(private colorGenerator: ColorGenerator) {}

  ngOnChanges(changes: SimpleChanges) {
    let text = changes['text'] ? changes['text'].currentValue : null;
    const color = changes['color'] ? changes['color'].currentValue : null;
    const textColor = changes['textColor'] ? changes['textColor'].currentValue : this.styles.color;

    if (text === null || typeof text !== 'string' || text.length === 0) {
      text = '?';
    }
    text = text.replace('undefined', '');
    if (text === '?') {
      this.firstLetter = '?';
      this.styles = {
        ...this.styles,
        'border-color': this.backgroundColorHexString(color, text),
        color: textColor,
      };
    } else {
      text = this.extractFirstCharacter(text);

      this.firstLetter = text.substring(0, 2);

      const backgroundColor = this.backgroundColorHexString(color, text);

      this.styles = {
        ...this.styles,
        'border-color': backgroundColor,
        'background-color': backgroundColor,
        color: textColor,
      };
    }
  }

  private extractFirstCharacter(text: string): string {
    let initials = '';
    text
      .toUpperCase()
      .split(' ')
      .forEach((i) => {
        initials += i[0];
      });
    return initials;
  }

  private backgroundColorHexString(color: string, text: string): string {
    return color || this.colorGenerator.getColor(text);
  }
}
