import { Injectable } from '@angular/core';
import {
  ConsumptionReportGroupedDataModel,
  ConsumptionReportModel,
  IrrigationReportDataModel,
  IrrigationReportGroupsDataModel,
  IrrigationReportModel,
  TerminalReportModel,
} from '../reports.model';
import { TranslationsLibService } from '../../services/libraries/translations-lib.service';
import { ConsumptionsReportChartDataModel } from '../reports-charts.model';
import { TimeLibService } from '../../services/libraries/time-lib.service';
import * as moment from 'moment/moment';

@Injectable({
  providedIn: 'root',
})
export class ReportsConsumptionsService {
  public terminalsLength = 0;
  public advancedModeIsAllowed = false;
  consumptionColumnsToDisplay = [
    'itemName',
    'duration',
    'progConsumption_water',
    'realConsumption_water',
  ];
  basicIrrigationProgramsConsumptionsReportBarsData: [] = [];
  basicFertilizerProgramsConsumptionsReportBarsData: [] = [];
  basicIrrigationGroupingConsumptionsReportBarsData: [] = [];
  basicFertilizerGroupingConsumptionsReportBarsData: [] = [];
  basicIrrigationProgramsConsumptionsReportChartData: ConsumptionsReportChartDataModel[] =
    [];
  basicFertilizerProgramsConsumptionsReportChartData: ConsumptionsReportChartDataModel[] =
    [];
  basicIrrigationGroupingConsumptionsReportChartData: ConsumptionsReportChartDataModel[] =
    [];
  basicFertilizerGroupingConsumptionsReportChartData: ConsumptionsReportChartDataModel[] =
    [];
  irrigationProgramsConsumptionsReportChartData: ConsumptionsReportChartDataModel[] =
    [];
  fertilizerProgramsConsumptionsReportChartData: ConsumptionsReportChartDataModel[] =
    [];
  irrigationGroupingConsumptionsReportChartData: ConsumptionsReportChartDataModel[] =
    [];
  fertilizerGroupingConsumptionsReportChartData: ConsumptionsReportChartDataModel[] =
    [];
  fertilizerColumnsToDisplay: string[] = [];
  terminalCountWithConsumptions = 0;
  irrigationProgramsConsumptionsReportChartData_program_indexes = {};
  fertilizerProgramsConsumptionsReportChartData_program_indexes = {};
  irrigationProgramsConsumptionsReportChartData_group_indexes = {};
  fertilizerProgramsConsumptionsReportChartData_group_indexes = {};
  irrigationProgramsConsumptionsReportChartData_valves_indexes = {};
  fertilizerProgramsConsumptionsReportChartData_valves_indexes = {};
  irrigationProgramsConsumptionsReportChartData_group_phase_valves_indexes = {};
  fertilizerProgramsConsumptionsReportChartData_group_phase_valves_indexes = {};
  irrigationGroupingConsumptionsReportChartData_group_indexes = {};
  fertilizerGroupingConsumptionsReportChartData_group_indexes = {};
  irrigationGroupingConsumptionsReportChartData_valves_indexes = {};
  fertilizerGroupingConsumptionsReportChartData_valves_indexes = {};
  irrigationGroupingConsumptionsReportChartData_group_phase_valves_indexes = {};
  fertilizerGroupingConsumptionsReportChartData_group_phase_valves_indexes = {};
  loader = false;
  showCharts = false;
  totalRealWaterConsumptionTotal = 0;
  reportsData: ConsumptionReportModel[] = [];
  private groupedConsumptions: ConsumptionReportGroupedDataModel = {};
  reportModeIsProgramConsumptions = false;
  reportModeIsGroupingConsumptions = false;

  public timeLib: TimeLibService;
  public translationsLib: TranslationsLibService;

  constructor(
    timeLib: TimeLibService,
    translationsLib: TranslationsLibService
  ) {
    this.timeLib = timeLib;
    this.translationsLib = translationsLib;
  }
  public processTabConsumptions(
    terminalReport: TerminalReportModel,
    begin: string,
    end: string
  ): ConsumptionReportModel {
    const consumptionReportModel = this.getInitialConsumptionReportModel(
      terminalReport,
      begin,
      end
    );

    const fields_parent = this.setSunburstRootData();

    Object.getOwnPropertyNames(this.groupedConsumptions).forEach((fieldKey) => {
      this.setSunburstFieldData(fields_parent, fieldKey);

      Object.getOwnPropertyNames(
        this.groupedConsumptions[fieldKey].terminals
      ).forEach((terminalKey) => {
        const terminal_id = this.setSunburstTerminalData(fieldKey, terminalKey);

        // Process by grouping
        Object.getOwnPropertyNames(
          this.groupedConsumptions[fieldKey].terminals[terminalKey].groups
        ).forEach((groupKey) => {
          const groupConsumption = {
            itemName:
              this.groupedConsumptions[fieldKey].terminals[terminalKey].groups[
                groupKey
              ].name,
            duration: this.timeLib.secondsToTime(
              this.groupedConsumptions[fieldKey].terminals[terminalKey].groups[
                groupKey
              ].duration
            ),
          };
          consumptionReportModel.groupingConsumptions.push(groupConsumption);
          let groupFertilizerTotal = 0;

          Object.getOwnPropertyNames(
            this.groupedConsumptions[fieldKey].terminals[terminalKey].groups[
              groupKey
            ]
          ).forEach((key) => {
            // realConsumption || progConsumption
            if (key.indexOf('Consumption_') >= 0) {
              groupConsumption[key] =
                +this.groupedConsumptions[fieldKey].terminals[
                  terminalKey
                ].groups[groupKey][key].toFixed(2);
              consumptionReportModel.groupingTotals[key] +=
                this.groupedConsumptions[fieldKey].terminals[
                  terminalKey
                ].groups[groupKey][key];
              consumptionReportModel.groupingTotals[key] =
                +consumptionReportModel.groupingTotals[key].toFixed(2);
            }
            if (key.indexOf('realConsumption_fertilizer') >= 0) {
              groupFertilizerTotal +=
                this.groupedConsumptions[fieldKey].terminals[terminalKey]
                  .groups[groupKey][key];
            }
          });
        });

        // Process by programs!
        Object.getOwnPropertyNames(
          this.groupedConsumptions[fieldKey].terminals[terminalKey].programs
        ).forEach((programKey) => {
          const programConsumption = {
            itemName:
              this.groupedConsumptions[fieldKey].terminals[terminalKey]
                .programs[programKey].programName,
            duration: this.timeLib.secondsToTime(
              this.groupedConsumptions[fieldKey].terminals[terminalKey]
                .programs[programKey].duration
            ),
          };
          consumptionReportModel.programTotals.duration_s +=
            this.groupedConsumptions[fieldKey].terminals[terminalKey].programs[
              programKey
            ].duration;

          let programFertilizerTotal = 0;

          Object.getOwnPropertyNames(
            this.groupedConsumptions[fieldKey].terminals[terminalKey].programs[
              programKey
            ]
          ).forEach((key) => {
            // realConsumption || progConsumption
            if (key.indexOf('Consumption_') >= 0) {
              programConsumption[key] =
                +this.groupedConsumptions[fieldKey].terminals[
                  terminalKey
                ].programs[programKey][key].toFixed(2);
              consumptionReportModel.programTotals[key] +=
                this.groupedConsumptions[fieldKey].terminals[
                  terminalKey
                ].programs[programKey][key];
              consumptionReportModel.programTotals[key] =
                +consumptionReportModel.programTotals[key].toFixed(2);
            }
            if (key.indexOf('realConsumption_fertilizer') >= 0) {
              programFertilizerTotal +=
                this.groupedConsumptions[fieldKey].terminals[terminalKey]
                  .programs[programKey][key];
            }
          });

          const programRealWaterTotal =
            +this.groupedConsumptions[fieldKey].terminals[terminalKey].programs[
              programKey
            ].realConsumption_water.toFixed(2);
          const programRealFertilizerTotal = +programFertilizerTotal.toFixed(2);

          const irrigation_id = this.setSunburstProgramData(
            fieldKey,
            terminalKey,
            programKey,
            'irrigationProgramsConsumptionsReportChartData_program_indexes',
            'irrigationProgramsConsumptionsReportChartData',
            'basicIrrigationProgramsConsumptionsReportChartData',
            programRealWaterTotal
          );

          this.setSunburstProgramData(
            fieldKey,
            terminalKey,
            programKey,
            'fertilizerProgramsConsumptionsReportChartData_program_indexes',
            'fertilizerProgramsConsumptionsReportChartData',
            'basicFertilizerProgramsConsumptionsReportChartData',
            programRealFertilizerTotal
          );

          this.setBarsProgramData(
            fieldKey,
            terminalKey,
            programKey,
            'irrigationProgramsConsumptionsReportChartData_program_indexes',
            'basicIrrigationProgramsConsumptionsReportBarsData',
            programRealWaterTotal
          );

          this.setBarsProgramData(
            fieldKey,
            terminalKey,
            programKey,
            'fertilizerProgramsConsumptionsReportChartData_program_indexes',
            'basicFertilizerProgramsConsumptionsReportBarsData',
            programRealFertilizerTotal
          );

          consumptionReportModel.programConsumptions.push(programConsumption);
          this.totalRealWaterConsumptionTotal += programRealWaterTotal;

          // Groups + Valves + Valves Data is only processed when advancedModeIsAllowed!
          if (this.advancedModeIsAllowed === true) {
            this.groupedConsumptions[fieldKey].terminals[terminalKey].programs[
              programKey
            ].irrigations.forEach((irrigation, irrigationKey) => {
              Object.getOwnPropertyNames(irrigation.groups).forEach(
                (groupKey) => {
                  Object.getOwnPropertyNames(
                    irrigation.groups[groupKey]
                  ).forEach((groupPhaseKey, groupPhaseKeyIndex) => {
                    //irrigation.groups[groupKey][groupPhaseKey].group_model;
                    irrigation.groups[groupKey][
                      groupPhaseKey
                    ].valves_model.forEach((valve, valveKey) => {
                      if ('realConsumption' in valve) {
                        this.setBarsGroupData(
                          fieldKey,
                          terminalKey,
                          programKey,
                          irrigationKey,
                          groupKey,
                          groupPhaseKey,
                          'irrigationGroupingConsumptionsReportChartData_group_indexes',
                          'basicIrrigationGroupingConsumptionsReportBarsData',
                          valve.realConsumption.water
                        );

                        // Only once
                        if (groupPhaseKeyIndex === 0 && valveKey === 0) {
                          this.setSunburstProgramsGroupData(
                            irrigation_id,
                            fieldKey,
                            terminalKey,
                            programKey,
                            irrigationKey,
                            groupKey,
                            groupPhaseKey,
                            'irrigationProgramsConsumptionsReportChartData'
                          );
                          this.setSunburstGroupingGroupData(
                            terminal_id,
                            fieldKey,
                            terminalKey,
                            groupKey,
                            'basicIrrigationGroupingConsumptionsReportChartData',
                            'irrigationGroupingConsumptionsReportChartData',
                            'irrigationGroupingConsumptionsReportChartData'
                          );
                          const parent =
                            this.groupedConsumptions[fieldKey].terminals[
                              terminalKey
                            ].programs[programKey].id;
                          this.setSunburstProgramsGroupData(
                            parent,
                            fieldKey,
                            terminalKey,
                            programKey,
                            irrigationKey,
                            groupKey,
                            groupPhaseKey,
                            'fertilizerProgramsConsumptionsReportChartData'
                          );
                          this.setSunburstGroupingGroupData(
                            terminal_id,
                            fieldKey,
                            terminalKey,
                            groupKey,
                            'basicFertilizerGroupingConsumptionsReportChartData',
                            'fertilizerGroupingConsumptionsReportChartData',
                            'fertilizerGroupingConsumptionsReportChartData'
                          );
                          this.setSunburstValveData(
                            fieldKey,
                            terminalKey,
                            programKey,
                            irrigationKey,
                            groupKey,
                            groupPhaseKey,
                            valveKey,
                            'irrigationProgramsConsumptionsReportChartData',
                            'irrigationGroupingConsumptionsReportChartData'
                          );
                          this.setSunburstValveData(
                            fieldKey,
                            terminalKey,
                            programKey,
                            irrigationKey,
                            groupKey,
                            groupPhaseKey,
                            valveKey,
                            'fertilizerProgramsConsumptionsReportChartData',
                            'fertilizerGroupingConsumptionsReportChartData'
                          );
                        }
                        if (groupPhaseKeyIndex === 0) {
                          this.setSunburstValveData(
                            fieldKey,
                            terminalKey,
                            programKey,
                            irrigationKey,
                            groupKey,
                            groupPhaseKey,
                            valveKey,
                            'irrigationProgramsConsumptionsReportChartData',
                            'irrigationGroupingConsumptionsReportChartData'
                          );
                          this.setSunburstValveData(
                            fieldKey,
                            terminalKey,
                            programKey,
                            irrigationKey,
                            groupKey,
                            groupPhaseKey,
                            valveKey,
                            'fertilizerProgramsConsumptionsReportChartData',
                            'fertilizerGroupingConsumptionsReportChartData'
                          );
                        }
                        Object.getOwnPropertyNames(
                          valve.realConsumption
                        ).forEach((rCKey) => {
                          if (rCKey.indexOf('fertilizer') >= 0) {
                            this.setBarsGroupData(
                              fieldKey,
                              terminalKey,
                              programKey,
                              irrigationKey,
                              groupKey,
                              groupPhaseKey,
                              'fertilizerGroupingConsumptionsReportChartData_group_indexes',
                              'basicFertilizerGroupingConsumptionsReportBarsData',
                              valve.realConsumption[rCKey]
                            );
                            this.setSunburstGroupPhaseValveData(
                              fieldKey,
                              terminalKey,
                              programKey,
                              irrigationKey,
                              groupKey,
                              groupPhaseKey,
                              valveKey,
                              rCKey,
                              valve.realConsumption[rCKey],
                              'fertilizerProgramsConsumptionsReportChartData',
                              'fertilizerGroupingConsumptionsReportChartData',
                              ''
                            );
                          }
                        });
                        this.setSunburstGroupPhaseValveData(
                          fieldKey,
                          terminalKey,
                          programKey,
                          irrigationKey,
                          groupKey,
                          groupPhaseKey,
                          valveKey,
                          '',
                          valve.realConsumption.water,
                          'irrigationProgramsConsumptionsReportChartData',
                          'irrigationGroupingConsumptionsReportChartData',
                          ''
                        );
                      }
                    });
                  });
                }
              );
            });
          }
        });
      });
    });

    consumptionReportModel.groupingTotals.duration = this.timeLib.secondsToTime(
      // Hacky :(
      //consumptionReportModel.groupingTotals.duration_s
      consumptionReportModel.programTotals.duration_s
    );
    consumptionReportModel.programTotals.duration = this.timeLib.secondsToTime(
      consumptionReportModel.programTotals.duration_s
    );
    /*
    console.log(
      'this.basicIrrigationGroupingConsumptionsReportChartData',
      this.basicIrrigationGroupingConsumptionsReportChartData
    );
    console.log(
      'this.irrigationGroupingConsumptionsReportChartData',
      this.irrigationGroupingConsumptionsReportChartData
    );
    console.log(
      'this.basicFertilizerGroupingConsumptionsReportChartData',
      this.basicFertilizerGroupingConsumptionsReportChartData
    );
    console.log(
      'this.fertilizerGroupingConsumptionsReportChartData',
      this.fertilizerGroupingConsumptionsReportChartData
    );
     */
    return consumptionReportModel;
  }

  public processGroupedConsumptions(
    irrigationReportModel: IrrigationReportModel,
    irrigationReportDataModel: IrrigationReportDataModel
  ) {
    let terminalFieldVid = irrigationReportModel.terminalFieldVid;
    let terminalFieldName = irrigationReportModel.terminalFieldName;
    if (terminalFieldVid === null) {
      terminalFieldVid = 'no_field';
      terminalFieldName = this.translationsLib.get('field_no_field');
    }

    // FIELD
    if (!(irrigationReportModel.terminalFieldVid in this.groupedConsumptions)) {
      this.groupedConsumptions[irrigationReportModel.terminalFieldVid] = {
        id: 'f_' + terminalFieldVid,
        fieldVid: terminalFieldVid,
        fieldName: terminalFieldName,
        terminals: {},
      };
    }

    // TERMINAL
    if (
      !(
        irrigationReportModel.terminalVid in
        this.groupedConsumptions[irrigationReportModel.terminalFieldVid]
          .terminals
      )
    ) {
      this.groupedConsumptions[
        irrigationReportModel.terminalFieldVid
      ].terminals[irrigationReportModel.terminalVid] = {
        id: 't_' + irrigationReportModel.terminalVid,
        terminalNameVid: irrigationReportModel.terminalNameVid,
        terminalVid: irrigationReportModel.terminalVid,
        groups: {},
        programs: {},
      };
    }

    // PROGRAM
    if (
      !(
        irrigationReportDataModel.program in
        this.groupedConsumptions[irrigationReportModel.terminalFieldVid]
          .terminals[irrigationReportModel.terminalVid].programs
      )
    ) {
      this.groupedConsumptions[
        irrigationReportModel.terminalFieldVid
      ].terminals[irrigationReportModel.terminalVid].programs[
        irrigationReportDataModel.program
      ] = {
        id:
          't_' +
          irrigationReportModel.terminalVid +
          '_p_' +
          irrigationReportDataModel.program,
        programName: irrigationReportDataModel.programName,
        program: irrigationReportDataModel.program,
        duration: 0,
        irrigations: [],
      };

      irrigationReportModel.irrigations.forEach((irrigation) => {
        if (irrigation.program === irrigationReportDataModel.program) {
          Object.keys(irrigation.groups).forEach((g_index) => {
            Object.keys(irrigation.groups[g_index]).forEach((p_index) => {
              const diff = this.timeLib.diff(
                moment(
                  irrigation.groups[g_index][p_index].timeBegin,
                  'YYYY-MM-DD HH:mm:ss'
                ).toDate(),
                moment(
                  irrigation.groups[g_index][p_index].timeEnd,
                  'YYYY-MM-DD HH:mm:ss'
                ).toDate()
              );
              // Program Consumptions
              this.groupedConsumptions[
                irrigationReportModel.terminalFieldVid
              ].terminals[irrigationReportModel.terminalVid].programs[
                irrigationReportDataModel.program
              ].duration += diff;
              // Grouping consumptions
              if (
                !(
                  g_index in
                  this.groupedConsumptions[
                    irrigationReportModel.terminalFieldVid
                  ].terminals[irrigationReportModel.terminalVid].groups
                )
              ) {
                let g_name =
                  irrigation.groups[g_index][p_index].group_model.nombre;
                if (g_name === null || g_name.trim() === '') {
                  g_name = g_index;
                }

                this.groupedConsumptions[
                  irrigationReportModel.terminalFieldVid
                ].terminals[irrigationReportModel.terminalVid].groups[g_index] =
                  {
                    id:
                      't_' +
                      irrigationReportModel.terminalVid +
                      '_g_' +
                      irrigationReportDataModel.program,
                    agrup:
                      irrigation.groups[g_index][p_index].group_model.agrup,
                    name: g_name,
                    duration: diff,
                    valves: {},

                    progConsumption_fertilizer1: 0,
                    progConsumption_fertilizer2: 0,
                    progConsumption_fertilizer3: 0,
                    progConsumption_fertilizer4: 0,
                    progConsumption_fertilizer5: 0,
                    progConsumption_fertilizer6: 0,
                    progConsumption_fertilizer7: 0,
                    progConsumption_fertilizer8: 0,
                    progConsumption_fertilizer9: 0,
                    progConsumption_water: 0,

                    realConsumption_fertilizer1: 0,
                    realConsumption_fertilizer2: 0,
                    realConsumption_fertilizer3: 0,
                    realConsumption_fertilizer4: 0,
                    realConsumption_fertilizer5: 0,
                    realConsumption_fertilizer6: 0,
                    realConsumption_fertilizer7: 0,
                    realConsumption_fertilizer8: 0,
                    realConsumption_fertilizer9: 0,
                    realConsumption_water: 0,
                  };
              } else {
                this.groupedConsumptions[
                  irrigationReportModel.terminalFieldVid
                ].terminals[irrigationReportModel.terminalVid].groups[
                  g_index
                ].duration += diff;
              }

              ['realConsumption', 'progConsumption'].forEach((kKey) => {
                Object.getOwnPropertyNames(
                  irrigation.groups[g_index][p_index][kKey]
                ).forEach((vKey) => {
                  // realConsumption || progConsumption
                  if (
                    typeof irrigation.groups[g_index][p_index][kKey][vKey] ===
                    'number'
                  ) {
                    const k_t = kKey + '_' + vKey;

                    if (
                      !(
                        k_t in
                        this.groupedConsumptions[
                          irrigationReportModel.terminalFieldVid
                        ].terminals[irrigationReportModel.terminalVid].groups[
                          g_index
                        ]
                      )
                    ) {
                      // @ts-ignore
                      this.groupedConsumptions[
                        irrigationReportModel.terminalFieldVid
                      ].terminals[irrigationReportModel.terminalVid].groups[
                        g_index
                      ][k_t] = 0;
                    }
                    // @ts-ignore
                    this.groupedConsumptions[
                      irrigationReportModel.terminalFieldVid
                    ].terminals[irrigationReportModel.terminalVid].groups[
                      g_index
                    ][k_t] +=
                      // @ts-ignore
                      +irrigation.groups[g_index][p_index][kKey][vKey].toFixed(
                        2
                      );

                    this.groupedConsumptions[
                      irrigationReportModel.terminalFieldVid
                    ].terminals[irrigationReportModel.terminalVid].groups[
                      g_index
                    ][k_t] =
                      +this.groupedConsumptions[
                        irrigationReportModel.terminalFieldVid
                      ].terminals[irrigationReportModel.terminalVid].groups[
                        g_index
                      ][k_t].toFixed(2);
                  }
                });
              });
            });
          });
        }
      });
    }

    Object.getOwnPropertyNames(irrigationReportDataModel).forEach((key) => {
      // realConsumption || progConsumption
      if (key.indexOf('Consumption_') >= 0) {
        if (
          !(
            key in
            this.groupedConsumptions[irrigationReportModel.terminalFieldVid]
              .terminals[irrigationReportModel.terminalVid].programs[
              irrigationReportDataModel.program
            ]
          )
        ) {
          this.groupedConsumptions[
            irrigationReportModel.terminalFieldVid
          ].terminals[irrigationReportModel.terminalVid].programs[
            irrigationReportDataModel.program
          ][key] = 0;
        }
        this.groupedConsumptions[
          irrigationReportModel.terminalFieldVid
        ].terminals[irrigationReportModel.terminalVid].programs[
          irrigationReportDataModel.program
        ][key] += irrigationReportDataModel[key];
      }
    });

    this.groupedConsumptions[irrigationReportModel.terminalFieldVid].terminals[
      irrigationReportModel.terminalVid
    ].programs[irrigationReportDataModel.program].irrigations.push(
      irrigationReportDataModel
    );
  }

  public processFertilizer(
    irg: IrrigationReportGroupsDataModel
  ): IrrigationReportGroupsDataModel {
    const fertilizerColumnsToDisplay = this.fertilizerColumnsToDisplay;
    ['realConsumption', 'progConsumption'].forEach((m_index) => {
      Object.getOwnPropertyNames(irg[m_index]).forEach((key) => {
        if (typeof irg[m_index][key] === 'number') {
          irg[m_index + '_' + key] = +irg[m_index][key].toFixed(2);
          if (
            m_index.indexOf('realConsumption') >= 0 &&
            key.indexOf('fertilizer') >= 0
          ) {
            if (
              irg[m_index][key] > 0 &&
              !fertilizerColumnsToDisplay.includes(key)
            ) {
              fertilizerColumnsToDisplay.push(key);
            }
          }
        } else {
          irg[m_index + '_' + key] = irg[m_index][key];
        }
      });
    });
    this.fertilizerColumnsToDisplay = fertilizerColumnsToDisplay;
    return irg;
  }

  private getInitialConsumptionReportModel(
    terminalReport: TerminalReportModel,
    begin: string,
    end: string
  ): ConsumptionReportModel {
    return {
      reportName:
        this.translationsLib.get('reports_irrigation_consumptions') +
        ' ' +
        terminalReport.name_vid,
      terminalFieldVid: terminalReport.vid,
      terminalFieldName: terminalReport.field_name,
      terminalNameVid: terminalReport.name_vid,
      terminalVid: terminalReport.vid,
      ready: true,
      begin,
      end,
      programConsumptions: [],
      groupingConsumptions: [],
      groupingTotals: {
        duration: '',
        duration_s: 0,
        progConsumption_fertilizer1: 0,
        progConsumption_fertilizer2: 0,
        progConsumption_fertilizer3: 0,
        progConsumption_fertilizer4: 0,
        progConsumption_fertilizer5: 0,
        progConsumption_fertilizer6: 0,
        progConsumption_fertilizer7: 0,
        progConsumption_fertilizer8: 0,
        progConsumption_fertilizer9: 0,
        progConsumption_water: 0,
        realConsumption_fertilizer1: 0,
        realConsumption_fertilizer2: 0,
        realConsumption_fertilizer3: 0,
        realConsumption_fertilizer4: 0,
        realConsumption_fertilizer5: 0,
        realConsumption_fertilizer6: 0,
        realConsumption_fertilizer7: 0,
        realConsumption_fertilizer8: 0,
        realConsumption_fertilizer9: 0,
        realConsumption_water: 0,
      },
      programTotals: {
        duration: '',
        duration_s: 0,
        progConsumption_fertilizer1: 0,
        progConsumption_fertilizer2: 0,
        progConsumption_fertilizer3: 0,
        progConsumption_fertilizer4: 0,
        progConsumption_fertilizer5: 0,
        progConsumption_fertilizer6: 0,
        progConsumption_fertilizer7: 0,
        progConsumption_fertilizer8: 0,
        progConsumption_fertilizer9: 0,
        progConsumption_water: 0,
        realConsumption_fertilizer1: 0,
        realConsumption_fertilizer2: 0,
        realConsumption_fertilizer3: 0,
        realConsumption_fertilizer4: 0,
        realConsumption_fertilizer5: 0,
        realConsumption_fertilizer6: 0,
        realConsumption_fertilizer7: 0,
        realConsumption_fertilizer8: 0,
        realConsumption_fertilizer9: 0,
        realConsumption_water: 0,
      },
    };
  }

  private setSunburstRootData(): string {
    let fields_parent = '';
    if (Object.keys(this.groupedConsumptions).length > 1) {
      const rootData = {
        id: '0.0',
        parent: '',
        name: this.translationsLib.get('field_fields'),
      };
      this.irrigationProgramsConsumptionsReportChartData.push(rootData);
      this.fertilizerProgramsConsumptionsReportChartData.push(rootData);
      this.basicIrrigationProgramsConsumptionsReportChartData.push(rootData);
      this.basicFertilizerProgramsConsumptionsReportChartData.push(rootData);
      this.irrigationGroupingConsumptionsReportChartData.push(rootData);
      this.fertilizerGroupingConsumptionsReportChartData.push(rootData);
      this.basicIrrigationGroupingConsumptionsReportChartData.push(rootData);
      this.basicFertilizerGroupingConsumptionsReportChartData.push(rootData);
      fields_parent = '0.0';
    }
    return fields_parent;
  }
  private setSunburstFieldData(field_parent: string, fieldKey: string): void {
    const fieldData = {
      id: this.groupedConsumptions[fieldKey].id,
      parent: field_parent,
      name: this.groupedConsumptions[fieldKey].fieldName,
      value: null,
    };
    this.irrigationProgramsConsumptionsReportChartData.push(fieldData);
    this.fertilizerProgramsConsumptionsReportChartData.push(fieldData);
    this.basicIrrigationProgramsConsumptionsReportChartData.push(fieldData);
    this.basicFertilizerProgramsConsumptionsReportChartData.push(fieldData);
    this.irrigationGroupingConsumptionsReportChartData.push(fieldData);
    this.fertilizerGroupingConsumptionsReportChartData.push(fieldData);
    this.basicIrrigationGroupingConsumptionsReportChartData.push(fieldData);
    this.basicFertilizerGroupingConsumptionsReportChartData.push(fieldData);
  }
  private setSunburstTerminalData(
    fieldKey: string,
    terminalKey: string
  ): string {
    const terminalData = {
      id: this.groupedConsumptions[fieldKey].terminals[terminalKey].id,
      parent: this.groupedConsumptions[fieldKey].id,
      name: this.groupedConsumptions[fieldKey].terminals[terminalKey]
        .terminalNameVid,
      value: null,
    };
    this.irrigationProgramsConsumptionsReportChartData.push(terminalData);
    this.fertilizerProgramsConsumptionsReportChartData.push(terminalData);
    this.basicIrrigationProgramsConsumptionsReportChartData.push(terminalData);
    this.basicFertilizerProgramsConsumptionsReportChartData.push(terminalData);
    this.irrigationGroupingConsumptionsReportChartData.push(terminalData);
    this.fertilizerGroupingConsumptionsReportChartData.push(terminalData);
    this.basicIrrigationGroupingConsumptionsReportChartData.push(terminalData);
    this.basicFertilizerGroupingConsumptionsReportChartData.push(terminalData);

    return terminalData.id;
  }

  private setSunburstProgramData(
    fieldKey: string,
    terminalKey: string,
    programKey: string,
    avoidProgramDuplicatesIndex: string,
    consumptionsReportChartData: string,
    basicConsumptionsReportChartData: string,
    value: number
  ): string {
    const program_id =
      this.groupedConsumptions[fieldKey].terminals[terminalKey].programs[
        programKey
      ].id;

    // Avoid duplicates
    if (program_id in this[avoidProgramDuplicatesIndex]) {
      return program_id;
    }

    const programData = {
      id: program_id,
      parent: this.groupedConsumptions[fieldKey].terminals[terminalKey].id,
      name: this.groupedConsumptions[fieldKey].terminals[terminalKey].programs[
        programKey
      ].programName,
      value,
      //value: null,
    };
    this[consumptionsReportChartData].push(programData);
    this[basicConsumptionsReportChartData].push(programData);

    this[avoidProgramDuplicatesIndex][program_id] = true;

    return program_id;
  }

  private setBarsProgramData(
    fieldKey: string,
    terminalKey: string,
    programKey: string,
    avoidProgramDuplicatesIndex: string,
    basicConsumptionsReportChartData: string,
    value: number
  ): string {
    const program_id =
      this.groupedConsumptions[fieldKey].terminals[terminalKey].programs[
        programKey
      ].id;

    // Avoid duplicates
    //if (program_id in this[avoidProgramDuplicatesIndex]) {
    //  return program_id;
    //}

    let t_name = '';
    if (this.terminalsLength > 1) {
      t_name =
        this.groupedConsumptions[fieldKey].terminals[terminalKey].terminalVid +
        ' - ';
    }

    const name =
      t_name +
      this.groupedConsumptions[fieldKey].terminals[terminalKey].programs[
        programKey
      ].programName;

    this[basicConsumptionsReportChartData].push([name, value]);
    this[avoidProgramDuplicatesIndex][program_id] = true;

    return program_id;
  }

  private setBarsGroupData(
    fieldKey: string,
    terminalKey: string,
    programKey: any,
    irrigationKey: number,
    groupKey: string,
    groupPhaseKey: any,
    groupStatusIndex: string,
    basicConsumptionsReportChartData: string,
    value: number
  ): void {
    let t_name = '';
    if (this.terminalsLength > 1) {
      t_name =
        this.groupedConsumptions[fieldKey].terminals[terminalKey].terminalVid +
        ' - ';
    }
    let name =
      t_name +
      this.groupedConsumptions[fieldKey].terminals[terminalKey].programs[
        programKey
      ].irrigations[irrigationKey].groups[groupKey][groupPhaseKey].group_model
        .nombre;

    const g_name =
      this.groupedConsumptions[fieldKey].terminals[terminalKey].programs[
        programKey
      ].irrigations[irrigationKey].groups[groupKey][groupPhaseKey].group_model
        .nombre;
    if (g_name === null || g_name.trim() === '') {
      name =
        name +
        this.translationsLib.get('irrigation_group') +
        ' ' +
        this.groupedConsumptions[fieldKey].terminals[terminalKey].programs[
          programKey
        ].irrigations[irrigationKey].groups[groupKey][groupPhaseKey].group_model
          .agrup;
    }

    if (!(groupKey in this[groupStatusIndex])) {
      // @ts-ignore
      this[basicConsumptionsReportChartData].push([name, +value.toFixed(2)]);
      this[groupStatusIndex][groupKey] =
        this[basicConsumptionsReportChartData].length - 1;
    } else {
      const g_index = this[groupStatusIndex][groupKey];
      // @ts-ignore
      this[basicConsumptionsReportChartData][g_index][1] += +value.toFixed(2);
      this[basicConsumptionsReportChartData][g_index][1] =
        +this[basicConsumptionsReportChartData][g_index][1].toFixed(2);
    }
  }
  private getSunburstIrrigationGroupKey(
    fieldKey: string,
    terminalKey: string,
    programKey: string,
    irrigationKey: number,
    groupKey: string,
    groupPhaseKey: string
  ): string {
    let key = '';
    if (fieldKey !== null && fieldKey.trim() !== '') {
      key += 'f_' + fieldKey;
    }
    if (terminalKey !== null && terminalKey.trim() !== '') {
      key += '_t_' + terminalKey;
    }
    if (programKey !== null && programKey.trim() !== '') {
      key += '_p_' + programKey;
    }
    if (irrigationKey !== null) {
      key += '_i_' + irrigationKey;
    }
    if (groupKey !== null && groupKey.trim() !== '') {
      key += '_g_' + groupKey;
    }
    if (groupPhaseKey !== null && groupPhaseKey.trim() !== '') {
      key += '_' + groupPhaseKey;
    }
    return key;
  }
  private setSunburstProgramsGroupData(
    parent: string,
    fieldKey: string,
    terminalKey: string,
    programKey: string,
    irrigationKey: number,
    groupKey: string,
    groupPhaseKey: string,
    dataIndex: string
  ): string {
    const irrigation_group_id = this.getSunburstIrrigationGroupKey(
      fieldKey,
      terminalKey,
      programKey,
      irrigationKey,
      groupKey,
      ''
    );

    // Avoid duplicates
    if (irrigation_group_id in this[dataIndex + '_group_indexes']) {
      return irrigation_group_id;
    }

    let group_name =
      this.translationsLib.get('irrigation_group') +
      ' ' +
      this.groupedConsumptions[fieldKey].terminals[terminalKey].programs[
        programKey
      ].irrigations[irrigationKey].groups[groupKey][groupPhaseKey].group_model
        .agrup;

    if (
      this.groupedConsumptions[fieldKey].terminals[terminalKey].programs[
        programKey
      ].irrigations[irrigationKey].groups[groupKey][groupPhaseKey].group_model
        .nombre !== ''
    ) {
      group_name =
        this.groupedConsumptions[fieldKey].terminals[terminalKey].programs[
          programKey
        ].irrigations[irrigationKey].groups[groupKey][groupPhaseKey].group_model
          .nombre;
    }

    group_name =
      group_name +
      ' ' +
      this.translationsLib.get('reports_numActivation') +
      ' ' +
      this.groupedConsumptions[fieldKey].terminals[terminalKey].programs[
        programKey
      ].irrigations[irrigationKey].numActivation;

    this[dataIndex].push({
      id: irrigation_group_id,
      parent,
      name: group_name,
      value: null,
    });

    this[dataIndex + '_group_indexes'][irrigation_group_id] = true;

    return irrigation_group_id;
  }

  private setSunburstGroupingGroupData(
    parent: string,
    fieldKey: string,
    terminalKey: string,
    groupKey: string,
    basicDataIndex: string,
    advancedDataIndex: string,
    avoidDuplicateIndex: string
  ): string {
    const grouping_group_id = this.getSunburstIrrigationGroupKey(
      fieldKey,
      terminalKey,
      null,
      null,
      groupKey,
      ''
    );

    const group_name =
      this.translationsLib.get('irrigation_group') +
      ' ' +
      this.groupedConsumptions[fieldKey].terminals[terminalKey].groups[groupKey]
        .name;

    let value = null;
    // Water
    if (avoidDuplicateIndex.indexOf('irrigation') >= 0) {
      // Basic water grouping sunburst
      if (grouping_group_id in this[avoidDuplicateIndex + '_group_indexes']) {
        return grouping_group_id;
      } else {
        this[avoidDuplicateIndex + '_group_indexes'][grouping_group_id] = true;
      }

      // Advanced water grouping sunburst
      if (grouping_group_id in this[avoidDuplicateIndex + '_valves_indexes']) {
        return grouping_group_id;
      } else {
        this[avoidDuplicateIndex + '_valves_indexes'][grouping_group_id] = true;
      }
      //console.log('Group avoid irrigation ', avoidDuplicateIndex);
      value =
        this.groupedConsumptions[fieldKey].terminals[terminalKey].groups[
          groupKey
        ].realConsumption_water;

      this[basicDataIndex].push({
        id: grouping_group_id,
        parent,
        name: group_name,
        value,
      });

      this[advancedDataIndex].push({
        id: grouping_group_id,
        parent,
        name: group_name,
        value,
      });
    }
    // Fertilizer
    else {
      //      console.log('Group avoid fertilizer ', avoidDuplicateIndex);
      /*
      this[basicDataIndex].push({
        id: grouping_group_id,
        parent,
        name: group_name,
        value: null,
      });

      this[advancedDataIndex].push({
        id: grouping_group_id,
        parent,
        name: group_name,
        value: null,
      });
*/
      Object.getOwnPropertyNames(
        this.groupedConsumptions[fieldKey].terminals[terminalKey].groups[
          groupKey
        ]
      ).forEach((groupInnerKey) => {
        if (groupInnerKey.indexOf('realConsumption_fertilizer') >= 0) {
          const fertilizer_id = groupInnerKey.replace(
            'realConsumption_fertilizer',
            ''
          );
          const group_fertilizer_id = grouping_group_id + '_f_' + fertilizer_id;
          const group_fertilizer_name =
            group_name +
            ' ' +
            this.translationsLib.get('irrigation_fertilizer') +
            ' ' +
            fertilizer_id;

          //            console.log('Group avoid fertilizer #2', basicDataIndex);

          /*
            this[basicDataIndex].push({
              id: group_fertilizer_id,
              grouping_group_id,
              name: group_fertilizer_name,
              value: this.groupedConsumptions[fieldKey].terminals[terminalKey].groups[
                groupKey
                ][groupInnerKey],
            });

 */
        }
      });
    }

    return grouping_group_id;
  }

  private setSunburstValveData(
    fieldKey: string,
    terminalKey: string,
    programKey: string,
    irrigationKey: number,
    groupKey: string,
    groupPhaseKey: string,
    valveKey: number,
    programDataIndex: string,
    groupingDataIndex: string
  ): string {
    const parent = this.getSunburstIrrigationGroupKey(
      fieldKey,
      terminalKey,
      programKey,
      irrigationKey,
      groupKey,
      ''
    );
    const irrigation_valve_id = parent + '_v_' + valveKey;

    // Avoid duplicates
    if (
      irrigation_valve_id in this[programDataIndex + '_valves_indexes'] ||
      irrigation_valve_id in this[groupingDataIndex + '_valves_indexes']
    ) {
      return irrigation_valve_id;
    }

    let valve_name = '';
    if (
      this.groupedConsumptions[fieldKey].terminals[terminalKey].programs[
        programKey
      ].irrigations[irrigationKey].groups[groupKey][groupPhaseKey].valves_model[
        valveKey
      ].nombre !== ''
    ) {
      valve_name =
        this.groupedConsumptions[fieldKey].terminals[terminalKey].programs[
          programKey
        ].irrigations[irrigationKey].groups[groupKey][groupPhaseKey]
          .valves_model[valveKey].nombre;
    } else {
      valve_name =
        this.translationsLib.get('irrigation_valve') +
        ' ' +
        this.groupedConsumptions[fieldKey].terminals[terminalKey].programs[
          programKey
        ].irrigations[irrigationKey].groups[groupKey][groupPhaseKey]
          .valves_model[valveKey].valvula;
    }

    this[programDataIndex].push({
      id: irrigation_valve_id,
      parent,
      name: valve_name,
    });
    this[groupingDataIndex].push({
      id: irrigation_valve_id,
      parent,
      name: valve_name,
    });

    this[programDataIndex + '_valves_indexes'][irrigation_valve_id] = true;
    this[groupingDataIndex + '_valves_indexes'][irrigation_valve_id] = true;

    return irrigation_valve_id;
  }

  private setSunburstGroupPhaseValveData(
    fieldKey: string,
    terminalKey: string,
    programKey: string,
    irrigationKey: number,
    groupKey: string,
    groupPhaseKey: string,
    valveKey: number,
    fertilizerKey: string,
    value: number,
    programDataIndex: string,
    groupingDataIndex: string,
    forceParent: string
  ): string {
    const parent =
      this.getSunburstIrrigationGroupKey(
        fieldKey,
        terminalKey,
        programKey,
        irrigationKey,
        groupKey,
        ''
      ) +
      '_v_' +
      valveKey;

    let fertilizer_name = '';
    let irrigation_valve_id =
      parent + '_gp_' + groupPhaseKey + '_v_' + valveKey;
    if (fertilizerKey !== '') {
      fertilizer_name =
        ' ' +
        //this.translationsLib.get('irrigation_fertilizer') +
        //' ' +
        fertilizerKey.replace('fertilizer', '') +
        ' ';
      irrigation_valve_id = irrigation_valve_id + '_f_' + fertilizerKey;
    }

    // Avoid duplicates
    if (
      irrigation_valve_id in
        this[programDataIndex + '_group_phase_valves_indexes'] ||
      irrigation_valve_id in
        this[groupingDataIndex + '_group_phase_valves_indexes']
    ) {
      return irrigation_valve_id;
    }

    let valve_name = '';
    if (
      this.groupedConsumptions[fieldKey].terminals[terminalKey].programs[
        programKey
      ].irrigations[irrigationKey].groups[groupKey][groupPhaseKey].valves_model[
        valveKey
      ].nombre !== ''
    ) {
      valve_name =
        this.translationsLib.get('reports_' + groupPhaseKey) +
        fertilizer_name +
        ' ' +
        this.groupedConsumptions[fieldKey].terminals[terminalKey].programs[
          programKey
        ].irrigations[irrigationKey].groups[groupKey][groupPhaseKey]
          .valves_model[valveKey].nombre;
    } else {
      valve_name =
        this.translationsLib.get('reports_' + groupPhaseKey) +
        fertilizer_name +
        ' ' +
        this.translationsLib.get('irrigation_valve') +
        ' ' +
        this.groupedConsumptions[fieldKey].terminals[terminalKey].programs[
          programKey
        ].irrigations[irrigationKey].groups[groupKey][groupPhaseKey]
          .valves_model[valveKey].valvula;
    }

    this[programDataIndex].push({
      id: irrigation_valve_id,
      parent,
      name: valve_name,
      value,
    });
    this[groupingDataIndex].push({
      id: irrigation_valve_id,
      parent,
      name: valve_name,
      value,
    });

    this[programDataIndex + '_group_phase_valves_indexes'][
      irrigation_valve_id
    ] = true;
    this[groupingDataIndex + '_group_phase_valves_indexes'][
      irrigation_valve_id
    ] = true;

    return irrigation_valve_id;
  }

  resetAll() {
    console.log('[ReportsConsumption]: Reset all!');
    this.basicIrrigationProgramsConsumptionsReportBarsData = [];
    this.basicFertilizerProgramsConsumptionsReportBarsData = [];
    this.basicIrrigationGroupingConsumptionsReportBarsData = [];
    this.basicFertilizerGroupingConsumptionsReportBarsData = [];
    this.basicIrrigationProgramsConsumptionsReportChartData = [];
    this.basicFertilizerProgramsConsumptionsReportChartData = [];
    this.basicIrrigationGroupingConsumptionsReportChartData = [];
    this.basicFertilizerGroupingConsumptionsReportChartData = [];
    this.irrigationProgramsConsumptionsReportChartData = [];
    this.fertilizerProgramsConsumptionsReportChartData = [];
    this.irrigationGroupingConsumptionsReportChartData = [];
    this.fertilizerGroupingConsumptionsReportChartData = [];
    this.fertilizerColumnsToDisplay = [];
    this.terminalCountWithConsumptions = 0;
    this.irrigationProgramsConsumptionsReportChartData_program_indexes = {};
    this.fertilizerProgramsConsumptionsReportChartData_program_indexes = {};
    this.irrigationProgramsConsumptionsReportChartData_group_indexes = {};
    this.fertilizerProgramsConsumptionsReportChartData_group_indexes = {};
    this.irrigationProgramsConsumptionsReportChartData_valves_indexes = {};
    this.fertilizerProgramsConsumptionsReportChartData_valves_indexes = {};
    this.irrigationProgramsConsumptionsReportChartData_group_phase_valves_indexes =
      {};
    this.fertilizerProgramsConsumptionsReportChartData_group_phase_valves_indexes =
      {};
    this.irrigationGroupingConsumptionsReportChartData_group_indexes = {};
    this.fertilizerGroupingConsumptionsReportChartData_group_indexes = {};
    this.irrigationGroupingConsumptionsReportChartData_valves_indexes = {};
    this.fertilizerGroupingConsumptionsReportChartData_valves_indexes = {};
    this.irrigationGroupingConsumptionsReportChartData_group_phase_valves_indexes =
      {};
    this.fertilizerGroupingConsumptionsReportChartData_group_phase_valves_indexes =
      {};
    this.totalRealWaterConsumptionTotal = 0;
    this.reportsData = [];
    this.groupedConsumptions = {};
  }
}
