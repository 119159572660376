/**
 * @TODO REFACTOR + CLEANUP
 */
import { FieldDataModel } from '../fields/fields.model';
import {
  PermissionMappingIdentityDataModel,
  TerminalIdentityDataModel,
  UserIdentityDataModel,
} from '../permissions/permission.model';
import { OrganizationIdentityDataModel } from '../organizations/organization.model';
export interface PrePairingDataModel {
  metadata: {
    terminal: string;
  };
  terminal_info_id: any;
  terminal_info_image_path: string;

  pair_billing_me_allowed: boolean;
  pair_owner_me_allowed: boolean;
  pair_installer_me_allowed: boolean;
  pair_owner_email_allowed: boolean;
  pair_installer_email_allowed: boolean;
  terminal_info_model_text: string;
}

export interface PairingDataModel {
  paired_owner: string;
  paired_owner_status: string;
  paired_installer: string;
  paired_installer_status: string;
  terminal_info: {
    activated: boolean;
  };
}

export interface AttachDataModel {
  created_at: string;
}

export interface ContactsDataModel {
  vid: string;
  email: string;
  name: string;
  surname: string;
}

export interface DetachDataModel {
  deleted_at: string;
}

export interface TerminalDataModel {
  vid: string;
  name: string;
  source_database: string;
  source_endpoint: string;
  version: string;
  type: number;
  enabled: number;
  latitude: number;
  longitude: number;
  warp: string;
  pretty_type_name: string;
  metadata: string;
  image_path: string;
  activated: boolean;
  authed_user_can_write: boolean;
  authed_user_can_read: boolean;
  authed_user_can_admin: boolean;
  timezone: string;
  witch_hour: number;
  field?: FieldDataModel;
  field_vid?: string;
  permission_mapping_identity: PermissionMappingIdentityDataModel;
  is_demo: boolean;
  activated_at: string;
  updated_at: string;
  created_at: string;
}
export interface TerminalValvesDataModel {
  valve: string;
  waterSystem: string;
  name: string;
  latitude: string;
  longitude: string;
  active: boolean;
  irrigationParams: number;
  groups: [];
}
export interface TerminalAdministrativeManagementIdentityModel {
  administrative_management_type: string;
  terminal_identity: TerminalIdentityDataModel;
  user_identity: UserIdentityDataModel;
  organization_identity: OrganizationIdentityDataModel;
}

export interface MasterTemplate {
  id: number;
  name: {
    es: string;
    fr: string;
    pt: string;
    en: string;
  };
  // eslint-disable-next-line @typescript-eslint/ban-types
  locked_unlocked_configuration: {};
  locked_unlocked_configuration_html?: string;
  locked_unlocked_configuration_json?: any;
  // eslint-disable-next-line @typescript-eslint/ban-types
  default_layout_configuration: {};
  default_layout_configuration_html?: string;
  default_layout_configuration_json?: any;
  screen: string;
  priority: number;
  created_at: string;
  updated_at: string;
  read_only: boolean;
  terminals_vid: [];
  types: [];
}
export interface SpecificTemplate {
  // eslint-disable-next-line @typescript-eslint/ban-types
  locked_unlocked_configuration: {};
  // eslint-disable-next-line @typescript-eslint/ban-types
  default_layout_configuration: {};
  created_at: string;
  updated_at: string;
  master_configuration_template_id: number;
  master_configuration_template: MasterTemplate;
  screen: string;
  vid: string;
  name: string;
}
export interface SpecificTemplateKeys {
  [key: string]: SpecificTemplate;
}
export const TERMINAL_TYPES_IRRIGATION = [20, 21, 22, 23, 24, 25, 26, 27];
export const TERMINAL_TYPES_CLIMATE = [30, 31, 32, 33, 34, 35, 36, 37, 38];
export const TERMINAL_TYPES_DATALOGGER = [
  100, 101, 102, 103, 104, 105, 106, 107, 108, 109,
];
export const TERMINAL_TYPES_CARTS = [40];
export const TERMINAL_TYPES_CONFIGURABLE = [50, 51];

export const TERMINAL_ENABLED_FULLY_DISABLED = 0;
export const TERMINAL_ENABLED_ENABLED = 1;
export const TERMINAL_ENABLED_TEMPORARY_DISABLED = 2;
