import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { RestApiService } from '../services/api/rest-api.service';
import { EventCalendarDataModel } from './events-calendar.model';
import { IrrigationReportModel } from '../reports/reports.model';
import { TranslationsLibService } from '../services/libraries/translations-lib.service';
@Injectable({
  providedIn: 'root',
})
export class EventsCalendarService {
  private restApiService: RestApiService;
  public translationsLib: TranslationsLibService;

  constructor(
    restApiService: RestApiService,
    translationsLib: TranslationsLibService
  ) {
    this.restApiService = restApiService;
    this.translationsLib = translationsLib;
  }

  public getPastEvents(
    terminalVid: string,
    begin: string,
    end: string,
    user: string
  ): Observable<IrrigationReportModel> {
    return new Observable((observer) => {
      this.restApiService
        .getIrrigationReport(terminalVid, begin, end, user)
        .subscribe(
          (response) => {
            return observer.next(response);
          },
          (error) => {
            observer.next(error);
          }
        );
    });
  }

  public getFutureEvents(terminalVid): Observable<EventCalendarDataModel[]> {
    return new Observable((observer) => {
      this.restApiService.getActiveIrrigationPrograms(terminalVid).subscribe(
        (response) => {
          const futureEvents: EventCalendarDataModel[] = [];

          response.forEach((v) => {
            const begin_arr = v.begin.split(' ');
            const end_arr = v.end.split(' ');
            futureEvents.push({
              week_days: v.week_days,
              week: v.week,
              endHour: end_arr[1],
              terminalVid,
              beginHour: begin_arr[1],
              id: String(v.program),
              description:
                this.translationsLib.get('irrigation_program') + ' ' + v.name,
              url:
                '/farming/' + terminalVid + '/irrigation/program/' + v.program,
            });
          });

          observer.next(futureEvents);
        },
        (error) => {
          observer.next(error);
        }
      );
    });
  }
}
