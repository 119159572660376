import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root',
})
export class SemanticVersioningService {
  constructor() {}

  getSemanticVersioning(versionToFormat): string {
    if (typeof versionToFormat !== 'string') {
      return '-';
    }
    const numberToString = versionToFormat.toString();
    let formatted = '';

    for (let i = numberToString.length - 1, count = 0; i >= 0; i--, count++) {
      if (count > 0 && count % 2 === 0) {
        formatted = '.' + formatted;
      }
      formatted = numberToString[i] + formatted;
    }

    formatted = formatted
      .split('.')
      .map((segment) => {
        if (segment.length > 1) {
          segment = segment.replace(/^0+/, '');
        }
        return segment;
      })
      .join('.');

    formatted = formatted.replace(/\.(\d{2})$/g, (match, group) => {
      return '.' + (group === '00' ? '0' : group.replace(/^0+/, ''));
    });

    formatted = formatted.replace(/\.00$/g, '.0');

    formatted = formatted.replace(/(\d+)\.\.(\d+)/g, '$1.0.$2');
    formatted = formatted.replace(/^\.(\d+)/g, '0.$1');
    formatted = formatted.replace(/(\d+)\.$/g, '$1.0');

    const regex1 = /\.\.(\d+)$/; // ..XX
    const regex2 = /^(\d+)\.\.$/; // XX..
    const regex3 = /^\.\.$/; // ..

    if (regex1.test(formatted)) {
      formatted = formatted.replace(regex1, '0.0.$1');
    } else if (regex2.test(formatted)) {
      formatted = formatted.replace(regex2, '$1.0.0');
    } else if (regex3.test(formatted)) {
      formatted = '0.0.0';
    }

    return formatted;
  }
}
