import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { FieldsService } from '../fields.service';
import { SessionLibService } from '../../services/libraries/session-lib.service';
import { TerminalsService } from '../../terminals/terminals.service';
import { Title } from '@angular/platform-browser';
import { HelpersLibService } from '../../services/libraries/helpers-lib.service';
import { TerminalsLibService } from '../../services/libraries/terminals-lib.service';
import { TranslationsLibService } from '../../services/libraries/translations-lib.service';
import { WeatherService } from '../../weather/weather.service';
import { TimeLibService } from '../../services/libraries/time-lib.service';
import { AppCommonsService } from '../../commons/app-commons.service';
import { WeatherCalculateET0Request } from '../../weather/weather.model';
import { FieldDataModel } from '../fields.model';
import { PlatformLibService } from '../../services/libraries/platform-lib.service';
import Swal from 'sweetalert2';
import { TerminalDataModel } from '../../terminals/terminals.model';

@Component({
  selector: 'app-fields-dashboard',
  templateUrl: './fields-dashboard.component.html',
  styleUrls: ['./fields-dashboard.component.scss'],
})
export class FieldsDashboardComponent implements OnInit {
  private avatar;
  fieldName = '';
  field: FieldDataModel;
  fieldLoaded = false;
  calculateET0Request: WeatherCalculateET0Request;
  private vid: string;
  terminals: TerminalDataModel[] = [];
  terminalsError: TerminalDataModel[] = [];
  private coords;
  private color;
  private zoom;
  equipments;
  allValves: any;
  breadcrumbs: any = [];
  loading = true;
  loadingEquipment = true;
  platform: boolean;
  noRightsVids = [];
  constructor(
    private route: ActivatedRoute,
    private fieldsService: FieldsService,
    private session: SessionLibService,
    public router: Router,
    private terminalService: TerminalsService,
    private helpersLib: HelpersLibService,
    private titleService: Title,
    private timeLib: TimeLibService,
    private terminalsLib: TerminalsLibService,
    public translationsLib: TranslationsLibService,
    public commonService: AppCommonsService,
    public weatherService: WeatherService,
    private platformLib: PlatformLibService
  ) {}
  ngOnInit() {
    this.titleService.setTitle('');
    this.avatar = this.session.getAvatar();
    this.vid = this.route.snapshot.paramMap.get('vid');
    this.getField();
    this.platform = this.platformLib.isNative();
  }

  getHistoricWeather() {
    const startingEndingDate = this.timeLib.getModifiedDate(
      new Date(),
      -1,
      'days'
    );
    this.weatherService
      .getHistoric(this.vid, startingEndingDate, startingEndingDate)
      .subscribe((historic) => {
        this.calculateET0Request = {
          lat: historic.field_identity.latitude,
          lon: historic.field_identity.latitude,
          info: [],
        };
        historic.results.forEach((h) => {
          this.calculateET0Request.info.push({
            date: h.prediction_date,
            main_humidity: h.main_humidity,
            main_pressure: h.main_pressure,
            main_temp: h.main_temp,
            solar_rad: h.solar_rad,
            wind_speed: h.wind_speed,
            wind_deg: h.wind_deg,
          });
        });
        this.loadingEquipment = false;
      });
  }

  editField() {
    this.router.navigate(['./edit-field', { vid: this.vid }]);
  }

  async getField() {
    this.fieldsService.getField(this.vid).subscribe(
      async (response) => {
        this.field = response;
        this.fieldLoaded = true;
        this.helpersLib.setTitle(
          this.translationsLib.get('field_field'),
          response.name,
          null
        );
        this.fieldName = response.name;
        this.coords = response.coordinates;
        this.color = response.color;
        this.terminals = response.terminals_info;
        // eslint-disable-next-line @typescript-eslint/dot-notation
        this.zoom = response.config['map_zoom'];
        let coordenadasString = '';

        this.coords.forEach((coord) => {
          coordenadasString += coord.latitude + ',' + coord.longitude + '%7C';
        });
        coordenadasString = coordenadasString.slice(
          0,
          coordenadasString.length - 3
        ); //quito la última coma
        if (this.terminals.length > 0) {
          const vids = [];
          this.terminals.forEach((terminal) => {
            if (terminal.authed_user_can_read === true) {
              vids.push(terminal.vid);
              if (terminal.type === 0 || terminal.type === 40) {
                this.terminalsError.push(terminal);
              }
            } else {
              this.noRightsVids.push(terminal.vid);
              console.warn(
                '[NO RIGHTS]: Authed user has no rights to see ' + terminal.vid
              );
            }
          });
          if (vids.length > 0) {
            const data = {
              method: 'get',
              terminals_vid: vids,
            };
            this.terminalService.getTerminalsApiInfo(data).subscribe((res) => {
              res.forEach((equipment, kt1) => {
                if (!('error' in equipment)) {
                  this.terminalService
                    .getTerminal(equipment.serialNumber)
                    .subscribe((terminalResult) => {
                      res[kt1].systemType = this.terminalsLib.getType(
                        terminalResult.type
                      );
                    });
                } else {
                  console.log('[API-ERROR]: Error at API response');
                }
              });
              if (res.length > 0) {
                this.getHistoricWeather();
              }
              this.equipments = res;
              this.getAllValves();
            });
          }
        }
        setTimeout(() => {
          this.helpersLib.sendEventFieldView(this.field.name, this.vid);
          this.breadcrumbs = [
            {
              text: this.field.name,
              disabled: false,
              to: ['/field', { vid: this.vid }],
              active: false,
            },
            {
              text: this.translationsLib.get('dashboard'),
              disabled: false,
              to: ' ',
              active: true,
            },
          ];
          this.loading = false;
        }, 300);
      },
      (error) => {
        Swal.fire({
          text: this.translationsLib.get('field_no_rights'),
          icon: 'error',
          confirmButtonColor: '#224261',
        }).then((value) => {
          this.router.navigate(['/home/dashboard']);
        });
      }
    );
  }

  getAllValves() {
    this.allValves = [];
    this.equipments.forEach((term) => {
      if (term.valves) {
        term.valves.forEach((valve) => {
          this.allValves.push(valve);
        });
      }
    });
  }

  getTerminal(serialNumber: string) {
    return this.terminals.find((t) => t.vid === serialNumber);
  }
}
