import { EventEmitter, Inject, Injectable } from '@angular/core';

import {
  ActivatedRouteSnapshot,
  ActivationStart,
  NavigationEnd,
  NavigationStart,
  Router,
  Scroll,
} from '@angular/router';
import { DOCUMENT } from '@angular/common';

@Injectable({
  providedIn: 'root',
})
export class RouteLib {
  currentHash = '';
  previousHash = '';
  previousHash2 = '';
  mapHashes = [
    'fields-general',
    'field',
    'create-field',
    'edit-field',
    'terminal-activation',
    'terminals-general',
    'edit-terminal',
    'terminals-edit',
    'weather',
  ];
  navigationLifecycleIsRunning: EventEmitter<boolean> = new EventEmitter();

  constructor(
    @Inject(DOCUMENT) private document: Document,
    private router: Router
  ) {}

  navigationStart() {
    this.router.events.subscribe((event) => {
      /*if (event instanceof NavigationEnd) {
        console.log('%1');
      }*/
      if (event instanceof NavigationStart) {
        this.emitNavigationLifecycleIsRunning(true);
      } else if (event instanceof Scroll) {
        const that = this;
        // eslint-disable-next-line prefer-arrow/prefer-arrow-functions
        setTimeout(function () {
          that.emitNavigationLifecycleIsRunning(false);
        }, 200);
      }
    });
  }
  emitNavigationLifecycleIsRunning(running) {
    this.navigationLifecycleIsRunning.emit(running);
  }
  getNavigationLifecycleIsRunningEmitter() {
    return this.navigationLifecycleIsRunning;
  }
  getResolvedUrl(route: ActivatedRouteSnapshot): string {
    return route.pathFromRoot
      .map((v) => v.url.map((segment) => segment.toString()).join('/'))
      .join('/');
  }
}
