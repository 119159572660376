import { Injectable } from '@angular/core';
import { BarcodeScanner } from '@ionic-native/barcode-scanner/ngx';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class QrLibService {
  constructor(private barcodeScanner: BarcodeScanner) {}

  scaner(): Observable<any> {
    let data;
    return new Observable((subscriber) => {
      this.barcodeScanner
        .scan()
        .then((barcodeData) => {
          data = barcodeData.text;
          subscriber.next(data);
        })
        .catch((err) => {
          console.log('Error', err);
          subscriber.next(err);
        });
    });
  }
}
