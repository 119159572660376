import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  standalone: true,
  name: 'replaceNegative',
})
export class ReplaceNegativePipe implements PipeTransform {
  transform(value: number | string): string {
    if (value === -1) {
      return '-';
    }
    return value.toString();
  }
}
