import { Component, Input, OnInit } from '@angular/core';
import { TranslationsLibService } from 'src/app/services/libraries/translations-lib.service';
import { TerminalDataModel } from 'src/app/terminals/terminals.model';

@Component({
  selector: 'app-fields-dashboard-terminals-error',
  templateUrl: './fields-dashboard-terminals-error.component.html',
  styleUrls: ['./fields-dashboard-terminals-error.component.scss'],
})
export class FieldsDashboardTerminalsErrorComponent implements OnInit {
  @Input() terminalsError: TerminalDataModel[] = [];

  constructor(public translationsLib: TranslationsLibService) {}

  ngOnInit(): void {}
}
