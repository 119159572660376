import { Injectable } from '@angular/core';
import { ModulesService } from '../../modules/modules.service';
import {
  IrrigationBandDataModel,
  ModuleDataDataModel,
  ModuleDataModel,
} from '../../modules/modules.model';
import { Observable } from 'rxjs';
import * as moment from 'moment/moment';

@Injectable({
  providedIn: 'root',
})
export class ModulesLibService {
  constructor(private modulesService: ModulesService) {}
  getModulesByType(type: string): Observable<ModuleDataModel[]> {
    return new Observable((observer) => {
      this.modulesService.getModules().subscribe((res) => {
        const foundModules = [];
        if (res.length > 0) {
          res.forEach((mod) => {
            if (mod.type === type) {
              foundModules.push(mod);
            }
          });
        }
        observer.next(foundModules);
      });
    });
  }
  getModulesByVid(vid: string, type): Observable<ModuleDataModel> {
    return new Observable((observer) => {
      this.modulesService.getModulesByVid(vid).subscribe(
        (res) => {
          observer.next(res);
        },
        (error) => {
          observer.error(error);
        }
      );
    });
  }
  getModuleByTerminalVidAndType(
    terminalVid: string,
    type: string
  ): Observable<ModuleDataModel> {
    return new Observable((observer) => {
      this.modulesService
        .getModulesByTerminalVid(terminalVid)
        .subscribe((res) => {
          if (res.length > 0) {
            let foundModule = null;
            res.forEach((mod) => {
              if (mod.type === type) {
                foundModule = mod;
              }
            });
            observer.next(foundModule);
          } else {
            observer.next(null);
          }
        });
    });
  }
  getModuleDataByModule(
    module: ModuleDataModel
  ): Observable<ModuleDataDataModel> {
    const startingDate = new Date();
    const endingDate = new Date();
    endingDate.setDate(startingDate.getDate() + 1);
    const data = {
      start: moment(startingDate).format('YY-MM-DD HH:mm:ss'),
      end: moment(endingDate).format('YY-MM-DD HH:mm:ss'),
    };
    return new Observable((observer) => {
      this.modulesService
        .getModuleDataByVid(module.vid, data)
        .subscribe((moduleData) => {
          observer.next(moduleData);
        });
    });
  }
  getIrrigationBandFromModuleData(
    moduleData: ModuleDataDataModel
  ): IrrigationBandDataModel {
    let bottom = null;
    if (moduleData.bands.length > 0) {
      if (
        typeof moduleData.bands[moduleData.bands.length - 1] !== undefined &&
        moduleData.bands[moduleData.bands.length - 1][1] !== undefined
      ) {
        bottom = moduleData.bands[moduleData.bands.length - 1][1];
      }

      let upper = null;
      if (
        typeof moduleData.bands[moduleData.bands.length - 1] !== undefined &&
        moduleData.bands[moduleData.bands.length - 1][2] !== undefined
      ) {
        upper = moduleData.bands[moduleData.bands.length - 1][2];
      }
      return {
        bottom,
        upper,
      };
    }
  }
}
