import { Component, Input, OnInit } from '@angular/core';
import { MeteoChartsModel } from '../meteo-charts.model';
import { WeatherPredictionDataModel } from '../../weather.model';
import { WeatherLibService } from '../../../services/libraries/weather-lib.service';
import theme from 'highcharts/themes/high-contrast-light';
import * as Highcharts from 'highcharts/highstock';
import Exporting from 'highcharts/modules/exporting';
import ExportData from 'highcharts/modules/export-data';
import HC_accessibility from 'highcharts/modules/accessibility';
import highchartsMore from 'highcharts/highcharts-more';
import highchartsWindbarb from 'highcharts/modules/windbarb';
import * as moment from 'moment-timezone';
import { TranslationsLibService } from '../../../services/libraries/translations-lib.service';
import { HighchartsLibService } from '../../../services/libraries/highcharts-lib.service';

theme(Highcharts);
Exporting(Highcharts);
ExportData(Highcharts);
HC_accessibility(Highcharts);
highchartsMore(Highcharts);
highchartsWindbarb(Highcharts);

@Component({
  selector: 'app-meteogram-large',
  templateUrl: './meteogram-large.component.html',
  styleUrls: ['./meteogram-large.component.scss'],
})
export class MeteogramLargeComponent implements OnInit {
  chart;
  updateFlag = false;
  chartConstructor = 'chart';
  chartCallback;
  Highcharts = Highcharts;

  // Copy of MeteoChartsModel
  meteoChart: any = JSON.parse(JSON.stringify(MeteoChartsModel));
  @Input() forecast: WeatherPredictionDataModel[];
  loaded = false;
  constructor(
    public weatherLib: WeatherLibService,
    public translationsLib: TranslationsLibService,
    private highchartsLib: HighchartsLibService
  ) {}

  ngOnInit() {
    const xAxis = [];
    const temperatureSeries = [];
    const humiditySeries = [];
    const rainSeries = [];
    const windSeries = [];
    const solarRadSeries = [];
    const zone = this.forecast[0].prediction_time_zone;

    Highcharts.setOptions({
      time: {
        /**
         * Use moment-timezone.js to return the timezone offset for individual
         * timestamps, used in the X axis labels and the tooltip header.
         */
        getTimezoneOffset: (timestamp) => {
          return -moment.tz(timestamp, zone).utcOffset();
        },
      },
      lang: this.highchartsLib.getLangOptions(),
    });

    //@ts-ignore
    Highcharts.seriesTypes.windbarb.prototype.beaufortName = [
      this.translationsLib.get('highcharts_windbarb_calm'),
      this.translationsLib.get('highcharts_windbarb_light_air'),
      this.translationsLib.get('highcharts_windbarb_light_breeze'),
      this.translationsLib.get('highcharts_windbarb_gentle_breeze'),
      this.translationsLib.get('highcharts_windbarb_moderate_breeze'),
      this.translationsLib.get('highcharts_windbarb_fresh_breeze'),
      this.translationsLib.get('highcharts_windbarb_strong_breeze'),
      this.translationsLib.get('highcharts_windbarb_near_gale'),
      this.translationsLib.get('highcharts_windbarb_gale'),
      this.translationsLib.get('highcharts_windbarb_strong_gale'),
      this.translationsLib.get('highcharts_windbarb_storm'),
      this.translationsLib.get('highcharts_windbarb_violent_storm'),
      this.translationsLib.get('highcharts_windbarb_hurricane'),
    ];

    for (const hourlyForecast of this.forecast) {
      const seriesTime = new Date(hourlyForecast.prediction_date).getTime();

      xAxis.push(new Date(hourlyForecast.prediction_date).getTime());

      temperatureSeries.push([seriesTime, hourlyForecast.main_temp]);

      humiditySeries.push([seriesTime, hourlyForecast.main_humidity]);

      solarRadSeries.push([seriesTime, hourlyForecast.solar_rad]);

      rainSeries.push([seriesTime, Number(hourlyForecast.precip.toFixed(1))]);

      windSeries.push([
        seriesTime,
        hourlyForecast.wind_speed,
        hourlyForecast.wind_deg,
      ]);
    }

    this.meteoChart.title.text = this.translationsLib.get(
      'meteo_detailed_pred'
    );

    this.meteoChart.series[0].name =
      this.translationsLib.get('climate_temp') + ' (ºC)';
    this.meteoChart.series[0].data = temperatureSeries;

    this.meteoChart.series[1].name =
      this.translationsLib.get('climate_hum') + ' (%)';
    this.meteoChart.series[1].data = humiditySeries;

    this.meteoChart.series[2].name =
      this.translationsLib.get('climate_rad') + ' (W/m²)';
    this.meteoChart.series[2].data = solarRadSeries;

    this.meteoChart.series[3].name =
      this.translationsLib.get('climate_rain') + ' (mm)';
    this.meteoChart.series[3].data = rainSeries;

    this.meteoChart.series[4].name =
      this.translationsLib.get('climate_wind') + ' (Km/h)';
    this.meteoChart.series[4].data = windSeries;

    this.meteoChart.series[5].name = this.translationsLib.get('ET0');

    this.meteoChart.xAxis[0].data = xAxis;

    this.meteoChart.yAxis[0].title.text = this.translationsLib.get('TEMP');
    this.meteoChart.yAxis[1].title.text = this.translationsLib.get('HUMIDITY');
    this.meteoChart.yAxis[2].title.text =
      this.translationsLib.get('INSTANT_RADIATION');

    this.chartCallback = (chart) => {
      this.drawWeatherSymbols(chart);

      Highcharts.addEvent(chart, 'redraw', () => {
        // Remove existing icons
        chart.series[0].data.forEach((point) => {
          if (point.customIcon) {
            point.customIcon.destroy();
          }
        });
        if (chart.series[0].visible) {
          // Redraw the icons in the new position
          this.drawWeatherSymbols(chart);
        }
      });

      // Adjust windbarb sizes
      window.addEventListener('resize', () => {
        const smallWindbarb = window.innerWidth < 1500;

        chart.xAxis[0].options.offset = smallWindbarb ? 20 : 30;
        chart.series[4].options.vectorLength = smallWindbarb ? 10 : 18;
        chart.series[4].options.lineWidth = smallWindbarb ? 1 : 1.5;
        chart.series[4].options.yOffset = smallWindbarb ? -10 : -15;
      });
    };

    setTimeout(() => {
      this.updateFlag = true;
      this.loaded = true;
    }, 200);
  }

  private drawWeatherSymbols(chart) {
    const icons = [];
    this.forecast.forEach((hourlyForecast, index) => {
      icons.push(
        this.weatherLib.getIcon(
          hourlyForecast.weather_id,
          hourlyForecast.weather_icon[3]
        )
      );
    });

    const data = chart.series[0].data;

    for (let i = 0; i < data.length - 1; i++) {
      const point = data[i];
      const icon = icons[i];

      const image = chart.renderer
        .image(
          icon,
          point.plotX + chart.plotLeft - 8,
          point.plotY + chart.plotTop - 30,
          30,
          30
        )
        .attr({
          zIndex: 5,
        })
        .add();

      // Add shadow to icon
      image.css({
        filter: 'drop-shadow(1px 1px 1px rgba(50, 50, 50, 0.3))',
      });

      point.customIcon = image;
    }
  }

  loadEt0Serie(et0Forecast: any): void {
    if (this.meteoChart) {
      const et0Series = [];

      for (let i = 0; i < et0Forecast.length; i++) {
        // Today and after 12
        if (i === 0 && new Date().getHours() >= 12) {
          et0Series.push([
            new Date().setHours(new Date().getHours() + 1, 0, 0, 0),
            et0Forecast[i].ET0,
          ]);
        } else {
          et0Series.push([
            new Date(et0Forecast[i].startDate).setHours(12, 0, 0, 0),
            et0Forecast[i].ET0,
          ]);
        }
      }
      this.meteoChart.series[5].data = et0Series;

      this.meteoChart.yAxis[4].title.text = this.translationsLib.get('ET0');

      // Creating a new instance ensures that the graph is updated.
      this.meteoChart = { ...this.meteoChart };
    }
  }
}
