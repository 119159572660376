 <div class="row">
    <div class="col-12">
      <mat-chip-list>
          <div cdkDropListGroup id="listGroup">

            <div class="row">
              <div class="col-md-6 col-12">
                <div class="card p-4">
                  <div class="example-container">
                    <h5 class="text-header-primary mt-0 mb-3">{{ translationsLib.get('configuration_upper_menu') }}</h5>
                    <div
                      cdkDropList
                      [cdkDropListData]="upperMenu"
                      class="example-list"
                      (cdkDropListDropped)="drop($event)">
                      <div class="example-box" *ngFor="let item of upperMenu" cdkDrag>
                        <mat-chip color="primary" *ngIf="item.items.length === 0" (click)="saveHome(item.page)" >
                          <ion-icon name="ellipsis-vertical-outline"></ion-icon>
                          <ion-icon style="margin-top: 0px; margin-right: 5px;" slot="start" [ios]="item.icon + '-outline'" [md]="item.icon + '-outline'"></ion-icon> {{item.title}}
                        </mat-chip>
                        <div *ngIf="item.items.length !== 0">
                          <ion-icon name="ellipsis-vertical-outline"></ion-icon>
                          <ion-icon style="margin-top: 0px; position: relative; top: 2px;" slot="start" [ios]="item.icon + '-outline'" [md]="item.icon + '-outline'"></ion-icon> {{item.title}}
                          <br>
                          <mat-chip color="primary" *ngFor="let itemsInside of item.items" (click)="saveHome(item.page+ ';vid='+ itemsInside.vid)">
                            {{itemsInside.name}}
                          </mat-chip>
                        </div>
                      </div>
                    </div>
                    <hr class="my-12">
                    <h5 class="text-header-primary mt-0 mb-3">{{ translationsLib.get('configuration_bottom_menu') }}</h5>
                    <div
                      cdkDropList
                      [cdkDropListData]="bottomMenu"
                      class="example-list"
                      (cdkDropListDropped)="drop($event)">
                      <div class="example-box" *ngFor="let item of bottomMenu" cdkDrag>
                        <mat-chip color="primary" *ngIf="item.items.length === 0" (click)="saveHome(item.page)">
                          <ion-icon name="ellipsis-vertical-outline"></ion-icon>
                          <ion-icon style="margin-top: 0px; margin-right: 5px;" slot="start" [ios]="item.icon + '-outline'" [md]="item.icon + '-outline'"></ion-icon> {{item.title}}
                        </mat-chip>
                        <div *ngIf="item.items.length !== 0">
                          <ion-icon name="ellipsis-vertical-outline"></ion-icon>
                          <ion-icon style="margin-top: 0px; position: relative; top: 2px;" slot="start" [ios]="item.icon + '-outline'" [md]="item.icon + '-outline'"></ion-icon> {{item.title}}
                          <br>
                          <mat-chip color="primary" *ngFor="let itemsInside of item.items" (click)="saveHome(item.page+ ';vid='+ itemsInside.vid)">
                            {{itemsInside.name}}
                          </mat-chip>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div class="col-md-6 col-12">
                <div class="card p-4">
                  <div class="example-container">
                    <h5 class="text-header-primary mt-0 mb-3">{{ translationsLib.get('configuration_hidden_menu') }}</h5>
                    <div
                      cdkDropList
                      [cdkDropListData]="hiddenMenu"
                      class="example-list"
                      (cdkDropListDropped)="drop($event)">
                      <div class="example-box" style="color: darkgray" *ngFor="let item of hiddenMenu" cdkDrag>
                        <ion-icon name="ellipsis-vertical-outline"></ion-icon>
                        <ion-icon style="margin-top: 0px; margin-right: 5px;" slot="start" [ios]="item.icon + '-outline'" [md]="item.icon + '-outline'"></ion-icon> {{item.title}}</div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <!--<form  [formGroup]="homeSelected" >
            <div class="form-group col-md-12">
              &lt;!&ndash; Label &ndash;&gt;
              <label class="form-label">
                Seleccione cual desea que sea su página de inicio <i>(Si no selecciona ninguna se mantendrá la página actual)</i>
              </label>
              <select class="form-select" formControlName="item">
                <option [value]="''"> </option>
                <option id ="item" *ngFor="let item of setHome" value="{{item.page||item.vid}}" >{{item.name || item.title}}</option>
              </select>
            </div>
          </form>-->
      </mat-chip-list>
    </div>
    <div class="col-12 text-end">
      <button class="btn btn-primary" (click)="saveMenu()">
        {{ translationsLib.get('save') }}
      </button>
    </div>
  </div>
