<app-loader *ngIf="loader === true"></app-loader>

<section class="reports-main-subcomponent-container" *ngIf="this.reportsConsumptionService.totalRealWaterConsumptionTotal > 0 && showCharts === true">
  <app-reports-consumptions-charts
    [basicIrrigationConsumptionsReportBarsData]="this.reportsConsumptionService.basicIrrigationProgramsConsumptionsReportBarsData"
    [basicFertilizerConsumptionsReportBarsData]="this.reportsConsumptionService.basicFertilizerProgramsConsumptionsReportBarsData"
    [basicIrrigationConsumptionsReportChartData]="this.reportsConsumptionService.basicIrrigationProgramsConsumptionsReportChartData"
    [basicFertilizerConsumptionsReportChartData]="this.reportsConsumptionService.basicFertilizerProgramsConsumptionsReportChartData"
    [irrigationConsumptionsReportChartData]="this.reportsConsumptionService.irrigationProgramsConsumptionsReportChartData"
    [fertilizerConsumptionsReportChartData]="this.reportsConsumptionService.fertilizerProgramsConsumptionsReportChartData"
    [prettyBegin]="this.prettyBegin"
    [prettyEnd]="this.prettyEnd"
    [advancedModeIsAllowed]="advancedModeIsAllowed"
    [selectorModeIsAllowed]="true"
  ></app-reports-consumptions-charts>
</section>

<section class="reports-main-subcomponent-container" *ngFor="let reportData of reportsData;">
  <div class="card pb-3" *ngIf="reportData.ready === true">
    <div class="card-header">
      <h2 class="card-header-title">
        {{ translationsLib.get('reports_irrigation_programs_consumptions') }}  {{ reportData.terminalNameVid }}
      </h2>
      <h2 class="card-header-subtitle">
        {{ reportData.terminalFieldName }}
      </h2>
    </div>
    <div class="row mx-2 mt-3 print-no-padding">
      <div class="col-12 p-0 p-lg-2 consumption-container print-no-padding">
        <div class="alert alert-info mb-2" role="alert" *ngIf="reportData.programConsumptions.length === 0">
          {{ translationsLib.get('reports_no_data') }}
        </div>

        <article *ngIf="reportData.programConsumptions.length > 0">
          <!-- No need for simple table in consumptions! -->
          <app-reports-consumptions-table
            [reportName]="reportData.reportName"
            [consumptionColumnsToDisplay]="this.reportsConsumptionService.consumptionColumnsToDisplay"
            [fertilizerColumnsToDisplay]="this.reportsConsumptionService.fertilizerColumnsToDisplay"
            [totals]="reportData.programTotals"
            [data]="reportData.programConsumptions"
            [count]="reportData.programConsumptions.length"
            [tablePageItemNumber]="createdReport.tablePageItemNumber"
          ></app-reports-consumptions-table>
        </article>

      </div>
    </div>
  </div>
</section>

