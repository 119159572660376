<app-main-header [name]="translationsLib.get('dashboard_main_title')"></app-main-header>

<ion-content>
  <app-loader *ngIf="loader === true"></app-loader>
  <app-wizard-dashboard *ngIf="wizard && !loader" id="wizard"></app-wizard-dashboard>
  <ng-container *ngIf="!wizard && !loader">
    <div class="mx-md-4">
        <div class="card connections mx-2 mt-4">
          <div class="row">
            <div class="col-8 align-self-center ml-2">
              <h1 style="color: #224261">{{translationsLib.get('welcome')}}, {{name}}</h1>
            </div>
            <div class="col-4 text-end align-self-center">
              <img src="assets/img/Icons/Logo_UCROP_fondo_triptico.svg" style="max-height: 125px">
            </div>
          </div>
        </div>
        <ng-container *ngIf="fields !== undefined && fields.length > 0">
          <ng-container *ngFor="let field of fields, index as i">
            <div class="card mx-2">
              <div class="card-body p-0">
                <div class="row">
                  <div class="col-12 col-lg-3">
                    <div class="container-fluid px-1 px-lg-3">
                      <app-fields-header class="mx-2 mb-2" [field]="field"></app-fields-header>
                    </div>
                  </div>
                  <div class="col-12 col-md-6">
                    <div class="row">
                      <div class="col-4 align-self-center text-center">
                        <h6 class="text-center title">{{translationsLib.get(field.weather_status)}}</h6>
                        <img src="{{weatherLib.getIcon(field.weather_id, field.moment)}}" class="icon weather-icon-shadow" style="max-height:40px">
                      </div>
                      <div class="col-4 align-self-center">
                        <h6 class="title text-center">{{translationsLib.get('DL_Temp')}}</h6>
                        <h2 class="text-center data">{{field.temperature}}ºC</h2>
                      </div>
                      <div class="col-4 align-self-center">
                        <h6 class="title text-center">{{translationsLib.get('humidity')}}</h6>
                        <h2 class="text-center data">{{field.humidity}}%</h2>
                      </div>
                    </div>
                    <div class="row">
                      <div class="col-4 align-self-center">
                        <h6 class="title text-center">{{translationsLib.get('wind_speed')}}</h6>
                        <h2 class="text-center data">{{field.wind}}</h2>
                      </div>
                      <div class="col-4 align-self-center">
                        <h6 class="title text-center">{{translationsLib.get('radiation')}}</h6>
                        <h2 class="text-center data">{{field.radiation}} w/m<sup>2</sup></h2>
                      </div>
                      <div class="col-4 align-self-center">
                        <h6 class="title text-center">{{translationsLib.get('field_size_lower')}}</h6>
                        <h2 class="text-center data">{{(field.coordinates_area/10000).toFixed(2)}} ha</h2>
                      </div>
                    </div>

                  </div>
                  <div class="d-none d-md-block col-md-3 text-end align-self-center">
                    <img src="{{field.staticMap}}" class="rounded" style="max-height: 180px;">
                  </div>
                </div>
                <ng-container *ngIf="field.terminals_info.length > 0">
                  <div class="row mx-1 mx-md-2 mb-2">
                    <div class="col-12">
                      <hr class="mx-2 mt-3">
                    </div>
                    <div class="col-12 px-0">
                      <h3 class="text-uppercase mt-2" style="color:#66b8be">{{translationsLib.get('devices_in_field')}}</h3>
                    </div>
                  </div>

                  <ng-container *ngIf="userTypeIsInstaller === false">
                    <div class="row" *ngIf="equipmentDataLoader === true">
                      <div class="col-12 text-center mb-3">
                        <div class="spinner-border text-gray-700" role="status"></div>
                      </div>
                    </div>
                    <app-main-dashboard-terminals-simple-meteo-pills
                      id="fieldsAttached"
                      [terminals]="field.terminals_info"
                      [equipmentsData]="equipmentsData"
                      [irrigationConsultingModules]="irrigationConsultingModules"
                      *ngIf="equipmentDataLoader === false"
                    ></app-main-dashboard-terminals-simple-meteo-pills>
                  </ng-container>

                  <ng-container *ngIf="userTypeIsInstaller === true">
                    <app-main-dashboard-terminals-no-meteo-pills
                      [terminals]="field.terminals_info"
                    ></app-main-dashboard-terminals-no-meteo-pills>
                  </ng-container>


                </ng-container>
              </div>
            </div>
          </ng-container>
        </ng-container>
        <div class="card mx-2" *ngIf="terminalsNoField.length > 0">
          <h2 class="mx-2 my-2 text-uppercase text-muted">{{translationsLib.get('devices_no_field')}}</h2>
          <hr class="mx-2">

          <ng-container *ngIf="userTypeIsInstaller === false">
            <div class="row" *ngIf="equipmentDataLoader === true">
              <div class="col-12 text-center mb-3">
                <div class="spinner-border text-gray-700" role="status"></div>
              </div>
            </div>
            <app-main-dashboard-terminals-simple-meteo-pills
              [terminals]="terminalsNoField"
              [equipmentsData]="equipmentsData"
              *ngIf="equipmentDataLoader === false"
            ></app-main-dashboard-terminals-simple-meteo-pills>
          </ng-container>

          <ng-container *ngIf="userTypeIsInstaller === true">
            <app-main-dashboard-terminals-no-meteo-pills
              [terminals]="terminalsNoField"
            ></app-main-dashboard-terminals-no-meteo-pills>
          </ng-container>

        </div>
    </div>
  </ng-container>
</ion-content>
