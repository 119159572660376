<div class="container" *ngIf="ready === false">
  <app-loader></app-loader>
</div>

<div class="container-fluid" *ngIf="ready === true">
  <!-- PROGS -->
  <div class="row mb-2">
    <div class="col-4">
      <label class="form-label fw-bold"> {{translationsLib.get('irrigation_programs')}}</label>
    </div>
    <ng-container *ngIf="selected_progprog && selected_progprog.master_configuration_template_id === null">
      <div class="col-6 text-muted">
        {{translationsLib.get('terminal_template')}} {{ selected_progprog.name }}
      </div>
      <div class="col-2 align-self-center">
        <button class="btn btn-sm btn-danger" (click)="deleteSpecificTemplate(selected_progprog.vid)">
          <ion-icon name="trash-outline"></ion-icon>
        </button>
      </div>
    </ng-container>
    <ng-container *ngIf="selected_progprog && selected_progprog.master_configuration_template_id !== null">
      <div class="col-6">
        <select class="form-select" #progSelected>
          <option *ngFor="let template of progs" [value]="template.master_configuration_template_id">{{ template.name }}</option>
        </select>
      </div>
      <div class="col-2 align-self-center">
        <button class="btn btn-sm btn-terciary" (click)="attachTemplate(progSelected.value,'progprog')"><ion-icon name="save-outline"></ion-icon></button>
      </div>
    </ng-container>
  </div>
  <!-- GROUPS -->
  <div class="row mb-2">
    <div class="col-4">
      <label class="form-label fw-bold">{{translationsLib.get('irrigation_groups')}}</label>
    </div>
    <ng-container *ngIf="selected_progagrup && selected_progagrup.master_configuration_template_id === null">
      <div class="col-6 text-muted">
        {{translationsLib.get('terminal_template')}} {{ selected_progagrup.name }}
      </div>
      <div class="col-2 align-self-center">
        <button class="btn btn-sm btn-danger" (click)="deleteSpecificTemplate(selected_progagrup.vid)">
          <ion-icon name="trash-outline"></ion-icon>
        </button>
      </div>
    </ng-container>
    <ng-container *ngIf="selected_progagrup && selected_progprog.master_configuration_template_id !== null">
      <div class="col-6">
        <select class="form-select" #agrupSelected>
          <option *ngFor="let template of agrups" [value]="template.master_configuration_template_id">{{template.name}}</option>
        </select>
      </div>
      <div class="col-2 align-self-center">
        <button class="btn btn-sm btn-terciary" (click)="attachTemplate(agrupSelected.value,'progagrup')"><ion-icon name="save-outline"></ion-icon></button>
      </div>
    </ng-container>
  </div>
  <!-- PROGREC -->
  <div class="row mb-2">
    <div class="col-4">
      <label class="form-label fw-bold">{{translationsLib.get('irrigation_fertilizer_formulas')}}</label>
    </div>
    <ng-container *ngIf="selected_progrec && selected_progrec.master_configuration_template_id === null">
      <div class="col-6 text-muted">
        {{translationsLib.get('terminal_template')}} {{ selected_progrec.name }}
      </div>
      <div class="col-2 align-self-center">
        <button class="btn btn-sm btn-danger" (click)="deleteSpecificTemplate(selected_progrec.vid)">
          <ion-icon name="trash-outline"></ion-icon>
        </button>
      </div>
    </ng-container>
    <ng-container *ngIf="selected_progrec && selected_progrec.master_configuration_template_id !== null">
      <div class="col-6">
        <select class="form-select" #fertilizerSelected>
          <option *ngFor="let template of fertilizers">{{ template.name }}</option>
        </select>
      </div>
      <div class="col-2 align-self-center">
        <button class="btn btn-sm btn-terciary" (click)="attachTemplate(fertilizerSelected.value,'progrec')"><ion-icon name="save-outline"></ion-icon></button>
      </div>
    </ng-container>
  </div>
  <!-- PROGDREN -->
  <div class="row mb-2">
    <div class="col-4">
      <label class="form-label fw-bold">{{ translationsLib.get('irrigation_drainages') }}</label>
    </div>
    <ng-container *ngIf="selected_progdren && selected_progdren.master_configuration_template_id === null">
      <div class="col-6 text-muted">
        {{translationsLib.get('terminal_template')}} {{ selected_progdren.name }}
      </div>
      <div class="col-2 align-self-center">
        <button class="btn btn-sm btn-danger" (click)="deleteSpecificTemplate(selected_progdren.vid)">
          <ion-icon name="trash-outline"></ion-icon>
        </button>
      </div>
    </ng-container>
    <ng-container *ngIf="selected_progdren && selected_progdren.master_configuration_template_id !== null">
      <div class="col-6">
        <select class="form-select" #drainagesSelected>
          <option *ngFor="let template of drainages" [value]="template.master_configuration_template_id">{{template.name}}</option>
        </select>
      </div>
      <div class="col-2 align-self-center">
        <button class="btn btn-sm btn-terciary" (click)="attachTemplate(drainagesSelected.value,'progdren')"><ion-icon name="save-outline"></ion-icon></button>
      </div>
    </ng-container>
  </div>
  <!-- RMEZCLA -->
  <div class="row mb-2">
    <div class="col-4">
      <label class="form-label fw-bold">{{ translationsLib.get('irrigation_mixtures_formula') }}</label>
    </div>
    <ng-container *ngIf="selected_progrmezcla && selected_progrmezcla.master_configuration_template_id === null">
      <div class="col-6 text-muted">
        {{translationsLib.get('terminal_template')}} {{ selected_progrmezcla.name }}
      </div>
      <div class="col-2 align-self-center">
        <button class="btn btn-sm btn-danger" (click)="deleteSpecificTemplate(selected_progrmezcla.vid)">
          <ion-icon name="trash-outline"></ion-icon>
        </button>
      </div>
    </ng-container>
    <ng-container *ngIf="selected_progrmezcla && selected_progrmezcla.master_configuration_template_id !== null">
      <div class="col-6">
        <select class="form-select" #mixesSelected>
          <option *ngFor="let template of mixes" [value]="template.master_configuration_template_id">{{template.name}}</option>
        </select>
      </div>
      <div class="col-2 align-self-center">
        <button class="btn btn-sm btn-terciary" (click)="attachTemplate(mixesSelected.value,'progrmezcla')"><ion-icon name="save-outline"></ion-icon></button>
      </div>
    </ng-container>
  </div>
</div>
