import { Component, OnInit } from '@angular/core';
import { AppCommonsService } from '../../app-commons.service';
import { TranslationsLibService } from '../../../services/libraries/translations-lib.service';
import { PlatformLibService } from '../../../services/libraries/platform-lib.service';

@Component({
  selector: 'app-bottom-tabs',
  templateUrl: './bottom-tabs.component.html',
  styleUrls: ['./bottom-tabs.component.scss'],
})
export class BottomTabsComponent implements OnInit {
  menu;
  ready = false;
  fix20pxMarginTop = false;
  constructor(
    private commonsService: AppCommonsService,
    private platformLib: PlatformLibService,
    public translationsLib: TranslationsLibService
  ) {}

  ngOnInit() {
    this.fix20pxMarginTop = this.platformLib.isPad();
    this.commonsService.getMenu().subscribe((res) => {
      this.menu = res.layout_configuration.bottom_tabs;
      this.ready = true;
    });
  }
}
