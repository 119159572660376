<ng-container *ngIf="isFetchingFertilizerFormulasData || isFetchingMixtureFormulasData">
  <app-loader></app-loader>
</ng-container>

<ng-container *ngIf="!isFetchingFertilizerFormulasData && !isFetchingMixtureFormulasData">
  <!-- DESKTOP -->
  <ng-container *ngIf="!isMobile">
    <div [ngClass]="{'compact-table': compactTable === true}" (click)="goToLink()">
      <div class="card"
           [ngStyle]="{'cursor': linkToTerminal !== ''? 'pointer': 'default'}"
           [ngClass]="{'irrigation-card-border': terminalType === 'IRRIGATION', 'configurable-card-border': terminalType === 'CONFIGURABLE'}"
      >
        <div
          class="card-body fs-5 p-0 table-responsive custom-scrollbar ws-borders"
        >
          <h3 class="ws-title">
            {{ this.translationsLib.get('irrigation_ws_watersystemState') }}
          </h3>

          <div class="elements-table">
            <table class="table mb-0" *ngIf="waterSystems !== undefined && waterSystems.length > 0">
              <thead>
              <tr>
                <th
                  scope="col"
                  class="text-uppercase fs-6 terminal-table-header"
                >
                  {{ this.translationsLib.get('irrigation_ws_watersystem') }}
                </th>
                <th
                  scope="col"
                  class="text-uppercase fs-6 terminal-table-header"
                >
                  {{ this.translationsLib.get('irrigation_ws_idealCE') }}
                </th>
                <th
                  scope="col"
                  class="text-uppercase fs-6 terminal-table-header"
                >
                  {{ this.translationsLib.get('irrigation_ws_flow') }}
                </th>
                <th
                  scope="col"
                  class="text-uppercase fs-6 terminal-table-header"
                >
                  {{ this.translationsLib.get('irrigation_ws_pressure') }}
                </th>
                <th
                  scope="col"
                  class="text-uppercase fs-6 terminal-table-header"
                >
                  {{
                  this.translationsLib.get('irrigation_ws_fertilizerFormula')
                  }}
                </th>
                <th
                  scope="col"
                  class="text-uppercase fs-6 terminal-table-header"
                >
                  {{ this.translationsLib.get('irrigation_ws_mixtureFormula') }}
                </th>
                <th
                  scope="col"
                  class="text-uppercase fs-6 terminal-table-header"
                >
                  {{ this.translationsLib.get('irrigation_ws_readPH') }}
                </th>
                <th
                  scope="col"
                  class="text-uppercase fs-6 terminal-table-header"
                >
                  {{ this.translationsLib.get('irrigation_ws_idealPH') }}
                </th>
                <th
                  scope="col"
                  class="text-uppercase fs-6 terminal-table-header"
                >
                  {{ this.translationsLib.get('irrigation_ws_InEC') }}
                </th>
                <th
                  scope="col"
                  class="text-uppercase fs-6 terminal-table-header"
                >
                  {{ this.translationsLib.get('irrigation_ws_OutEC') }}
                </th>
              </tr>
              </thead>
              <tbody class="fs-4" *ngFor="let ws of waterSystems">
              <tr>
                <td class="terminal-table-data">{{ ws?.waterSystem + 1 }}</td>
                <td class="terminal-table-data">
                  {{ ws?.idealCE + ' mS/cm' }}
                </td>
                <td class="terminal-table-data">
                  {{ ws?.flow + ' m3/h' }}
                </td>
                <td class="terminal-table-data">
                  {{ ws?.pressure | replaceNegative }}
                  {{ ws?.pressure !== -1 ? ' bar' : '' }}
                </td>
                <td class="terminal-table-data">
                  {{
                  ws?.fertilizerFormula === 255
                    ? '-'
                    : getFertizerFormulaName(ws?.fertilizerFormula)
                  }}
                </td>
                <td class="terminal-table-data">
                  {{ getMixtureFormulaName(ws?.mixtureFormula) }}
                </td>
                <td class="terminal-table-data">
                  {{ ws?.readPH | replaceNegative }}
                </td>
                <td class="terminal-table-data">
                  {{ ws?.idealPH | replaceNegative }}
                </td>
                <td class="terminal-table-data">
                  {{ ws?.InEC | replaceNegative }}
                  {{ ws?.InEC !== -1 ? ' mS/cm' : '' }}
                </td>
                <td class="terminal-table-data">
                  {{ ws?.OutEC | replaceNegative }}
                  {{ ws?.OutEC !== -1 ? ' mS/cm' : '' }}
                </td>
              </tr>
              </tbody>
            </table>

            <div class="alert alert-light m-2 p-2" *ngIf="waterSystems === undefined || waterSystems.length === 0">
              <b>
                {{ translationsLib.get('no_data') }}
              </b>
            </div>
          </div>
        </div>
      </div>
    </div>
  </ng-container>

  <!-- MOBILE -->
  <ng-container *ngIf="isMobile">
    <div [ngClass]="{'compact-table': compactTable === true}">
      <div class="card irrigation-card-border">
        <div class="card-body fs-5 p-0 table-responsive ws-borders">
          <h3 class="ws-title">
            {{ this.translationsLib.get('irrigation_ws_watersystemState') }}
          </h3>

          <div class="elements-table">
            <table *ngFor="let ws of waterSystems" class="table mb-0">
              <tbody>
              <tr>
                <td class="terminal-table-data fw-bold" colspan="2">
                  {{
                  this.translationsLib.get('irrigation_ws_watersystem') +
                  ' ' +
                  (ws?.waterSystem + 1)
                  }}
                </td>
              </tr>
              <tr>
                <td class="terminal-table-data">
                  {{ this.translationsLib.get('irrigation_ws_idealCE') }}
                </td>
                <td class="terminal-table-data text-center mobile-td">
                  {{ ws?.idealCE + ' mS/cm' }}
                </td>
              </tr>
              <tr>
                <td class="terminal-table-data">
                  {{ this.translationsLib.get('irrigation_ws_flow') }}
                </td>
                <td class="terminal-table-data text-center mobile-td">
                  {{ ws?.flow + ' m3/h' }}
                </td>
              </tr>
              <tr>
                <td class="terminal-table-data">
                  {{ this.translationsLib.get('irrigation_ws_pressure') }}
                </td>
                <td class="terminal-table-data text-center mobile-td">
                  {{ ws?.pressure | replaceNegative }}
                  {{ ws?.pressure !== -1 ? ' bar' : '' }}
                </td>
              </tr>
              <tr>
                <td class="terminal-table-data">
                  {{
                  this.translationsLib.get('irrigation_ws_fertilizerFormula')
                  }}
                </td>
                <td class="terminal-table-data text-center mobile-td">
                  {{
                  ws?.fertilizerFormula === 255
                    ? '-'
                    : getFertizerFormulaName(ws?.fertilizerFormula)
                  }}
                </td>
              </tr>
              <tr *ngIf="ws?.mixtureFormula !== 255">
                <td class="terminal-table-data">
                  {{ this.translationsLib.get('irrigation_ws_mixtureFormula') }}
                </td>
                <td class="terminal-table-data text-center mobile-td">
                  {{ getMixtureFormulaName(ws?.mixtureFormula) }}
                </td>
              </tr>
              <tr>
                <td class="terminal-table-data">
                  {{ this.translationsLib.get('irrigation_ws_readPH') }}
                </td>
                <td class="terminal-table-data text-center mobile-td">
                  {{ ws?.readPH | replaceNegative }}
                </td>
              </tr>
              <tr>
                <td class="terminal-table-data">
                  {{ this.translationsLib.get('irrigation_ws_idealPH') }}
                </td>
                <td class="terminal-table-data text-center mobile-td">
                  {{ ws?.idealPH | replaceNegative }}
                </td>
              </tr>
              <tr>
                <td class="terminal-table-data">
                  {{ this.translationsLib.get('irrigation_ws_InEC') }}
                </td>
                <td class="terminal-table-data text-center mobile-td">
                  {{ ws?.OutEC | replaceNegative }}
                  {{ ws?.OutEC !== -1 ? ' mS/cm' : '' }}
                </td>
              </tr>
              <tr>
                <td class="terminal-table-data">
                  {{ this.translationsLib.get('irrigation_ws_OutEC') }}
                </td>
                <td class="terminal-table-data text-center mobile-td">
                  {{ ws?.InEC | replaceNegative }}
                  {{ ws?.InEC !== -1 ? ' mS/cm' : '' }}
                </td>
              </tr>
              </tbody>
            </table>

            <div class="alert alert-light m-2 p-2" *ngIf="waterSystems === undefined || waterSystems.length === 0">
              <b>
                {{ translationsLib.get('no_data') }}
              </b>
            </div>
          </div>
        </div>
      </div>
    </div>
  </ng-container>
</ng-container>
