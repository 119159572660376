import {
  AfterViewInit,
  Component,
  Input,
  OnInit,
  ViewChild,
} from '@angular/core';
import { AlarmsLibService } from '../../../services/libraries/alarms-lib.service';
import { TranslationsLibService } from '../../../services/libraries/translations-lib.service';
import { HelpersLibService } from '../../../services/libraries/helpers-lib.service';
import { ReportsService } from '../../reports.service';
import {IrrigationReportDataModel, IrrigationReportGroupsDataModel} from '../../reports.model';
import { TimeLibService } from '../../../services/libraries/time-lib.service';
import {
  animate,
  state,
  style,
  transition,
  trigger,
} from '@angular/animations';

import { MatPaginator } from '@angular/material/paginator';
import { MatTableDataSource } from '@angular/material/table';
import { MatSort } from '@angular/material/sort';

@Component({
  selector: 'app-reports-fertilizers-table',
  templateUrl: './reports-fertilizers-table.component.html',
  styleUrls: ['./reports-fertilizers-table.component.scss'],
  animations: [
    trigger('detailExpand', [
      state('collapsed', style({ height: '0px', minHeight: '0' })),
      state('expanded', style({ height: '*' })),
      transition(
        'expanded <=> collapsed',
        animate('225ms cubic-bezier(0.4, 0.0, 0.2, 1)')
      ),
    ]),
  ],
})
export class ReportsFertilizersTableComponent implements OnInit, AfterViewInit {
  @Input() data: IrrigationReportGroupsDataModel[];
  @Input() count: number;
  @Input() fertilizerColumnsToDisplay: string[];
  @ViewChild(MatPaginator) paginator: MatPaginator;
  @ViewChild(MatSort) sort: MatSort;
  dataSource: MatTableDataSource<IrrigationReportGroupsDataModel>;
  ready = false;
  constructor(
    public alarmsLib: AlarmsLibService,
    public translationsLib: TranslationsLibService
  ) {
    this.dataSource = new MatTableDataSource(this.data);
  }

  ngOnInit(): void {
    console.log(this.data);
    this.dataSource = new MatTableDataSource(this.data);
    this.ready = true;
  }
  ngAfterViewInit() {
    this.dataSource.paginator = this.paginator;
    this.dataSource.sort = this.sort;
  }
}
