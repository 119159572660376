<app-main-header [breadcrumbs]="breadcrumbs"></app-main-header>
<ion-content>
  <div class="container-fluid p-1 p-lg-2">
    <div class="mx-lg-4">
      <div class="card p-0 mt-4 fields-border">
        <div class="card-header main-field-dashboard-header">
          <div class="card-header-title my-2">
            <app-fields-header [field]="field" [buttons]="true" [space]="false" *ngIf="fieldLoaded === true"></app-fields-header>
          </div>
        </div>
      </div>
    </div>
    <div class="mx-lg-4">
      <div class="row align-items-end" *ngIf="loading === false && field.is_demo">
        <div class="col">
          <app-demo-alert></app-demo-alert>
        </div>
      </div>
    </div>
    <div class="mx-lg-4">
      <div class="col-12">
        <app-loader *ngIf="loading === true"></app-loader>
        <div id="container-fluid" *ngIf="loading === false">
          <app-weather-widget></app-weather-widget>
          <ng-container *ngIf="terminals.length > 0">
            <div class="row m-0 mb-4 px-0">
              <!--<div  class="mapaValvulas" id="prueba"></div>-->
              <!--<app-native-maps *ngIf="platform === true && allValves !== undefined" [fields]="{'terminals': terminals, 'allValves': allValves, 'field': field}" [coming]="'fields-dashboard'"></app-native-maps>-->
             <!-- <app-webapp-maps *ngIf="platform === false && allValves !== undefined" [fields]="{'terminals': terminals, 'allValves': allValves, 'field': field}" [coming]="'fields-dashboard'"></app-webapp-maps>-->
              <app-leaflet-maps style="padding: 0px" *ngIf="allValves !== undefined"
                                [coming]="'fields-dashboard'"
                                [field]="{
                                            terminals: terminals,
                                            allValves: allValves,
                                            field: field,
                                            color: null,
                                            coordinates: null,
                                            type: null,
                                            longitude: null,
                                            latitude: null,
                                            irrgationStatus: null
                                          }"></app-leaflet-maps>
            </div>
            <div class="row" *ngIf="loadingEquipment === false">
              <div class="col-12" *ngFor="let equipment of equipments" [ngSwitch]="equipment.systemType">
                <app-fields-dashboard-irrigation
                  *ngSwitchCase="'IRRIGATION'"
                  [calculateET0Request]="calculateET0Request"
                  [equipment]="equipment"
                ></app-fields-dashboard-irrigation>
                <app-fields-dashboard-datalogger
                  *ngSwitchCase="'DATALOGGER'"
                  [equipment]="equipment"
                ></app-fields-dashboard-datalogger>
                <app-fields-dashboard-climate
                  *ngSwitchCase="'CLIMATE'"
                  [equipment]="equipment"
                ></app-fields-dashboard-climate>
                <app-fields-dashboard-configurable
                  *ngSwitchCase="'CONFIGURABLE'"
                  [equipment]="equipment"
                  [terminal]="getTerminal(equipment.serialNumber)"
                ></app-fields-dashboard-configurable>
              </div>
            </div>

            <ng-container *ngIf="terminalsError.length > 0">
              <app-fields-dashboard-terminals-error [terminalsError]="terminalsError"></app-fields-dashboard-terminals-error>
            </ng-container>

            <ng-container *ngIf="noRightsVids.length > 0">
              <app-fields-dashboard-terminals-no-rights [allTerminals]="terminals" [terminalVidsWithNoRights]="noRightsVids"></app-fields-dashboard-terminals-no-rights>
            </ng-container>

          </ng-container>
          <ng-container *ngIf="terminals.length === 0">
            <div class="card">
              <div class="row my-4 text-center">
                <h1 class="text-uppercase" style="color: #224261; font-size: 25px">{{translationsLib.get('fields_no_devices')}}</h1>
                <h2 style="color: #444444; font-size: 18px">{{translationsLib.get('fields_no_devices_choose')}}</h2>
              </div>
              <div class="row mx-4  d-none d-md-flex">
                <div class="col-3 mt-7 text-center">
                  <button class="btn btn-terciary"(click)="router.navigate(['./pair-new-terminal'])">{{translationsLib.get('fields_add_new_device')}}</button>
                </div>
                <div class="col-6">
                  <img src="./assets/img/covers/no_devices.svg">
                </div>
                <div class="col-3 mt-7 text-center">
                  <button class="btn btn-terciary" (click)="editField()">{{translationsLib.get('fields_add_existing_device')}}</button>
              </div>
              </div>
              <div class="row mx-4 d-flex d-md-none">
                <div class="col-6 text-center ">
                  <button class="btn btn-terciary" (click)="router.navigate(['./pair-new-terminal'])" >{{translationsLib.get('fields_add_new_device')}}</button>
                </div>
                <h4 class="text-uppercase text-center mt-2" style="font-size: 15px">{{translationsLib.get('field_or')}}</h4>
                <div class="col-6 mb-4 text-center">
                  <button class="btn btn-terciary" (click)="editField()">{{translationsLib.get('fields_add_existing_device')}}</button>
                </div>
              </div>
              <div class="row mx-4 d-flex d-md-none">
                <div class="col-12">
                  <img src="./assets/img/covers/no_devices.svg">
                </div>
              </div>
            </div>
          </ng-container>
        </div>
      </div>
    </div>
  </div>
</ion-content>
