import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root',
})
export class ClimateInstallerService {
  constructor() {}

  checkLocalStorageForCurrentTabIndex() {
    if (localStorage.getItem('currentClimateTabIndex')) {
      localStorage.setItem('currentClimateTabIndex', JSON.stringify(0));
    }
  }

  removeInstallerOptionSelected() {
    if (localStorage.getItem('installerOption')) {
      localStorage.removeItem('installerOption');
    }
  }
}
