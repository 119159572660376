import { Component, Input, OnInit } from '@angular/core';
import { TranslationsLibService } from '../../../services/libraries/translations-lib.service';
import { IrrigationBandDataModel } from '../../../modules/modules.model';

@Component({
  selector: 'app-terminal-irrigation-consulting-pill',
  templateUrl: './terminal-irrigation-consulting-pill.component.html',
  styleUrls: ['./terminal-irrigation-consulting-pill.component.scss'],
})
export class TerminalIrrigationConsultingPillComponent implements OnInit {
  @Input() irrigationBand: IrrigationBandDataModel = null;
  @Input() tensiometer: any = {};
  @Input() class = 'normal';

  constructor(public translationsLib: TranslationsLibService) {}

  ngOnInit() {}
}
