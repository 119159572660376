import {Component, OnInit, ViewChild} from '@angular/core';
import { HelpersLibService } from '../../services/libraries/helpers-lib.service';
import { TranslationsLibService } from '../../services/libraries/translations-lib.service';
import {MatTabGroup} from "@angular/material/tabs";
import {AppCommonsService} from "../../commons/app-commons.service";

@Component({
  selector: 'app-profile-tabs',
  templateUrl: './profile-tabs.component.html',
  styleUrls: ['./profile-tabs.component.scss'],
})
export class ProfileTabsComponent implements OnInit {
  breadcrumbs: any = [];
  private tabSelected = this.translationsLib.get('general');
  layout;
  @ViewChild('tabGroup', { static: false }) tab: MatTabGroup;
  constructor(
    private helpersLib: HelpersLibService,
    public translationsLib: TranslationsLibService,
    private commonService: AppCommonsService,
  ) {}

  ngOnInit() {
    this.helpersLib.sendEventPageView('Profile');
    this.getLayout();
    this.breadcrumbs = [
      {
        text: this.translationsLib.get('profile_profile'),
        disabled: false,
        to: '',
        active: false,
      },
      {
        text: this.tabSelected,
        disabled: false,
        to: ' ',
        active: true,
      },
    ];
  }
  ngAfterViewInit() {
    this.helpersLib.focusTab(this.tab);
  }

  getLayout() {
    this.commonService.getLayout().subscribe((res) => {
      this.layout = res;
    });
  }
  changeTitle(evento) {
    this.tabSelected = evento.tab.textLabel;
    /*if(evento.index == 2){
      this.paysLoad.initPaysComponent();
    }*/
    this.breadcrumbs = [
      {
        text: this.translationsLib.get('profile_profile'),
        disabled: false,
        to: '',
        active: false,
      },
      {
        text: this.tabSelected,
        disabled: false,
        to: ' ',
        active: true,
      },
    ];
    this.helpersLib.setTitle(
      '',
      this.tabSelected,
      this.translationsLib.get('profile_profile')
    );
  }
}
