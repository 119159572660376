import { Component, Input, OnInit } from '@angular/core';
import { TerminalDataModel } from '../../../terminals/terminals.model';
import { TerminalsLibService } from '../../../services/libraries/terminals-lib.service';
import { TranslationsLibService } from '../../../services/libraries/translations-lib.service';
import { EquipmentGeneralTypes } from '../../../farming/farming.model';
import { DataloggerLibService } from '../../../farming/libraries/datalogger-lib.service';
import { ModuleDataModel } from '../../../modules/modules.model';
import { ModulesLibService } from '../../../services/libraries/modules-lib.service';
import { Et0LibService } from '../../../farming/libraries/et0-lib.service';

@Component({
  selector: 'app-main-dashboard-terminals-simple-meteo-pills',
  templateUrl: './main-dashboard-terminals-simple-meteo-pills.component.html',
  styleUrls: ['./main-dashboard-terminals-simple-meteo-pills.component.scss'],
})
export class MainDashboardTerminalsSimpleMeteoPillsComponent implements OnInit {
  @Input() irrigationConsultingModules: ModuleDataModel[] = [];
  @Input() terminals: TerminalDataModel[] = [];
  @Input() equipmentsData: any[] = [];
  orderedByIndexEquipments: any[] = [];
  orderedByIndexEquipmentsPills: any[] = [];
  orderedByIndexEquipmentsLastMeasure: string[] = [];
  orderedByIndexIrrigationConsultingModule: any[] = [];
  orderedByIndexIrrigationConsultingBand: any[] = [];
  orderedByIndexIrrigationConsultingTensiometer: any[] = [];
  orderedByIndexET0: any[] = [];
  pillsOrderDatalogger = [
    'SOIL_TENSION',
    'VWC',
    'TEMPERATURE',
    'HUMIDITY',
    'VPD',
    'ET0',
    'RADIATION',
    'WIND_VELOCITY',
    'PLUVIOMETER',
    'SOIL_TEMP',
    'VOLTAGE',
    'SIGNAL',
  ];
  equipmentGeneralTypes;
  constructor(
    private et0Lib: Et0LibService,
    private modulesLib: ModulesLibService,
    public terminalsLib: TerminalsLibService,
    public translationsLib: TranslationsLibService,
    public dataloggerLib: DataloggerLibService
  ) {
    this.equipmentGeneralTypes = EquipmentGeneralTypes;
  }

  ngOnInit() {
    this.terminals.forEach((terminal, index) => {
      const equipmentsDataFound = this.equipmentsData.filter(
        (equipmentData) => {
          let serialNumber = 'error';
          if ('serialNumber' in equipmentData) {
            // Datalogger API return serialNumber as an integer
            serialNumber = String(equipmentData.serialNumber).toString();
          }
          return terminal.vid === serialNumber;
        }
      );
      if (equipmentsDataFound.length > 0) {
        this.orderedByIndexEquipments[index] = equipmentsDataFound[0];

        let pills = equipmentsDataFound[0].meteo;
        this.orderedByIndexEquipmentsLastMeasure[index] =
          equipmentsDataFound[0].lastMeasure;
        this.et0Lib
          .getHistoricWeather(terminal)
          .then((historicWeatherResult) => {
            this.et0Lib
              .getET0(
                historicWeatherResult.calculateET0Request,
                equipmentsDataFound[0]
              )
              .then((et0Result) => {
                if (et0Result.yesterday_ET0 !== 'N/A') {
                  // Add first isolated of et0 webservice latency
                  this.orderedByIndexEquipmentsPills[index] = [
                    {
                      key: 'et0',
                      unit: 'mm',
                      value: et0Result.yesterday_ET0,
                      origin: 'VIRTUAL',
                    },
                  ].concat(this.orderedByIndexEquipmentsPills[index]);
                }
              });
          })
          .catch((error) => {
            console.log(error);
          });
        let text_key_prefix = '';
        if (
          this.terminalsLib.getType(terminal.type) ===
          EquipmentGeneralTypes.datalogger
        ) {
          pills = this.dataloggerLib.statesToPills(
            pills,
            equipmentsDataFound[0].states
          );
          pills = this.dataloggerLib.orderPills(
            pills,
            this.pillsOrderDatalogger
          );
          text_key_prefix = 'DL_PILLS_';
        }

        pills.forEach((pill, i) => {
          pills[i].temp_name = this.translationsLib.get(
            text_key_prefix + pill.key
          );
        });
        /*pills.sort((a, b) => a.temp_name.localeCompare(b.temp_name));*/

        this.orderedByIndexEquipmentsPills[index] = pills;

        const irrigationConsultingModuleFound =
          this.irrigationConsultingModules.filter(
            (irrigationConsultingModule) => {
              return terminal.vid === irrigationConsultingModule.terminal_vid;
            }
          );
        if (irrigationConsultingModuleFound.length > 0) {
          this.modulesLib
            .getModuleDataByModule(irrigationConsultingModuleFound[0])
            .subscribe((moduleData) => {
              this.orderedByIndexIrrigationConsultingBand[index] =
                this.modulesLib.getIrrigationBandFromModuleData(moduleData);
            });

          this.orderedByIndexIrrigationConsultingTensiometer[index] =
            this.dataloggerLib.getTensiometerForIrrigationConsulting(
              irrigationConsultingModuleFound[0],
              equipmentsDataFound[0].states
            );
        }
      }
    });
  }
}
