<!--<div class="card m-2 mx-auto">
  <h4 class="text-center" style="color: #364888">{{this.cursor}} {{this.actualUnit}} - <span style="color: #999999">{{this.fecha}}</span></h4>
</div>-->
<div style="min-height: 300px; overflow-x: hidden" [hidden]="loaded===true">
  <app-loader *ngIf="loaded === false"></app-loader>
</div>
<div *ngIf="chartError===true">
  <h4 style="font-style: italic">{{translationsLib.get('graphs_error_permissions')}}</h4>
</div>
<div [hidden]="loaded===false
    || (loaded === true && magnitudesInChart>4 && platformLib.isNative() && getOrientation() === 'portrait')" *ngIf="chartError===false">
  <highcharts-chart [hidden]="gotData!==null&& gotData.length===0"
    id="graphics"
    [Highcharts]="Highcharts"
    [constructorType]="chartConstructor"
    [options]="consumptionChart"
    [(update)]="updateFlag"
    [callbackFunction]="chartCallback"
    [oneToOne]="true"
    style="height: 550px; display: block;"
    class="mb-2"
  >
  </highcharts-chart>
  <ng-container *ngIf="fields.length===0">
    <div class=" table-responsive">
      <table class="table card-table" *ngIf="elementsDisposition===1 && gotData!==null && gotData.length>0 && operationInCharts.length===0">
        <thead>
        <tr>
          <th class="px-1 px-xl-4">
            {{translations.get('DL_sonda')}}
          </th>
          <th class="px-1 px-xl-4 d-none d-xl-table-cell" style="min-width: 150px">
            {{translations.get('DL_cursor')}}
          </th>
          <th class="px-1 px-xl-4">
            {{translations.get('DL_minima')}}
          </th>
          <th class="px-1 px-xl-4">
            {{translations.get('DL_maxima')}}
          </th>
          <th class="px-1 px-xl-4">
            {{translations.get('DL_media')}}
          </th>
        </tr>
        </thead>
        <tbody>
        <tr *ngFor="let sensor of sensorConnected;  index as i">
          <td class="px-1 px-xl-4">
            <span *ngIf="sensor.deviceName" style="color: #6f9ad1">{{sensor.deviceName}} - </span>{{sensor.name}}
          </td>
          <td class="px-1 px-xl-4 columnText d-none d-xl-table-cell">
            {{ this.multipleCursor[sensor.dataName] }} {{sensor.unit}}
          </td>
          <td class="px-1 px-xl-4 columnText">
            {{sensor.min || '-'}} {{sensor.unit}}
          </td>
          <td class="px-1 px-xl-4 columnText">
            {{sensor.max || '-'}} {{sensor.unit}}
          </td>
          <td class="px-1 px-xl-4 columnText">
            {{sensor.media || '-'}} {{sensor.unit}}
          </td>
        </tr>
        </tbody>
      </table>
    </div>
    <table class="table table-responsive card-table" *ngIf="elementsDisposition===1 && gotData!==null && gotData.length>0 && operationInCharts.length>0">
      <thead>
      <tr>
        <th>
          {{translations.get('name')}}
        </th>
        <th>
          {{translations.get('graphics_operation_device')}}
        </th>
        <th class="px-1 px-xl-4">
          {{translations.get('DL_minima')}}
        </th>
        <th class="px-1 px-xl-4">
          {{translations.get('DL_maxima')}}
        </th>
        <th class="px-1 px-xl-4">
          {{translations.get('DL_media')}}
        </th>
        <th class="d-none d-lg-table-cell">
          {{translations.get('DL_cursor')}}
        </th>
        <!--<th >
        {{translations.get('DL_media')}}
      </th>-->
      </tr>
      </thead>
      <tbody>
      <tr *ngFor="let sensor of operationInCharts;  index as i">
        <td>
          {{sensor.name}}
        </td>
        <td>
          <span *ngIf="sensor.operation" style="color: #6f9ad1">{{translations.get('graphics_' + sensor.operation)}} <span *ngIf="sensor.subtype">({{translations.get(sensor.subtype)}})</span></span>
          <span *ngIf="!sensor.operation" style="color: #6f9ad1">{{sensor.deviceName}}</span>
        </td>
        <td>
          {{sensor.min}} {{sensor.unit}}
        </td>
        <td>
          {{sensor.max}} {{sensor.unit}}
        </td>
        <td>
          {{sensor.media}} {{sensor.unit}}
        </td>
        <td class="columnText d-none d-lg-table-cell">{{ this.multipleCursor[sensor.dataName] }} {{sensor.unit}}
        </td>
        <!--<td>
        {{sensor.media || '-'}} {{sensor.unit}}
      </td>-->
      </tr>
      </tbody>
    </table>
  </ng-container>
  <div class="row mb-2" *ngIf="fields.length>0" >
    <div class="col-12">
      <button style="float: right; margin-right: 6px" class="btn-fields" id="nextButton">{{translationsLib.get('graphics_view_fields')}}<ion-icon style="position: relative; top:3px" name="arrow-forward-outline"></ion-icon></button>
      <button style="float: right; margin-right: 6px" class="btn-fields" id="backButton"><ion-icon style="position: relative; top:3px" name="arrow-back-outline"></ion-icon>{{translationsLib.get('graphics_view_table')}}</button>
    </div>
  </div>
  <swiper
    [slidesPerView]="1"
    [centeredSlides]="true"
    [navigation]="{ prevEl: '#backButton', nextEl: '#nextButton' }"
    [keyboard]="{enabled: true}"
    [virtual]="true"
    [autoHeight]="true"
    (navigationNext)="'nextButton'"
    class="my-swiper" *ngIf="fields.length>0" #swiper>
    <ng-template swiperSlide >
      <div class="swiper-slide-container">
        <div class=" table-responsive">
          <table class="table card-table" *ngIf="elementsDisposition===1 && gotData!==null && gotData.length>0 && operationInCharts.length===0">
            <thead>
            <tr>
              <th class="px-1 px-xl-4">
                {{translations.get('DL_sonda')}}
              </th>
              <th class="px-1 px-xl-4 d-none d-xl-table-cell">
                {{translations.get('DL_cursor')}}
              </th>
              <th class="px-1 px-xl-4">
                {{translations.get('DL_minima')}}
              </th>
              <th class="px-1 px-xl-4">
                {{translations.get('DL_maxima')}}
              </th>
              <th class="px-1 px-xl-4">
                {{translations.get('DL_media')}}
              </th>
            </tr>
            </thead>
            <tbody>
            <tr *ngFor="let sensor of sensorConnected;  index as i">
              <td class="px-1 px-xl-4">
                <span *ngIf="sensor.deviceName" style="color: #6f9ad1">{{sensor.deviceName}} - </span>{{sensor.name}}
              </td>
              <td class="px-1 px-xl-4 columnText d-none d-xl-table-cell">
                {{ this.multipleCursor[sensor.dataName] }} {{sensor.unit}}
              </td>
              <td class="px-1 px-xl-4 columnText">
                {{sensor.min || '-'}} {{sensor.unit}}
              </td>
              <td class="px-1 px-xl-4 columnText">
                {{sensor.max || '-'}} {{sensor.unit}}
              </td>
              <td class="px-1 px-xl-4 columnText">
                {{sensor.media || '-'}} {{sensor.unit}}
              </td>
            </tr>
            </tbody>
          </table>
        </div>
        <table class="table table-responsive card-table" *ngIf="elementsDisposition===1 && gotData!==null && gotData.length>0 && operationInCharts.length>0">
          <thead>
          <tr>
            <th>
              {{translations.get('name')}}
            </th>
            <th>
              {{translations.get('graphics_operation_device')}}
            </th>
            <th class="px-1 px-xl-4">
              {{translations.get('DL_minima')}}
            </th>
            <th class="px-1 px-xl-4">
              {{translations.get('DL_maxima')}}
            </th>
            <th class="px-1 px-xl-4">
              {{translations.get('DL_media')}}
            </th>
            <th class="d-none d-lg-table-cell">
              {{translations.get('DL_cursor')}}
            </th>
            <!--<th >
              {{translations.get('DL_media')}}
            </th>-->
          </tr>
          </thead>
          <tbody>
          <tr *ngFor="let sensor of operationInCharts;  index as i">
            <td>
              {{sensor.name}}
            </td>
            <td>
              <span *ngIf="sensor.operation" style="color: #6f9ad1">{{translations.get('graphics_' + sensor.operation)}} ({{translations.get(sensor.subtype)}})</span>
              <span *ngIf="!sensor.operation" style="color: #6f9ad1">{{sensor.deviceName}}</span>
            </td>
            <td>
              {{sensor.min}} {{sensor.unit}}
            </td>
            <td>
              {{sensor.max}} {{sensor.unit}}
            </td>
            <td>
              {{sensor.media}} {{sensor.unit}}
            </td>
            <td class="columnText d-none d-lg-table-cell">{{ this.multipleCursor[sensor.dataName] }} {{sensor.unit}}
            </td>
            <!--<td>
              {{sensor.media || '-'}} {{sensor.unit}}
            </td>-->
          </tr>
          </tbody>
        </table>
      </div>
    </ng-template>
    <ng-template swiperSlide>
      <div class="swiper-slide-container" *ngFor="let field of fields" style="margin-left: 20px">
        <app-fields-header [field]="field"  [space]="false"></app-fields-header>
        <hr class="my-2">
        <div class="row align-self-center">
            <div class="col-12 mb-4">
                  <table class="table table-responsive card-table">
                    <thead>
                    <tr>
                      <th class="col-4">
                        {{translations.get('name')}}
                      </th>
                      <th class="col-4">
                        {{translations.get('terminal_activation_modelo')}}
                      </th>
                      <th class="col-4">
                        {{translations.get('terminal_activation_serial_number')}}
                      </th>
                      <!--<th >
                        {{translations.get('DL_media')}}
                      </th>-->
                    </tr>
                    </thead>
                    <tbody>
                    <ng-container  *ngFor="let equipo of field.terminals_info">
                      <tr  *ngIf="equipo.completed === true">
                        <td>
                          {{equipo.name}}
                        </td>
                        <td>
                          {{equipo.pretty_type_name}}
                        </td>
                        <td>
                          {{equipo.vid}}
                        </td>
                      </tr>
                    </ng-container>
                    </tbody>
                  </table>
            </div>
        </div>
      </div>
    </ng-template>
  </swiper>
</div>
<div class="container" role="alert" *ngIf="loaded===true && gotData !== null && gotData.length !== 0 && magnitudesInChart>4 && platformLib.isNative() && getOrientation() === 'portrait'">
  <div class="row">
    <div class="col-12 alert alert-warning p-2 my-4 text-center">
      {{translations.get('DL_alert_some_magnitudes')}}
    </div>
  </div>
</div>
<div class="container" role="alert" *ngIf="gotData !== null && gotData.length === 0 && loaded === true">
  <div class="row">
    <div class="col-12 alert alert-danger p-2 my-4 text-center">
      {{translations.get('DL_alert_no_DATA')}}
    </div>
  </div>
</div>
