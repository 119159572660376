<app-loader *ngIf="loading===false"></app-loader>
<div style="width: 100%; height: 450px; display: block;" *ngIf="loading===false">

</div>
<highcharts-chart
  [hidden]="loading===false || noET0data === true"
  [Highcharts]="Highcharts"
  [constructorType]="chartConstructor"
  [options]="consumptionChart"
  [(update)]="updateFlag"
  [callbackFunction]="chartCallback"
  [oneToOne]="true"
  style="width: 100%; height: 450px; display: block;"
  class="mb-4"
>
</highcharts-chart>
<div class="row" *ngIf="elementsDisposition===1 && ETCs.length>0 && noET0data === false">

  <ng-container *ngFor="let date of dataSeriesX">
    <div class="col-12 mb-4">
      <div class="table-responsive">
        <div>
          <h4 style="font-style: italic; font-weight: bold" class="text-uppercase mx-2">{{translations.get('DL_date')}} {{date}} </h4>
        </div>
        <table class="table table-responsive table-bordered table-sm mb-0 text-center">
          <thead>
          <tr>
            <th>{{translations.get('DL_crop')}}</th>
            <th>{{translations.get('DL_ETC')}}<span class="text-lowercase">(mm)</span></th>
            <th>{{translations.get('DL_consumo')}}<span class="text-lowercase">(m<sup>3</sup>)</span></th>
            <th>{{translations.get('DL_irrigation_time')}}</th>
            <th>{{translations.get('DL_sector_dosis')}}<span class="text-lowercase">(m<sup>3</sup>)</span></th>
          </tr>
          </thead>
          <tbody>
          <tr *ngFor="let etc of ETCs">
            <td>{{etc.etc.cropType}}</td>
            <td>{{roundValue(this.ET0s[date] * etc.etc.constant)|| '-'}} mm</td>
            <td>{{roundValue((this.ET0s[date] * etc.etc.constant) * etc.etc.sectorSurface*10000/1000)|| '-'}} m<sup>3</sup> </td>
            <td>{{timeLib.hoursToTime(roundValue(etcLib.getTiempoRiego(etc.etc,this.ET0s[date] * etc.etc.constant)/etc.etc.dayInterval))|| '-'}}</td>
            <td>{{roundValue(etcLib.getDosisSector(etc.etc,this.ET0s[date] * etc.etc.constant)/1000)|| '-'}} m<sup>3</sup></td>
          </tr>
          </tbody>
        </table>
      </div>
    </div>

  </ng-container>
</div>
<div class="container" role="alert" *ngIf="noET0data === true">
  <div class="row">
    <div class="col-12 alert alert-danger p-2 my-4 text-center">
      {{translations.get('DL_alert_no_DATA')}}
    </div>
  </div>
</div>
