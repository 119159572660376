<app-main-header [name]="terminalName"></app-main-header>
<ion-content>
  <!-- HEADER -->
  <div class="header">
    <div class="container-fluid">

      <!-- Body -->
      <div class="header-body">
        <div class="row align-items-end">
          <div class="col">
             <div class="header-title" style="display: flex; align-items:baseline">
                  <h3 style="margin-bottom: 0px; font-weight: normal">{{ translationLib.get('terminal_terminal') }}/</h3>
                  <h2 style="margin-bottom: 0px; color: #faa01e;" >{{terminalName}}</h2>
             </div>
          </div>
          <div class="col-auto">

            <!-- Button -->
            <a (click)="configTerminal()" class="btn btn-primary" *ngIf="!hasPermissions">
              <ion-icon id="icono" [ios]="'settings-outline'" [md]="'settings-outline'"></ion-icon>
            </a>

          </div>
        </div> <!-- / .row -->
      </div> <!-- / .header-body -->

    </div>
  </div> <!-- / .header -->

</ion-content>
