<app-main-header [breadcrumbs]="breadcrumbs"></app-main-header>
<ion-content id="wrap">
  <div class="mx-4">
    <div class="row mt-3">
      <div class="card" *ngIf="weatherLoaded">
        <div class="row align-items-center">
          <div class="col-12 col-md-6">
            <h2 class="city text-center">
              <ng-container *ngIf="weather_station === null">
                {{ this.translationsLib.get('meteo_station') }}: {{ weather.weather_station_cid }}
              </ng-container>
              <ng-container *ngIf="weather_station !== null">
                {{ this.translationsLib.get('meteo_station') }}:
                {{ weather_station.name }} ({{ weather.weather_station_cid }})
              </ng-container>
            </h2>
            <div class="d-flex align-items-center justify-content-center flex-wrap">
              <img style="max-width: 90px;" src="{{weatherLib.getIcon(weather.weather_id, moment)}}" >
              <h1 class="temp m-0">{{weather.main_temp}}ºC</h1>
            </div>
          </div>
          <div class="col-12 col-md-6 mb-4 mb-md-0">
            <h4 class="text-center mt-2">{{weather.weather_description}} - <span style="color: #224261;">Min. {{weather.main_temp_min}}º </span><span style="color: #ee7869;">Max. {{weather.main_temp_max}}º</span></h4>
            <h4 class="d-flex align-items-center justify-content-center flex-wrap">
              <span class="d-flex me-1" [innerHTML]="'WIND_VELOCITY_OUTLINE' | sensorIcon"></span> {{weather.wind_speed}} Km/h &nbsp;
              <span class="d-flex me-1" [innerHTML]="'WIND_DIRECTION_OUTLINE' | sensorIcon"></span> {{windDirection}} &nbsp;
              <span class="d-flex me-2" [innerHTML]="'HUMIDITY_OUTLINE' | sensorIcon"></span> {{weather.main_humidity}} % &nbsp;
              <span class="d-flex me-1" [innerHTML]="'RADIATION_OUTLINE' | sensorIcon"></span> {{weather.solar_rad}} W/m<sup>2</sup>
            </h4>
            <h4 class="d-flex align-items-center justify-content-center">
              <span [innerHTML]="'SUNRISE' | sensorIcon: 'width: 22px;'"></span> {{getHour(weather.sunrise)}} &nbsp;
              <span [innerHTML]="'SUNSET' | sensorIcon: 'width: 22px;'"></span> {{getHour(weather.sunset)}} &nbsp;
            </h4>
            <span class="position-absolute text-muted fs-5 fst-italic" style="right: 12px; bottom: 6px"> {{getHour(weather.prediction_date)}} {{weather.prediction_time_zone}} {{predictionTimezoneDifferenceUTC}} </span>
          </div>
        </div>
      </div>
    </div>
    <div class="row">
      <app-loader *ngIf="!forecastLoaded"></app-loader>
      <div class="card" *ngIf="forecastLoaded">
        <app-meteogram-large class="d-none d-md-block" [forecast]="hourlyForecast"></app-meteogram-large>
        <app-meteogram-large-mobile class="d-md-none" [forecast]="hourlyForecast"></app-meteogram-large-mobile>

          <h4 class="text-uppercase"> {{translationsLib.get('meteo_by_hours')}} <hr style="color: #224261"></h4>
          <div class="row meteo-pills">

          <ng-container *ngFor="let prediction of forecast.predictions">
            <div class="col d-flex pb-4 text-center" style="cursor: pointer" *ngIf="prediction.sublevel.length > 0">
              <div class="card align-items-center flex-fill mb-0" style="border: 2px #ccc solid" [ngClass]="{ 'selectedCard': selectedPrediction === prediction }" (click)="selectPrediction(prediction)">
                <div class="row w-100 flex-fill">
                  <div class="col-12 col-xl-5 d-flex flex-column justify-content-around align-items-center">
                    <div class="mt-2">
                      {{translationsLib.get('meteo_day_'+ getDay(prediction.prediction_date))}}
                      <span style="font-size: 12px">({{getDate(prediction.prediction_date)}})</span>
                    </div>
                    <img src="{{weatherLib.getIcon(prediction.weather_id, getMoment(prediction))}}" class="day-icon weather-icon-shadow">
                  </div>
                  <div class="col-12 col-xl-7 d-flex flex-column justify-content-around">
                    <h4 class="mt-0 mb-3 my-md-2" style="color: #224261;"><span class="d-none d-md-inline">Min.</span> {{prediction.main_temp_min}}º</h4>
                    <h4 class="mt-0 mb-3 my-md-2" style="color: #ee7869;"><span class="d-none d-md-inline">Max.</span> {{prediction.main_temp_max}}º</h4>
                    <h4 class="mt-0 mb-3 my-md-2" style="color: #0369a1;"><span class="d-none d-md-inline">{{this.translationsLib.get('climate_rain')}}.</span> {{getFormatPrecip(prediction.precip)}} mm</h4>
                    <ng-container *ngIf="et0Loaded">
                      <ng-container *ngFor="let et0 of et0Forecast">
                        <h4 *ngIf="getDate(prediction.prediction_date) === getDate(et0.startDate)" class="mt-0 mb-3 my-md-2" style="color: #708090;"><span class="d-none d-md-inline">{{this.translationsLib.get('DL_ET0')}}.</span> {{et0.ET0}} mm</h4>
                      </ng-container>
                    </ng-container>
                  </div>
                </div>
              </div>
            </div>
          </ng-container>
        </div>

        <div class="row px-3" *ngIf="selectedPrediction && selectedPrediction.sublevel.length > 0">
            <div class="card mb-3" style="border: 2px #ccc solid">
              <div class="card-title d-none d-md-block mb-0">
                <div class="row">
                  <div class="col-4 d-flex">
                    <h4 class="col-12 align-items-center justify-content-center text-muted">
                      {{translationsLib.get('meteo_day_'+ getDay(selectedPrediction.prediction_date))}}
                      <span class="fs-5">({{getDate(selectedPrediction.prediction_date)}})</span>
                    </h4>
                  </div>
                  <div class="col-8 d-flex align-items-center">
                    <h4 class="col-2 d-flex align-items-center justify-content-center text-muted">{{this.translationsLib.get('climate_temp')}}</h4>
                    <h4 class="col-2 d-flex align-items-center justify-content-center text-muted">{{this.translationsLib.get('climate_hum')}}</h4>
                    <h4 class="col-2 d-flex align-items-center justify-content-center text-muted">{{this.translationsLib.get('climate_rad')}}</h4>
                    <h4 class="col-2 d-flex align-items-center justify-content-center text-muted">{{this.translationsLib.get('climate_wind')}}</h4>
                    <h4 class="col-2 d-flex align-items-center justify-content-center text-muted">{{this.translationsLib.get('climate_dir')}}</h4>
                    <h4 class="col-2 d-flex align-items-center justify-content-center text-muted">{{this.translationsLib.get('climate_rain')}}</h4>
                  </div>
                </div>
                <hr class="mt-2 mb-0" style="color: #224261">
              </div>
              <div class="card-body">
                <div class="row align-items-center">
                  <div class="col-12 py-2 text-center item-background-color" *ngFor="let prediction of selectedPrediction.sublevel">
                    <div class="row align-items-center">
                      <div class="col-3 col-md-4 d-flex align-items-center">
                        <div class="col">
                          <h4 class="m-0">{{getHour(prediction.prediction_date)}}</h4>
                        </div>
                        <div class="col">
                          <img src="{{weatherLib.getIcon(prediction.weather_id, getMoment(prediction))}}" class="day-icon weather-icon-shadow">
                        </div>
                      </div>
                      <div class="d-none d-md-flex col-8 flex-wrap pt-0">
                        <div class="col-6 col-sm-4 col-md-2 d-flex align-items-center justify-content-center">
                          <span class="d-flex me-1" [innerHTML]="'TEMPERATURE_OUTLINE' | sensorIcon"></span> <h4 class="m-0">{{prediction.main_temp}}ºC</h4>
                        </div>
                        <div class="col-6 col-sm-4 col-md-2 d-flex align-items-center justify-content-center">
                          <span class="d-flex me-2" [innerHTML]="'HUMIDITY_OUTLINE' | sensorIcon"></span> <h4 class="m-0">{{prediction.main_humidity}} %</h4>
                        </div>
                        <div class="col-6 col-sm-4 col-md-2 d-flex align-items-center justify-content-center">
                          <span class="d-flex me-1" [innerHTML]="'RADIATION_OUTLINE' | sensorIcon"></span> <h4 class="m-0">{{prediction.solar_rad}} W/m²</h4>
                        </div>
                        <div class="col-6 col-sm-4 col-md-2 d-flex align-items-center justify-content-center">
                          <span class="d-flex me-1" [innerHTML]="'WIND' | sensorIcon"></span> <h4 class="m-0">{{prediction.wind_speed}} Km/h</h4>
                        </div>
                        <div class="col-6 col-sm-4 col-md-2 d-flex align-items-center justify-content-center">
                          <span class="d-flex me-1" [innerHTML]="'WIND_DIRECTION_OUTLINE' | sensorIcon"></span> <h4 class="m-0">{{this.translationsLib.get(this.weatherLib.getWindDirection(prediction.wind_deg))}}</h4>
                        </div>
                        <div class="col-6 col-sm-4 col-md-2 d-flex align-items-center justify-content-center">
                          <span class="d-flex me-1" [innerHTML]="'RAIN' | sensorIcon"></span> <h4 class="m-0">{{getFormatPrecip(prediction.precip)}} mm</h4>
                        </div>
                      </div>
                      <div class="d-md-none col-5 text-start d-flex justify-content-center align-items-center">
                        <div class="row">
                          <div class="col-12">
                            <div class="row">
                              <div class="col-auto text-end pe-1" [innerHTML]="'TEMPERATURE_OUTLINE' | sensorIcon"></div>
                              <div class="col text-start ps-1">
                                {{prediction.main_temp}}ºC
                              </div>
                            </div>
                            <div class="row py-2">
                              <div class="col-auto text-end pe-1" [innerHTML]="'HUMIDITY_OUTLINE' | sensorIcon"></div>
                              <div class="col text-start ps-1">
                                {{prediction.main_humidity}} %
                              </div>
                            </div>
                            <div class="row">
                              <div class="col-auto text-end pe-1" [innerHTML]="'RADIATION_OUTLINE' | sensorIcon"></div>
                              <div class="col text-start ps-1">
                                {{prediction.solar_rad}} W/m²
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div class="d-md-none col-4 text-start d-flex justify-content-center align-items-center">
                        <div class="col-12">
                          <div class="row">
                            <div class="col-auto text-end pe-1" [innerHTML]="'WIND' | sensorIcon"></div>
                            <div class="col text-start ps-1">
                              {{prediction.wind_speed}} Km/h
                            </div>
                          </div>
                          <div class="row py-2">
                            <div class="col-auto text-end pe-1" [innerHTML]="'WIND_DIRECTION_OUTLINE' | sensorIcon"></div>
                            <div class="col text-start ps-1">
                              {{this.translationsLib.get(this.weatherLib.getWindDirection(prediction.wind_deg))}}
                            </div>
                          </div>
                          <div class="row">
                            <div class="col-auto text-end pe-1" [innerHTML]="'RAIN' | sensorIcon"></div>
                            <div class="col text-start ps-1">
                              {{prediction.precip}} mm
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
        </div>
      </div>
    </div>

    <div class="row" *ngIf="forecastLoaded">
      <div class="card">
        <div class="card-title">
          <h4 class="text-uppercase"> {{translationsLib.get('meteo_per_days')}}</h4>
          <hr class="mb-0" style="color: #224261">
        </div>
        <div class="card-body p-0">
          <div class="row">
            <ng-container *ngFor="let prediction of forecast.predictions">
              <div class="col-12 item-background-color pb-2" *ngIf="prediction.sublevel.length === 0">
                <div class="row">
                  <div class="col-3 col-lg-4">
                    <h4 class="d-none d-lg-block">
                      {{translationsLib.get('meteo_day_'+ getDay(prediction.prediction_date))}}
                      <span style="font-size: 12px">({{getDate(prediction.prediction_date)}})</span>
                    </h4>
                    <h4 class="d-lg-none">
                      {{getDate(prediction.prediction_date)}}
                    </h4>
                  </div>
                  <div class="col-1 col-lg-2 text-center align-self-center">
                    <img src="{{weatherLib.getIcon(prediction.weather_id, getMoment(prediction))}}" class="day-icon weather-icon-shadow">
                  </div>
                  <div class="col-8 col-lg-6">
                    <!--<hr class="temperature-bar">-->
                    <div class="row">
                      <div class="col-3 text-end">
                        <h4 style="color: #224261;">{{prediction.main_temp_min}}º</h4>
                      </div>
                      <div class="col-6">
                        <section class="range-slider container">
                          <span class="full-range" ></span>
                          <span class="incl-range" id="{{prediction.prediction_date}}"></span>
                          <input disabled name="rangeOne" value="{{prediction.main_temp_min}}" min="0" max="50" step="1" type="range">
                          <input disabled name="rangeTwo" value="{{prediction.main_temp_max}}" min="0" max="50" step="1" type="range">
                        </section>
                      </div>
                      <div class="col-3 align-self-center">
                        <div class="row">
                          <h4 id="tempMax">{{prediction.main_temp_max}}º</h4>
                        </div>
                      </div>
                    </div>

                  </div>
                </div>
              </div>
            </ng-container>

          </div>
        </div>
      </div>
    </div>
  </div>
</ion-content>
