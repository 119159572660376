import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { RestApiService } from '../services/api/rest-api.service';
import { LayoutDataModel } from '../commons/app-commons.model';

@Injectable({
  providedIn: 'root',
})
export class DashboardService {
  private restApiService: RestApiService;

  constructor(restApiService: RestApiService) {
    this.restApiService = restApiService;
  }
  public getLayout(): Observable<LayoutDataModel> {
    return new Observable((observer) => {
      this.restApiService.getLayout().subscribe(
        (response) => {
          observer.next(response);
        },
        (error) => {
          observer.next(error);
        }
      );
    });
  }
}
