<ng-container *ngIf="ready === true">
  <aside id="bottom-tabs-main-container" [ngClass]="{'fix20pxMarginTop': fix20pxMarginTop === true}">
    <div class="row">
      <div class="col" *ngFor="let tab of menu">
        <a [routerLink]="[tab.page]">
          <div class="row pb-3">
            <div class="col-12 text-center">
              <ion-icon name="{{tab.icon}}"></ion-icon>
            </div>
            <div class="col-12 text-center bottom-tab-name p-0">
              {{tab.title}}
            </div>
          </div>
        </a>
      </div>
      <div class="col">
        <div class="row">
          <div class="col-12 text-center">
            <ion-menu-button></ion-menu-button>
          </div>
          <div class="col-12 text-center bottom-tab-name p-0" id="menu-text">
            <ion-menu-toggle>
              {{translationsLib.get('sidebar_menu')}}
            </ion-menu-toggle>
          </div>
        </div>
      </div>
    </div>
  </aside>
</ng-container>
