import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { LoginComponent } from './auth/login/login.component';
import { FieldsNewComponent } from './fields/fields-new/fields-new.component';
import { RegisterComponent } from './auth/register/register.component';
import { PasswordResetComponent } from './auth/password-reset/password-reset.component';
import { PasswordResetUserInvitationComponent } from './auth/password-reset-user-invitation/password-reset-user-invitation.component';
import { RegisterConfirmationComponent } from './auth/register-confirmation/register-confirmation.component';
import { FieldsEditComponent } from './fields/fields-edit/fields-edit.component';
import { FieldsDashboardComponent } from './fields/fields-dashboard/fields-dashboard.component';
import { CanActivateViaAuthGuard } from './services/guards/guards';
import { InfoTerminalsComponent } from './terminals/info-terminals/info-terminals.component';
import { ConfigMenuComponent } from './profile/config-menu/config-menu.component';
import { ProfileTabsComponent } from './profile/profile-tabs/profile-tabs.component';
import { TerminalsGeneralComponent } from './terminals/terminals-general/terminals-general.component';
import { FieldsGeneralComponent } from './fields/fields-general/fields-general.component';
import { WeatherScreenComponent } from './weather/weather-screen/weather-screen.component';
import { EventsCalendarScreenComponent } from './calendar/events-calendar-screen/events-calendar-screen.component';
import { ReportsHeaderComponent } from './reports/reports-header/reports-header.component';
import { InfoScreenComponent } from './info/info-screen/info-screen.component';
import { PairTerminalsComponent } from './terminals/pair-terminals/pair-terminals.component';
import { TerminalActivationComponent } from './terminals/terminal-activation/terminal-activation.component';
import { TerminalsEditComponent } from './terminals/terminals-edit/terminals-edit.component';
import { TerminalsAdminTabsComponent } from './terminals/terminals-admin-tabs/terminals-admin-tabs.component';
import { SupportScreenComponent } from './support/support-screen/support-screen.component';
import { MainDashboardComponent } from './dashboard/main-dashboard/main-dashboard.component';
import { IrrigationConsultingMainChartComponent } from './modules/irrigation-consulting/irrigation-consulting-main-chart/irrigation-consulting-main-chart.component';
import { NotificationsTabsComponent } from './notifications/notifications-tabs/notifications-tabs.component';

const routes: Routes = [
  {
    path: '',
    redirectTo: 'login',
    pathMatch: 'full',
  },
  {
    path: 'home/dashboard',
    component: MainDashboardComponent,
    canActivate: [CanActivateViaAuthGuard],
  },
  {
    path: 'login',
    component: LoginComponent,
  },
  {
    path: 'register',
    component: RegisterComponent,
  },
  {
    path: 'register-confirmation',
    component: RegisterConfirmationComponent,
  },
  {
    path: 'reset-password',
    component: PasswordResetComponent,
  },
  {
    path: 'reset-user-invitation',
    component: PasswordResetUserInvitationComponent,
  },
  {
    path: 'edit-field',
    component: FieldsEditComponent,
    canActivate: [CanActivateViaAuthGuard],
  },
  {
    path: 'create-field',
    component: FieldsNewComponent,
    canActivate: [CanActivateViaAuthGuard],
  },
  {
    path: 'field',
    component: FieldsDashboardComponent,
    canActivate: [CanActivateViaAuthGuard],
  },
  {
    path: 'pair-new-terminal',
    component: PairTerminalsComponent,
    canActivate: [CanActivateViaAuthGuard],
  },
  {
    path: 'terminals-info',
    component: InfoTerminalsComponent,
    canActivate: [CanActivateViaAuthGuard],
  },
  {
    path: 'menu-configurations',
    component: ConfigMenuComponent,
    canActivate: [CanActivateViaAuthGuard],
  },
  {
    path: 'terminal',
    component: InfoTerminalsComponent,
    canActivate: [CanActivateViaAuthGuard],
  },
  {
    path: 'edit-terminal',
    component: TerminalsAdminTabsComponent,
    canActivate: [CanActivateViaAuthGuard],
  },
  {
    path: 'profile',
    component: ProfileTabsComponent,
    canActivate: [CanActivateViaAuthGuard],
  },
  {
    path: 'terminals-general',
    component: TerminalsGeneralComponent,
    canActivate: [CanActivateViaAuthGuard],
  },
  {
    path: 'fields-general',
    component: FieldsGeneralComponent,
    canActivate: [CanActivateViaAuthGuard],
  },
  {
    path: 'terminal-activation',
    component: TerminalActivationComponent,
    canActivate: [CanActivateViaAuthGuard],
  },
  {
    path: 'terminal-calendar',
    component: EventsCalendarScreenComponent,
    canActivate: [CanActivateViaAuthGuard],
  },
  {
    path: 'reports',
    component: ReportsHeaderComponent,
    canActivate: [CanActivateViaAuthGuard],
  },
  {
    path: 'terminal-edit',
    component: TerminalsEditComponent,
    canActivate: [CanActivateViaAuthGuard],
  },
  {
    path: 'notifications',
    component: NotificationsTabsComponent,
    canActivate: [CanActivateViaAuthGuard],
  },
  {
    path: 'weather',
    component: WeatherScreenComponent,
    canActivate: [CanActivateViaAuthGuard],
  },
  {
    path: 'info',
    component: InfoScreenComponent,
    canActivate: [CanActivateViaAuthGuard],
  },
  {
    path: 'support',
    component: SupportScreenComponent,
    canActivate: [CanActivateViaAuthGuard],
  },
  {
    path: 'analytics',
    loadChildren: () =>
      import('./graphics/graphics.module').then((m) => m.GraphicsModule),
  },
  {
    path: 'farming/:vid/datalogger',
    loadChildren: () =>
      import('./farming/datalogger/datalogger.module').then(
        (m) => m.DataloggerModule
      ),
  },
  {
    path: 'farming/:vid/climate',
    loadChildren: () =>
      import('./farming/climate/climate.module').then((m) => m.ClimateModule),
  },
  {
    path: 'farming/:vid/irrigation',
    loadChildren: () =>
      import('./farming/irrigation/irrigation.module').then(
        (m) => m.IrrigationModule
      ),
  },
  {
    path: 'farming/:vid/configurable',
    loadChildren: () =>
      import('./farming/configurable/configurable.module').then(
        (m) => m.ConfigurableModule
      ),
  },
  {
    path: 'asesor',
    component: IrrigationConsultingMainChartComponent,
    canActivate: [CanActivateViaAuthGuard],
  },
  {
    path: '**',
    redirectTo: '/home/dashboard',
  },
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule],
})
export class AppRoutingModule {}
