import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root',
})
export class SanitizeLibService {
  constructor() {}

  kebab(text: string) {
    return text
      .replace(/([a-z])([A-Z])/g, '$1-$2')
      .replace(/[\s_]+/g, '-')
      .toLowerCase();
  }
}
