import { EventEmitter, Injectable } from '@angular/core';
import { AppCommonsService } from '../../commons/app-commons.service';

@Injectable({
  providedIn: 'root',
})
export class TranslationsLibService {
  loadedTranslationsCount = 0;
  nonExistingKeys = {};
  translationsLoaded: EventEmitter<boolean> = new EventEmitter();
  constructor(private commonService: AppCommonsService) {}

  public initialize(): void {
    this.commonService.getTranslations().subscribe((res) => {
      for (const [k, v] of Object.entries(res)) {
        sessionStorage.setItem('translations_' + k, String(v));
      }
      this.loadedTranslationsCount = Object.keys(res).length;
      const that = this;
      // eslint-disable-next-line prefer-arrow/prefer-arrow-functions
      setTimeout(function () {
        console.log(
          '[TranslationsLibService]: emitTranslationsLoaded, translations count ' +
            that.loadedTranslationsCount
        );
        that.emitTranslationsLoaded(true);
      }, 200);
    });
  }

  public get(key): any {
    let g = key;
    const g2 = sessionStorage.getItem('translations_' + key);
    if (g2) {
      g = g2;
    } else {
      if (key in this.nonExistingKeys) {
        return this.nonExistingKeys[key];
      } else if (
        key !== null &&
        typeof key !== 'undefined' &&
        String(key).toLowerCase() in this.nonExistingKeys
      ) {
        return this.nonExistingKeys[key.toLowerCase()];
      } else {
        console.warn(
          '[TranslationsLibService]: Key ' + key + ' does not exist'
        );
        this.nonExistingKeys[key] = key;
        return key;
      }
    }
    return g;
  }

  emitTranslationsLoaded(loaded) {
    this.translationsLoaded.emit(loaded);
  }
  getTranslationsLoadedEmitter() {
    return this.translationsLoaded;
  }
}
