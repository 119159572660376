<div class="reports-simple-table-container pt-3 pb-3">
  <table>
    <thead>
      <tr>
        <th *ngFor="let column of columnsToDisplay">
          {{ translationsLib.get('reports_' + column) }}
        </th>
      </tr>
    </thead>
    <tbody>
      <tr *ngFor="let element of data">
        <td *ngFor="let column of columnsToDisplay">
          {{ element[column] }}
        </td>
      </tr>
    </tbody>
  </table>
  <div class="page-break"> </div>
  <p *ngFor="let p_index of p_break_count">&nbsp;</p>
  <div class="page-break"> </div>
</div>
<div class="page-break"> </div>
<p *ngFor="let p_index of p_break_count">&nbsp;</p>
<div class="page-break"> </div>

