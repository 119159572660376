import { Component, Input, OnInit } from '@angular/core';
import { ConfigurableEquipmentModel } from 'src/app/farming/configurable/configurable.model';
import { TranslationsLibService } from 'src/app/services/libraries/translations-lib.service';
import { TerminalDataModel } from 'src/app/terminals/terminals.model';

@Component({
  selector: 'app-fields-dashboard-configurable',
  templateUrl: './fields-dashboard-configurable.component.html',
  styleUrls: ['./fields-dashboard-configurable.component.scss'],
})
export class FieldsDashboardConfigurableComponent implements OnInit {
  @Input() equipment: ConfigurableEquipmentModel;
  @Input() terminal: TerminalDataModel;

  constructor(public translationsLib: TranslationsLibService) {}

  ngOnInit(): void {}

  getPhaseLabel(phase: number) {
    switch (phase) {
      case 1:
        return this.translationsLib.get('configurable_phase_PRE_FERTILIZE');
      case 2:
        return this.translationsLib.get('configurable_phase_FERTILIZE');
      case 3:
        return this.translationsLib.get('configurable_phase_POST_FERTILIZE');
      case 4:
        return this.translationsLib.get('configurable_phase_OVERLAPPING');
      default:
        return '';
    }
  }
}
