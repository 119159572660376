<ion-content>
  <aside id="login-loader" *ngIf="sessionLib.getSessionToken() !== null">
    <div class="spinner-container">
      <div class="spinner-logo">
        <img src="../../../assets/img/ucrop.svg" alt="LOGO">
      </div>
      <div class="spinner-image">
        <img src="../../../assets/img/spinner.svg" alt="Loading">
      </div>
    </div>
  </aside>
  <div class="container-fluid" id ="login">
    <div class="row justify-content-center">
      <div class="col-12 col-md-8 col-lg-6 col-xl-4 px-lg-6 align-self-center">
        <img class="display-4 text-center mt-4" src="../../../assets/img/ucrop.svg" style="margin: 0 auto;display: block;">
        <!-- Heading -->
        <h1 class="text-center mb-3">
          {{ translationsLib.get('auth_login_title') }}
        </h1>

        <!-- Subheading -->
        <p class="text-muted text-center mb-5">
          {{ translationsLib.get('auth_login_subtitle') }}
        </p>

        <!-- Form -->
        <form [formGroup]="loginForm" (submit)="onSubmit()">
          <!-- Email address -->
          <div class="form-group">
            <!-- Label -->
            <label class="form-label">
              {{ translationsLib.get('auth_login_email') }}
            </label>
            <!-- Input -->
            <input id="loginEmail" type="email" class="form-control" placeholder="{{ translationsLib.get('auth_login_email_example') }}" formControlName="email">
          </div>

          <!-- Password -->
          <div class="form-group">
            <div class="row">
              <div class="col">
                <!-- Label -->
                <label class="form-label">
                  {{ translationsLib.get('auth_password') }}
                </label>
              </div>
              <div class="col-auto">
                <!-- Help text -->
                <a id="passForgot" style="cursor:pointer" (click)="router.navigate(['/reset-password']);" class="form-text small text-muted">
                  {{ translationsLib.get('auth_password_forgot') }}
                </a>
              </div>
            </div> <!-- / .row -->
            <!-- Input group -->
            <div class="input-group input-group-merge">
              <!-- Input -->
              <input id="password" class="form-control" type="password" placeholder="{{ translationsLib.get('auth_login_password_example') }}" formControlName="password">
              <!-- Icon -->
              <span class="input-group-text" style="cursor: pointer" (click)="showPassword()">
                <ion-icon name="eye-outline"></ion-icon>
              </span>
            </div>
          </div>
          <!-- Submit -->
          <!--[disabled]="loginForm.invalid"-->
          <button class="btn btn-lg w-100 btn-primary mb-3" type="submit">
            {{ translationsLib.get('auth_login') }}
          </button>
          <!-- Link -->
          <p class="text-center">
            <span class="text-muted text-center">
              {{ translationsLib.get('auth_register_text1') }}
              <a style="cursor: pointer; color:#3D6493" (click)="router.navigate(['/register']);">{{ translationsLib.get('auth_register_text2') }}</a>.
            </span>
          </p>
          <p class="text-center">
            <span class="text-muted text-center">
              {{ translationsLib.get('auth_register_confirmation_text1') }}
              <a style="cursor: pointer; color:#3D6493" (click)="router.navigate(['./register-confirmation',{ validated: false }]);">{{ translationsLib.get('auth_register_confirmation_text2') }}</a>.
            </span>
          </p>
          <p class="text-center">
            <app-language></app-language>
          </p>
          <p class="text-center mt-5">
            <span class="text-muted text-center">
              <a style="cursor: pointer; color:#3D6493" (click)="legalAgreementsLib.showCookiePolicy()">{{ translationsLib.get('cookie_policy') }}</a> |
              <a style="cursor: pointer; color:#3D6493" (click)="legalAgreementsLib.showLegalAdvice()">{{ translationsLib.get('legal_advice') }}</a> |
              <a style="cursor: pointer; color:#3D6493" (click)="legalAgreementsLib.showPrivacyPolicy()">{{ translationsLib.get('privacy_policy') }}</a>
            </span>
          </p>
        </form>
      </div>
      <div class="col-12 col-md-7 col-lg-6 col-xl-8 d-none d-lg-block">
        <div class="bg-cover h-100 min-vh-100 mt-n1 me-n3" style="background-image: url(assets/img/covers/Cover_login.jpg);"></div>
      </div>
    </div> <!-- / .row -->
  </div>
</ion-content>
