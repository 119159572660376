<div class="my-4">
<app-loader *ngIf="ready === false"></app-loader>
  <app-permissions-table
    *ngIf="ready === true"
    [vid]="vid"
    [canAdmin]="canAdmin"
    [owner]="owner"
    [regularUsers]="regularUsers"
    [contacts]="contacts"
    [mode]="'field'"
    (getPermissionsList)="getPermissionsList()"
  >
  </app-permissions-table>
</div>
