import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { IonicModule } from '@ionic/angular';
import { WeatherScreenComponent } from './weather-screen/weather-screen.component';
import { TextAvatarModule } from '../text-avatar';
import { MatTabsModule } from '@angular/material/tabs';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { AppCommonsModule } from '../commons/app-commons.module';
import { MatAutocompleteModule } from '@angular/material/autocomplete';
import { PermissionsModule } from '../permissions/permissions.module';
import { NgxPaginationModule } from 'ngx-pagination';
import { MatProgressBarModule } from '@angular/material/progress-bar';
import { ProfileModule } from '../profile/profile.module';
import { RouterModule } from '@angular/router';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { Ng2SearchPipeModule } from 'ng2-search-filter';
import { AuthModule } from '../auth/auth.module';
import { WeatherWidgetComponent } from './weather-widget/weather-widget.component';
import { MeteogramSmallComponent } from './meteogram/meteogram-small/meteogram-small.component';
import { HighchartsChartModule } from 'highcharts-angular';
import { MeteogramLargeComponent } from './meteogram/meteogram-large/meteogram-large.component';
import { MeteogramLargeMobileComponent } from './meteogram/meteogram-large-mobile/meteogram-large-mobile.component';

@NgModule({
  declarations: [
    WeatherScreenComponent,
    WeatherWidgetComponent,
    MeteogramSmallComponent,
    MeteogramLargeComponent,
    MeteogramLargeMobileComponent,
  ],
  exports: [WeatherWidgetComponent],
  imports: [
    CommonModule,
    TextAvatarModule,
    MatTabsModule,
    ReactiveFormsModule,
    IonicModule,
    AppCommonsModule,
    MatAutocompleteModule,
    PermissionsModule,
    NgxPaginationModule,
    MatProgressBarModule,
    ProfileModule,
    RouterModule,
    MatProgressSpinnerModule,
    FormsModule,
    Ng2SearchPipeModule,
    AuthModule,
    HighchartsChartModule,
  ],
})
export class WeatherModule {}
