import { Component, Input, OnInit } from '@angular/core';
import * as Highcharts from 'highcharts/highstock';
import highchartsMore from 'highcharts/highcharts-more';
import HC_accessibility from 'highcharts/modules/accessibility';
import theme from 'highcharts/themes/high-contrast-light';
import Exporting from 'highcharts/modules/exporting';
import ExportData from 'highcharts/modules/export-data';
import { MeteoChartsWidgetModel } from '../meteo-charts.model';
import { WeatherPredictionDataModel } from '../../weather.model';
import { WeatherLibService } from '../../../services/libraries/weather-lib.service';
import * as moment from 'moment-timezone';
import { TranslationsLibService } from '../../../services/libraries/translations-lib.service';
import { HighchartsLibService } from '../../../services/libraries/highcharts-lib.service';

theme(Highcharts);
Exporting(Highcharts);
ExportData(Highcharts);
HC_accessibility(Highcharts);
highchartsMore(Highcharts);

@Component({
  selector: 'app-meteogram-small',
  templateUrl: './meteogram-small.component.html',
  styleUrls: ['./meteogram-small.component.scss'],
})
export class MeteogramSmallComponent implements OnInit {
  chart;
  updateFlag = false;
  chartConstructor = 'chart';
  chartCallback;
  Highcharts = Highcharts;
  meteoChart: any = MeteoChartsWidgetModel;
  @Input() forecast: WeatherPredictionDataModel[];
  loaded = true;
  constructor(
    public weatherLib: WeatherLibService,
    public translationsLib: TranslationsLibService,
    private highchartsLib: HighchartsLibService
  ) {}

  ngOnInit() {
    const xAxis = [];
    const temperatureSeries = [];
    const humiditySeries = [];
    const zone = this.forecast[0].prediction_time_zone;

    Highcharts.setOptions({
      time: {
        /**
         * Use moment-timezone.js to return the timezone offset for individual
         * timestamps, used in the X axis labels and the tooltip header.
         */
        getTimezoneOffset: (timestamp) => {
          return -moment.tz(timestamp, zone).utcOffset();
        },
      },
      lang: this.highchartsLib.getLangOptions(),
    });

    for (const hourlyForecast of this.forecast) {
      xAxis.push(new Date(hourlyForecast.prediction_date).getTime());

      temperatureSeries.push([
        new Date(hourlyForecast.prediction_date).getTime(),
        hourlyForecast.main_temp,
      ]);

      humiditySeries.push([
        new Date(hourlyForecast.prediction_date).getTime(),
        hourlyForecast.main_humidity,
      ]);
    }

    this.meteoChart.title.text = this.translationsLib.get('meteo_24_pred');

    this.meteoChart.series[0].name = this.translationsLib.get('climate_temp');
    this.meteoChart.series[0].data = temperatureSeries;

    this.meteoChart.series[1].name = this.translationsLib.get('climate_hum');
    this.meteoChart.series[1].data = humiditySeries;

    this.meteoChart.xAxis.data = xAxis;

    this.chartCallback = (chart) => {
      this.drawWeatherSymbols(chart);

      Highcharts.addEvent(chart, 'redraw', () => {
        // Remove existing icons
        chart.series[0].data.forEach((point) => {
          if (point.customIcon) {
            point.customIcon.destroy();
          }
        });

        // Redraw the icons in the new position
        this.drawWeatherSymbols(chart);
      });
    };

    setTimeout(() => {
      this.updateFlag = true;
      this.loaded = true;
    }, 150);
  }

  private drawWeatherSymbols(chart) {
    const icons = [];
    this.forecast.forEach((hourlyForecast, index) => {
      icons.push(
        this.weatherLib.getIcon(
          hourlyForecast.weather_id,
          hourlyForecast.weather_icon[3]
        )
      );
    });

    const data = chart.series[0].data;

    for (let i = 0; i < data.length - 1; i++) {
      const point = data[i];
      const icon = icons[i];

      const image = chart.renderer
        .image(
          icon,
          point.plotX + chart.plotLeft - 8,
          point.plotY + chart.plotTop - 30,
          30,
          30
        )
        .attr({
          zIndex: 5,
        })
        .add();

      // Add shadow to icon
      image.css({
        filter: 'drop-shadow(1px 1px 4px rgba(50, 50, 50, 0.6))',
      });

      point.customIcon = image;
    }
  }
}
