<div class="my-5">
  <hr/>
</div>
<mat-tab-group #tabGroup>
  <mat-tab label="{{ translationsLib.get('calendar_current_events') }}">
    <div class="table-responsive mt-5">
      <app-events-calendar-table
        [events]="current_events"
        dataHeaderLabel1="{{ translationsLib.get('calendar_current_events_today') }}"
        dataHeaderLabel2="{{ translationsLib.get('calendar_current_events_starts') }}"
        dataHeaderLabel3="{{ translationsLib.get('calendar_current_events_ends') }}"
        showData2="true"
        showData3="true"
        showFilter="true"
        [defaultPaginationItemsNumber]="20"
        [dateTimeFormat]="'DD-MM-YYYY HH:mm:ss'"
        *ngIf="current_events.length > 0"
      >
      </app-events-calendar-table>
      <p class="text-muted" *ngIf="current_events.length === 0">
        {{ this.translationsLib.get('reports_no_data') }}
      </p>
    </div>
  </mat-tab>
  <mat-tab label="{{ translationsLib.get('calendar_past_events') }}">
    <div class="table-responsive mt-5">
      <app-events-calendar-table
        [events]="past_events"
        dataHeaderLabel1="{{ translationsLib.get('calendar_past_events_finished') }}"
        dataHeaderLabel2="{{ translationsLib.get('calendar_past_events_started') }}"
        dataHeaderLabel3="{{ translationsLib.get('calendar_past_events_ended') }}"
        showData2="true"
        showData3="true"
        showFilter="true"
        [defaultPaginationItemsNumber]="20"
        [dateTimeFormat]="'DD-MM-YYYY HH:mm:ss'"
        *ngIf="past_events.length > 0"
      >
      </app-events-calendar-table>
      <p class="text-muted" *ngIf="past_events.length === 0">
        {{ this.translationsLib.get('reports_no_data') }}
      </p>
    </div>
  </mat-tab>
  <mat-tab label="{{ translationsLib.get('calendar_future_events') }}">
    <div class="table-responsive mt-5">
      <app-events-calendar-table
        [events]="future_events"
        dataHeaderLabel1="{{ translationsLib.get('calendar_future_events_about_to_start') }}"
        dataHeaderLabel2="{{ translationsLib.get('calendar_future_events_starts') }}"
        dataHeaderLabel3="{{ translationsLib.get('calendar_future_events_ends') }}"
        showData2="true"
        showData3="true"
        showFilter="true"
        [defaultPaginationItemsNumber]="20"
        [dateTimeFormat]="'DD-MM-YYYY HH:mm:ss'"
        *ngIf="future_events.length > 0"
      >
      </app-events-calendar-table>
      <p class="text-muted" *ngIf="future_events.length === 0">
        {{ this.translationsLib.get('reports_no_data') }}
      </p>
    </div>
  </mat-tab>
</mat-tab-group>
