<div class="row">
  <div class="col-12 p-0">
    <div class="card terminals-border">
      <div class="row mx-2 align-items-end">
        <div class="col-md-4">
          <div class="input-group search-container">
            <span class="input-group-text search-icon"><i class="bi bi-search"></i></span>
            <input type="text" class="form-control" placeholder="{{ translationsLib.get('search') }}"  (keyup)="checkChanges()" [(ngModel)]="term"/>
          </div>
        </div>
        <div class="col-md-2">
          <ng-select [items]="uniquePrettyTypeNames"
                     placeholder="{{ translationsLib.get('terminal_model') }}"
                     [multiple]="true"
                     [closeOnSelect]="false"
                     [(ngModel)]="selectedTypeNames"
                     (ngModelChange)="onSelectionModelChange($event)"
          ></ng-select>
        </div>
        <div class="col-md-2 d-none d-sm-block">
          <ng-select [items]="uniqueFields"
                     placeholder="{{ translationsLib.get('field_field') }}"
                     [multiple]="true"
                     [closeOnSelect]="false"
                     [(ngModel)]="selectedFields"
                     (ngModelChange)="onSelectionFieldChange($event)"
          ></ng-select>
        </div>
      </div>
    </div>
    <div class="card terminals-border">
      <div class="card-body" style="overflow-x: auto">
        <table id="devicesTable" class="table table-responsive-sm card-table">
          <thead>
          <tr>
            <th>
              {{ translationsLib.get('terminal_name') }}
            </th>
            <th class="d-none d-xl-table-cell">
              {{ translationsLib.get('terminal_serial_number') }}
            </th>
            <th>
              {{ translationsLib.get('terminal_model') }}
            </th>
            <th class="d-none d-sm-table-cell">
              {{ translationsLib.get('field_field') }}
            </th>
            <th class="d-none d-xl-table-cell">
              {{ translationsLib.get('terminal_version') }}
            </th>
            <th class="d-none d-xl-table-cell">
              {{ translationsLib.get('terminal_last_connection') }}
            </th>
            <th>
              {{ translationsLib.get('terminal_status') }}
            </th>
          </tr>
          </thead>

          <ng-template #tableInfo let-text="text" let-click="click">
            <span (click)="router.navigate(click)">{{ text }}</span>
          </ng-template>

          <tbody class="list">
          <tr *ngFor="let terminal of terminals | paginate: { itemsPerPage: itemsPerPage, currentPage: p }"
              (click)="clickTable(terminal)">
            <td>
              {{terminal.name}}
            </td>
            <td class="d-none d-xl-table-cell">
              {{terminal.vid}}
            </td>
            <td>
              {{terminal.pretty_type_name}}
            </td>
            <td class="d-none d-sm-table-cell">
              {{ getFieldName(terminal) }}
            </td>
            <td class="d-none d-xl-table-cell">
              {{ semanticVersioningService.getSemanticVersioning(terminal.warp) }}
            </td>
            <td id="{{ terminal.vid }}-last-conexion" class="d-none d-xl-table-cell">
              <div class="spinner-border spinner-border-sm p-0" role="status" *ngIf="terminal.type !== 0"></div>
            </td>
            <td id="{{ terminal.vid }}-status">
              <div class="spinner-border spinner-border-sm p-0" role="status" *ngIf="terminal.type !== 0"></div>
            </td>
          </tr>
          </tbody>
        </table>
        <br>
        <pagination-controls (pageChange)="p = $event; getTerminalsInfo()"
                             [responsive]="true"
                             previousLabel="{{ translationsLib.get('previous') }}"
                             nextLabel="{{ translationsLib.get('next') }}"></pagination-controls>
      </div>
    </div>
  </div>
</div>
