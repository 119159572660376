<section class="pill-format" [ngClass]="class">
  <div class="card card-irrigation-consulting">
    <div class="row align-items-center">
      <div class="col text-uppercase data-header">
        {{ translationsLib.get('asesor_title') }}: {{ translationsLib.get('asesor_tension') }}
      </div>
    </div>
    <div class="row align-items-center">
      <div class="col-6 col-lg-5">
        <span class="mb-0" [ngClass]="class === 'normal' ? 'h1' : 'h2'"> {{ tensiometer.value || '-'}} {{ tensiometer.unit }}</span>
      </div>
      <div class="col-6 col-lg-5 p-0 text-center">
        <div class="row">
          <span style="font-size: 0.5rem" class="mb-0 text-uppercase text-muted">{{ translationsLib.get('asesor_recomendation') }}</span>
        </div>
        <div class="row">
          <span class="h3 p-0 mb-0"> {{ irrigationBand.bottom || '-' }}-{{ irrigationBand.upper || '-' }}&nbsp;{{ tensiometer.unit }}</span>
        </div>
      </div>
      <div class="d-none d-lg-block col-lg-2 text-center p-0">
        <ion-icon name="water-outline" style="color: #ee7869; font-size: 22px; font-weight: bold; margin-left: 7px;"></ion-icon>
      </div>
    </div>
  </div>
</section>
