import {Component, Input, OnInit} from '@angular/core';
import {AlarmsLibService} from '../../../services/libraries/alarms-lib.service';
import {TranslationsLibService} from '../../../services/libraries/translations-lib.service';
import {HelpersLibService} from '../../../services/libraries/helpers-lib.service';
import {ReportsService} from '../../reports.service';
import {ConsumptionReportModel, CreatedReportModel,} from '../../reports.model';

import {ReportsConsumptionsService} from '../reports-consumptions.service';

@Component({
  selector: 'app-reports-programs-consumptions',
  templateUrl: './reports-programs-consumptions.component.html',
  styleUrls: ['./reports-programs-consumptions.component.scss'],
  animations: [],
})
export class ReportsProgramsConsumptionsComponent implements OnInit {
  @Input() createdReport: CreatedReportModel;
  @Input() begin: string;
  @Input() end: string;
  @Input() prettyBegin: string;
  @Input() prettyEnd: string;
  @Input() advancedModeIsAllowed = false;
  terminalCountWithConsumptions = 0;
  loader = false;
  showCharts = false;
  reportsData: ConsumptionReportModel[] = [];
  constructor(
    private alarmsLib: AlarmsLibService,
    private helpersLib: HelpersLibService,
    private reportsService: ReportsService,
    public reportsConsumptionService: ReportsConsumptionsService,
    public translationsLib: TranslationsLibService
  ) {}
  ngOnInit() {
    this.reportsConsumptionService.resetAll();
    this.reportsConsumptionService.advancedModeIsAllowed =
      this.advancedModeIsAllowed;
    this.reportsConsumptionService.reportModeIsProgramConsumptions = true;
    this.reportsConsumptionService.terminalsLength =
      this.createdReport.terminals.length;
    this.processConsumptions();
  }
  public processConsumptions(): void {
    this.loader = true;
    const terminal_id_max = this.createdReport.terminals.length - 1;

    this.createdReport.terminals.forEach((terminalReport, terminalId) => {
      this.reportsService
        .getIrrigationReport(terminalReport.vid, this.begin, this.end, null)
        .subscribe((res) => {
          this.helpersLib.sendEventPageView(
            'Reports | Program Consumptions | ' + terminalReport.name_vid
          );
          res.terminalVid = terminalReport.vid;
          res.terminalNameVid = terminalReport.name_vid;
          res.terminalFieldName = terminalReport.field_name;
          res.terminalFieldVid = terminalReport.field_vid;
          res.irrigations.forEach((irrigation, index) => {
            ['realConsumption', 'progConsumption'].forEach((m_index) => {
              Object.getOwnPropertyNames(
                res.irrigations[index][m_index]
              ).forEach((key) => {
                res.irrigations[index][m_index + '_' + key] =
                  +res.irrigations[index][m_index][key].toFixed(2);
              });
            });
            res.irrigations[index].fertilizers = [];
            Object.getOwnPropertyNames(irrigation.groups).forEach((g_name) => {
              Object.getOwnPropertyNames(irrigation.groups[g_name]).forEach(
                (key1) => {
                  res.irrigations[index].fertilizers.push(
                    this.reportsConsumptionService.processFertilizer(
                      irrigation.groups[g_name][key1]
                    )
                  );
                }
              );
            });
            this.reportsConsumptionService.processGroupedConsumptions(
              res,
              irrigation
            );
          });
          res.ready = true;

          const tabConsumptions =
            this.reportsConsumptionService.processTabConsumptions(
              terminalReport,
              this.begin,
              this.end
            );

          console.log('tabConsumptions', tabConsumptions);

          this.reportsData.push(tabConsumptions);
          if (tabConsumptions.programConsumptions.length > 0) {
            this.terminalCountWithConsumptions++;
          }
          if (terminalId === terminal_id_max) {
            this.loader = false;
            const self = this;
            setTimeout(() => {
              self.showCharts = true;
            }, 1000);
          }
        });
    });
  }
}
