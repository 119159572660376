import { Injectable } from '@angular/core';
import { environment } from '../../../environments/environment';
import { UserType } from '../../commons/app-commons.model';

@Injectable({
  providedIn: 'root',
})
export class SessionLibService {
  expired = false;

  // Session API Methods
  // ===================

  public createSession(
    vid: string,
    name: string,
    token: string,
    email: string,
    accessToken: string,
    typeUser: number,
    language: string
  ): void {
    localStorage.setItem('vid', vid);
    localStorage.setItem('name', name);
    localStorage.setItem('session_token', token);
    localStorage.setItem('access_token', accessToken);
    localStorage.setItem('email', email);
    localStorage.setItem('language', language);
    localStorage.setItem('type', JSON.stringify(typeUser));
  }
  public setLanguage(language) {
    localStorage.setItem('language', language);
  }
  public getLanguage() {
    let language = localStorage.getItem('language');
    if (
      typeof language !== 'string' ||
      language.trim() === 'null' ||
      language.trim() === '' ||
      language.trim() === null
    ) {
      language = this.getPreferredLanguage();
    }
    return language;
  }
  public setCookiesPolicy(value: string) {
    localStorage.setItem('cookies_policy', value);
  }
  public getCookiesPolicy(): string {
    return localStorage.getItem('cookies_policy');
  }
  public setHome(page) {
    localStorage.setItem('home', page);
  }
  public getHome() {
    return localStorage.getItem('home');
  }
  public updateSession(token: string): void {
    localStorage.setItem('session_token', token);
  }
  public getImpersonated() {
    return localStorage.getItem('impersonated');
  }
  public getName() {
    return localStorage.getItem('name');
  }
  public setImpersonated(impersonated: boolean) {
    if (impersonated === true) {
      localStorage.setItem('impersonated', 'true');
    } else {
      if (this.getImpersonated() !== undefined) {
        localStorage.removeItem('impersonated');
      }
    }
  }
  public destroySession(): void {
    localStorage.removeItem('vid');
    localStorage.removeItem('name');
    localStorage.removeItem('session_token');
    localStorage.removeItem('access_token');
    localStorage.removeItem('email');
    localStorage.removeItem('avatar');
    localStorage.removeItem('type');
    this.setImpersonated(false);
    window.location.href = '/login';
  }

  public setAvatar(data): void {
    localStorage.setItem('avatar', JSON.stringify(data));
  }
  public getAvatar(): string {
    return JSON.parse(localStorage.getItem('avatar'));
  }
  public getAccessToken(): string {
    return localStorage.getItem('access_token');
  }
  public getSessionToken(): string {
    return localStorage.getItem('session_token');
  }
  public getSessionEmail(): string {
    return localStorage.getItem('email');
  }
  public getSessionVid(): string {
    return localStorage.getItem('vid');
  }
  public getUserType(): UserType {
    return Number(localStorage.getItem('type'));
  }
  public userTypeIsInstaller(): boolean {
    const userType = this.getUserType();
    return (
      userType === UserType.installer ||
      userType === UserType.nonVerifiedInstaller
    );
  }
  public userTypeIsBOSuperAdmin(): boolean {
    const userType = this.getUserType();
    return (
      userType === UserType.backofficeSuperAdmin ||
      userType === UserType.backofficeSuperAdminDeveloper
    );
  }
  public sessionTokenIsExpired(expired: boolean): void {
    this.expired = expired;
  }
  public isSessionTokenIsExpired(): boolean {
    return this.expired;
  }
  private getPreferredLanguage(): string {
    let userLang = navigator.language;
    if (typeof userLang === 'string') {
      const userLang_arr = userLang.split('-');
      userLang = userLang_arr[0];
    } else {
      userLang = environment.language;
    }
    return userLang;
  }
}
