<!-- Current user has no permissions for at least one terminal of this field -->
<div class="row">
  <div class="col-12">
    <div class="card">
      <div class="card-header">
        <div class="card-header-title">
          <h2 class="mb-0 text-start">
            {{ translationsLib.get('field_no_terminal_rights_title') }}
          </h2>
        </div>
      </div>
      <div class="card-body">
        <div class="row">
          <div class="col-12 mb-4">
            {{ translationsLib.get('field_no_terminal_rights_text') }}
          </div>
        </div>
        <div class="row" *ngFor="let terminal of noRightsTerminals">
          <div class="col-12">
            <div class="card">
              <div class="card-body">
                <app-device-header [equipment]="terminal" [showButton]="false"></app-device-header>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
