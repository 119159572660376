import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { RestApiService } from '../services/api/rest-api.service';
import {
  ChangePasswordDataModel,
  NotificationPreferencesModel,
  UserDataModel,
  UserDeviceNotificationPreferencesModel,
  UserNotificationPreferencesModel,
  UserTerminalNotificationPreferencesModel,
} from './profile.model';

@Injectable({
  providedIn: 'root',
})
export class ProfileService {
  private restApiService: RestApiService;
  constructor(restApiService: RestApiService) {
    this.restApiService = restApiService;
  }

  public putUsersData(data): Observable<UserDataModel> {
    return new Observable((observer) => {
      this.restApiService.putUserData(data).subscribe(
        (response) => {
          observer.next(response);
        },
        (error) => {
          observer.error(error);
        }
      );
    });
  }
  public putNewPassword(data): Observable<ChangePasswordDataModel> {
    return new Observable((observer) => {
      this.restApiService.putNewPassword(data).subscribe(
        (response) => {
          observer.next(response);
        },
        (error) => {
          observer.error(error);
        }
      );
    });
  }

  public postDeleteUser(data): Observable<UserDataModel> {
    return new Observable((observer) => {
      this.restApiService.deleteUser(data).subscribe(
        (response) => {
          observer.next(response);
        },
        (error) => {
          observer.error(error);
        }
      );
    });
  }

  public getNotificationPreferences(): Observable<NotificationPreferencesModel> {
    return new Observable((observer) => {
      this.restApiService.getNotificationPreferences().subscribe(
        (response) => {
          observer.next(response);
        },
        (error) => {
          observer.error(error);
        }
      );
    });
  }

  public putUserNotificationPreferences(): Observable<UserNotificationPreferencesModel> {
    return new Observable((observer) => {
      this.restApiService.putUserNotificationPreferences().subscribe(
        (response) => {
          observer.next(response);
        },
        (error) => {
          observer.error(error);
        }
      );
    });
  }

  public putUserDeviceNotificationPreferences(
    deviceId: string
  ): Observable<UserDeviceNotificationPreferencesModel> {
    return new Observable((observer) => {
      this.restApiService
        .putUserDeviceNotificationPreferences(deviceId)
        .subscribe(
          (response) => {
            observer.next(response);
          },
          (error) => {
            observer.error(error);
          }
        );
    });
  }

  public putUserTerminalNotificationPreferences(
    terminalVid: string
  ): Observable<UserTerminalNotificationPreferencesModel> {
    return new Observable((observer) => {
      this.restApiService
        .putUserTerminalNotificationPreferences(terminalVid)
        .subscribe(
          (response) => {
            observer.next(response);
          },
          (error) => {
            observer.error(error);
          }
        );
    });
  }
}
