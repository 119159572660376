<app-main-header [breadcrumbs]="breadcrumbs"></app-main-header>
<ion-content>
  <div class="container-fluid p-1 p-lg-2">
    <div class="col-12 mb-4">
    </div>
    <div class="mx-lg-4">
      <div class="col-12">
        <app-loader *ngIf="loading === true"></app-loader>

        <div *ngIf="loading === false" class="pe-1 ps-2">
          <div class="card card-inside">
            <div class="card-header">
              <h4 class="card-header-title">{{ translationsLib.get('calendar_calendar') }}</h4>
            </div>
            <div class="card-body p-2 p-lg-4">
              <app-events-calendar-widget [mode]="'screen'" [terminal_vid]="terminalVid"></app-events-calendar-widget>
            </div>
          </div>
        </div>

      </div>
    </div>
  </div>
</ion-content>
