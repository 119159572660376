<app-main-header [breadcrumbs]="breadcrumbs"></app-main-header>
<ion-content>
  <div class="container-fluid p-1 p-lg-2">
    <div class="mx-lg-4">

      <div class="card mt-4">
        <div class="row m-3">
          <div class="col-12">
            <h2 class="form-label my-2">
              {{ this.translationsLib.get('pairing_title') }}
            </h2>
            <div class="header-img col-12 p-0 d-md-none">
              <img src="assets/img/covers/pairing_header_mobile.svg">
            </div>
            <div class="header-img col-12 p-0 d-none d-md-block">
              <img src="assets/img/covers/pairing_header.svg">
            </div>
          </div>
        </div>
      </div>

      <swiper
        [preventInteractionOnTransition]="true"
        [preventClicksPropagation]="true"
        [slidesPerView]="1"
        [centeredSlides]="true"
        [keyboard]="{enabled: true}"
        [virtual]="true"
        [autoHeight]="true"
        (navigationNext)="'nextButton'"
        class="my-swiper" #swiper>

        <ng-template swiperSlide>
          <div class="swiper-slide-container">
            <div class="card">
              <div class="row m-2">
                <div class="col-12 col-lg-6 p-2">
                  <h4 class="help-title">{{ translationsLib.get('pairing_find_serial_number') }}</h4>
                  <div class="help-text" [innerHTML]="translationsLib.get('pairing_find_serial_number_text')"></div>
                </div>
                <div class="col-12" *ngIf="native === true">
                  <button class="btn btn-lg btn-primary" id="botonQR" style="width: 100%" (click)="scan()">{{this.translationsLib.get('pair_qr')}}</button>
                </div>
                <!--<div class="row mt-4 text-center d-md-none">
                  <h4 style="color: #EE7869">{{this.translationsLib.get('or')}}</h4>
                </div>-->
                <div class="col-12 col-lg-6 mt-1 p-2">
                  <form [formGroup]="serialNumber">
                    <div class="form-group">
                      <label class="form-label text-uppercase">
                        {{ translationsLib.get('pairing_serial_number') }}
                      </label>
                      <input type="text" id="serialNumber" class="form-control" formControlName="serial">
                    </div>
                    <div class="form-group text-end">
                      <button id="botondcha" class="btn btn-primary" (click)="searchPrePairing(this.serialNumber.value.serial)">
                        {{ translationsLib.get('search') }}
                      </button>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </ng-template>

        <ng-template swiperSlide>
          <div class="mb-5 pb-5">
            <div class="card mt-3 mb-5" id="deviceInfo" *ngIf="terminalFound === true">
              <div class="container-fluid mt-3">
                <div class="row mt-4">
                  <div class="col-12 col-lg-6">
                    <img src="{{image_path}}">
                  </div>
                  <div class="col-12 col-lg-6 p-4 pb-0">
                    <div class="row">
                      <div class="col-auto">
                        <label class="form-label text-uppercase">
                          {{ translationsLib.get('model') }}
                        </label>
                      </div>
                      <div class="col-auto">
                        <h2 class="m-0">{{results.value.nombre_equipo}}</h2>
                      </div>
                    </div>
                    <div class="row">
                      <div class="col-auto">
                        <label class="form-label text-uppercase">
                          {{ translationsLib.get('serial_number') }}
                        </label>
                      </div>
                      <div class="col-auto">
                        <h2 class="m-0">{{serialNumberRes}}</h2>
                      </div>
                    </div>
                    <div class="row" *ngIf="numTerminal !== null">
                      <div class="col-auto">
                        <label class="form-label text-uppercase">
                          {{ translationsLib.get('terminal_number') }}
                        </label>
                      </div>
                      <div class="col-auto">
                        <h2 class="m-0">{{numTerminal}}</h2>
                      </div>
                    </div>
                  </div>
                  <div class="col-12">
                    <form class="mt-4" [formGroup]="results">
                      <div class="row">

                        <!-- OWNER -->
                        <div class="col-12">
                          <div class="card">
                            <div class="row m-2">
                              <div class="col-12 col-lg-6">

                                <!-- Current user can be owner -->
                                <div *ngIf="prePairingData.pair_owner_me_allowed">
                                  <h4 class="help-title">{{ translationsLib.get('pairing_are_you_owner') }}</h4>
                                  <h5 class="help-text" [innerHTML]="translationsLib.get('pairing_are_you_owner_text')"></h5>
                                </div>
                                <!-- This terminal already has an owner -->
                                <div *ngIf="!prePairingData.pair_owner_me_allowed && !prePairingData.pair_owner_email_allowed">
                                  <h4 class="help-title">{{ translationsLib.get('pairing_already_have_owner') }}</h4>
                                  <h5 class="help-text" [innerHTML]="translationsLib.get('pairing_already_have_owner_text')"></h5>
                                </div>
                                <!-- Installer can invite an owner -->
                                <div *ngIf="prePairingData.pair_installer_me_allowed && !this.results.value.pair_owner_me">
                                  <h4 class="help-title">{{ translationsLib.get('pairing_do_you_want_to_invite_an_owner') }}</h4>
                                  <h5 class="help-text" [innerHTML]="translationsLib.get('pairing_do_you_want_to_invite_an_owner_text')"></h5>
                                </div>
                              </div>
                              <div class="col-12 col-lg-6 align-self-center pt-0 pt-lg-2">
                                <div class="form-group form-switch mb-2" *ngIf="prePairingData.pair_owner_me_allowed && !prePairingData.pair_owner_email_allowed">
                                  <input [attr.disabled]="disabled"  class="form-check-input" type="checkbox" [value]="results.value.pair_owner_me" formControlName="pair_owner_me" checked="checked"/>
                                  <ion-label>&nbsp;{{ translationsLib.get('pairing_i_am_owner') }}</ion-label>
                                </div>
                                <div class="form-group form-switch mb-2" *ngIf="prePairingData.pair_owner_email_allowed">
                                  <input class="form-check-input" type="checkbox" [value]="results.value.pair_owner_me" formControlName="pair_owner_me" checked="checked"/>
                                  <ion-label>&nbsp;{{ translationsLib.get('pairing_i_am_owner') }}</ion-label>
                                </div>
                                <div class="form-group form-switch mb-0" *ngIf="!prePairingData.pair_owner_me_allowed && !prePairingData.pair_owner_email_allowed" style="padding:0px">
                                  <h4>&nbsp;</h4>
                                </div>
                                <div class="mt-3 mt-lg-5" id="installerEmail" *ngIf="prePairingData.pair_installer_me_allowed && !this.results.value.pair_owner_me">
                                  <div class="form-group form-switch mb-2">
                                    <input class="form-check-input" type="checkbox" [value]="results.value.pair_installer" checked="true" formControlName="pair_want_owner"/>
                                    <ion-label>&nbsp;{{ translationsLib.get('pairing_i_want_to_invite_an_owner') }}</ion-label>
                                  </div>
                                  <div class="form-group">
                                    <input type="text"
                                           matInput
                                           class="form-control"
                                           aria-label="Text"
                                           formControlName="proposedOwnerEmail"
                                           required [matAutocomplete]="auto"
                                           placeholder="Email">
                                    <mat-autocomplete autoActiveFirstOption #auto="matAutocomplete">
                                      <mat-option *ngFor="let option of filteredOptions2 | async" [value]="option['email']">
                                        {{option['email']}}
                                      </mat-option>
                                    </mat-autocomplete>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>

                        <!-- INSTALLER -->
                        <div class="col-12">
                          <div class="card">
                            <div class="row m-2">
                              <div class="col-12 col-lg-6">
                                <div *ngIf="prePairingData.pair_owner_me_allowed">
                                  <h4 class="help-title">{{ translationsLib.get('pairing_are_you_installer') }}</h4>
                                  <h5 class="help-text">{{ translationsLib.get('pairing_are_you_installer_text') }}</h5>
                                </div>
                                <div *ngIf="!prePairingData.pair_owner_me_allowed">
                                  <h4 class="help-title">{{ translationsLib.get('pairing_do_you_want_to_invite_an_installer') }}</h4>
                                  <h5 class="help-text" [innerHTML]="translationsLib.get('pairing_do_you_want_to_invite_an_installer_text')"></h5>
                                </div>
                              </div>
                              <div class="col-12 col-lg-6 align-self-center pt-0 pt-lg-2" >
                                <div class="form-group form-switch mb-3" *ngIf="prePairingData.pair_installer_me_allowed">
                                  <input class="form-check-input" type="checkbox" [value]="results.value.pair_installer" formControlName="pair_installer"/>
                                  <ion-label>&nbsp;{{ translationsLib.get('pairing_i_am_installer') }}</ion-label>
                                </div>
                                <div class="form-group form-switch mb-0" *ngIf="!prePairingData.pair_installer_me_allowed">
                                  <input class="form-check-input" type="checkbox" [value]="results.value.pair_installer" checked="true" formControlName="pair_want_installer"/>
                                  <ion-label>&nbsp;{{ translationsLib.get('pairing_i_want_to_invite_an_installer') }}</ion-label>
                                </div>
                                <div class="row mb-3 mt-3" id="email" *ngIf="!prePairingData.pair_installer_me_allowed">
                                  <div>
                                    <input type="text"
                                           matInput
                                           class="form-control"
                                           aria-label="Text"
                                           formControlName="proposedInstallerEmail"
                                           [matAutocomplete]="auto"
                                           placeholder="{{ translationsLib.get('profile_account_email') }}">
                                    <mat-autocomplete autoActiveFirstOption #auto="matAutocomplete">
                                      <mat-option *ngFor="let option of filteredOptions | async" [value]="option['email']">
                                        {{option['email']}}
                                      </mat-option>
                                    </mat-autocomplete>
                                  </div>
                                </div>
                              </div>
                            </div>

                          </div>
                        </div>

                        <!-- ADMINISTRATIVE MANAGEMENT -->
                        <div class="col-12">
                          <div class="card">
                            <div class="row m-2">
                              <div class="col-12 col-lg-6">
                                <h4 class="help-title">{{ translationsLib.get('pairing_are_you_administrative_manager') }}</h4>
                                <h5 class="help-text" [innerHTML]="translationsLib.get('pairing_are_you_administrative_manager_text')"></h5>
                              </div>
                              <div class="col-12 col-lg-6 align-self-center pt-0 pt-lg-2">
                                <div class="form-group form-switch mb-2">
                                  <input class="form-check-input" type="checkbox" [value]="results.value.pair_billing_me" formControlName="pair_billing_me"/>
                                  <ion-label>&nbsp;{{ translationsLib.get('pairing_i_am_administrative_manager') }}</ion-label>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>

                        <div class="col-6 text-start">
                          <button class="btn btn-danger mb-3" id="backButton" (click)="goToPreviousSlide();">
                            <ion-icon style="position: relative; top:3px" name="arrow-back-outline"></ion-icon>
                            {{translationsLib.get('back')}}
                          </button>
                        </div>
                        <div class="col-6 text-end">
                          <button id="saveButton" class="btn btn-primary mb-3" (click)="saveData()" *ngIf="terminalFound === true;">{{ translationsLib.get('save') }}</button>
                        </div>
                      </div>
                    </form>

                  </div>
                </div>
              </div>
            </div>
          </div>
        </ng-template>

      </swiper>

    </div>
  </div>
</ion-content>
