<div class="card fields-border">
  <h2 class="text-start mt-0" style="color: #ee7869;">{{translationsLib.get('demo_field_title')}}</h2>
  <div class="row">
    <div class="col-12 col-md-4 text-center flower">
      <div class="garden">
        <div class="dirt"></div>
        <div class="seed"></div>
        <div class="carrot">
          <div class="leaf">
            <span></span>
            <span></span>
            <span></span>
          </div>
          <div class="root">
            <span></span>
            <span></span>
            <span></span>
            <span></span>
          </div>
        </div>
        <div class="beet">
          <div class="leaf">
            <span></span>
          </div>
          <div class="root"></div>
        </div>
        <div class="garlic">
          <div class="leaf">
            <span></span>
            <span></span>
            <span></span>
          </div>
          <div class="root">
            <span></span>
            <span></span>
            <span></span>
          </div>
        </div>
      </div>
    </div>
    <div class="col-12 col-md-8">
      <p class="text-muted text-start mt-4 mb-6 mt-md-0 mb-md-0">
        {{translationsLib.get('demo_field_text')}}
      </p>
      <button class="btn btn-primary float-end mx-3 buttonDown" id="attachDemo" (click)="showAttachDemoFieldAlert()">{{translationsLib.get('demo_field_button')}}</button>
    </div>
  </div>
</div>
