<div class="card fields-dashboard-configurable">
  <div class="card-header">
    <div class="card-header-title my-2">
      <app-device-header [equipment]="equipment"
        [link]="'/farming/'+equipment.serialNumber+'/configurable'"
      ></app-device-header>
    </div>
  </div>
  <div class="card-body">

    <app-configurable-states
      [equipment]="equipment"
      [terminal]="terminal"
    ></app-configurable-states>

    <div class="alert alert-light" *ngIf="!equipment.states || !equipment.states.programs || equipment.states.programs.length === 0; else withProgramsState">
      {{translationsLib.get('configurable_no_program_states')}}
    </div>

    <ng-template #withProgramsState>
      <app-terminal-table-wrapper
        [data]="equipment.states.programs"
        name="configurable_programs"
        [searchPlaceholder]="translationsLib.get('configurable_name')"
        [isFetchingData]="false"
        [tableHeaders]="[
          translationsLib.get('configurable_name'),
          translationsLib.get('configurable_status'),
          translationsLib.get('configurable_group'),
          translationsLib.get('configurable_phase'),
          translationsLib.get('configurable_time'),
          translationsLib.get('configurable_activations')
        ]"
        type="configurable"
      >
        <tr class="row-pointer"
          *appTerminalTableData="let prog"
          [routerLink]="[
            '/farming',
            equipment.serialNumber,
            'configurable',
            'program',
            prog.program,
            'group',
            0
          ]"
        >
          <td class="terminal-table-data">
            {{ prog.name }}
          </td>
          <td class="terminal-table-data">
            <span class="badge" *ngIf="prog.program !== undefined"
            [ngClass]="{
              'bg-info': prog.state === 'IRRIGATING',
              'bg-secondary': prog.state === 'STANDBY' ||
                prog.state === 'WAITING_DIESEL_START' ||
                prog.state === 'WAITING_DIESEL_STOP' ||
                prog.state === 'WAITING_PUMP_START' ||
                prog.state === 'RETAINED',
              'bg-light': prog.state === 'STOPPED'
              }"
            >
              {{
                prog !== undefined
                ? translationsLib.get('configurable_state_'+prog?.state)
                : ''
              }}
            </span>
          </td>
          <td class="terminal-table-data">
            {{prog.state === 'IRRIGATING' ? prog.actualGroup + 1 : ''}}
          </td>
          <td class="terminal-table-data">
            {{getPhaseLabel(prog.actualPhase)}}
          </td>
          <td class="terminal-table-data">
            {{prog.actualTime * 1000 | date:'HH:mm:ss':'UTC'}}
            /
            {{prog.todayTime * 1000 | date:'HH:mm:ss':'UTC'}}
          </td>
          <td class="terminal-table-data">
            {{prog.activations}}
          </td>
        </tr>
      </app-terminal-table-wrapper>
    </ng-template>

  </div>
</div>
