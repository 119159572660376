import { Pipe, PipeTransform } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';

@Pipe({
  name: 'sensorIcon',
})
export class SensorIconPipe implements PipeTransform {
  private ionicIcons = {
    TEMPERATURE_OUTLINE: 'thermometer-outline',
    HUMIDITY_OUTLINE: 'cloudy-outline',
    SIGNAL: 'cellular-outline',
    BATTERY: 'battery-outline',
    ET0: 'flame-outline',
    RADIATION_OUTLINE: 'sunny-outline',
    RAIN: 'rainy-outline',
    NAVIGATE: 'navigate-outline',
    WIND_DIRECTION_OUTLINE: 'compass-outline',
    VIRTUAL: 'help-circle-outline',
    'SHOW-MORE': 'alert-circle-outline',
    PLAY: 'play-circle-outline',
    STOP: 'stop-circle-outline',
    COG: 'cog-outline',
    CLEAR: 'close-outline',
    DOTS: 'ellipsis-vertical-outline',
    EXPORT: 'reader-outline',
    ARROW: 'arrow-redo-outline',
    'FORWARD-ARROW': 'chevron-forward-outline',
    'DOWN-ARROW': 'chevron-down-outline',
    'CREATE-INSTALLER': 'create',
    SUNNY: 'sunny-outline',
    'ARROW-UP': 'arrow-up-outline',
    'ARROW-DOWN': 'arrow-down-outline',
    'HORIZONTAL-DOTS': 'ellipsis-horizontal-outline',
    'PLAY-SKIP-BACK': 'play-skip-back',
    'CARET-BACK': 'caret-back',
    'CARET-FORWARD': 'caret-forward',
    'PLAY-SKIP-FORWARD': 'play-skip-forward',
    'RELOAD-OUTLINE': 'reload-outline',
    'CLOSE-CIRCLE-OUTLINE': 'close-circle-outline',
  };

  private bootstrapIcons = {
    WIND: 'bi bi-wind',
    SUNRISE: 'bi bi-sunrise',
    SUNSET: 'bi bi-sunset',
    ACCUMULATED_RADIATION: 'bi bi-calendar',
    PLUVIOMETER: 'bi bi-water',
    WIND_DIRECTION: 'bi bi-repeat',
    WIND_VELOCITY_OUTLINE: 'bi bi-wind',
    WIND_LEVEL_0: 'bi bi-wind',
    WIND_LEVEL_1: 'bi bi-wind',
    SEARCH: 'bi bi-search',
    'LEFT-ARROW': 'bi bi-caret-left',
    'RIGHT-ARROW': 'bi bi-caret-right',
    CLOCK: 'bi bi-clock',
    THERMOMETER: 'bi bi-thermometer-half',
    'THERMOMETER-SUN': 'bi bi-thermometer-sun',
    SUN: 'bi bi-sun',
    DRIZZLE: 'bi bi-cloud-drizzle',
    SIGNPOST: 'bi bi-signpost-split',
    BRIGHTNESS: 'bi bi-brightness-high',
    SNOW: 'bi bi-snow',
    WATER: 'bi bi-water',
    'BRIGHTNESS-ALT': 'bi bi-brightness-alt-high',
    'CLOUD-LIGHTNING': 'bi bi-cloud-lightning-rain',
    'CLOUD-FILL': 'bi bi-cloud-fill',
    'MET-WIND': 'bi bi-wind',
    'MET-CALENDAR': 'bi bi-calendar',
    REMOVE: 'bi bi-x',
  };
  //../../../assets/img/magnitudes_icons/
  private colorIcons = {
    SUNRISE:
      '../../../assets/img/magnitudes_icons/Iconos_Magnitudes_UCROP-SUNRISE-Amanecer.svg',
    SUNSET:
      '../../../assets/img/magnitudes_icons/Iconos_Magnitudes_UCROP-SUNSET-Anochecer.svg',
    SNOW: '../../../assets/img/magnitudes_icons/Iconos_Magnitudes_UCROP-SNOW-Snow.svg',
    SIGNPOST:
      '../../../assets/img/magnitudes_icons/Iconos_Magnitudes_UCROP-SIGNPOST-SPLIT-Signpost-split_01.svg',
    DRIZZLE:
      '../../../assets/img/magnitudes_icons/Iconos_Magnitudes_UCROP-CLOUD-DRIZZLE-Dia-Cloud-drizzle.svg',
    CLOCK:
      '../../../assets/img/magnitudes_icons/Iconos_Magnitudes_UCROP-CLOCK-Clock.svg',
    BRIGHTNESS_ALT:
      '../../../assets/img/magnitudes_icons/Iconos_Magnitudes_UCROP-DAYNIGHT-Dia-Noche_02.svg',
    ACCUMULATED_RADIATION:
      '../../../assets/img/magnitudes_icons/Iconos_Magnitudes_UCROP-RADIATION-Radiacion_Acumulada.svg',
    PLUVIOMETER:
      '../../../assets/img/magnitudes_icons/Iconos_Magnitudes_UCROP-PLUVIOMETER-Pluviometro.svg',
    WIND_DIRECTION:
      '../../../assets/img/magnitudes_icons/Iconos_Magnitudes_UCROP-WIND_DIR-Direccion_del_viento.svg',
    WIND_VELOCITY:
      '../../../assets/img/magnitudes_icons/Iconos_Magnitudes_UCROP-WIND_SPEED-Velocidad_del_viento.svg',
    TEMPERATURE:
      '../../../assets/img/magnitudes_icons/Iconos_Magnitudes_UCROP-TEMP-Temperatura_Ambiente.svg',
    HUMIDITY:
      '../../../assets/img/magnitudes_icons/Iconos_Magnitudes_UCROP-RH-Humedad_relativa_ambiente.svg',
    SIGNAL:
      '../../../assets/img/magnitudes_icons/Iconos_Magnitudes_UCROP-SIGNAL-Intensidad_señal_portadora_telefonía.svg',
    BATTERY: '../../../assets/img/magnitudes_icons/',
    ET0: '../../../assets/img/magnitudes_icons/Iconos_Magnitudes_UCROP-ET0-Evapotranspiracion_de_referencia.svg',
    RADIATION:
      '../../../assets/img/magnitudes_icons/Iconos_Magnitudes_UCROP-RADIATION-Radiacion.svg',
    CHILL_HOURS:
      '../../../assets/img/magnitudes_icons/Iconos_Magnitudes_UCROP-CHILL_HOURS-Horas_Frio (2).svg',
    DECAGON:
      '../../../assets/img/magnitudes_icons/Iconos_Magnitudes_UCROP-DECAGON-Sonda_Decagon.svg',
    DWP: '../../../assets/img/magnitudes_icons/Iconos_Magnitudes_UCROP-DWP-Punto_de_Rocio (2).svg',
    EC: '../../../assets/img/magnitudes_icons/Iconos_Magnitudes_UCROP-EC-Conductividad_Electrica.svg',
    ETc: '../../../assets/img/magnitudes_icons/Iconos_Magnitudes_UCROP-ETc-Evapotranspiracion_del_cultivo.svg',
    GENERIC:
      '../../../assets/img/magnitudes_icons/Iconos_Magnitudes_UCROP-GENERIC.svg',
    HD: '../../../assets/img/magnitudes_icons/Iconos_Magnitudes_UCROP-HD-Deficit_Hidrico (2).svg',
    MATRIX_POTENTIAL:
      '../../../assets/img/magnitudes_icons/Iconos_Magnitudes_UCROP-MATRIX_POTENTIAL-Potencial_matricial_del_suelo.svg',
    OTHER:
      '../../../assets/img/magnitudes_icons/Iconos_Magnitudes_UCROP-OTHER.svg',
    MV_V: '../../../assets/img/magnitudes_icons/Iconos_Magnitudes_UCROP-MV_V-Milivoltios_por_voltio (2).svg',
    pH: '../../../assets/img/magnitudes_icons/Iconos_Magnitudes_UCROP-pH.svg',
    PORE_WATER_EC:
      '../../../assets/img/magnitudes_icons/Iconos_Magnitudes_UCROP-PORE_WATER_EC-CE_del_poro_de_agua.svg',
    PULSE_COUNTERMETER:
      '../../../assets/img/magnitudes_icons/Iconos_Magnitudes_UCROP-PULSE_COUNTERMETER-Contador_de_agua-abono.svg',
    SOIL_EC:
      '../../../assets/img/magnitudes_icons/Iconos_Magnitudes_UCROP-SOIL_EC-CE del suelo.svg',
    SOIL_TEMP:
      '../../../assets/img/magnitudes_icons/Iconos_Magnitudes_UCROP-SOIL_TEMP-Temperatura_Suelo.svg',
    SOIL_TENSION:
      '../../../assets/img/magnitudes_icons/Iconos_Magnitudes_UCROP-MATRIX_POTENTIAL-Potencial_matricial_del_suelo.svg',
    SOIL_TEXTURE:
      '../../../assets/img/magnitudes_icons/Iconos_Magnitudes_UCROP-SOIL_TEXTURE-Textura_del_suelo.svg',
    TENSION:
      '../../../assets/img/magnitudes_icons/Iconos_Magnitudes_UCROP-TENSION-Tension-Voltaje_leido.svg',
    VPD: '../../../assets/img/magnitudes_icons/Iconos_Magnitudes_UCROP-VPD-Deficit_Presion_Vapor.svg',
    VWC: '../../../assets/img/magnitudes_icons/Iconos_Magnitudes_UCROP-VWC-Volumetric_Water_Content-Referencia_humedad_suelo.svg',
    WATER_TEMP:
      '../../../assets/img/magnitudes_icons/Iconos_Magnitudes_UCROP-WATER_TEMP-Temperatura_Agua.svg',
  };
  constructor(private sanitizer: DomSanitizer) {}

  transform(
    key: string,
    style: string = '',
    className?: string,
    isIonIconTag?: boolean
  ) {
    key = key.toUpperCase();
    let output = '';
    if (this.colorIcons.hasOwnProperty(key)) {
      output = `<img src="${this.colorIcons[key]}" style="${style} max-width:22px;">`;
    } else {
      if (this.ionicIcons.hasOwnProperty(key) && isIonIconTag) {
        return this.ionicIcons[key];
      } else if (this.ionicIcons.hasOwnProperty(key)) {
        output = `<ion-icon name="${this.ionicIcons[key]}" class="${className}" style="${style}"></ion-icon>`;
      } else if (this.bootstrapIcons.hasOwnProperty(key)) {
        output = `<i class="${this.bootstrapIcons[key]}" style="${style}"></i>`;
      } else {
        console.warn('[SENSOR-ICON-PIPE]: Icono no encontrado ' + key);
      }
    }
    return this.sanitizer.bypassSecurityTrustHtml(output);
  }
}
