import { Pipe, PipeTransform } from '@angular/core';
import * as moment from 'moment';

@Pipe({
  name: 'transformDateTime',
})
export class TransformDateTimePipe implements PipeTransform {
  transform(date: string): string {
    if (date === null) {
      return '-';
    } else {
      return moment.utc(date, 'YYYY/MM/DD HH:mm:ss').format('DD/MM/YY HH:mm');
    }
  }
}
