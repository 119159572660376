<div class="row justify-content-between" style="display: contents">
  <div class="col-12">
    <div class="row" *ngIf="showTitle === true">
      <div class="col-12">
        <h2>
          {{ translationsLib.get('organizations_organizations') }}
        </h2>
      </div>
    </div>
    <div class="row">
      <div class="col-12" *ngIf="parsedOrganizations.length > 0">
        <div class="row mb-2">
          <div class="col-12 col-lg-6" *ngFor="let parsedOrganization of parsedOrganizations">
            <h5>{{ parsedOrganization.name }}</h5>
            <img class="item-avatar-logo" src="{{ parsedOrganization.avatar_logo }}" *ngIf="parsedOrganization.avatar_logo"/>
          </div>
        </div>
        <div class="row">
          <div class="col-12">
            <div class="small text-muted mb-md-0" [innerHTML]="translationsLib.get('organizations_help_text')"></div>
          </div>
        </div>
      </div>
      <div class="col-12" [ngClass]="showTitle === true ? '': 'ps-0 mb-4'" *ngIf="parsedOrganizations.length === 0">
        <p class="small text-muted mb-md-0">
          {{ translationsLib.get('organizations_no_organization') }}
        </p>
      </div>
    </div>
  </div>
</div>
