import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router, RouterModule } from '@angular/router';
import { AppCommonsService } from '../../app-commons.service';
import { SessionLibService } from '../../../services/libraries/session-lib.service';
import { IonicModule } from '@ionic/angular';
import { MatExpansionModule } from '@angular/material/expansion';
import { NgClass, NgForOf, NgIf } from '@angular/common';
import { PlatformLibService } from '../../../services/libraries/platform-lib.service';
import Swal from 'sweetalert2';

@Component({
  standalone: true,
  selector: 'app-sidebar',
  templateUrl: './sidebar.component.html',
  styleUrls: ['./sidebar.component.scss'],
  imports: [
    RouterModule,
    IonicModule,
    MatExpansionModule,
    NgForOf,
    NgIf,
    NgClass,
  ],
})
export class SidebarComponent implements OnInit {
  public appPages;
  public instalatorMenu = [];
  private isSelected;
  private expandir;
  isNative = false;
  constructor(
    public router: Router,
    private commonsService: AppCommonsService,
    private session: SessionLibService,
    private route: ActivatedRoute,
    public platformLib: PlatformLibService
  ) {}

  ngOnInit() {
    this.isNative = this.platformLib.isNative();
    this.expandir = true;
    this.commonsService.getMenu().subscribe((response) => {
      this.appPages = response.layout_configuration.upper_menu;
      this.appPages = Object.values(this.appPages);
      this.instalatorMenu = response.layout_configuration.bottom_menu;
      this.session.setHome(response.layout_configuration.home[0].page);
    });
    if (this.session.getImpersonated()) {
      Swal.fire({
        toast: true,
        icon: 'warning',
        title: 'Está conectado como: ' + this.session.getSessionEmail(),
        position: 'bottom-left',
        showConfirmButton: false,
        background: '#FFFF00',
        iconColor: '#000000',
        color: '#000000',
        timer: 300,
        width: 'auto',
        didOpen: () => {
          Swal.toggleTimer();
        },
      });
    }
    setTimeout(() => {
      try {
        if (this.route.snapshot.firstChild.params.vid) {
          this.selectItem({ vid: this.route.snapshot.firstChild.params.vid });
        }
      } catch (e) {
        console.log("[Sidebar]: Couldn't selectItem by vid");
      }
    }, 500);
  }

  goField(item) {
    this.router.navigate(['./field', { vid: item.vid }]);
  }
  goTerminal(item) {
    this.router.navigate(['./terminal', { vid: item.vid }]);
  }
  goHome() {
    const home = String(this.session.getHome());
    location.replace(home);
  }
  selectItem(item) {
    if (this.isSelected != null) {
      const oldSelected = document.getElementById(this.isSelected.vid);
      oldSelected.style.backgroundColor = '#224261';
      oldSelected.classList.remove('menu-item-active');
    }
    if (item !== '' && item !== 'fields') {
      const selected = document.getElementById(item.vid);
      if (selected) {
        selected.style.backgroundColor = '#ee7869';
        this.isSelected = item;
        selected.classList.add('menu-item-active');
      }
    }
    if (item === 'fields') {
      const selected = document.getElementById('mat-expansion-panel-header-0');
      if (selected !== null) {
        selected.style.backgroundColor = '#ee7869';
        selected.classList.add('menu-item-active');
        this.isSelected = { vid: 'mat-expansion-panel-header-0' };
      }
    }
  }
}
