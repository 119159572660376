import { CUSTOM_ELEMENTS_SCHEMA, NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SidebarComponent } from './components/sidebar/sidebar.component';
import { RouterModule } from '@angular/router';
import { IonicModule } from '@ionic/angular';
import { MainHeaderComponent } from './components/main-header/main-header.component';
import { TextAvatarModule } from '../text-avatar';
import { MatExpansionModule } from '@angular/material/expansion';
import { MatTabsModule } from '@angular/material/tabs';
import { inputTime } from './pipes/input-time.pipe';
import { BreadcrumbsComponent } from './components/breadcrumbs/breadcrumbs.component';
import { ModeComponent } from './components/mode/mode.component';
import { MatMenuModule } from '@angular/material/menu';
import { MeteoGridComponent } from './components/meteo-grid/meteo-grid.component';
import { AlarmsGridComponent } from './components/alarms-grid/alarms-grid.component';
import { DirtyMessageComponent } from './components/dirty-message/dirty-message.component';
import { SanitizeTextPipe } from './pipes/sanitize-text.pipe';
import { SafeHtmlPipe } from './pipes/safe-html.pipe';
import { DeviceHeaderComponent } from './components/device-header/device-header.component';
import { FieldsHeaderComponent } from './components/fields-header/fields-header.component';
import { MetricsHeaderComponent } from './components/metrics-header/metrics-header.component';
import { WeekDaysSelectorComponent } from './components/week-days-selector/week-days-selector.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { NgxPaginationModule } from 'ngx-pagination';
import { TerminalTableWrapperComponent } from './components/terminal-table-wrapper/terminal-table-wrapper.component';
import { FilterByPropertyPipe } from './pipes/filter-by-property.pipe';
import { DemoAlertComponent } from './components/demo-alert/demo-alert.component';
import { MatTooltipModule } from '@angular/material/tooltip';
import { ReplaceNegativePipe } from './pipes/replace-negative.pipe';
import { AuthModule } from '../auth/auth.module';
import { AlarmGridTagPipe } from './pipes/alarm-grid-tag.pipe';
import { AlarmGridTitlesPipe } from './pipes/alarm-grid-titles.pipe';
import { BottomTabsComponent } from './components/bottom-tabs/bottom-tabs.component';
import { ToHexPipe } from './pipes/to-hex.pipe';
import { SortPipe } from './pipes/sort.pipe';
import { TransformDatePipe } from './pipes/transform-date.pipe';
import { TransformDateTimePipe } from './pipes/transform-date-time.pipe';
import { FieldCropsNamesPipe } from './pipes/field-crops-names.pipe';
import { SensorIconPipe } from './pipes/sensor-icon.pipe';
import { BadgesPipe } from './pipes/badges.pipe';
import { DemoFieldCardComponent } from './components/demo-field-card/demo-field-card.component';
import { SecondsToTimePipe } from './pipes/seconds-to-time.pipe';
import { TerminalEnabledPipe } from './pipes/terminal-enabled.pipe';
import { SortableHeaderDirective } from './directives/sortable-header.directive';
import { WatersystemTablesComponent } from './components/watersystem-tables/watersystem-tables.component';
import {LeafletMapsComponent} from "./components/leaflet-maps/leaflet-maps.component";

@NgModule({
  declarations: [
    MainHeaderComponent,
    inputTime,
    BreadcrumbsComponent,
    ModeComponent,
    MeteoGridComponent,
    AlarmsGridComponent,
    DirtyMessageComponent,
    SanitizeTextPipe,
    SafeHtmlPipe,
    DeviceHeaderComponent,
    FieldsHeaderComponent,
    MetricsHeaderComponent,
    WeekDaysSelectorComponent,
    TerminalTableWrapperComponent,
    FilterByPropertyPipe,
    DemoAlertComponent,
    AlarmGridTagPipe,
    AlarmGridTitlesPipe,
    BottomTabsComponent,
    ToHexPipe,
    SortPipe,
    TransformDatePipe,
    TransformDateTimePipe,
    FieldCropsNamesPipe,
    SensorIconPipe,
    BadgesPipe,
    DemoFieldCardComponent,
    SecondsToTimePipe,
    TerminalEnabledPipe,
    SortableHeaderDirective,
    LeafletMapsComponent
  ],
  imports: [
    FormsModule,
    ReactiveFormsModule,
    SidebarComponent,
    CommonModule,
    IonicModule,
    RouterModule,
    TextAvatarModule,
    MatExpansionModule,
    MatTabsModule,
    MatMenuModule,
    NgxPaginationModule,
    MatTooltipModule,
    WatersystemTablesComponent,
    AuthModule,
  ],
  exports: [
    SidebarComponent,
    MainHeaderComponent,
    inputTime,
    BreadcrumbsComponent,
    ModeComponent,
    WatersystemTablesComponent,
    MeteoGridComponent,
    AlarmsGridComponent,
    DirtyMessageComponent,
    SanitizeTextPipe,
    SafeHtmlPipe,
    DeviceHeaderComponent,
    FieldsHeaderComponent,
    MetricsHeaderComponent,
    WeekDaysSelectorComponent,
    TerminalTableWrapperComponent,
    FilterByPropertyPipe,
    DemoAlertComponent,
    BottomTabsComponent,
    ToHexPipe,
    SortPipe,
    TransformDatePipe,
    TransformDateTimePipe,
    FieldCropsNamesPipe,
    SecondsToTimePipe,
    SensorIconPipe,
    BadgesPipe,
    TerminalEnabledPipe,
    DemoFieldCardComponent,
    SortableHeaderDirective,
    LeafletMapsComponent,
  ],
  providers: [MainHeaderComponent, LeafletMapsComponent],
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
})
export class AppCommonsModule {}
