<div
  class="my-4 d-flex justify-content-center"
  *ngIf="isFetchingData; else notFetching"
>
  <div class="spinner-border text-warning" role="status">
    <span class="visually-hidden">Loading...</span>
  </div>
</div>

<ng-template #notFetching>
  <div class="card card-table-wrapper" [ngClass]="cardClass">
    <div class="input-group search-container">
      <div class="input-group-prepend">
        <span class="input-group-text search-icon"
          ><i class="bi bi-search"></i
        ></span>
      </div>
      <input
        class="form-control table-search-text"
        type="text"
        placeholder="{{ searchPlaceholder }}"
        [(ngModel)]="searchText"
      />
    </div>
    <div class="card-body fs-5 p-0 table-responsive">
      <div class="elements-table">
        <div class="table table-hover mb-0" style="display: table">
          <thead>
            <tr>
              <th
                scope="col"
                class="text-uppercase fs-6 terminal-table-header"
                *ngFor="let header of tableHeaders"
              >
                {{ header }}
              </th>
            </tr>
          </thead>
          <tbody class="fs-4">
            <ng-container
              *ngFor="
                let elem of data
                  | filterByProperty : filterProperty : searchText : includeText
                  | paginate : { id: name, itemsPerPage: 10, currentPage: page }
              "
            >
              <ng-container
                *ngTemplateOutlet="dataTemplate; context: { $implicit: elem }"
              ></ng-container>
            </ng-container>
            <tr
              *ngIf="
                (
                  data
                  | filterByProperty : filterProperty : searchText : includeText
                )?.length === 0
              "
            >
              <td colspan="5" class="terminal-table-data text-center">
                {{ translationsLib.get('no_results') }}
              </td>
            </tr>
          </tbody>
        </div>
        <pagination-controls
          id="{{ name }}"
          class="mt-4 text-center"
          (pageChange)="page = $event"
          previousLabel="{{ translationsLib.get('previous') }}"
          nextLabel="{{ translationsLib.get('next') }}"
          [responsive]="true"
        ></pagination-controls>
      </div>
    </div>
  </div>
</ng-template>
