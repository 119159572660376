import { Component, Input, OnInit } from '@angular/core';
import { OrganizationDataModel } from '../organization.model';
import { OrganizationsService } from '../organizations.service';
import { TranslationsLibService } from '../../services/libraries/translations-lib.service';

@Component({
  selector: 'app-organizations-item-profile',
  templateUrl: './organizations-item-profile.component.html',
  styleUrls: ['./organizations-item-profile.component.scss'],
})
export class OrganizationsItemProfileComponent implements OnInit {
  @Input() organizations: OrganizationDataModel[] = [];
  parsedOrganizations: OrganizationDataModel[] = [];
  @Input() showTitle = true;
  constructor(
    public translationsLib: TranslationsLibService,
    public organizationService: OrganizationsService
  ) {}

  ngOnInit() {
    this.parsedOrganizations = this.organizationService.parsedOrganizations(
      this.organizations
    );
  }
}
