<div class="row" *ngIf="img">
  <div class="d-flex px-3 px-lg-0" [ngClass]="{'col-12': !showButton, 'col-9': showButton}">
    <div class="d-inline-block pe-2">
      <a (click)="clickButton(equipment)">
        <img src="{{img}}">
      </a>
    </div>
    <div class="d-inline-block align-content-start id-container"  id="{{equipment.source_database}}">
      <a (click)="clickButton(equipment)">
        <div class="title d-inline-block">
          {{equipment?.name}}
          <span *ngIf="showPrettyTypeName === true && pretty_type_name.trim() !== ''">- {{pretty_type_name}}</span>
        </div>

        <p class="text-muted">{{ vid }}</p>
      </a>
    </div>
  </div>
  <div class="d-none d-lg-block col-3 text-end align-self-center" *ngIf="showButton === true">
    <button (click)="clickButton(equipment)" class="btn btn-sm btn-primary" *ngIf="showButton === true">
      {{translationsLib.get('watch_device')}}
    </button>
  </div>
  <div class="d-block d-lg-none col-3 text-center align-self-center">
    <a (click)="clickButton(equipment)" class="btn btn-primary lift text-white" *ngIf="showButton === true">
      <ion-icon [ios]="'arrow-redo-outline'" [md]="'arrow-redo-outline'"></ion-icon>
    </a>
  </div>
</div>

