import { Component, Input, OnInit } from '@angular/core';
import { TerminalsService } from '../terminals.service';
import { TranslationsLibService } from '../../services/libraries/translations-lib.service';
import { MasterTemplate, SpecificTemplate } from '../terminals.model';
import Swal from 'sweetalert2';

@Component({
  selector: 'app-terminals-attach-template',
  templateUrl: './terminals-attach-template.component.html',
  styleUrls: ['./terminals-attach-template.component.scss'],
})
export class TerminalsAttachTemplateComponent implements OnInit {
  @Input() terminalVid;
  selected_progagrup: SpecificTemplate = null;
  selected_progprog: SpecificTemplate = null;
  selected_progrec: SpecificTemplate = null; // Fertilizer Formula
  selected_progrmezcla: SpecificTemplate = null;
  selected_progdren: SpecificTemplate = null;
  progs: any[] = [];
  agrups: any[] = [];
  mixes: any[] = [];
  fertilizers: any[] = [];
  drainages: any[] = [];
  already_used_master_template_id = {};
  ready = false;
  constructor(
    private terminalsService: TerminalsService,
    public translationsLib: TranslationsLibService
  ) {}

  ngOnInit() {
    this.getCurrentTemplates();
  }
  getCurrentTemplates() {
    this.ready = false;
    this.terminalsService
      .getTerminalSpecificTemplates(this.terminalVid)
      .subscribe((res) => {
        const resArray = Object.values(res);
        if ('progagrup' in res) {
          this.selected_progagrup = res.progagrup;
        }
        if ('progdren' in res) {
          this.selected_progdren = res.progdren;
        }
        if ('progprog' in res) {
          this.selected_progprog = res.progprog;
        }
        if ('progrec' in res) {
          this.selected_progrec = res.progrec;
        }
        if ('progrmezcla' in res) {
          this.selected_progrmezcla = res.progrmezcla;
        }
        resArray.forEach((template) => {
          template.name =
            template.name + ' (' + this.translationsLib.get('in_use') + ')';
        });
        this.makeArrays(resArray);
        this.getAvailableTemplates();
      });
  }

  getAvailableTemplates() {
    this.terminalsService
      .getTerminalAvailableTemplates(this.terminalVid)
      .subscribe((res) => {
        this.makeArrays(res);
        this.ready = true;
      });
  }
  makeArrays(data: MasterTemplate[] | SpecificTemplate[]) {
    data.forEach((template) => {
      let allow_to_be_added = true;
      if ('master_configuration_template_id' in template) {
        if (
          template.master_configuration_template_id in
          this.already_used_master_template_id
        ) {
          allow_to_be_added = false;
        }
      }

      if (
        typeof template.master_configuration_template_id !== 'undefined' &&
        allow_to_be_added
      ) {
        this.already_used_master_template_id[
          template.master_configuration_template_id
        ] = true;
        if (template.screen === 'progagrup') {
          this.agrups.push(template);
        }
        if (template.screen === 'progprog') {
          this.progs.push(template);
        }
        if (template.screen === 'progrmezcla') {
          this.mixes.push(template);
        }
        if (template.screen === 'progdren') {
          this.drainages.push(template);
        }
        if (template.screen === 'progrec') {
          this.fertilizers.push(template);
        }
      }
    });
  }

  attachTemplate(id, screen) {
    this.terminalsService
      .postAttachMasterTemplates(this.terminalVid, id, screen)
      .subscribe((res) => {
        Swal.fire({
          text: this.translationsLib.get('template_saved_success'),
          showConfirmButton: true,
          confirmButtonText: this.translationsLib.get('accept'),
          icon: 'success',
        });
      });
  }
  deleteSpecificTemplate(specificTemplateVid: string) {
    this.terminalsService
      .deleteAttachedSpecificTemplates(this.terminalVid, specificTemplateVid)
      .subscribe((res) => {
        Swal.fire({
          text: this.translationsLib.get('template_saved_success'),
          showConfirmButton: true,
          confirmButtonText: this.translationsLib.get('accept'),
          icon: 'success',
        }).then((result) => {
          this.getCurrentTemplates();
        });
      });
  }
}
