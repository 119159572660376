import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SensorsListComponent } from './sensors-list/sensors-list.component';
import { IonicModule } from '@ionic/angular';
import { AppCommonsModule } from 'src/app/commons/app-commons.module';
import { MatTabsModule } from '@angular/material/tabs';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { SensorsListItemComponent } from './sensors-list-item/sensors-list-item.component';

@NgModule({
  declarations: [SensorsListComponent, SensorsListItemComponent],
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    IonicModule,
    MatTabsModule,
    MatFormFieldModule,
    MatInputModule,
    AppCommonsModule,
  ],
  exports: [SensorsListComponent],
})
export class SensorsModule {}
