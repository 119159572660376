import { NgModule } from '@angular/core';
import { TextAvatarDirective } from './text-avatar';
import { CommonModule } from '@angular/common';
import { ColorGenerator } from './color-generator';

import {MatInputModule} from "@angular/material/input";
import {MatTableModule} from "@angular/material/table";

@NgModule({
  imports: [CommonModule, MatInputModule, MatTableModule],
  declarations: [TextAvatarDirective],
  exports: [TextAvatarDirective],
  providers: [ColorGenerator],
})
export class TextAvatarModule {}
