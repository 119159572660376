import { Component, OnInit } from '@angular/core';
import { DashboardService } from '../dashboard.service';
import { TranslationsLibService } from '../../services/libraries/translations-lib.service';
import { FieldsService } from '../../fields/fields.service';
import { WeatherService } from '../../weather/weather.service';
import { WeatherLibService } from '../../services/libraries/weather-lib.service';
import { TerminalsService } from '../../terminals/terminals.service';
import { SessionLibService } from '../../services/libraries/session-lib.service';
import { environment } from '../../../environments/environment';
import { HelpersLibService } from '../../services/libraries/helpers-lib.service';
import { TerminalDataModel } from '../../terminals/terminals.model';
import { ModulesLibService } from '../../services/libraries/modules-lib.service';
import { ModuleDataModel } from '../../modules/modules.model';
import { MapsLibService } from '../../services/libraries/maps-lib.service';

@Component({
  selector: 'app-main-dashboard',
  templateUrl: './main-dashboard.component.html',
  styleUrls: ['./main-dashboard.component.scss'],
})
export class MainDashboardComponent implements OnInit {
  wizard = false;
  fields = undefined;
  name = '';
  loader = true;
  equipmentDataLoader = true;
  userTypeIsInstaller = false;
  terminalsNoField: TerminalDataModel[] = [];
  irrigationConsultingModules: ModuleDataModel[] = [];
  terminalsVid: string[] = [];
  equipmentsData: any[] = []; // At this point all equipments types are mixed
  constructor(
    private dashboardService: DashboardService,
    public translationsLib: TranslationsLibService,
    private fieldsService: FieldsService,
    private modulesLib: ModulesLibService,
    private helpersLib: HelpersLibService,
    private weatherService: WeatherService,
    public weatherLib: WeatherLibService,
    public terminalsService: TerminalsService,
    public sessionService: SessionLibService,
    private mapsLib: MapsLibService
  ) {}

  ngOnInit() {
    this.name = this.sessionService.getName();
    this.getLayout();
    this.userTypeIsInstaller = this.sessionService.userTypeIsInstaller();
    this.helpersLib.setTitle(
      '',
      this.translationsLib.get('dashboard_main_title'),
      null
    );
  }
  getLayout() {
    this.dashboardService.getLayout().subscribe((res) => {
      this.loader = false;
      this.wizard = res.home.show_wizard;
      if (res.home.show_wizard === false) {
        this.getFields();
      }
    });
  }
  getTerminals() {
    this.terminalsService.getTerminals().subscribe((res) => {
      const terminalsVid: string[] = [];
      res.forEach((terminal) => {
        let terminalNoField = false;
        // Terminal has no field
        if (terminal.field === null) {
          terminalNoField = true;
        } else {
          // Terminal has field but authed user cannot access to that field
          // Warning! Backend does not inject this permission at this point, cannot be used at this way
          //if (terminal.field.authed_user_can_read !== true) {
          //  terminalNoField = true;
          //}
          let hasField = false;
          this.fields.forEach((field) => {
            if (terminal.field.vid === field.vid) {
              hasField = true;
            }
          });
          if (hasField === false) {
            terminalNoField = true;
          }
        }
        if (terminalNoField) {
          this.terminalsNoField.push(terminal);
        }
        this.terminalsVid.push(terminal.vid);
      });
      if (this.userTypeIsInstaller === false) {
        this.modulesLib
          .getModulesByType('irrigation_consulting')
          .subscribe((modules) => {
            this.irrigationConsultingModules = modules;
            this.getTerminalsApiInfo();
          });
      }
    });
  }
  // Make equipments data calls here and not in simple meteo pills to only make one call
  // to farming and not one per component call
  getTerminalsApiInfo() {
    const data = {
      method: 'get',
      terminals_vid: this.terminalsVid,
    };
    this.terminalsService.getTerminalsApiInfo(data).subscribe((res) => {
      this.equipmentsData = res;
      this.equipmentDataLoader = false;
    });
    if (
      // eslint-disable-next-line @typescript-eslint/dot-notation
      typeof window['mainDashboardTerminalsSimpleMeteoInterval'] !== undefined
    ) {
      // eslint-disable-next-line @typescript-eslint/dot-notation
      clearInterval(window['mainDashboardTerminalsSimpleMeteoInterval']);
    }
    // eslint-disable-next-line @typescript-eslint/dot-notation
    window['mainDashboardTerminalsSimpleMeteoInterval'] = setInterval(() => {
      this.getTerminalsApiInfo();
    }, environment.intervalDefaultTimeout);
  }
  getFields() {
    this.fieldsService.getFields().subscribe((res) => {
      this.fields = res;
      this.fields.forEach((field) => {
        this.weatherService
          .getWeatherCurrentByField(field.vid)
          .subscribe((res) => {
            // @TODO: Crear modelo específico para el dashboard
            field.temperature = res.main_temp;
            field.humidity = res.main_humidity;
            field.wind =
              res.wind_speed +
              ' Km/h ' +
              this.translationsLib.get(
                this.weatherLib.getWindDirection(res.wind_deg)
              );
            field.radiation = res.solar_rad;
            field.moment = res.weather_icon[3];
            field.weather_id = res.weather_id;
            field.weather_status = res.weather_description;
          });
        field.route = './field;vid=' + field.vid;
        let coordenadasString = '';
        field.coordinates.forEach((coord) => {
          coordenadasString += coord.latitude + ',' + coord.longitude + '%7C';
        });
        coordenadasString = coordenadasString.slice(
          0,
          coordenadasString.length - 3
        );
        field.center = this.getCenterCoords(field.coordinates);
        field.staticMap = this.staticMapString(
          coordenadasString,
          field.color,
          field.config.map_zoom + 1, // Force to have more zoom in!
          field.center,
          field.coordinates_area / 10000
        );
      });
      this.getTerminals();
    });
  }
  getCenterCoords(coords) {
    let lat = 0;
    let lng = 0;

    // eslint-disable-next-line @typescript-eslint/prefer-for-of
    for (let i = 0; i < coords.length; ++i) {
      lat += coords[i].latitude;
      lng += coords[i].longitude;
    }

    lat /= coords.length;
    lng /= coords.length;

    return { lat, lng };
  }
  staticMapString(coordinates: string, color, zoom, center, size) {
    color = color.slice(1);
    return this.mapsLib.staticMapString(coordinates, center, color, size, zoom);
  }
}
