import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { AppCommonsModule } from '../commons/app-commons.module';
import { ReportsHeaderComponent } from './reports-header/reports-header.component';
import { ReportsIrrigationsComponent } from './reports-irrigations/reports-irrigations.component';
import { ReportsDrainagesComponent } from './reports-drainages/reports-drainages.component';
import { IonicModule } from '@ionic/angular';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatAutocompleteModule } from '@angular/material/autocomplete';
import { MatInputModule } from '@angular/material/input';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatSelectModule } from '@angular/material/select';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatNativeDateModule } from '@angular/material/core';
import { AuthModule } from '../auth/auth.module';
import { MatTableModule } from '@angular/material/table';
import { MatIconModule } from '@angular/material/icon';
import { MatButtonModule } from '@angular/material/button';
import { HighchartsChartModule } from 'highcharts-angular';
import { MatTooltipModule } from '@angular/material/tooltip';
import { NgSelectModule } from '@ng-select/ng-select';
import { MatPaginatorModule } from '@angular/material/paginator';
import { ReportsIrrigationsTableComponent } from './reports-irrigations/reports-irrigations-table/reports-irrigations-table.component';
import { ReportsDrainagesTableComponent } from './reports-drainages/reports-drainages-table/reports-drainages-table.component';
import { ReportsConsumptionsComponent } from './reports-consumptions/reports-consumptions.component';
import { ReportsProgramsConsumptionsComponent } from './reports-consumptions/reports-programs-consumptions/reports-programs-consumptions.component';
import { ReportsGroupingConsumptionsComponent } from './reports-consumptions/reports-grouping-consumptions/reports-grouping-consumptions.component';
import { ReportsConsumptionsChartsComponent } from './reports-consumptions/reports-consumptions-charts/reports-consumptions-charts.component';
import { ReportsConsumptionsTableComponent } from './reports-consumptions/reports-consumptions-table/reports-consumptions-table.component';
import { ReportsProgRealComponent } from './reports-widgets/reports-prog-real/reports-prog-real.component';
import { MatSortModule } from '@angular/material/sort';
import { ReportsFertilizersTableComponent } from './reports-irrigations/reports-fertilizers-table/reports-fertilizers-table.component';
import { MatExpansionModule } from '@angular/material/expansion';
import { MatTableExporterModule } from 'mat-table-exporter';
import { MatMenuModule } from '@angular/material/menu';
import { ReportsAlarmsComponent } from './reports-alarms/reports-alarms.component';
import { ReportsAlarmsTableComponent } from './reports-alarms/reports-alarms-table/reports-alarms-table.component';
import { MatDialogModule } from '@angular/material/dialog';
import { MatTabsModule } from '@angular/material/tabs';
import { ReportsTasksComponent } from './reports-tasks/reports-tasks.component';
import { ReportsTasksItemsComponent } from './reports-tasks/reports-tasks-items/reports-tasks-items.component';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatChipsModule } from '@angular/material/chips';
import { MaxRangeDirective } from '../commons/directives/max-range-selection-strategy.directive';
import { ReportsSimpleTableComponent } from './reports-widgets/reports-simple-table/reports-simple-table.component';

@NgModule({
  declarations: [
    ReportsHeaderComponent,
    ReportsDrainagesComponent,
    ReportsIrrigationsComponent,
    ReportsIrrigationsTableComponent,
    ReportsDrainagesTableComponent,
    ReportsConsumptionsComponent,
    ReportsConsumptionsChartsComponent,
    ReportsConsumptionsTableComponent,
    ReportsProgramsConsumptionsComponent,
    ReportsGroupingConsumptionsComponent,
    ReportsProgRealComponent,
    ReportsFertilizersTableComponent,
    ReportsAlarmsComponent,
    ReportsAlarmsTableComponent,
    ReportsTasksComponent,
    ReportsTasksItemsComponent,
    ReportsSimpleTableComponent,
    MaxRangeDirective,
  ],
  exports: [MaxRangeDirective],
  imports: [
    CommonModule,
    AppCommonsModule,
    IonicModule,
    MatFormFieldModule,
    MatAutocompleteModule,
    MatInputModule,
    ReactiveFormsModule,
    MatSelectModule,
    MatDatepickerModule,
    MatNativeDateModule,
    AuthModule,
    MatTableModule,
    MatIconModule,
    MatButtonModule,
    HighchartsChartModule,
    MatTooltipModule,
    NgSelectModule,
    FormsModule,
    MatPaginatorModule,
    MatSortModule,
    MatExpansionModule,
    MatTableExporterModule,
    MatMenuModule,
    MatDialogModule,
    MatTabsModule,
    MatSlideToggleModule,
    MatCheckboxModule,
    MatChipsModule,
  ],
})
export class ReportsModule {}
