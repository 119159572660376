import { Component, Input, OnInit } from '@angular/core';
import { AlarmsLibService } from '../../services/libraries/alarms-lib.service';
import { TranslationsLibService } from '../../services/libraries/translations-lib.service';
import { TimeLibService } from '../../services/libraries/time-lib.service';
import { HelpersLibService } from '../../services/libraries/helpers-lib.service';
import { ReportsService } from '../reports.service';
import {
  AlarmsReportDataModel,
  AlarmsReportModel,
  CreatedReportModel,
} from '../reports.model';
import * as moment from 'moment';

@Component({
  selector: 'app-reports-alarms',
  templateUrl: './reports-alarms.component.html',
  styleUrls: ['./reports-alarms.component.scss'],
  animations: [],
})
export class ReportsAlarmsComponent implements OnInit {
  reportsData: AlarmsReportModel[] = [];
  expandedElement: AlarmsReportDataModel | null;
  alarmColumnsToDisplay = [
    'begin',
    'end',
    'activeStr',
    'typeStr',
    'whoActivatesStr',
  ];
  @Input() createdReport: CreatedReportModel;
  loader = false;
  constructor(
    private alarmsLib: AlarmsLibService,
    private helpersLib: HelpersLibService,
    private reportsService: ReportsService,
    private timeLib: TimeLibService,
    public translationsLib: TranslationsLibService
  ) {}
  ngOnInit() {
    this.processAlarms();
  }

  public processAlarms(): void {
    const begin = this.timeLib.beginDate(
      this.createdReport.dateRange.start,
      0,
      'days'
    );
    const end = this.timeLib.endDate(
      this.createdReport.dateRange.end,
      0,
      'days'
    );
    this.loader = true;
    const terminal_id_max = this.createdReport.terminals.length - 1;
    this.createdReport.terminals.forEach((terminalReport, terminalId) => {
      this.reportsService
        .getAlarms(terminalReport.vid, begin, end, null)
        .subscribe((res) => {
          this.helpersLib.sendEventPageView(
            'Reports | Alarms | ' + terminalReport.name_vid
          );
          res.forEach((alarmReportDataModel, i) => {
            res[i].begin = moment(
              alarmReportDataModel.begin,
              'YYYY-MM-DD HH:mm:ss'
            ).format('DD/MM/YYYY HH:mm:ss');
            res[i].end = moment(
              alarmReportDataModel.end,
              'YYYY-MM-DD HH:mm:ss'
            ).format('DD/MM/YYYY HH:mm:ss');
            res[i].typeStr = this.alarmsLib.getName(res[i].typeStr);
            if (res[i].active === true) {
              res[i].activeStr = this.translationsLib.get('true');
            } else {
              res[i].activeStr = this.translationsLib.get('false');
            }
          });

          this.reportsData.push({
            reportName:
              this.translationsLib.get('reports_alarms') +
              ' ' +
              terminalReport.name_vid,
            terminalVid: terminalReport.vid,
            terminalNameVid: terminalReport.name_vid,
            terminalFieldName: terminalReport.field_name,
            terminalFieldVid: terminalReport.field_vid,
            begin,
            end,
            ready: true,
            alarms: res,
          });

          if (terminalId === terminal_id_max) {
            this.loader = false;
          }
        });
    });
  }
}
