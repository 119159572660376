import * as Highcharts from 'highcharts';

/**
 * Build and return the Highcharts options structure
 */
export const MeteoChartsWidgetModel: any = {
  chart: {
    type: 'line',
    zoomType: 'x',
    panning: true,
    panKey: 'shift',
    backgroundColor: 'rgba(0,0,0,0)',
    spacingBottom: 5, // Chart Bottom Space
  },

  title: {
    text: null,
    align: 'left',
    style: {
      color: 'white',
      fontSize: '11px',
      fontWeight: 'normal',
      textShadow: '0 0 3px black',
    },
    margin: 10, // Margin between title and chart
  },

  tooltip: {
    shared: true,
    useHTML: true,
    headerFormat:
      '<span style="font-size:11px">{point.x:%e/%m %H:%M}</span><br><br>',
  },

  xAxis: [
    {
      // Bottom X axis
      type: 'datetime',
      tickInterval: 2 * 36e5, // two hours
      gridLineWidth: 1,
      gridLineColor: 'rgba(0, 0, 0, 0.1)',
      minPadding: 0,
      maxPadding: 0,
      showLastLabel: true,
      lineColor: 'white',
      tickColor: 'white',
      labels: {
        format: '{value:%H}',
        style: {
          color: 'white',
          fontSize: '12px',
          textShadow: '0 0 3px black',
        },
        distance: 10,
      },
      crosshair: true,
    },
  ],

  yAxis: [
    {
      title: {
        text: null,
      },
      labels: {
        format: '{value}°',
        style: {
          fontSize: '10px',
          color: 'white',
          textShadow: '0 0 3px black',
        },
        x: -3,
      },
    },
    {
      allowDecimals: false,
      title: {
        text: '%',
        offset: 0,
        align: 'high',
        rotation: 0,
        style: {
          fontSize: '10px',
          color: 'white',
          textShadow: '0 0 3px black',
        },
        textAlign: 'left',
        x: 3,
      },
      labels: {
        style: {
          fontSize: '10px',
          color: 'white',
          textShadow: '0 0 3px black',
        },
        y: 2,
        x: 3,
      },
      gridLineWidth: 0,
      opposite: true,
      showLastLabel: false,
    },
  ],

  legend: {
    enabled: false,
  },

  credits: {
    enabled: false,
  },

  series: [
    {
      // temperature
      type: 'spline',
      marker: {
        enabled: false,
        states: {
          hover: {
            enabled: true,
          },
        },
      },
      tooltip: {
        valueSuffix: ' ºC',
      },
      color: 'red',
      shadow: {
        color: 'rgba(50, 50, 50, 0.5)',
        offsetX: 3,
        offsetY: 3,
        opacity: 0.5,
        width: 5,
      },
    },
    {
      // humidity
      marker: {
        enabled: false,
      },
      tooltip: {
        valueSuffix: ' %',
      },
      color: '#00BD3F',
      yAxis: 1,
      shadow: {
        color: 'rgba(50, 50, 50, 0.5)',
        offsetX: 3,
        offsetY: 3,
        opacity: 0.5,
        width: 5,
      },
    },
  ],

  exporting: {
    enabled: false,
  },
};

export const MeteoChartsModel: any = {
  chart: {
    type: 'line',
    zoomType: 'null',
    panning: true,
    panKey: 'shift',
    backgroundColor: 'rgba(0,0,0,0)',
  },

  title: {
    text: null,
    align: 'left',
    style: {
      whiteSpace: 'nowrap',
      textOverflow: 'ellipsis',
      fontSize: '15px',
      textTransform: 'uppercase',
    },
    y: 18,
    x: -8,
  },

  tooltip: {
    shared: true,
    useHTML: true,
    headerFormat:
      '<span style="font-size:11px">{point.x:%e/%m %H:%M}</span><br><br>',
    backgroundColor: 'white',
  },

  xAxis: [
    {
      // Bottom X axis
      type: 'datetime',
      tickInterval: 2 * 36e5, // two hours
      minorTickInterval: 36e5, // one hour
      tickLength: 0,
      gridLineWidth: 1,
      gridLineColor: 'rgba(128, 128, 128, 0.1)',
      startOnTick: false,
      endOnTick: false,
      minPadding: 0,
      maxPadding: 0,
      offset: window.innerWidth < 1500 ? 20 : 30,
      showLastLabel: true,
      labels: {
        format: '{value:%H}',
      },
      crosshair: true,
    },
    {
      // Top X axis
      linkedTo: 0,
      type: 'datetime',
      tickInterval: 24 * 3600 * 1000,
      labels: {
        format: '{value: %e/%m}',
        align: 'left',
        x: 3,
        y: 8,
      },
      opposite: true,
      tickLength: 20,
      gridLineWidth: 1,
    },
  ],

  yAxis: [
    {
      // temperature axis
      title: {
        text: null,
        style: {
          fontSize: '10px',
        },
      },
      labels: {
        style: {
          fontSize: '10px',
        },
        x: -3,
      },
      plotLines: [
        {
          // zero plane
          value: 0,
          color: '#BBBBBB',
          width: 1,
          zIndex: 2,
        },
      ],
      maxPadding: 0.3,
      minRange: 8,
      tickInterval: 1,
      gridLineColor: 'rgba(128, 128, 128, 0.1)',
    },
    {
      // Humidity axis
      allowDecimals: false,
      title: {
        text: null,
        style: {
          color: '#00A838',
          fontSize: '10px',
        },
      },
      labels: {
        style: {
          fontSize: '10px',
          color: '#00A838',
        },
        x: 3,
      },
      gridLineWidth: 0,
      opposite: true,
    },
    {
      // solar radiation axis
      title: {
        text: null,
        style: {
          color: 'orange',
          fontSize: '10px',
          textTransform: 'uppercase',
        },
      },
      opposite: true,
      labels: {
        style: {
          color: 'orange',
          fontSize: '10px',
        },
      },
    },
    {
      // precipitation axis
      title: {
        text: null,
      },
      labels: {
        enabled: false,
      },
      gridLineWidth: 0,
      tickLength: 0,
      minRange: 40,
      min: 0,
    },
    {
      // Et0 axis
      allowDecimals: false,
      title: {
        text: null,
        style: {
          fontSize: '10px',
        },
      },
      labels: {
        style: {
          fontSize: '10px',
        },
      },
    },
  ],

  legend: {
    enabled: true,
  },

  plotOptions: {
    series: {
      pointPlacement: 'between',
    },
  },

  credits: {
    enabled: false,
  },

  series: [
    {
      // temperature
      type: 'spline',
      marker: {
        enabled: false,
        states: {
          hover: {
            enabled: true,
          },
        },
      },
      tooltip: {
        valueSuffix: ' ºC',
      },
      color: 'red',
    },
    {
      // humidity
      marker: {
        enabled: false,
      },
      shadow: false,
      tooltip: {
        valueSuffix: ' %',
      },
      dashStyle: 'shortdot',
      color: '#00A838',
      yAxis: 1,
    },
    {
      // radiation
      marker: {
        enabled: false,
      },
      shadow: false,
      tooltip: {
        valueSuffix: ' W/m²',
      },
      dashStyle: 'dash',
      color: 'orange',
      yAxis: 2,
    },
    {
      // rain
      type: 'column',
      color: 'rgb(104, 207, 232)',
      yAxis: 3,
      groupPadding: 0,
      pointPadding: 0,
      tooltip: {
        valueSuffix: ' mm',
      },
      grouping: false,
      dataLabels: {
        enabled: true,
        filter: {
          operator: '>',
          property: 'y',
          value: 0,
        },
        style: {
          fontSize: '8px',
          color: '#666',
        },
      },
    },
    {
      // wind
      type: 'windbarb',
      id: 'windbarbs',
      color: Highcharts.getOptions().colors[1],
      lineWidth: window.innerWidth < 1500 ? 1 : 1.5,
      vectorLength: window.innerWidth < 1500 ? 10 : 18,
      yOffset: window.innerWidth < 1500 ? -10 : -15,
      marker: {
        enabled: false,
        radius: 0, // Tamaño del marcador en 0
        width: 0, // Ancho del marcador en 0
      },
      tooltip: {
        valueSuffix: ' Km/h',
      },
      dataGrouping: {
        enabled: false,
      },
    },
    {
      // et0
      type: 'column',
      borderWidth: 0,
      color: 'rgba(112, 128, 144, 0.5)',
      yAxis: 4,
    },
  ],

  exporting: {
    filename: 'ucrop chart',
    buttons: {
      contextButton: {
        menuItems: [
          'printChart',
          'separator',
          'downloadPNG',
          'downloadJPEG',
          'downloadPDF',
          'separator',
          'downloadCSV',
          'downloadXLS',
          //"viewData",
          'openInCloud',
        ],
      },
    },
    chartOptions: {
      chart: {
        backgroundColor: 'white', // White background when downloading
      },
    },
  },
};
