import { Component, OnInit } from '@angular/core';
import { AuthService } from '../auth.service';
import { ActivatedRoute, Router } from '@angular/router';
import { FormBuilder, Validators } from '@angular/forms';
import { HelpersLibService } from '../../services/libraries/helpers-lib.service';
import { TranslationsLibService } from '../../services/libraries/translations-lib.service';
import { SessionLibService } from '../../services/libraries/session-lib.service';
import Swal from 'sweetalert2';
import { ConfirmationCodeModel } from '../auth.model';

@Component({
  selector: 'app-register-confirmation',
  templateUrl: './register-confirmation.component.html',
  styleUrls: ['./register-confirmation.component.scss'],
})
export class RegisterConfirmationComponent implements OnInit {
  identifier = '';
  resend = false;
  validate = true;
  private confirmationCode: ConfirmationCodeModel = {
    identifier: '',
    data: {
      confirmation_code: '',
    },
  };
  private fb: FormBuilder = new FormBuilder();
  // eslint-disable-next-line @typescript-eslint/member-ordering
  emailSend = this.fb.group({
    email: [
      '',
      [
        Validators.required,
        Validators.pattern(
          '^[A-Za-z0-9._%+-]+@[A-Za-z0-9.-]+\\.[A-Za-z]{2,4}$'
        ),
      ],
    ],
  });
  constructor(
    private route: ActivatedRoute,
    private authService: AuthService,
    public router: Router,
    private helpersLib: HelpersLibService,
    public translationsLib: TranslationsLibService,
    private sessionLib: SessionLibService
  ) {}

  ngOnInit() {
    if (this.route.snapshot.paramMap.get('validated')) {
      this.validate =
        this.route.snapshot.paramMap.get('validated').toLowerCase() === 'true';
    }
    this.helpersLib.setTitle(
      '',
      this.translationsLib.get('auth_register_title'),
      'Registro'
    );
    this.identifier = this.route.snapshot.queryParams.identifier;
  }

  confirmRecentlyRegistration(code) {
    this.confirmationCode.identifier = this.identifier;
    if (typeof code !== 'string') {
      this.sendCode();
    } else {
      // @ts-ignore
      this.confirmationCode.data.confirmation_code = code;
      this.sendCode();
    }
  }
  sendCode() {
    this.authService.confirmCode(this.confirmationCode).subscribe(
      (response) => {
        Swal.fire({
          icon: 'success',
          text: this.translationsLib.get('auth_confirmed_email'),
          confirmButtonColor: '#224261',
        }).then((res) => {
          if (res.isDismissed || res.isConfirmed) {
            this.sessionLib.createSession(
              response.vid,
              response.name,
              response.auth_response.token,
              response.email,
              response.auth_response.access_token,
              response.type,
              response.language
            );
            const initialName = response.name[0];
            const initialSurname = response.surname[0];
            const avatar = {
              name: (initialName + ' ' + initialSurname).trim(),
              background: response.avatar_colors[0],
              color: response.avatar_colors[1],
            };
            this.sessionLib.setAvatar(avatar);
            // eslint-disable-next-line prefer-arrow/prefer-arrow-functions
            setTimeout(function () {
              window.location.href = '/home'; // Force full reload!
            }, 100);
          }
        });
      },
      (error) => {
        Swal.fire({
          icon: 'error',
          title: this.translationsLib.get('error'),
          text: this.translationsLib.get('auth_registration_code_error'),
          confirmButtonColor: '#224261',
        });
      }
    );
  }
  setIdentifier() {
    this.resend = false;

    this.authService
      .resendCodeEmail({
        identifier: this.emailSend.value.email,
      })
      .subscribe(
        (response) => {
          if (this.validate === false) {
            this.validate = true;
          }
          this.identifier = response.vid;
          Swal.fire({
            icon: 'success',
            text: this.translationsLib.get('auth_email_sent_success'),
            confirmButtonColor: '#224261',
          });
        },
        (error) => {
          if (
            error.error &&
            String(error.error).indexOf('user was confirmed before') >= 0
          ) {
            Swal.fire({
              icon: 'error',
              title: this.translationsLib.get('error'),
              text: this.translationsLib.get('auth_email_was_confirmed_before'),
              confirmButtonColor: '#224261',
            }).then((result) => {
              if (result.isConfirmed) {
                this.router.navigate(['/reset-password']);
              }
            });
          } else {
            Swal.fire({
              icon: 'error',
              title: this.translationsLib.get('error'),
              text: this.translationsLib.get('auth_email_error'),
              confirmButtonColor: '#224261',
            });
          }
        }
      );
  }
}
