import { Injectable } from '@angular/core';
import { Platform } from '@ionic/angular';
import { Capacitor } from '@capacitor/core';

@Injectable({
  providedIn: 'root',
})
export class PlatformLibService {
  constructor(private platform: Platform) {}

  isNative(): boolean {
    return Capacitor.isNativePlatform();
  }
  choosePlatform() {
    if (this.platform.is('ios')) {
      return 'ios';
    }
    if (this.platform.is('android')) {
      return 'android';
    }
    return '';
  }
  isPad() {
    return this.platform.is('ipad');
  }
}
