<app-main-header [name]="translationsLib.get('terminal_activation_title')"></app-main-header>
<ion-content>
  <div class="container-fluid p-1 p-lg-2">

    <div class="row" *ngIf="loading === true">
      <div class="col-12">
        <app-loader></app-loader>
      </div>
    </div>

    <div class="m-3 m-lg-4" *ngIf="loading === false">
      <div class="row">
        <div class="col-12 p-0">
          <div class="card" id="activation">
            <div class="row">
              <div class="col-12">
                <img src="assets/img/covers/header_panoramic_img.jpeg" style="border-radius: 34px">
              </div>
              <div class="col-12 col-md-4">
                <div class="row">
                  <div class="col-6 text-start">
                    <h6 class="title-red">{{ this.translationsLib.get('terminal_activation_serial_number') }}</h6>
                    <h2>{{serialNumber}}</h2>
                  </div>
                  <div class="col-6 text-lg-center text-end">
                    <h6 class="title-red">{{ this.translationsLib.get('terminal_activation_status') }}</h6>
                    <div class="spinner-border spinner-border-sm" role="status" style="padding:0px;" *ngIf="deviceStatus===undefined">
                    </div>
                    <span class="badge bg-success" *ngIf="deviceStatus===true">{{ this.translationsLib.get('terminal_status_connected') }}</span>
                    <span class="badge bg-danger" *ngIf="deviceStatus===false">{{ this.translationsLib.get('terminal_status_disconnected') }}</span>
                  </div>
                </div>
              </div>
              <div class="col-12 col-md-4">
                <div class="row">
                  <div class="col-6 text-lg-center text-start">
                    <h6 class="title-red">{{ translationsLib.get('terminal_activation_modelo') }}</h6>
                    <h4>{{terminalModel}}</h4>
                  </div>
                  <div class="col-6 text-lg-center text-end">
                    <h6 class="title-red">{{ this.translationsLib.get('terminal_activation_last_connection') }}</h6>
                    <div class="spinner-border spinner-border-sm" role="status" style="padding:0px;" *ngIf="lastConexion === undefined"></div>
                    <h4>{{lastConexion}}</h4>
                  </div>
                </div>
              </div>
              <div class="col-12 col-md-4">
                <div class="row">
                  <div class="col-6 text-lg-center text-start">
                    <h6 class="title-red">{{ this.translationsLib.get('terminal_activation_firmware') }}</h6>
                    <h4>{{warp}}</h4>
                  </div>
                  <div class="col-6 text-end">
                    <button class="btn btn-success" (click)="pickUpData()"  style="margin-top: 25px" [disabled]="activationStatus===1 || cancelled===1">
                      {{ translationsLib.get('terminal_activate_terminal') }}
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="row">
        <div class="col-12 p-0">
          <div class="card">
            <div class="container-fluid ps-3 pe-0">
              <div class="row">
                <div class="col-12">
                  <app-device-header [equipment]="terminalData"></app-device-header>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="row" *ngIf="activationStatus===1">
        <div class="row my-3" *ngIf="deviceStatus===false">
          <div class="col-lg-2 d-none d-lg-block">
            &nbsp;
          </div>
          <div class="col-12 col-lg-8 text-danger text-center">
            {{ translationsLib.get('terminal_is_not_online_tools_text') }}
          </div>
          <div class="col-lg-2 d-none d-lg-block">
            &nbsp;
          </div>
        </div>
        <app-progress-bar class="p-0" [progress]="progress" (newItemEvent)="cancelPickUp()"></app-progress-bar>
        <div class="col-12">
          <hr class="my-4" style="color: #b8b7b7">
        </div>
        <div class="col-12 text-center">
          <a class="btn btn-secondary" (click)="router.navigate(['./terminals-general'])">
            {{ translationsLib.get('terminal_view_terminals') }}
          </a>
        </div>
        <div class="col-12">
          &nbsp;
        </div>
      </div>
      <app-loader *ngIf="activationStatus === 0.5"></app-loader>
        <div class="row" *ngIf="activationStatus===0 && cancelled === 0">
          <div class="col-12 col-lg-6 ps-0 pe-0 pe-lg-4 pb-4 pb-lg-0">
            <div class="card" style="height: 100%">
              <h6 class="title-red">{{ this.translationsLib.get('terminal_activation_help_title') }}</h6><br>
              <div [innerHTML]="this.translationsLib.get('terminal_activation_help_text')"></div>
            </div>
          </div>
          <div class="col-12 col-lg-6 ps-0 ps-lg-1 pe-0 pe-lg-1">
            <div class="card map-edit-card" style="height: 100%">
              <form [formGroup]="fillData">
                  <div class="form-group">
                        <label class="form-label">
                          {{ this.translationsLib.get('terminal_name') }}
                        </label>
                        <!-- Input -->
                        <input type="text" class="form-control" formControlName="terminalName" [value]="fillData.get('terminalName')">
                  </div>
                  <div class="form-group">
                    <label class="form-label">
                      {{ translationsLib.get('terminal_creation_date')}}
                    </label>
                    <p class="text-muted">
                      {{ createdAt }}
                    </p>
                  </div>
                  <!--
                  <div class="form-group">
                    <label class="form-label">
                      {{ translationsLib.get('timezone') }}
                    </label>
                    <p class="text-muted">
                      {{ timezone }}
                    </p>
                  </div>
                  -->
              </form>
              <!--<app-webapp-maps *ngIf="terminalData!== null && markerCoordinates!==undefined && !isNative" [markerCoordinates]="markerCoordinates" [coming]="'terminals-activation'" [terminalData]="terminalData"></app-webapp-maps>
              <app-native-maps *ngIf="terminalData!== null && isNative" [markerCoordinates]="markerCoordinates" [coming]="'terminals-activation'" [terminalData]="terminalData"></app-native-maps>-->
              <app-leaflet-maps *ngIf="terminalData!== null" [markerCoordinates]="markerCoordinates" [coming]="'terminals-activation'" [terminalData]="terminalData"></app-leaflet-maps>
              <br>
                <button style="max-width: 150px; align-self: end;" class="btn btn-primary"(click)="saveTerminalChanges()">
                  {{ this.translationsLib.get('save') }}
                </button>
            </div>
          </div>
        </div>
        <div class="row" *ngIf="activationStatus===1 || cancelled ===1">
      </div>
    </div>
  </div>
</ion-content>
