import { Component, Inject, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { TranslationsLibService } from '../../services/libraries/translations-lib.service';
import { HelpersLibService } from '../../services/libraries/helpers-lib.service';
import { Breadcrumb } from '../../farming/irrigation/shared/shared.model';
import { ReportsService } from '../reports.service';
import { SessionLibService } from '../../services/libraries/session-lib.service';
import { TerminalsLibService } from '../../services/libraries/terminals-lib.service';
import { TerminalsService } from '../../terminals/terminals.service';
import { FormControl, FormGroup } from '@angular/forms';
import { DateAdapter } from '@angular/material/core';
import {
  AvailableReportModel,
  CreatedReportModel,
  TerminalReportModel,
} from '../reports.model';

import Swal from 'sweetalert2';
import * as moment from 'moment';

@Component({
  selector: 'app-reports',
  templateUrl: './reports-header.component.html',
  styleUrls: ['./reports-header.component.scss'],
})
export class ReportsHeaderComponent implements OnInit {
  selectedIrrigationTerminals: TerminalReportModel[] = [];
  minDate: Date;
  maxDate: Date;
  ready = false;
  selectedReport = '';
  createdReport: CreatedReportModel = null;
  report = new FormControl('');
  range = new FormGroup({
    start: new FormControl<Date | null>(null),
    end: new FormControl<Date | null>(null),
  });
  tablePageItemNumber = 20;
  breadcrumbs: Breadcrumb[] = [];
  irrigationTerminals: TerminalReportModel[] = [];
  availableReports: AvailableReportModel[] = [];
  showReportStartDate = '';
  showReportDateSplitter = '';
  showReportEndDate = '';
  simpleTable = false;
  constructor(
    private route: ActivatedRoute,
    private dateAdapter: DateAdapter<Date>,
    private helpersLib: HelpersLibService,
    public router: Router,
    private reportsService: ReportsService,
    private sessionLib: SessionLibService,
    private terminalsLib: TerminalsLibService,
    private terminalsService: TerminalsService,
    public translationsLib: TranslationsLibService
  ) {}

  ngOnInit() {
    this.helpersLib.sendEventPageView('Reports');
    this.helpersLib.setTitle(
      '',
      this.translationsLib.get('reports_reports'),
      null
    );
    this.breadcrumbs = [
      {
        text: this.translationsLib.get('reports_reports'),
        disabled: false,
        to: ' ',
        active: true,
      },
    ];

    this.minDate = moment().subtract(60, 'd').toDate();
    this.maxDate = new Date();

    this.terminalsService.getTerminals().subscribe((terminals) => {
      terminals.forEach((terminal) => {
        if (
          this.terminalsLib.getType(terminal.type) === 'IRRIGATION' &&
          terminal.is_demo !== true
        ) {
          const irrigationTerminal = {
            vid: terminal.vid,
            name_vid: terminal.name + ' - ' + terminal.vid,
            field_name: null,
            field_vid: null,
          };
          if (terminal.field !== null) {
            irrigationTerminal.field_name = terminal.field.name;
            irrigationTerminal.field_vid = terminal.field.vid;
          }
          this.irrigationTerminals.push(irrigationTerminal);
        }
      });
      this.parseParams();
      this.ready = true;
    });

    this.availableReports = this.reportsService.getAvailableReports();
    //this.setIrrigationDevReport();
    //this.setProgramsConsumptionDevReport();
    //this.setGroupingConsumptionDevReport();
  }
  parseParams() {
    this.route.queryParams.subscribe((params) => {
      if ('selectedIrrigationTerminals' in params) {
        const sit = params.selectedIrrigationTerminals.split(';');
        // eslint-disable-next-line @typescript-eslint/prefer-for-of
        for (let x = 0; x < sit.length; x++) {
          this.irrigationTerminals.forEach((irrigationTerminal) => {
            if (sit[x] === irrigationTerminal.vid) {
              this.selectedIrrigationTerminals.push(irrigationTerminal);
            }
          });
        }
      }
      if ('simpleTable' in params) {
        this.simpleTable = params.simpleTable === 'true';
      }
      if ('selectedReport' in params) {
        this.report.setValue(params.selectedReport);
      }
      if ('tablePageItemNumber' in params) {
        this.tablePageItemNumber = parseInt(params.tablePageItemNumber, 10);
      }
      if ('rangeStart' in params && 'rangeEnd' in params) {
        this.showReportStartDate = moment(
          decodeURI(params.rangeStart),
          'YYYY-MM-DD'
        ).format('DD/MM/YYYY');
        const proposedShowReportEndDate = moment(
          decodeURI(params.rangeEnd),
          'YYYY-MM-DD'
        ).format('DD/MM/YYYY');
        if (this.showReportStartDate !== proposedShowReportEndDate) {
          this.showReportDateSplitter = '-';
          this.showReportEndDate = proposedShowReportEndDate;
        }

        this.range.setValue({
          start: moment(decodeURI(params.rangeStart), 'YYYY-MM-DD').toDate(),
          end: moment(decodeURI(params.rangeEnd), 'YYYY-MM-DD').toDate(),
        });
      }
      if ('auto' in params) {
        this.createReport();
      }
    });
  }

  createReport() {
    if (
      this.report.value.length > 0 &&
      this.selectedIrrigationTerminals.length > 0 &&
      !!this.range.value.start &&
      !!this.range.value.end
    ) {
      this.selectedReport = 'none';
      const self = this;
      // Force update on regenerate selectedReport
      // eslint-disable-next-line prefer-arrow/prefer-arrow-functions
      setTimeout(() => {
        self.selectedReport = self.report.value;
        self.createdReport = {
          report: self.report.value,
          terminals: self.selectedIrrigationTerminals,
          dateRange: {
            start: self.range.value.start,
            end: self.range.value.end,
          },
          tablePageItemNumber: this.tablePageItemNumber,
          simpleTable: this.simpleTable,
        };
      }, 100);
    } else {
      Swal.fire({
        text: this.translationsLib.get('fill_in_all_inputs_alert'),
        showConfirmButton: true,
        confirmButtonText: this.translationsLib.get('accept'),
        icon: 'error',
      });
    }
  }

  private setIrrigationDevReport() {
    this.selectedReport = 'irrigations';
    this.createdReport = {
      report: 'irrigations',
      terminals: [
        {
          //vid: '290422072409-234',
          vid: '160919080943-153',
          name_vid: 'FORCED_NAME 290422072409-234',
          field_vid: 'field_vid',
          field_name: 'Tomates 3',
        },
      ],
      dateRange: {
        start: moment().subtract(7, 'days').toDate(),
        end: moment().subtract(7, 'days').toDate(),
      },
      tablePageItemNumber: 20,
      simpleTable: this.simpleTable,
    };
  }

  private setProgramsConsumptionDevReport() {
    this.selectedReport = 'programs_consumptions';
    this.createdReport = {
      report: 'programs_consumptions',
      terminals: [
        {
          vid: '290422072409-234',
          name_vid: 'FORCED_NAME 290422072409-234',
          field_vid: 'field_vid',
          field_name: 'Tomates 3',
        },
        {
          vid: '080221084629-013',
          name_vid: 'FORCED_NAME 080221084629-013',
          field_vid: null,
          field_name: null,
        },
      ],
      dateRange: {
        start: moment().subtract(1, 'days').toDate(),
        end: moment().subtract(1, 'days').toDate(),
      },
      tablePageItemNumber: 20,
      simpleTable: this.simpleTable,
    };
  }

  private setGroupingConsumptionDevReport() {
    this.selectedReport = 'grouping_consumptions';
    this.createdReport = {
      report: 'grouping_consumptions',
      terminals: [
        {
          vid: '290422072409-234',
          name_vid: 'FORCED_NAME 290422072409-234',
          field_vid: 'field_vid',
          field_name: 'Tomates 3',
        },
        {
          vid: '080221084629-013',
          name_vid: 'FORCED_NAME 080221084629-013',
          field_vid: null,
          field_name: null,
        },
      ],
      dateRange: {
        start: moment().subtract(1, 'days').toDate(),
        end: moment().subtract(1, 'days').toDate(),
      },
      tablePageItemNumber: 20,
      simpleTable: this.simpleTable,
    };
  }

  private setDrainageDevReport() {
    this.selectedReport = 'drainages';
    this.createdReport = {
      report: 'drainages',
      terminals: [
        {
          vid: '290422072409-234',
          name_vid: 'FORCED_NAME 290422072409-234',
          field_vid: 'field_vid',
          field_name: 'Tomates 3',
        },
        {
          vid: '080221084629-013',
          name_vid: 'FORCED_NAME 080221084629-013',
          field_vid: null,
          field_name: null,
        },
      ],
      dateRange: {
        start: moment().subtract(0, 'days').toDate(),
        end: moment().subtract(0, 'days').toDate(),
      },
      tablePageItemNumber: 20,
      simpleTable: this.simpleTable,
    };
  }

  private setAlarmsDevReport() {
    this.selectedReport = 'alarms';
    this.createdReport = {
      report: 'alarms',
      terminals: [
        {
          vid: '290422072409-234',
          name_vid: 'FORCED_NAME 290422072409-234',
          field_vid: 'field_vid',
          field_name: 'Tomates 3',
        },
        {
          vid: '080221084629-013',
          name_vid: 'FORCED_NAME 080221084629-013',
          field_vid: null,
          field_name: null,
        },
      ],
      dateRange: {
        start: moment().subtract(0, 'days').toDate(),
        end: moment().subtract(0, 'days').toDate(),
      },
      tablePageItemNumber: 20,
      simpleTable: this.simpleTable,
    };
  }

  compareTerminalReportModel = (item, selected) => {
    if (selected.field_vid && item.field_vid) {
      return item.field_vid === selected.field_vid;
    }
    if (item.vid && selected.vid) {
      return item.vid === selected.vid;
    }
    return false;
  };
}
