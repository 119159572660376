import { Component, Input, OnInit } from '@angular/core';
import { NotificationsService } from '../notifications.service';
import { TranslationsLibService } from '../../services/libraries/translations-lib.service';
import { TerminalsService } from '../../terminals/terminals.service';
import { TerminalDataModel } from '../../terminals/terminals.model';
import {
  NotificationDataModel,
  NotificationTerminalDataModel,
} from '../notifications.model';
import { Router } from '@angular/router';
import { TerminalsLibService } from '../../services/libraries/terminals-lib.service';

@Component({
  selector: 'app-notifications-table',
  templateUrl: './notifications-table.component.html',
  styleUrls: ['./notifications-table.component.scss'],
})
export class NotificationsTableComponent implements OnInit {
  public terminals: TerminalDataModel[] = [];
  public notifications: NotificationDataModel[] = [];
  public notificationTerminals: TerminalDataModel[] = [];
  public selectedTerminals: TerminalDataModel[] = [];
  public selectedVids: string[] = [];
  public startingDate: string;
  public endingDate = new Date().toISOString().slice(0, 10);
  public userTimeZone: string =
    Intl.DateTimeFormat().resolvedOptions().timeZone;
  public daysAgo = 1;
  @Input() terminalVids: string[] = [];
  @Input() type: 'climate' | 'irrigation' | 'configurable';
  constructor(
    public translationsLib: TranslationsLibService,
    private notificationsService: NotificationsService,
    private terminalService: TerminalsService,
    public terminalsLib: TerminalsLibService,
    public router: Router
  ) {}

  ngOnInit() {
    this.terminalService.getTerminals().subscribe((res) => {
      if (this.terminalVids.length === 0) {
        this.terminals = res;
      } else {
        this.terminals = res.filter((terminal) =>
          this.terminalVids.includes(terminal.vid)
        );

        this.selectedTerminals = this.getNotificationTerminals();
        this.selectedVids = this.terminalVids;
      }

      this.notificationTerminals = this.getNotificationTerminals();

      this.setStartingDate();
    });
  }

  getNotifications() {
    this.notificationsService
      .getNotifications(this.startingDate, this.endingDate, this.userTimeZone, {
        terminals_vid: this.selectedVids,
      })
      .subscribe((res) => {
        this.notifications = res;
      });
  }

  setStartingDate(daysAgo: number = 1) {
    this.daysAgo = daysAgo;

    const startingDate = new Date(
      new Date().getTime() - 24 * 60 * 60 * 1000 * daysAgo
    );

    this.startingDate = startingDate.toISOString().slice(0, 10);

    if (this.selectedTerminals.length > 0) {
      this.getNotifications();
    }
  }

  getNotificationTerminals(): any {
    const notificationTerminals: NotificationTerminalDataModel[] = [];

    this.terminals.forEach((terminal) => {
      const notificationTerminal = {
        vid: terminal.vid,
        name_vid: terminal.name + ' - ' + terminal.vid,
        field_name: null,
        field_vid: null,
      };
      if (terminal.field !== null) {
        notificationTerminal.field_name = terminal.field.name;
        notificationTerminal.field_vid = terminal.field.vid;
      }
      notificationTerminals.push(notificationTerminal);
    });

    return notificationTerminals;
  }

  onSelectionModelChange(selection: TerminalDataModel[]) {
    this.selectedTerminals = selection;
    this.selectedVids = selection.map((selectedItem) => selectedItem.vid);

    if (this.selectedVids.length > 0) {
      this.getNotifications();
    } else {
      this.notifications = [];
    }
  }

  clickTable(terminalVid: string) {
    const terminal = this.terminals.find(
      (terminal) => terminal.vid === terminalVid
    );

    if (terminal.type !== 0) {
      this.router.navigate([
        this.terminalsLib.generateRoute(terminal.vid, terminal.type),
      ]);
    } else {
      this.router.navigate([
        this.terminalsLib.generateRoute(terminal.vid, terminal.type),
        {
          vid: terminalVid,
        },
      ]);
    }
  }
}
