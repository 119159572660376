import { Component, Input, OnInit } from '@angular/core';
import { TranslationsLibService } from '../../services/libraries/translations-lib.service';
import { HelpersLibService } from '../../services/libraries/helpers-lib.service';
import { ReportsService } from '../reports.service';
import {
  CreatedReportModel,
  DrainageReportDataModel,
  DrainageReportModel,
} from '../reports.model';
import { TimeLibService } from '../../services/libraries/time-lib.service';
import * as moment from 'moment';

@Component({
  selector: 'app-reports-drainages',
  templateUrl: './reports-drainages.component.html',
  styleUrls: ['./reports-drainages.component.scss'],
  animations: [],
})
export class ReportsDrainagesComponent implements OnInit {
  reportsData: DrainageReportModel[] = [];
  expandedElement: DrainageReportDataModel | null;
  @Input() createdReport: CreatedReportModel;
  drainageColumnsToDisplay = [
    'drainage',
    'tray',
    'datetime',
    'nActivation',

    'irrigatedLtr',
    'drainedLtr',
    'drainagePercent',

    'accumulatedRadiation',
    'pulses',
    'standby',
    'standbyDra',

    'volAcumIrrig',
    'volAcumDrain',

    'avgDrain',
  ];
  loader = false;
  constructor(
    private helpersLib: HelpersLibService,
    private reportsService: ReportsService,
    private timeLib: TimeLibService,
    public translationsLib: TranslationsLibService
  ) {}
  ngOnInit() {
    this.processDrainage();
  }
  public processDrainage(): void {
    const begin = this.timeLib.beginDate(
      this.createdReport.dateRange.start,
      0,
      'days'
    );
    const end = this.timeLib.endDate(
      this.createdReport.dateRange.end,
      0,
      'days'
    );
    this.loader = true;
    const terminal_id_max = this.createdReport.terminals.length - 1;
    this.createdReport.terminals.forEach((terminalReport, terminalId) => {
      this.reportsService
        .getDrainageReport(terminalReport.vid, begin, end, null)
        .subscribe((res) => {
          this.helpersLib.sendEventPageView(
            'Reports | Drainages | ' + terminalReport.name_vid
          );
          res.forEach((drainageReportDataModel, i) => {
            res[i].drainage += 1;
            res[i].tray += 1;
            res[i].datetime = moment(
              drainageReportDataModel.datetime,
              'YYYY-MM-DD HH:mm:ss'
            ).format('DD/MM/YYYY HH:mm:ss');
            res[i].irrigatedLtr = +res[i].irrigatedLtr.toFixed(2);
            res[i].drainedLtr = +res[i].drainedLtr.toFixed(2);
            res[i].volAcumIrrig = +res[i].volAcumIrrig.toFixed(2);
          });

          this.reportsData.push({
            reportName:
              this.translationsLib.get('reports_drainages') +
              ' ' +
              terminalReport.name_vid,
            terminalVid: terminalReport.vid,
            terminalNameVid: terminalReport.name_vid,
            terminalFieldName: terminalReport.field_name,
            terminalFieldVid: terminalReport.field_vid,
            begin,
            end,
            ready: true,
            drainages: res,
          });
          if (terminalId === terminal_id_max) {
            this.loader = false;
          }
        });
    });
  }
}
