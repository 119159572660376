import { Component, Input, OnInit } from '@angular/core';
import * as Highcharts from 'highcharts/highstock';
import highchartsMore from 'highcharts/highcharts-more';
import HC_accessibility from 'highcharts/modules/accessibility';
import theme from 'highcharts/themes/high-contrast-light';
import Exporting from 'highcharts/modules/exporting';
import ExportData from 'highcharts/modules/export-data';
import { IrrigationConsultingChartModel } from '../irrigation-consulting-chart.model';
import { FormControl, FormGroup } from '@angular/forms';
import { TranslationsLibService } from '../../../services/libraries/translations-lib.service';
import { ModulesService } from '../../modules.service';
import * as moment from 'moment/moment';
import { GraphicsService } from '../../../graphics/graphics.service';
theme(Highcharts);
Exporting(Highcharts);
ExportData(Highcharts);
HC_accessibility(Highcharts);
highchartsMore(Highcharts);

@Component({
  selector: 'app-irrigation-consulting-main-chart',
  templateUrl: './irrigation-consulting-main-chart.component.html',
  styleUrls: ['./irrigation-consulting-main-chart.component.scss'],
})
export class IrrigationConsultingMainChartComponent implements OnInit {
  @Input() module;
  @Input() vid;
  @Input() viewMode = 1;
  @Input() dates;
  chart;
  updateFlag = false;
  chartConstructor = 'chart';
  chartCallback;
  Highcharts = Highcharts;
  irrigationChart: any = IrrigationConsultingChartModel;
  loadedGraph = false;
  maxValue = 0;
  unit;
  range = new FormGroup({
    start: new FormControl<Date | null>(null),
    end: new FormControl<Date | null>(null),
  });
  constructor(
    public translationsLib: TranslationsLibService,
    private modulesService: ModulesService,
    private graphicsService: GraphicsService
  ) {}
  ngOnInit() {
    console.log(this.dates, this.module);
    if (this.viewMode === 3 && this.dates) {
      const inicio = new Date(this.dates.start);
      const fin = new Date(this.dates.end);
      inicio.setDate(inicio.getDate() - 2);
      fin.setDate(fin.getDate() + 2);
      this.range.patchValue({
        start: inicio,
        end: fin,
      });
    } else {
      const inicio = new Date();
      const fin = new Date();
      inicio.setDate(fin.getDate() - 2);
      this.range.patchValue({
        start: inicio,
        end: fin,
      });
    }
    this.createGraph();
  }

  createGraph() {
    const data = {
      start: moment(this.range.value.start).format('YY-MM-DD HH:mm:ss'),
      end: moment(this.range.value.end).format('YY-MM-DD HH:mm:ss'),
    };
    const tensiometer = [];
    if (this.viewMode !== 3) {
      if (
        this.module.irrigation_consulting_configuration.sensor1.physicalType ===
        0
      ) {
        tensiometer.push(
          this.module.irrigation_consulting_configuration.sensor1
        );
      } else {
        tensiometer.push(
          this.module.irrigation_consulting_configuration.sensor2
        );
      }
    } else {
      tensiometer.push(this.module.irrigation_consulting_configuration.sensor1);
      tensiometer.push(this.module.irrigation_consulting_configuration.sensor2);
    }
    const sensorsData = {
      begin: moment(this.range.value.start).format('YY-MM-DD HH:mm:ss'),
      end: moment(this.range.value.end).format('YY-MM-DD HH:mm:ss'),
      sensors: tensiometer,
      interval: 1500,
      terminal_vid: this.module.terminal_vid,
    };
    this.modulesService
      .getModuleDataByVid(this.vid, data)
      .subscribe((moduleData) => {
        this.graphicsService
          .postMultiGraph({
            method: 'post',
            terminals: [sensorsData],
          })
          .subscribe(async (tension) => {
            const xAxis = [];
            const tensionSeries = [];
            const banda1 = [];
            const banda2 = [];
            const banda3 = [];
            const banda4 = [];
            for (const sensor of tension.terminals[0].sensors) {
              this.unit = sensor.unit;
              /*if (this.unit === 'mb') {
                this.maxValue = 10000;
              } else {
                this.maxValue = 100;
              }*/
              const seriesFixed = [];
              await sensor.data.forEach((val) => {
                if(this.maxValue === 0 || this.maxValue< val.value){
                  this.maxValue = val.value;
                }
                seriesFixed.push([val.timestamp, val.value]);
                xAxis.push(val.timestamp);
              });
              const serie = {
                name: sensor.name,
                type: 'line',
                data: seriesFixed,
              };
              tensionSeries.push(serie);
            }
            moduleData.bands.forEach((band, index) => {
              if (index === 0 && tensionSeries[0].data.length > 0) {
                band[0] = tensionSeries[0].data[0][0];
              } else {
                band[0] = band[0] * 1000;
              }
            });
            const datosRiego = moduleData.bands;
            if (this.viewMode === 3) {
              moduleData.backgrounds_by_tension.forEach((tension) => {
                tension[0] = tension[0] * 1000;
                let time = tension[0];
                if (banda1.length === 0 && tensionSeries[0].data.length > 0) {
                  time = tensionSeries[0].data[0][0]; //primer valor de las series
                }
                //Los datos vienen desordenados el 3 es inicio cc el 1 es inicio de recarga y el 2 es fin de recarga
                banda1.push([time, 0, tension[3]]);
                banda2.push([time, tension[3], tension[1]]);
                banda3.push([time, tension[1], tension[2]]);
                console.log(this.maxValue)
                if(this.maxValue!==0){
                  banda4.push([time, tension[2], this.maxValue]);
                }
                else {
                  if (this.unit === 'mb') {
                    this.maxValue = 10000;
                  } else {
                    this.maxValue = 100;
                  }
                  banda4.push([time, tension[2], this.maxValue]);
                }
              });
            }
            setTimeout(() => {
              this.loadedGraph = true;
              this.irrigationChart.xAxis.categories = xAxis;
              this.irrigationChart.plotOptions = {
                scatter: {
                  dataLabels: {
                    color: 'black',
                    verticalAlign: 'bottom',
                    y: +10,
                    align: 'left',
                    inside: true,
                    enabled: true,
                  },
                  enableMouseTracking: false,
                  marker: {
                    enabled: false,
                  },
                },
              };
              let series;
              if (this.viewMode === 3) {
                series = [
                  {
                    name: this.translationsLib.get('asesor_saturation'),
                    marker: {enabled: false},
                    enableMouseTracking: false,
                    color: 'rgba(0,0,255,0.25)',
                    data: banda1,
                  },
                  {
                    color: 'rgba(0,255,0,0.25)',
                    name: this.translationsLib.get('asesor_cc'),
                    marker: {enabled: false},
                    enableMouseTracking: false,
                    data: banda2,
                  },
                  {
                    name: this.translationsLib.get('asesor_recharge_zone'),
                    marker: {enabled: false},
                    enableMouseTracking: false,
                    color: 'rgba(255,211,0,0.4)',
                    data: banda3,
                  },
                  {
                    name: this.translationsLib.get('asesor_PMP'),
                    marker: {enabled: false},
                    enableMouseTracking: false,
                    color: 'rgba(255,0,0,0.25)',
                    data: banda4,
                  },
                  /*{
                    showInLegend: false,
                    dataLabels: {
                      format: 'Punto de marchitación',
                      zIndex: 2,
                      color: 'rgb(255,0,0)',
                      style: {
                        textOutline: 'none',
                      },
                    },
                    type: 'scatter',
                    data: [Date.UTC(2021, 0, 1), (banda1[0][1] + banda1[0][2]) / 2],
                  },
                  {
                    showInLegend: false,
                    dataLabels: {
                      format: 'Mucha agua',
                      align: 'left',
                      zIndex: 2,
                      color: 'rgb(0,150,255)',
                      style: {
                        textOutline: 'none',
                      },
                    },
                    type: 'scatter',
                    data: [Date.UTC(2021, 0, 1), (banda4[0][1] + banda4[0][2]) / 2],
                  },
                  {
                    showInLegend: false,
                    dataLabels: {
                      format: 'Absorción ideal',
                      align: 'left',
                      zIndex: 2,
                      color: 'rgb(50,200,100)',
                      style: {
                        textOutline: 'none',
                      },
                    },
                    type: 'scatter',
                    data: [Date.UTC(2021, 0, 1), (banda3[0][1] + banda3[0][2]) / 2],
                  },*/
                  {
                    color: '#006cfd',
                    name: this.translationsLib.get('asesor_irrigation_bands'),
                    marker: {enabled: false},
                    fillOpacity: 0.3,
                    data: datosRiego,
                  },
                  ...tensionSeries,
                ];
              }
              else{
                series = [
                  {
                    color: '#006cfd',
                    name: this.translationsLib.get('asesor_irrigation_bands'),
                    marker: {enabled: false},
                    fillOpacity: 0.3,
                    data: datosRiego,
                  },
                  ...tensionSeries,
                ]
              }
              const that = this;
              this.irrigationChart.tooltip.formatter = function () {
                let tooltip =
                  '<b>' +
                  Highcharts.dateFormat(
                    '%d/%m/%y %H:%M',
                    Number(new Date(this.x))
                  ) +
                  '</b><br/>';
                // Recorrer cada punto de datos en el tooltip
                this.points.forEach((point) => {
                  console.log(point);
                  const seriesType = point.series.options.type;
                  if (seriesType !== 'line') {
                    tooltip +=
                      '<span style="color:' +
                      point.color +
                      '">\u25CF</span> ' +
                      point.series.name +
                      ': ' +
                      point.point.low +
                      ' - ' +
                      point.point.high +
                      ' ' +
                      that.unit +
                      '<br/>';
                  } else {
                    tooltip +=
                      '<span style="color:' +
                      point.color +
                      '">\u25CF</span> ' +
                      point.series.name +
                      ': ' +
                      point.y +
                      ' ' +
                      that.unit +
                      '<br/>';
                  }
                });

                return tooltip;
              };
              this.irrigationChart.xAxis.tickInterval = 6 * 3600 * 1000;
              this.irrigationChart.yAxis.title.text =
                this.translationsLib.get('asesor_tension');
              this.irrigationChart.series = series;
              this.updateFlag = true;
              if(this.maxValue!==0){
                this.irrigationChart.yAxis.max = this.maxValue;
              }
              else{
                if (this.unit === 'mb') {
                  this.maxValue = 10000;
                } else {
                  this.maxValue = 100;
                }
                this.irrigationChart.yAxis.max = this.maxValue;
              }
            }, 2000);
          });
      });
  }
}
