import { Component, OnInit } from '@angular/core';
import { NotificationsService } from '../notifications.service';
import { TranslationsLibService } from '../../services/libraries/translations-lib.service';

@Component({
  selector: 'app-notifications',
  templateUrl: './notifications.component.html',
  styleUrls: ['./notifications.component.scss'],
})
export class NotificationsComponent implements OnInit {
  constructor(public translationsLib: TranslationsLibService) {}

  ngOnInit() {}

  reload() {
    location.reload();
  }
}
