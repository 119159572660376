import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { RestApiService } from '../services/api/rest-api.service';
import {
  ET0Model,
  LanguagesDataModel,
  LayoutDataModel,
  SidebarDataModel,
} from './app-commons.model';
import { WeatherCalculateET0Request } from '../weather/weather.model';
import { CropDataModel, CropsDataModel } from '../fields/fields.model';

@Injectable({
  providedIn: 'root',
})
export class AppCommonsService {
  private restApiService: RestApiService;
  constructor(restApiService: RestApiService) {
    this.restApiService = restApiService;
  }
  public getCrops(forceLoad: boolean = false): Observable<CropDataModel[]> {
    return new Observable((observer) => {
      if (
        !forceLoad &&
        typeof sessionStorage.getItem('crops_data') === 'string'
      ) {
        observer.next(JSON.parse(sessionStorage.getItem('crops_data')));
      } else {
        this.restApiService.getCropsList().subscribe(
          (response) => {
            sessionStorage.setItem(
              'crops_data',
              JSON.stringify(response.items)
            );
            observer.next(response.items);
          },
          (error) => {
            observer.error(error);
          }
        );
      }
    });
  }
  public getMenu(): Observable<SidebarDataModel> {
    return new Observable((observer) => {
      this.restApiService.getMenu().subscribe(
        (response) => {
          observer.next(response);
        },
        (error) => {
          observer.error(error);
        }
      );
    });
  }
  public putMenu(data): Observable<SidebarDataModel> {
    return new Observable((observer) => {
      this.restApiService.putMenu(data).subscribe(
        (response) => {
          observer.next(response);
        },
        (error) => {
          observer.error(error);
        }
      );
    });
  }
  public getLayout(): Observable<LayoutDataModel> {
    return new Observable((observer) => {
      this.restApiService.getLayout().subscribe(
        (response) => {
          observer.next(response);
        },
        (error) => {
          observer.error(error);
        }
      );
    });
  }
  public getTranslations(): Observable<any> {
    return new Observable((observer) => {
      this.restApiService.getTranslations().subscribe(
        (response) => {
          observer.next(response);
        },
        (error) => {
          observer.error(error);
        }
      );
    });
  }
  public getLanguages(): Observable<LanguagesDataModel> {
    return new Observable((observer) => {
      this.restApiService.getLanguages().subscribe(
        (response) => {
          observer.next(response);
        },
        (error) => {
          observer.error(error);
        }
      );
    });
  }
  public calculateET0ByTerminalVid(
    terminalVid: string,
    calculateET0Request: WeatherCalculateET0Request
  ): Observable<ET0Model> {
    return new Observable((observer) => {
      this.restApiService
        .calculateEquipmentET0(terminalVid, calculateET0Request)
        .subscribe(
          (response) => {
            observer.next(response);
          },
          (error) => {
            observer.error(error);
          }
        );
    });
  }

  public calculateET0ForecastByTerminalVid(
    terminalVid: string,
    calculateET0Request: WeatherCalculateET0Request
  ): Observable<ET0Model> {
    return new Observable((observer) => {
      this.restApiService
        .calculateEquipmentET0Forecast(terminalVid, calculateET0Request)
        .subscribe(
          (response) => {
            observer.next(response);
          },
          (error) => {
            observer.error(error);
          }
        );
    });
  }
}
